/*
    This is a child modal contained within our base modal directive.
    We have access to the base modal scope which means we can access
    data on our core modal directive using $scope.modalBase.<object>
*/

angular.module(app.appName).directive("modalConfirmCrop", function () {
  var controller = [
    "$rootScope",
    "$scope",
   function (
      $rootScope,
      $scope
   ) {

      // self.init();

      $scope.close = function() {
          $scope.modalBase.data.close();
          $scope.modalBase.closeModal();
      };

      $scope.continue = function() {
        $scope.modalBase.data.continue();
      };
    },
  ];
  return {
    restrict: "E",
    // 'scope: true' tells this child directive to create its own scope usable within the current directive.
    // What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
    // modalBase object.
    scope: true,
    controller: controller,
    templateUrl: "/assets/html/directives/modal/modalConfirmCrop.html",
  };
});
