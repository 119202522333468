angular.module(app.appName)
  .factory('dRetentionResource', [
    '$resource', 'apiUrl', function ($resource, apiUrl) {
      var api = apiUrl() + '/DataRetention/';
      return $resource(api, {}, {
        get: {
          method: 'Get',
          url: api + '/Index',
          isArray: true,
          params: {
            eventId: '@eventId' // GUID
          }
        },
        post: {
          method: 'POST',
          url: api + '/Post'
        }
      });
    }
  ]);
