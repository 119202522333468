angular.module(app.appName)
	.directive('modalPremium', function () {
		var controller = [
			'$q', '$scope', 'generalStore', 'modalService', 'sittingStore', 'helpers', 'itemService', 'itemStore', '$rootScope', '$routeParams', 'pipService', 'myPicturesService', 'routeService', 'modalTypes', 'itemBuilderService',
			function ($q, $scope, generalStore, modalService, sittingStore, helpers, itemService, itemStore, $rootScope, $routeParams, pipService, myPicturesService, routeService, modalTypes, itemBuilderService) {
				var self = this;
				self.errorPrefix = 'modalPremium.js Error :: ';
				self.product = $scope.modalBase.data;
				self.productItem = {};
				self.productItem.images = [];
				$scope.amountOfPicturesRequired = 1;
				$scope.showLightbox = false;
				$scope.lastSelectedImageUrl = '';
				$scope.showUnavailableMessage = false;
				$scope.isLoading = false;

				$scope.getPrettyEventDate = function (date) {
					return helpers.prettifyDate(helpers.trimDate(date));
				}

				$scope.modalBase.disableContinueAction = function () {
					return $scope.basketDisable || self.canAddPictures();
				}
				$scope.modalBase.showContinueSpinner = function () {
					return $scope.basketDisable;
				}
				$scope.modalBase.showContinueAction = function () {
					return false;
				}

				self.getImageOnProduct = function () {
					// Returns a reference to the image with the current position on the actual public array we are working from.
					return _.findWhere(itemStore.publicProductItem.images, { position: itemStore.publicProductItem.positionId });
				}

				$scope.modalBase.continueAction = function (picture) {
					$scope.isLoading = true;
					// - Get image by positionId from productItem.images
					var image = self.getImageOnProduct();
					// - set imgIndex = picture.id
					image.imgIndex = picture.id;
					// - set previewUrl = picture.preview.url
					image.previewUrl = picture.preview.url;
					// - Set placeholder false so the productItem knows the image is a real image
					image.isPlaceholder = false;

					// Tells the server to generate a PiP for the product preview
					itemStore.publicProductItem.preview = true;

					picture.isSelected = true;
					//THIS CODE HERE SHOULD DETERMINE THE HEIGHT / WIDTH of the PLACEHOLDER
					//assume placeholder of 500x500

					// Use the same logic as
					_.each(self.productItems, function (productItem) {
						_.each(productItem.images, function (image) {
							// console.info("[modalPicture.continueAction]", { image: image, ratio: image.ratio });
							var ratio = image.ratio; // This will probably not be set yet.
							if (ratio) {
								itemBuilderService.setDimensions(ratio, productItem);
							}
						});
					});

					// pipService.setDimensions(itemStore.publicProductItem.images, itemStore.publicProductItem);


					// Post our productItem to keep it up-to-date server-side
					itemService.post(itemStore.publicProductItem).$promise.then(function (savedItem) {
						// We can't just overwrite the entire object as there are some properties that we don't want to overwrite,
						// such as Options, description etc.
						itemStore.publicProductItem.images = savedItem.images;
						if (!itemStore.publicProductItem.images[itemStore.publicProductItem.positionId - 1].previewUrl)
							itemStore.publicProductItem.images[itemStore.publicProductItem.positionId - 1].previewUrl = image.previewUrl;
						itemStore.publicProductItem.price = savedItem.price;
						itemStore.publicProductItem.finalPrice = savedItem.finalPrice;

						itemStore.publicProductItem.pip = savedItem.pip;

						$rootScope.$broadcast('rendering');
						$scope.modalBase.closeModal();
					});
				}

				$scope.modalBase.backMessageKey = 'BUTTON_CLOSE_PICTURE_PICKER';
				$scope.modalBase.backIcon = 'fa-close';

				if ($scope.modalBase.isMobile) {
					// If we're on mobile we want to override the modalBase.backAction function to go back to the products modal.
					$scope.modalBase.backAction = function () {
						// var cat = generalStore.get('currentProductCategory').value;
						$scope.modalBase.showModal(modalTypes.premium, null, true, generalStore.get('currentProductCategory').value.name);
					}
				}

				self.updateModalData = function (productItem) {
					self.productItem = productItem;
				}

				self.canAddPictures = function () {
					return _.findWhere(self.productItem.images, { isPlaceholder: true }) != null ? true : false;
				}

				// Updates the HTML for the header
				self.updateHeader = function () {
					//var selectedPicturesCount = _.where(self.productItem.images, { isPlaceholder: false }).length;
					var selectedPicturesCount = 0;
					modalService.updateHeader(selectedPicturesCount, $scope.amountOfPicturesRequired).then(function (translation) {
						$scope.modalBase.headerTitle = translation;
					});
				}

				$scope.togglePicture = function (picture) {
					// Picture was previously, and is currently, selected.
					if (picture.isSelected) {
						self.removePictureFromProduct(picture);
					} else {
						self.addPictureToProduct(picture);
					}
					$scope.showLightbox = false;
				}

				/* Pass null to sittingId and folderId to load favourites */
				$scope.loadAlbumPicturesSync = function (sittingId, folderId, productId, favourites) {
					var deferred = $q.defer();

					modalService
						.loadAlbumPictures(sittingId, folderId, productId, favourites)
						.then(function (data) {
							if (data.favourites && data.none) {
								// We have favourites so we need to initialise them
								data.none.images = myPicturesService.initFavouritesOnPictures(
									data.favourites.images,
									data.none.images
								);
							}
							console.log("[modalPremium.loadAlbumPicturesSync]", data);

							$scope.sitting = data;
							deferred.resolve($scope.sitting);
						});

					return deferred.promise;
				};

				$scope.displayLightbox = function (img) {
					$scope.lightboxImg = img;
					$scope.showLightbox = true;
					$scope.lightboxToggleImg = img.isSelected ? 'LIGHTBOX_DISCARD_IMAGE' : 'LIGHTBOX_SELECT_IMAGE';
				}
				$scope.closeLightbox = function () {
					$scope.showLightbox = false;
				}

				$scope.displayUnavailableMessage = function (img) {
					$scope.lastSelectedImageUrl = 'Album/' + img.sittingSittingId + '/' + img.imageFolderId + '/' + img.id + '/Preview';
					$scope.showUnavailableMessage = true;
				};

				$scope.closeUnavailableMessage = function () {
					$scope.showUnavailableMessage = false;
				};

				$scope.$watch('disableQuantityChange', function () {
					// Camouflage the input text to avoid it "flickering" while the numbers update
					var inputTextColor = $scope.disableQuantityChange ? "white" : "black";
					var inputs = document.getElementsByClassName("basket-quantity-input");
					angular.forEach(inputs, function (input) {
						input.style.color = inputTextColor;
					});
				})

				self.updateData = function (data) {

					data = data || {};

					self.product = data.product;
					self.route = data.route;
					$scope.productName = self.product.name;
					$scope.currentAlbumId = $scope.modalBase.getModalAlbumId(data.route);

					if (self.product) {
						// Attempts to grab the item from the itemStore.
						itemService.get(self.product.id, self.productItem.id).then(function (newItem) {
							// Update our modal data with a copy of the new item so we don't modify the reference in the store by accident.

							//This is not the correct object.
							//self.updateModalData(angular.copy(newItem));
							//THIS is -- ME
							self.updateModalData(itemStore.publicProductItem);

							//IF WE ENABLE THIS THEN WE CAN NO LONGER SELECT ANY PICTURES IF NO INITIAL picture is not selected -- ME might cause collateral bugs
							//self.productItem.images = [];
						});
					}
				};

				// We're watching the data on the modalBase because we need to update our child modal $scope objects
				// if we have a change of data. This is to ensure we don't get any data inconsistencies.
				$scope.$watch('modalBase.data', function (newData, oldData) {

					if ($scope.sitting && $scope.sitting.none.images) {
						for (var i = 0; i < $scope.sitting.none.images.length; i++) {
							self.removePictureFromProduct($scope.sitting.none.images[i]);
						}
					}

					self.updateData(newData);
				});
				self.updateData($scope.modalBase.data);

				self.init = function () {
					self.updateHeader();
				}
				self.init();

				$rootScope.$on('currentFolder', function (e, args) {

					$scope.modalBase.setModalAlbumId(args.id);

					$scope.loadAlbumPicturesSync(
						args.sittingId,
						args.id,
						self.product.id,
						args.isFavourite
					);
				});
			}
		];
		return {
			restrict: 'E',
			// 'scope: true' tells this child directive to create its own scope usable within the current directive.
			// What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
			// modalBase object.
			scope: true,
			controller: controller,
			templateUrl: '/assets/html/directives/modal/modalPremium.html'
		}
	});