angular.module(app.appName)
	.service("validationService",
        function () {
            var self = this;
            this.checkAccountInput = function (inputData) {
                var errors = [];
                var emailFormat = /^[^@]+@[^@]+\.[^@]+$/;

                angular.forEach(inputData, function (input) {
                    if (input.description.indexOf('Email') == -1) {
                        if (!input.value || input.value === "NULL") {
                            errors.push(input.description);
                        }
                    }
                    if (input.description.indexOf('Email') >= 0) {
                        if (!input.value || input.value === "NULL" || !emailFormat.test(input.value) || input.value.length < 7) {
                            errors.push(input.description);
                        }
                    }
                });

                return errors;
            },
            this.checkPasswordInput = function (passwords) {
                var errors = [];
                angular.forEach(passwords, function (password) {
                    if (!password.value) {
                        errors.push(password.description);
                    }
                });
                return errors;
            },
	        this.checkAddressInput = function (addrInputData) {
	            var errors = [];
	            angular.forEach(addrInputData, function (addrInput) {
	                if (!addrInput.value) {
	                    errors.push(addrInput.description);
	                }
	            });
	            return errors;
	        },
	        this.checkPostcode = function (postcode) {
	            return postcode.match(/[A-Z]{1,2}[0-9][0-9A-Z]?\s?[0-9][A-Z]{2}/gi);
	        },
            this.checkEquality = function (a, b) {
                return a == b;
            },
            this.isInvalidEmail = function (email) {
                var emailFormat = /^[-_a-z0-9]+(\.[-_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
                return !email || email === null || !emailFormat.test(email) || email.length < 7
            }
        }
	);