angular.module(app.appName)
	.factory('myPicturesResource', [
		'sessionService', '$resource', 'apiUrl', 'useCache', 'config',
		function (sessionService, $resource, apiUrl, useCache, config) {
			var api = apiUrl() + '/MyPictures/';
			var newApiUrl = config.apiUrl + '/MyPictures/';
			return $resource(api, {}, {
				getSitting: {
				    method: 'GET',
					cache: useCache(),
				    url: newApiUrl + 'Get',
				    params: {
				        id: '@id', // int?
				        favourite: '@favourite', // bool?
				        productId: '@productId' //int?
					},
					headers: {
						'Authorization': sessionService.getAuthHeader
					}
				},
				add: {
				    method: 'POST',
				    url: newApiUrl + 'Add',
				    params: {
						sittingId: '@sittingId', // string
						getRootFolder: '@getRootFolder' // bool
				    },
					headers: {
						'Authorization': sessionService.getAuthHeader
					}
				},
				changeStatus: {
					method: 'POST',
					url: newApiUrl + 'ChangeStatus',
					params: {
						id: '@id', // int
						type: '@type', // Enum - Image = 0, ImageFolder = 1, Sitting = 2
						status: '@status', // Enum - None = 0, Favourite = 1, Hide = 2
						adjacentId: '@adjacentId',// int?
						direction: '@direction', // Enum? - Next = 0, Previous = 1
						folder: 'folder' // bool
					},
					headers: {
						'Authorization': sessionService.getAuthHeader
					}
				},
			});
		}
	]);