angular.module(app.appName)
	.controller('loginController', [
		'$scope', '$rootScope', '$route', 'routeService',
        function ($scope, $rootScope, $route, routeService) {
            var self = this;

            //routeService.redirect('/');
        
            //$scope.$emit('showProducts', { state : false });
        }
    ]
);