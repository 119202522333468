angular.module(app.appName)
  .service('dRetentionService', ['dRetentionResource',
    function (dRetentionResource) {
      this.get = function (eventId) {
        return dRetentionResource.get({eventId: eventId});
      }

      this.post = function (model) {
        return dRetentionResource.post(model);
      }
    }
  ]);
