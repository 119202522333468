/*
    General Store is used for general storage of key-value-pairs.

    To create a new KvP, call the set method with a key as a string, and a value. 
    Note: The value can be any data type.

    When the kvp is returned, you will get the whole object with both the key and the value. In order
    to retrieve the data you stored, just grab .value from the kvp object. If you store an object
    you wil need to call .value.<YourObjectKey>.
*/

angular.module(app.appName)
    .factory('statStore', ['_',
        function (_) {
            var self = this;
            return {
                marketing: {},
                email: null,
                favouriteCount: 0,
                basketCount: 0,
                downloadCount: 0
            };
        }
    ]);