angular.module(app.appName)
	.controller('favouriteController', [
		'$rootScope', '$scope', '$timeout', '$q', 'favouriteData', 'enums', 'myPicturesService', 'albumService', 'routeService', 'sittingStore', 'helpers', '_', '$location', 'generalStore', 'statStore',
		function ($rootScope, $scope, $timeout, $q, favouriteData, enums, myPicturesService, albumService, routeService, sittingStore, helpers, _, $location, generalStore, statStore) {
			var self = this;

			$scope.$emit('showProducts', { state : false });

			$scope.favouritesCount = 0;

			$scope.dataPromise = $q.defer();
			$scope.loading = true;

			favouriteData.promise.then(function (data) {

				/* Digest cycle problem, so to allow loading to behave, put in a 0 timeout.
					 I don't like this. */
				$timeout(function () {

					data.favourites.images = myPicturesService.initFavouritesOnPictures(data.favourites.images, data.favourites.images);
					$scope.favouritesCount = data.favourites.images.length;

					sittingStore.set(null);
					generalStore.set('currentSitting', null);
					generalStore.set('currentSittingId', null);
					generalStore.set('currentAlbumId', null);

					// We're copying the favourites over the 'none' array as well so that we can stil properly access the products
					data.none = { images: [] };
					data.none.images = angular.copy(data.favourites.images);
					data.favourites.images = _.groupBy(data.favourites.images, 'eventYear');
					$scope.sitting = data;
					$scope.dataPromise.resolve();

					$rootScope.$broadcast('changeAlbum', { albumId: null });
					$rootScope.$broadcast('productsTrigger', { albumId: null });

					$rootScope.$emit('disableBuy', { disable: false });

					$scope.loading = false;
				}, 0);

			});

			$scope.goToBasket = function ($event) {
				$event.stopPropagation();
				$location.path("/Basket");
			}

			$scope.togglePictureFavouriteState = function (picture) {
				// If the picture is a favourite, we want to assign the correct enum value to pass to the changeStatus method
				self.setFavourite = picture.isFavourite ? enums.imagePreferenceStatus.none : enums.imagePreferenceStatus.favourite;
				myPicturesService.changeStatus(picture.id, enums.imagePreferenceType.image, self.setFavourite, null, null, false)
					.$promise.then(function (data) {
						// If there isn't an error, we have successfully set the favourite
						if (data.error == false) {
							myPicturesService.setFavouriteOnPicture(picture);
							self.setFavourite == true ? $scope.favouritesCount++ : $scope.favouritesCount--;
						}

					});
			}

			$scope.noFavourites = function() {
				return Object.keys($scope.sitting.favourites.images).length === 0;
			}

			$rootScope.$on('imageAddedToBasket', function (evt, args) {
				if (args.imagesAdded) {
					_.each(args.imagesAdded, function (imageToFind) {
						var imageToAdd = _.find($scope.sitting.favourites.images, { id: imageToFind.imgIndex });
						imageToAdd.basketCount > 0 ? imageToAdd.basketCount++ : imageToAdd.basketCount = 1;
					});
				}
			});

			$rootScope.$on('disableBuy', function () {
					// This is triggered on first load.  Smurf.
					$scope.loading = true;
			});

			$scope.showPreferencesReminder = function() {
				if ($scope.statStore.marketing.prefs) {
					var nullPreference = $scope.statStore.marketing.prefs.find(function (x) { 
							return x.value == null; 
					});

					if (nullPreference) {
							return true;
					}
				}

				return false;
			}
		}
	]);