angular.module(app.appName)
	.config([
		'$routeProvider',
			function ($routeProvider) {

			$routeProvider
				.when('/', {
					templateUrl: 'assets/html/areas/home/home.html',
					controller: 'homeController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						}
					}
				})
				.when('/MyAlbums/:sittingId?', {
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						sittingData: function (myPicturesService, notifyService, routeService, generalStore, $route, $q, $rootScope, $window, navService, sessionResource) {

							var deferred = $q.defer();

							/* This is wrong - resolving too soon. */
							var sittingId = $route.current.params.sittingId;

							// Strokescribe once injected a trial notice into our QR URLs and now we have to do this.
							if (sittingId) {
								sittingId = sittingId.split('*strokescribe.com')[0];
							}

							/* Up until now, my albums has been unprotected. */
							/* Now we need to know if the user has an existing */
							var hasSession = false;
							// var isLoggedIn = false;
							sessionResource.sessionValid().$promise.then(function (data) {
								hasSession = data.isValid;
								// isLoggedIn = data.isLoggedIn;

								if (! hasSession) {
									navService.home(sittingId);
										deferred.reject();
								}

								if (sittingId) {

									navService.home(sittingId);

							} else {
									myPicturesService.getSitting(null, null, null).then(function (data) {
										generalStore.get('currentAlbumId'); // Why?
										data.promise.then(function (sittingData) {

											routeService.setPreviousRoute('/MyAlbums/');

											if (sittingData.none.folders.years.length == 0) {
												routeService.redirect('/Login'); // Redirect user to login
											} else {
												currentAlbumId = generalStore.get('currentAlbumId').value;
												if (currentAlbumId) {
													var lastFolder = sittingData.none.folders.years.flatMap(function (year) {
														return Object.values(year).flat().filter(function (x) { return x.id == currentAlbumId; });
													})[0];
												}
												else {
													var lastFolder = Object.values(sittingData.none.folders.years[sittingData.none.folders.years.length - 1])
													.filter(function (x) { return x[0] && x[0].sittingId; })
													.map(function (x) { return x[0]; })
													.sort(function (x, y) { return x.eventYear > y.eventYear; })
													.find(function (x) { return x; });
												}

												routeService.setPreviousRoute('/MyAlbums/');
												// Check the user hasn't already redirected.
												if (routeService.get().name == 'MYALBUMS') {
													routeService.redirect("/Album/" + lastFolder.sittingId + "/" + lastFolder.id); // Redirect user to newest folder
												}
											}

											deferred.reject(null);

										});
									});

									return deferred;
								}
							});
						}
					}
				})
				.when('/Packs/Favs', {
					templateUrl: '/assets/html/areas/packs/pack.html',
					controller: 'packController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						}
					}
				}
				)
				.when('/Packs/:sittingId/:albumId/:productId?', {
					templateUrl: '/assets/html/areas/packs/pack.html',
					controller: 'packController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						}
					}
				}
				)
				.when('/Packs', {
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
							sittingData: function (myPicturesService, notifyService, routeService, generalStore, $route, $q, $rootScope, $window) {

								myPicturesService.getSitting(null, null, null).then(function (data) {
									generalStore.get('currentAlbumId'); // Why?
									data.promise.then(function (sittingData) {

										routeService.setPreviousRoute('/Packs');

										if (sittingData.none.folders.years.length == 0) {
											routeService.redirect('/Login'); // Redirect user to login
										} else {
											currentAlbumId = generalStore.get('currentAlbumId').value;
											if (currentAlbumId) {
												var lastFolder = sittingData.none.folders.years.flatMap(function (year) {
													return Object.values(year).flat().filter(function (x) { return x.id == currentAlbumId; });
												})[0];
											}
											else {
												var lastFolder = Object.values(sittingData.none.folders.years[sittingData.none.folders.years.length - 1])
												.filter(function (x) { return x[0] && x[0].sittingId; })
												.map(function (x) { return x[0]; })
												.sort(function (x, y) { return x.eventYear > y.eventYear; })
												.find(function (x) { return x; });
											}

											routeService.setPreviousRoute('/Packs');
											// Check the user hasn't already redirected.
											if (routeService.get().name == 'PACKS') {
												routeService.redirect("/Packs/" + lastFolder.sittingId + "/" + lastFolder.id); // Redirect user to newest folder
											}
										}

										deferred.reject(null);
									});
								});
						}
					}
				})
			.when('/Pack/:productId/:albumId?/:imageId?', {
					templateUrl: '/assets/html/areas/packs/packBuilder.html',
					controller: 'packBuilderController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Whole/:sittingId/:folderId/:productId/', {
					templateUrl: '/assets/html/areas/products/wholeBuilder.html',
					controller: 'wholeBuilderController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},

					},
				})
				.when('/Album/:sittingId/:albumId', {
					templateUrl: '/assets/html/areas/myAlbums/album.html',
					controller: 'albumController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						sittingData: function (myPicturesService, $route) {
							return myPicturesService.getSitting($route.current.params.albumId);
						},
						productData: function (productService, $route) {
							return productService.getProducts(null, $route.current.params.albumId);
						}
					}
				})
				.when('/Album/:sittingId/:albumId/:pictureId/Preview', {
					templateUrl: '/assets/html/areas/picturePreview/picturePreview.html',
					controller: 'picturePreviewController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						filterFavourites: function () {
							return false;
						},
						previewData: function (myPicturesService, $route) {
							return myPicturesService.getSitting($route.current.params.albumId);
						},
					}
				})
				.when('/Preview/:pictureId', {
					templateUrl: '/assets/html/areas/picturePreview/picturePreview.html',
					controller: 'picturePreviewController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						previewData: function (myPicturesService, $route) {
							return myPicturesService.getPreview($route.current.params.pictureId, false, false);
						},
						productData: function (productService, $route) {
							return productService.getProducts($route.current.params.pictureId, null);
						}
					}
				})
				.when('/Basket', {
					templateUrl: '/assets/html/areas/basket/basket.html',
					controller: 'basketController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						basketData: function (basketService) {
							return basketService.get();
						}
					}
				})
				.when('/Favourites', {
					templateUrl: '/assets/html/areas/favourites/favourites.html',
					controller: 'favouriteController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						imageFolderData: function (myPicturesService) {
							return myPicturesService.getSitting(null, null, null);
						},

						favouriteData: function (myPicturesService) {
							return myPicturesService.getSitting(null, true, null);
						}
					}
				})
				.when('/Favourites/:pictureId/Preview', {
					templateUrl: '/assets/html/areas/picturePreview/picturePreview.html',
					controller: 'picturePreviewController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						filterFavourites: function () {
							return true;
						},
						previewData: function (myPicturesService, $route) {
							return myPicturesService.getSitting(null, true, null);
						},
					}
				})
				.when('/Account', {
					templateUrl: '/assets/html/areas/account/account.html',
					controller: 'accountController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/AccountDetails', {
					templateUrl: '/assets/html/areas/account/accountDetails.html',
					controller: 'accountDetailsController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/ChangePassword:token?', {
					templateUrl: '/assets/html/areas/account/changePassword.html',
					controller: 'changePasswordController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Addresses', {
					templateUrl: '/assets/html/areas/account/addresses.html',
					controller: 'addressesController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Orders', {
					templateUrl: '/assets/html/areas/account/orderHistory.html',
					controller: 'ordersController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Orders/:orderId', {
					templateUrl: '/assets/html/areas/account/viewOrder.html',
					controller: 'viewOrderController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						orderData: function ($route, accountService) {
							return accountService.getOrder($route.current.params.orderId).$promise;
						}
					}
				})
				.when('/Downloads', {
					templateUrl: '/assets/html/areas/account/downloads.html',
					controller: 'downloadsController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Approvals', {
					templateUrl: '/assets/html/areas/account/approvals.html',
					controller: 'approvalsController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Login', {
					templateUrl: '/assets/html/areas/login/login.html',
					controller: 'loginController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Register', {
					templateUrl: '/assets/html/areas/register/register.html',
					controller: 'registerController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/SittingId/:sittingId?', {
					templateUrl: '/assets/html/areas/password/password.html',
					controller: 'passwordController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Premium/:productId/:imageFolderId/:imageId?', {
					templateUrl: '/assets/html/areas/products/premiumBuilder.html',
					controller: 'premiumBuilderController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/Checkout', {
					templateUrl: '/assets/html/areas/checkout/checkout.html',
					controller: 'checkoutController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						checkoutData: function (paymentService) {
							return paymentService.updateForCheckout();
						},
						accountData: function (accountService) {
							return accountService.getDetails();
						}
						// deliveryCountries: function (accountService) {
						// 	return accountService.getCountries(true);
						// },
						// invoiceCountries: function (accountService) {
						// 	return accountService.getCountries(false);
						// }
					}
				})
				.when("/WeChatPay", {
					templateUrl: '/assets/html/areas/checkout/wechatpay.html',
					controller: 'wechatpayController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						jsApiParams: function (fusionPayService, $q) {

							/* $http is pre-promise (1.3).  So create a promise, swallow the error,
								 and return an object. */

							return $q(function (resolve) {
								fusionPayService.getJsApiParams()
									.success(function (params) { resolve(params); })
									.error(function () { resolve(null); });
							});
						}
					}
				})
				.when('/Terms', {
					templateUrl: '/assets/html/areas/checkout/termsAndConditions.html',
					controller: 'termsController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					},
				})
				.when('/ThankYou', {
					templateUrl: '/assets/html/areas/checkout/thankYou.html',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					},
				})
				// .when('/Feedback/:sittingId?', {
				// 	templateUrl: '/assets/html/areas/feedback/feedback.html',
				// 	controller: 'feedbackController',
				// 	caseInsensitiveMatch: true,
				// 	resolve: {
				// 		addSessionCheck: function(sessionService) {
				// 			return sessionService.updateSessionToken();
				// 		},
				// 	}
				// })

				.when('/DisplayInvoice/get/:orderId', {
					template: '',
					controller: function ($window) {
						$window.location.reload(); // Force a reload to break HTML5 routing
					},
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					},
				})
				.when('/OrderConfirmation', {
					templateUrl: 'assets/html/areas/order/confirmation.html',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
					}
				})
				.when('/NotifyMe/:sittingPassword', {
					templateUrl: 'assets/html/areas/notifyMe/notifyMe.html',
					controller: 'notifyMeController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						sittingData: function ($route) {
							var sittingPassword = $route.current.params.sittingPassword;
							// We may expand this object later
							return {
								sittingPassword: sittingPassword
							};
						},
						customerData: function (accountService) {
							return accountService.getDetails().$promise;
						},
						sessionValidData: function (sessionResource) {
							return sessionResource.sessionValid().$promise;
						}
					}
				})
				.when("/NotifyThanks", {
					templateUrl: 'assets/html/areas/notifyMe/notifyThanks.html',
					controller: 'notifyThanksController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						sessionValidData: function (sessionResource) {
							return sessionResource.sessionValid().$promise;
						}
					}
				})
				.when("/Error/:reason?", {
					templateUrl: 'assets/html/areas/error/error.html',
					controller: 'errorController',
					caseInsensitiveMatch: true,
					resolve: {
						addSessionCheck: function(sessionService) {
							return sessionService.updateSessionToken();
						},
						reason: function ($route) {
							var reason = ($route.current.params.reason || "").toUpperCase();
							return reason;
						}
					}
				})
		}
	])
	.run(['$rootScope', '$location', '$window', 'routeService', 'generalStore', 'userService', 'statStore', 'sessionService', 'storageService',
		function ($rootScope, $location, $window, routeService, generalStore, userService, statStore, sessionService, storageService) {

			$rootScope.$on('$routeChangeStart', function () {
				$rootScope.$emit('showProducts', { state: false });
			});

			$rootScope.$on('$locationChangeStart', function (evt, next, current) {

				// $rootScope.$emit('showProducts', { state: false });

				routeService.setPreviousName(current); // Stores the current route name as previous so that we can access it to know where the user has come from
				routeService.set($location.path());
				routeService.setPreviousRoute(current);
				//Set Show products to true, so if we go to Basket(Controller) this will be overridden
				//but won't keep it hidden for other pages where we need it
				if ($rootScope.showingModal == true)
					$rootScope.showingModal = false;

				$rootScope.$emit('disableBuy', { disable: true });

				$rootScope.productsConfig = {
					class: 'single',
					colWidth: 5,
					right: true,
				};

				$rootScope.getRouteName = function () {
					return routeService.get().name.toLowerCase();
				};

				$rootScope.isMobile = false;
				self.setMobile = function () {
					$rootScope.isMobile = window.innerWidth < 1280;
				}
				self.setMobile();

				window.addEventListener('resize', function () {
					self.setMobile();
				});

				userService.getStats().$promise.then(function (stats) {
					statStore.marketing = stats.marketing;
					statStore.email = stats.email;
					statStore.individualSittingsInSession = stats.individualSittingsInSession;
					statStore.favouriteCount = stats.counts.favouriteCount;
					statStore.basketCount = stats.counts.basketCount;
					statStore.downloadCount = stats.counts.downloadCount;

					if (statStore.email) {
						storageService.set('validatedEmail', true);
					} else {
						storageService.set('validatedEmail', false);
					}

					statStore.set = true;

					console.info("[Router] statsChanged broadcast started");
					$rootScope.$broadcast('statsChanged');
					console.info("[Router] statsChanged broadcast complete");
				});

				// TODO: If navigating away from preview, clear preview in store. ONLY if not navigating to premium
				if (next.toLowerCase().indexOf('preview') < 0 && next.toLowerCase().indexOf('premium') < 0) {
					generalStore.set('currentPreviewImage', null);
				}

				if ($window.ga) { // Only send to GA if loaded
					$window.ga('send', 'pageview', { page: $location.url() });
				}
			});
		}]);