angular.module(app.appName)
    .factory('fusionPayService', [
        'apiUrl', '$http',
        function (apiUrl, $http ) {
            var self = this;

            var api = apiUrl() + "/FusionPay";

        	return {
        		getJsApiParams: function () {

                    return $http.get(api + "/GetJsApiParams");
                }
           }
        }
    ]);