angular.module(app.appName)
    .factory('translationService', [
        '$rootScope', 'storageService', '$translate', '$location', '$route',
        function ($rootScope, storageService, $translate, $location, $route) {
            self.cultureKey = 'culture';
            return {
                defaultCulture: 'en',
                getCurrentCulture: function () {
                    return storageService.get(self.cultureKey);
                },
                setCulture: function(culture) {
                    $translate.use(culture);
                    var currentCulture = this.getCurrentCulture();

                    if ($route && $route.current) {
                        var albumId = $route.current.params.albumId;
                        var pictureId = $route.current.params.pictureId;
                        var args = { forceUpdate: true };
                        if (albumId) {
                            args = { albumId: albumId };
                        }
                        if (pictureId) {
                            args = { imageId: pictureId };
                        }
                        if (currentCulture != culture) {
                            $rootScope.$broadcast('productsTrigger', args);
                            $rootScope.$broadcast('translationChange', culture);
                        }
                    }

                    $location.search('lang', null);
                    storageService.set(self.cultureKey, culture);
                }
            }
        }
    ]);

