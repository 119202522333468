angular.module(app.appName)
    .factory('userService', [
        'userResource',
        function (userResource) {
           return {
           		getStats: function () {
           			return userResource.getStats();
           		},
           		getLocation: function () {
           		    return userResource.getLocation();
           		},
                setPreferences: function (dto) {
                    return userResource.setPreferences(dto);
                }
            };
        }
    ]);