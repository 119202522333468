angular.module(app.appName)
    .directive('modalProducts', function () {
    	var controller = [
            '$rootScope', '$scope', 'generalStore', '_',
            function ($rootScope, $scope, generalStore, _) {
            	var self = this;
            	// Gives us a local reference to the data on the base scope
            	self.categoryData = angular.copy($scope.modalBase.data);
            	$scope.currentProductCategory = generalStore.get('currentProductCategory').value;
            	if (generalStore.get('previewImageAllowedProducts').value) {
            		$scope.currentProductCategory.products = generalStore.get('previewImageAllowedProducts').value;
            	};

            	$rootScope.$watch('newCategories', function () {
            		$scope.currentProductCategory.products = _.findWhere($rootScope.newCategories, { id: $scope.currentProductCategory.id }).products;
            	});

            	self.init = function () {
            		$scope.modalBase.backMessageKey = null;
            		$scope.modalBase.backIcon = null;
					$scope.modalBase.headerTitle = $scope.currentProductCategory.name;
            	}();
            }
    	];
    	return {
    		restrict: 'E',
    		// 'scope: true' tells this child directive to create its own scope usable within the current directive.
    		// What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
    		// modalBase object.
    		scope: true,
    		controller: controller,
    		templateUrl: '/assets/html/directives/modal/modalProducts.html'
    	}
    });