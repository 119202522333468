angular.module(app.appName)
    .controller('viewOrderController', [
        '$scope', '$rootScope', '$q', 'orderData', '$route', 'accountService', 'notifyService', 'routeService', 'cacheService', '$translate', '_', 'config', 'helpers',
        function ($scope, $rootScope, $q, orderData, $route, accountService, notifyService, routeService, cacheService, $translate, _, config, helpers) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            self.paymentStatuses = [
                'CCPENDING',
                'PENDING',
                'COMPLETED',
                'FAILED',
                'COMMUNICATION',
                'FRAUD',
                'PREAUTH',
                'SERVERFAIL',
                'PAYPALPENDING'
            ];

            $scope.downloadStatus = {
				available: 0,
				processing: 1,
                onHold: 2,
				expired: 3
			}

            $scope.startDownload = function(download) {
                accountService.getDownloadUrl(download.orderItemId).$promise
                .then(function(data){
                    window.location.assign(data.url);
                });
            }

            $scope.onHold = function(paymentStatus) {
                if (self.paymentStatuses[paymentStatus] == 'COMPLETED') {
                    return false;
                } else {
                    return true;
                }
            }

            self.blurbPrefix = 'ORDERBLURB_';

            $scope.loadingPage = $q.defer();

            self.resolves = {
                orderData: orderData,
                downloadData: $q(function (resolve) {
                    accountService.getDownloads().$promise.then(function (data) {
                        resolve(data);
                    });
                })
            };

            $scope.heading = '';
            $scope.config = config;
            $scope.showReorder = $route.current.params.reorder;

            $scope.clone = function (itemId) {
                $scope.loading = true;
                accountService.cloneOrder(itemId).$promise.then(function (data) {
                    if (data.success == true) {
                        cacheService.obliterate();
                        notifyService.success('ADD_TO_BASKET_SUCCESS');
                        routeService.redirect("/Basket");
                    }
                });
            };

            /* Once all resources are loaded, build a key from the prefix, the payment provider, and
               the current payment status (as a string).
               Failed payments display a single failed payment message.
               If a blurb exists for this key, then the order is on hold. */
            $q.all(self.resolves).then(function (resolved) {
                $scope.loadingPage.resolve();
                $scope.order = resolved.orderData;

                // Group child items into parent items.
                var groupedOrderItems = [];
                var currentParent = null;
                for (var item of $scope.order.items) {
                    if (item.parentId == null) {
                        if (currentParent !== null) {
                            groupedOrderItems.push(currentParent);
                        }
                        currentParent = item;
                        currentParent.childItems = [];
                    } else {
                        if (currentParent !== null && item.parentId == currentParent.itemProductId) {
                            currentParent.childItems.push(item);
                        }
                        else {
                            // Lone child item.
                            currentParent = item;
                            currentParent.childItems = [];
                            groupedOrderItems.push(currentParent);
                            currentParent = null;
                        }
                    }
                }
                if (currentParent !== null) {
                    groupedOrderItems.push(currentParent);
                }

                // Group downloads into order items.
                var downloads = resolved.downloadData;
                angular.forEach(downloads, function (download) {
                    download.orderDate = helpers.prettifyDate(helpers.trimDate(download.orderDate));
                    if (download.zipTimeout) {
                        download.zipTimeout = helpers.prettifyDate(helpers.trimDate(download.zipTimeout));
                    } else {
                        download.zipTimeout = "-";
                    }

                    if (download.zipUploaded == null) {
                        if (orderData && $scope.onHold($scope.order.paymentStatus)) {
                            download.status = $scope.downloadStatus.onHold;
                        } else {
                            download.status = $scope.downloadStatus.processing;
                        }
                    } else if (download.expired) {
                        download.status = $scope.downloadStatus.expired;
                    } else {
                        download.status = $scope.downloadStatus.available;
                    }
                });

                for (var item of groupedOrderItems) {
                    item.download = downloads.find(function (download) {
                        return download.orderItemId == item.id;
                    });
                    for (var childItem of item.childItems) {
                        childItem.download = downloads.find(function (download) {
                            return download.orderItemId == childItem.id;
                        });
                    }
                }

                $scope.order.items = groupedOrderItems;

                $scope.order.paymentStatusKey = self.paymentStatuses[$scope.order.paymentStatus];

                switch ($scope.order.paymentStatusKey) {
                    case 'CCPENDING':
                    case 'PENDING':
                    case 'PAYPALPENDING':
                    case 'COMPLETED':
                        $scope.heading = "THANK_YOU_FOR_YOUR_ORDER";
                        $scope.blurbKey = self.blurbPrefix + $scope.order.paymentProvider.toUpperCase() + '_' + $scope.order.paymentStatusKey;
                        $scope.order.onHold = ! _.isUndefined($translate.getTranslationTable()[$scope.blurbKey] ||
                            $translate.getTranslationTable("en")[$scope.blurbKey]);
                        break;
                    case 'FAILED':
                    case 'COMMUNICATION':
                    case 'FRAUD':
                    case 'PREAUTH':
                    case 'SERVERFAIL':
                        $scope.heading = "ORDER_FAILED";
                        $scope.order.onHold = true;
                        $scope.blurbKey = "ORDERBLURB_FAILED";
                        break;
                    default:
                        console.error("[viewOrderController] Unknown payment status", $scope.order.paymentStatusKey);
                        break;
               }
           });
        }
    ]);