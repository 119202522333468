angular.module(app.appName)
	.controller('packBuilderController', [
		'$scope', '$route', '$q', 'routeService', 'generalStore', 'productService', 'albumService', 'myPicturesService', '$rootScope', 'itemService', 'itemStore', 'sittingStore', 'modalTypes', 'enums', '$timeout', 'pipService', 'previewService',
		function ($scope, $route, $q, routeService, generalStore, productService, albumService, myPicturesService, $rootScope, itemService, itemStore, sittingStore, modalTypes, enums, $timeout, pipService, previewService) {
			var self = this;

			$scope.$emit('showProducts', { state : false });

			$scope.loadingPage = $q.defer();
			$scope.itemStore = itemStore;
			$scope.modalTypes = modalTypes;
			$scope.toggleDescription = false;
			$scope.toggleDescriptionLabel = 'SHOW_MORE';

			$scope.previewRoute = {};

			$scope.setupItem = function (item, image) {
				// Setup our productItem position and isChangingPicture before we try and modify the image.
				// This is so we know which image we are changing.


				item.positionId = image.position;
				item.isChangingPicture = !image.isPlaceholder;

			}

			$scope.cancel = function () {
				//routeService.getPreviousRoute().toUpperCase().indexOf('pack') < 0 ? routeService.getPreviousRoute() : '/MyAlbums/'))
				routeService.redirect();
			}

			// Sets up the options in the UI
			$scope.setupOptions = function (optionGroup) {
				return _.findWhere(optionGroup.items, { selected: true });
			}

			$scope.startPicker = function (image, item) {

				$scope.setupItem(item, image);

				// Get product from
				var childProduct = self.product.children.find(function (x) { return x.id == item.productId; });

				$rootScope.modal.showModal(
					modalTypes.pack,
					{
						product: childProduct, // need to use the child product
						item: item,
						currentPreview: null,
						route: $scope.previewRoute,
					},
					false);
			};

			$scope.updateProductOptions = function (productItem, option, optionItem) {

				$scope.disableOptions = true;
				option.loading = true;
				var currentSelectedOption = _.findWhere(option.items, { selected: true });
				if (currentSelectedOption) {
					currentSelectedOption.selected = false;
				}
				// Checks we have selected a real option and not the null "Please Select..."
				if (optionItem != null) {
					optionItem.selected = true;

					itemService.post(itemStore.publicProductItem).$promise.then(function (savedItem) {
						// We need to update the productItem to reflect our changes


						itemStore.publicProductItem.options = savedItem.options;
						itemStore.publicProductItem.pip = savedItem.pip;
						itemStore.publicProductItem.price = savedItem.price;
						itemStore.publicProductItem.finalPrice = savedItem.finalPrice;
						itemStore.set(savedItem.productId, itemStore.publicProductItem);
						generalStore.set('currentProductItem', itemStore.publicProductItem);
						$scope.checkIncompleteOptions(productItem, savedItem);
						option.loading = false;
						$scope.disableOptions = false;
					});
				}
			}

			self.backupProductOptions = function (productItem) {
				self.productOptionsBackup = angular.copy(productItem.options);
			}

			$scope.resetProductItemOptions = function () {
				itemStore.publicProductItem.options = self.productOptionsBackup;
				$scope.checkIncompleteOptions(itemStore.publicProductItem);
			}

			$scope.checkIncompleteOptions = function (productItem) {
				productItem.hasIncompleteOptions = _.find(productItem.options, { completed: false }) != undefined;
				return productItem.hasIncompleteOptions;
			}

			self.checkIncompletePictures = function () {

				// A child is complete if we have no placeholders.
				return itemStore.childProductItem.find(function (child) {
					return child.images.find((function (image) { return image.imgIndex == null; })) != undefined;
				}) != undefined;
			}

			$scope.getPreviewUrl = function (item) {
				if (!item)
					return null; // We have no item in the store. Return nothing.

				// We should use the pip if:
				// a. It exists on the productItem
				// AND
				// b. We have at least 1 image set on the productItem.
				var shouldUsePip = item.pip != null;
				//the below was added but now kian has done the work to return an empty pip creation so no longer needed
				//&& _.findWhere(itemStore.publicProductItem.images, { isPlaceholder: false }) != null ? true : false;

				if (shouldUsePip)
					return item.pip.url;
				else
					return item.productThumbnailUrl;
			}

			$scope.disableBuy = function () {

				// For a pack, disable if images are incomplete.
				return itemStore.publicProductItem == null || itemStore.publicProductItem.showSpinner || $scope.checkIncompleteOptions(itemStore.publicProductItem) || self.checkIncompletePictures();
			}

			self.getImageOnProduct = function (isPreview) {
				// We only have one image slot, just grab it.
				if (itemStore.publicProductItem.images && isPreview) {
					return itemStore.publicProductItem.images[0];
				}

				// Returns a reference to the image with the current position on the actual public array we are working from.
				return _.findWhere(itemStore.publicProductItem.images, { position: itemStore.publicProductItem.positionId });
			}

			$scope.addToBasket = function () {
				// Show our spinner on our buy button
				itemStore.publicProductItem.showSpinner = true;
				// Save/Persist this item so that it has an id for adding to basket

				// For the sake of saving, set the image from the first product to the
				// parent product
				itemStore.publicProductItem.images = angular.copy(itemStore.childProductItem[0].images);

				itemStore.publicProductItem.parent = true;
				_.each(itemStore.childProductItem, function(child) { child.parent = false });

				itemService.save([itemStore.publicProductItem, ...itemStore.childProductItem], 1, true).then(function (itemToSave) {

					itemStore.publicProductItem.showSpinner = false;
					var previousRoute = routeService.getPreviousRoute();
					if (previousRoute.includes('Album')) {
						previousRoute = previousRoute.substring(previousRoute.indexOf('Album') - 1);
					}
					else {
						previousRoute = 'Packs';
					}

					routeService.redirect(previousRoute);
				});
			}

			// Determines if the processType for a productItem is any of the given processTypes types.
			$scope.processTypeIs = function(productItem, processTypes) {
				var selectedOptionItems = productItem.options.map(x => x.items).flat().filter(x => x.selected);
				return selectedOptionItems.filter(optionItem => processTypes.indexOf(optionItem.processType) > -1).length > 0;
			};

			// Makes sure the Product Item has the proper Product data
			self.setupProductDataOnProductItem = function (item, product) {
				item.shortDescription = product.shortDescription;
				item.longDescription = product.description;
				item.name = product.name;
			}

			$scope.toggleLongDescription = function () {
				$scope.toggleDescription = !$scope.toggleDescription;
				$scope.toggleDescriptionLabel = !$scope.toggleDescription ? 'SHOW_MORE' : 'SHOW_LESS';
			}

			self.setupProductData = function (productId) {
				// Setup a new promise
				var deferred = $q.defer();

				// Our product in the store doesn't match our id. We must get it from the server
				productService.getProductAndChildrenById(productId).$promise.then(function (product) {

					self.product = product;
					generalStore.set('currentProduct', self.product);
					return self.setupSittingData().then(function () {

						// Resolve and return the promise
						deferred.resolve();

					});
				});

				return deferred.promise;
			}

			self.setupSittingData = function () {
				// Setup a new promise
				var deferred = $q.defer();

				// Attempt to grab a sitting from the store
				var sitting = sittingStore.get();
				if (!sitting) {
					// We have no sitting, get it from the server
					return myPicturesService.getSitting().then(function (sittingFromServer) {
						//Set the sitting on our store
						//sittingStore.set(sittingFromServer);

						// Resolve and return the promise
						deferred.resolve();
					});
				} else {

					// Resolve and return the promise
					deferred.resolve();
				}

				return deferred.promise;
			}

			$rootScope.$on('rendering', function () {
				$scope.loading = true;
				$timeout(function () {
					$scope.loading = false;
				}, 0);
			});

			$rootScope.$on('cultureChange', function (data) {
				productService.getProducts(null, $route.current.params.albumId).$promise.then(function (data) {
					if (itemStore.publicProductItem) {
						var itemId = itemStore.publicProductItem.productId;
						var translatedItem = _.findWhere(data.products, { id: itemId });
						itemStore.publicProductItem.productName = translatedItem.name;
						itemStore.publicProductItem.description = translatedItem.description;
					}
				});
			});

			$scope.init = function () {
				/* Set up items on page */
				var productId = $route.current.params.productId;
				var albumId = $route.current.params.albumId;
				var imageId = $route.current.params.imageId;

				/* Find routing details*/
				var preview = generalStore.get('currentPreviewImage').value;
				self.previousRoute = routeService.getPreviousRoute();

				self.setupProductData(productId).then(function () {
					// If we don't have a public product item, we need to create one

					// We need to build the parent item *and* the child items.
					var parentPromise =

						$q(function (resolve) {
							itemService.get(productId, null, imageId, null, albumId).then(function (newItem) {
								itemStore.publicProductItem = newItem; // Parent item.
								self.setupProductDataOnProductItem(newItem, self.product);
								resolve(newItem);
							})
						});

					// We actually create multiple items in this approach.

					itemStore.childProductItem = [];

					var childPromises = self.product.children.map(function (child) {

						return $q(function (resolve) {
							itemService.get(child.id, null, imageId, null, albumId).then(function (newItem) {
								itemStore.childProductItem.push(newItem);
								self.setupProductDataOnProductItem(newItem, child);
								resolve(newItem);
							});
						});
					});

					$q.all([...childPromises, parentPromise]).then(function (resolves) {
						// childProductItems are returned in an undefined order. Reorder to match self.product.children.
						itemStore.childProductItem.sort(function(x, y) {
							var indexX = self.product.children.findIndex(function(child) {return child.id === x.productId;});
							var indexY = self.product.children.findIndex(function(child) {return child.id === y.productId;});
							return indexX - indexY;})

						$scope.productName = resolves[0].name;

						$scope.loadingPage.resolve();
					});
				});
			};

			$scope.init();
		}
	]);