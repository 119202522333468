

angular.module(app.appName).directive("modalPackReminder", function () {
    var controller = [
      '$rootScope',
      '$scope',
      'routeService',
     function (
        $rootScope,
        $scope,
        routeService
     ) {
        $scope.basketDisable = true;
        // These seem to persist from other modals so am resetting them.
        $scope.modalBase.headerTitle = '';
        $scope.modalBase.backMessageKey = null;
        $scope.modalBase.backIcon = null;
  
        $scope.goToPacks = function() {
          if ($scope.modalBase.data.product) {
            $rootScope.$broadcast('buildProduct', { product: $scope.modalBase.data.product, forceShowModal: true });
          }
          $scope.modalBase.closeModal();
        };
  
        $scope.continue = function() {
          $scope.modalBase.closeModal();
        };
      },
    ];
    return {
      restrict: "E",
      // 'scope: true' tells this child directive to create its own scope usable within the current directive.
      // What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
      // modalBase object.
      scope: true,
      controller: controller,
      templateUrl: "/assets/html/directives/modal/modalPackReminder.html",
    };
  });
  