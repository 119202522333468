angular.module(app.appName)
	.factory('paymentErrorResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl();
		    return $resource(api, {}, {
	        post: {
		            method: 'POST',
		            url: api + '/PaymentError/Post'
		        }
		    });
		}
	]);