angular.module(app.appName)
	.controller('pageController', [
		'$scope', '$rootScope', '$route', 'tutorialService', 'userService', 'statStore', '$location', 'sessionResource', 'navService', 'storageService', 'routeService', 'translationService', 'generalStore',
        function ($scope, $rootScope, $route, tutorialService, userService, statStore, $location, sessionResource, navService, storageService, routeService, translationService, generalStore) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            /* On first load, if a culture is present, set the culture */
            var culture = $location.search()["lang"]; // Using location rather than root in case route is not yet set.
            if (culture) {
                translationService.setCulture(culture);
                $rootScope.isRtlLanguage = culture == 'ar';
                translationService.setCulture(culture);
                $rootScope.$emit('languageChanged', culture);
                generalStore.set('culture', culture);
            }


            $scope.routeService = routeService;
            // userService.getStats().$promise.then(function (stats) {
            //     statStore.favouriteCount = stats.favouriteCount;
            //     statStore.basketCount = stats.basketCount;
            //     $rootScope.$broadcast('statsChanged');
            // });

            if (! window.allowDebug) {
                document.addEventListener("contextmenu", function (e) {
                    e.preventDefault();
                    document.body.style.cursor = "not-allowed";
                    setTimeout(function () {
                        document.body.style.cursor = "auto";
                    }, 750);
                }, false);
            }

            var tutorialAutoStart = function () {
                //$scope.getClient().$promise.then(function (client) {
                //    if (!client.CompletedTutorial) {
                tutorialService.start();
                //client.CompletedTutorial = true;
                //clientService.save(client);
                //}
                //)};
            };



            //tutorialAutoStart();

            $scope.startTutorial = function () {
                tutorialService.startByButton();
            };

            if (document.cookie.indexOf("AUTH") > 0) {
            	storageService.set('authenticated', true);
            	$rootScope.$broadcast('authenticationSet');
            } else {
            	storageService.set('authenticated', false);
            	$rootScope.$broadcast('authenticationSet');
            }
        }
	]);