angular.module(app.appName)
	.directive('radioButton',
		function () {
			var controller = [
				'$scope',
				function ($scope) {
					$scope.isChecked = function () {
						return isChecked
					}
				}
			];

			return {
				scope: {
					titleKey: '@',
				},
				controller: controller,
				templateUrl: '/assets/html/directives/radioButton.html'
			}
		}
	);