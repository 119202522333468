angular.module(app.appName)
	.directive('modalPack', function () {
		var controller = [
			'$q', '$scope', 'generalStore', 'modalService', 'sittingStore', 'basketService', 'helpers', 'itemService', 'itemStore', '$rootScope', '$routeParams', 'pipService', 'myPicturesService', 'routeService', 'modalTypes', '$timeout', 'productService', 'itemBuilderService',
			function ($q, $scope, generalStore, modalService, sittingStore, basketService, helpers, itemService, itemStore, $rootScope, $routeParams, pipService, myPicturesService, routeService, modalTypes, $timeout, productService, itemBuilderService) {
				var self = this;
				self.errorPrefix = 'modalPicturePicker.js Error :: ';

				self.product = $scope.modalBase.data;
				self.productItem = {};
				self.productItem.images = [];
				$scope.amountOfPicturesRequired = 1;
				$scope.showLightbox = false;
				$scope.isLoading = false;

				$scope.getPrettyEventDate = function (date) {
					return helpers.prettifyDate(helpers.trimDate(date));
				}

				$scope.modalBase.disableContinueAction = function () {
					return $scope.basketDisable || self.canAddPictures();
				}
				$scope.modalBase.showContinueSpinner = function () {
					return $scope.basketDisable;
				}
				$scope.modalBase.showContinueAction = function () {
					return false;
				}

				self.getImageOnProduct = function () {

					// Returns a reference to the image with the current position on the actual public array we are working from.
					return _.findWhere(self.productItem.images, { position: self.productItem.positionId });
				}


				$scope.modalBase.continueAction = function (picture) {
					$scope.isLoading = true;
					// - Get image by positionId from productItem.images
					picture.isSelected = true;
					//THIS CODE HERE SHOULD DETERMINE THE HEIGHT / WIDTH of the PLACEHOLDER
					//assume placeholder of 500x500

					/* Surely we want to set the image and set the dimensions before posting? */
					$scope.ratio = picture.ratio; // set ratio

					itemService
						.get(self.product.id, null, null, picture.id, picture.imgFolderId, $scope.ratio)
						.then(function (productItem) {

              if (!productItem.images || !productItem.images.length) {
                // If no images are defined, create an array
                productItem.images = [{}];
              }
              productItem.images[0].imgIndex = picture.id;
              productItem.images[0].isPlaceholder = false;
              itemBuilderService.setDimensions(
                itemBuilderService.currentPreviewRatio(self.currentPreview),
                productItem
              );

							itemService.post(productItem).$promise.then(function (savedItem) {

								self.productItem = savedItem;

								// Update the childitem, identify by productId.
								var child = itemStore.childProductItem.find(function (child) { return child.productId == self.productItem.productId; });
								var index = itemStore.childProductItem.indexOf(child);

								itemStore.childProductItem[index] = self.productItem;

								$rootScope.$broadcast('rendering');
								$scope.modalBase.closeModal();
							});
						});
				}

				$scope.modalBase.backMessageKey = 'BUTTON_CLOSE_PICTURE_PICKER';
				$scope.modalBase.backIcon = 'fa-close';

				if ($scope.modalBase.isMobile) {
					// If we're on mobile we want to override the modalBase.backAction function to go back to the products modal.
					$scope.modalBase.backAction = function () {
						$scope.modalBase.showModal(modalTypes.products, null, true, generalStore.get('currentProductCategory').value.name);
					}
				}

				self.updateModalData = function (productItem) {
					self.productItem = productItem;
				}

				self.canAddPictures = function () {
					return _.findWhere(self.productItem.images, { isPlaceholder: true }) != null ? true : false;
				}

				// Updates the HTML for the header
				self.updateHeader = function () {
					//var selectedPicturesCount = _.where(self.productItem.images, { isPlaceholder: false }).length;
					var selectedPicturesCount = 0;
					modalService.updateHeader(selectedPicturesCount, $scope.amountOfPicturesRequired).then(function (translation) {
						$scope.modalBase.headerTitle = translation;
					});
				}

				$scope.togglePicture = function (picture) {
					// Picture was previously, and is currently, selected.
					if (picture.isSelected) {
						self.removePictureFromProduct(picture);
					} else {
						self.addPictureToProduct(picture);
					}
					$scope.showLightbox = false;
				}

				$scope.loadAlbumPicturesSync = function (sittingId, folderId, productId, favourites) {

					productId = productId || self.product.productId;

					var deferred = $q.defer();
					return modalService.loadAlbumPictures(sittingId, folderId, productId, favourites).then(function (data) {
						if (data.favourites && data.none) {// We have favourites so we need to initialise them
							data.none.images = myPicturesService.initFavouritesOnPictures(data.favourites.images, data.none.images);
						}
						//data.none.images = myPicturesService.selectedToTop(data.none.images);
						$scope.sitting = data;
						deferred.resolve($scope.sitting);
						return deferred;
					});
				};

				$scope.displayLightbox = function (img) {
					$scope.lightboxImg = img;
					$scope.showLightbox = true;
					$scope.lightboxToggleImg = img.isSelected ? 'LIGHTBOX_DISCARD_IMAGE' : 'LIGHTBOX_SELECT_IMAGE';
				}

				$scope.closeLightbox = function () {
					$scope.showLightbox = false;
				}

				self.updateData = function (data) {
					data = data || {};

					self.product = data.product;
					self.route = data.route;

					$scope.currentAlbumId = $scope.modalBase.getModalAlbumId(data.route);

					if (self.product) {
						self.updateModalData(data.item); // I think.
					}
				};

				self.init = function () {
					self.updateHeader();
				};
				self.init();

				// We're watching the data on the modalBase because we need to update our child modal $scope objects
				// if we have a change of data. This is to ensure we don't get any data inconsistencies.
				$scope.$watch('modalBase.data', function (newData, oldData) {

					if ($scope.sitting && $scope.sitting.none.images) {
						for (var i = 0; i < $scope.sitting.none.images.length; i++) {
							self.removePictureFromProduct($scope.sitting.none.images[i]);
						}
					}

					self.updateData(newData);
				})
				self.updateData($scope.modalBase.data);


				self.initCallback = function () {
					if ($scope.sitting.none.images) {
						// We are viewing an individual album
						sittingStore.saveProductAlbum(self.product.id, $scope.sitting);
					}
					else if ($scope.sitting.none.folders && $scope.sitting.none.folders.years.length > 0) {
						// We are viewing all albums for the sitting
						sittingStore.saveProductSitting($scope.sitting);
					}

					$scope.loadAll = false;
					$scope.isLoading = false;
				}

				$rootScope.$on('currentFolder', function (e, args) {

					$scope.modalBase.setModalAlbumId(args.id);

					$scope.loadAlbumPicturesSync(
						args.sittingId,
						args.id,
						self.product.id,
						args.isFavourite
					);
				});
			}

		];
		return {
			restrict: 'E',
			// 'scope: true' tells this child directive to create its own scope usable within the current directive.
			// What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
			// modalBase object.
			scope: true,
			controller: controller,
			templateUrl: '/assets/html/directives/modal/modalPremium.html'
		}
	});