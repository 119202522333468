angular.module(app.appName)
    .service('tutorialService', ['introJs', '$q', '$timeout', 'storageService', function (introJs, $q, $timeout, storageService) {
        var translationStub = $q.defer();
        var seenAlbumTutorial = storageService.get('seenAlbumTutorial');

        if (!seenAlbumTutorial) {
            translationStub.resolve("This is your Albums, if you have albums from previous years you can see them by scrolling down.");
            storageService.set('seenAlbumTutorial', true);
        } 
       

        // First set up all of the steps of the tutorial
        function createStep(index, translation) {
            return {
                index: index,
                translation: translationStub.promise,
                ready: $q.defer()
            };
        }
        var steps = {};
        ['Welcome']
            .forEach(function (key, i) { steps[key] = createStep(i + 1, key); });


        // Provide a function for each directive to latch onto in order to apply introJS attributes 
        this.register = function (key) {
            var step = steps[key];
            step.registered = true;
            return step.translation
                .then(function (t) {
                    return { index: step.index, translation: t };
                });
        };

        // Provide a function for each directive to indicate that it has successfully applied the attributes
        this.ready = function (key) {
            steps[key].ready.resolve(true);
        };

        // Accumulate all 'ready' signals into array
        var allReady = [];
        for (var step in steps) {
            allReady.push(steps[step].ready.promise);
        }

        // Expose one final function to signal that the tutorial is to start
        var startDeferred = $q.defer();
        allReady.push(startDeferred.promise);
        this.start = function () {
            startDeferred.resolve(true);
        };

        this.startByButton = function () {
            introJs()
                        .setOption('scrollToElement', true)
                        .start();
        };

        // Start the tutorial when all signals have been given
        $q.all(allReady)
            .then(function () {
                $timeout(function () {
                    introJs()
                        .setOption('scrollToElement', true)
                        .start();
                }, 500);
            });
    }])
    .directive('tutorialStep', ['tutorialService',
        function (tutorialService) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var key = attrs.tutorialStep;

                    tutorialService.register(key)
                        .then(function (step) {
                            attrs.$set('data-step', step.index);
                            attrs.$set('data-intro', step.translation);
                            tutorialService.ready(key);
                        });
                }
            };
        }]);
