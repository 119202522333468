angular.module(app.appName)
  .directive('addressModule', function () {
    return {
      require: ['^^checkoutContainer', '^^checkoutModule'],
      scope: {
        filterByCountry: '=?', /* Whether or not we should filter out the current country from addresses */
        property: '@',    /* Address model on property. */
        setProperty: '@', /* Property to set on model on continue. */
        setIfProperty: '@', /* Property to set on model if condition is met */
        setIfCondition: '@', /* Property to check to see if 'if' property should be set. */
        settable: '=', /* Is the user allowed to change the address value? */
        onContinue: '&',
      },
      controller: ['$scope', 'accountService', '$timeout', 'fetchifyService', 'enums', '$q', '$rootScope',
        function ($scope, accountService, $timeout, fetchifyService, enums, $q, $rootScope) {

          $scope.addresses = [];
          $scope.fetchifyAddress = {};

          /* Let's start from there */
          var fetchifyResultSelected = function (c2a, elements, address) {

            console.info('Address selected', address);

            $scope.$apply(function () {
              var model = $scope.getModel();

              $scope.fetchifyAddress = address;
              $scope.fetchifyAddress.fullName = `${model.user.forename} ${model.user.surname}`;
              $scope.change(4);
            });
          };

          $scope.getSelectedIso = function () {
            ($scope.selectedCountry || {}).iso2;
          };

          // How can we get the id of the component we
          // actually care about?
          $scope.initializeFetchify = function () {
            /* Fetchify needs for form to be rendered first. */
            /* Using a timeout to circumvent this problem. */
            $timeout(function () {

              var searchBarId = 'search-bar_' + $scope.$id;
              console.log(`[addressModule] [initializeFetchify] ${searchBarId}`);

              fetchifyService.cc.then(function (cc) {

                cc.attach({
                  search: searchBarId,
                },
                  {
                    onResultSelected: fetchifyResultSelected
                  });

                // Go and get the countrry
                var isoCode = $scope.selectedCountry.iso2;
                console.info(`[addressModule] [initializeFetchify] init fetchify country ${isoCode}`);
                cc.selectCountry(isoCode);
              })
            }, 500); // :-(
          };

          // $rootScope.$on('translationChange', $scope.initializeFetchify);

          var filterListByCountry = function (list, property) {
            if ($scope.filterByCountry === true) {
              if (!!eventExcludeCountry()) {
                var excludedCountryIso2 = $scope.getExcludedCountryIso2();
                return list.filter(function (item) {
                  return item[property] !== excludedCountryIso2
                });
              }
            }

            return list;
          }

          /* We now will always have some countries with a delivery restriction. */
          var mapListByCountry = function (list, property) {
            if ($scope.filterByCountry === true) {
              if (!!eventExcludeCountry()) {
                var excludedCountryIso2 = $scope.getExcludedCountryIso2();
                return list.map(function (item) {

                  if (item[property] === excludedCountryIso2) {
                    item.deliveryFilter = enums.deliveryFilter.eventOnly // Deliver To Event.
                  }

                  return item;
                });
              }
            }

            return list;
          }

          var eventExcludeCountry = function () {
            return $scope.getModel().deliverToSchool === enums.deliverToSchool.yesAndFilter;
          }

          var setAddresses = function (result) {
            $scope.addresses = filterListByCountry(result.list, 'countryIso');
            $scope.bind.selectedAddressId = $scope.getAddress().id;
          };

          $scope.listAddresses = function () {
            accountService.getAddresses('delivery').$promise.then(setAddresses);
          };

          var setCountries = function (result) {
            return $q(function (resolve) {
              var model = $scope.getModel();
              $scope.countries = mapListByCountry(result.list, 'iso2');
              $scope.selectedCountry = $scope.selectedCountry ||
                $scope.countries.filter(function (x) { return x.id == model.user.countryId; })[0] || // User's country
                $scope.countries[0] // Default first
              $scope.country = $scope.selectedCountry;
              if ($scope.fetchifyAddress) $scope.fetchifyAddress.country_name = $scope.selectedCountry.name; // Set country name

              console.info(`[addressModule] [setCountries]`, $scope.selectedCountry);

              resolve();
            });
          };

          $scope.changeCountry = function (id) {

            var wasHidden = $scope.selectedCountry.deliveryFilter;

            $scope.selectedCountry = $scope.countries.filter(function (x) { return x.id == id; })[0];

            var nowHidden = $scope.selectedCountry.deliveryFilter;

            if (wasHidden && !nowHidden) {
              console.info("[addressModule] [changeCountry] init Fetchify");
              $scope.initializeFetchify();
            } else {
              console.info("[addressModule] [changeCountry] Select country", isoCode);
              var isoCode = $scope.selectedCountry.iso2;
              fetchifyService.cc.then(function (cc) {
                cc.selectCountry($scope.selectedCountry.iso2);
              });
            }
            if ($scope.fetchifyAddress) $scope.fetchifyAddress.country_name = $scope.selectedCountry.name; // Set country name
          };

          $scope.listCountries = function () {
            // for delivery flag

            var q = $q(function (resolve) {
              accountService.getCountries(false)
                .$promise
                .then(setCountries)
                .then(function () {
                  console.info(`I have set countries`);
                  resolve();
                });
            });

            return q;
            // Need list Countries to return a promise.

          };
          $scope.listCountries(); // temp

          $scope.isEmpty = function (str) { return (str.match(/^\s*$/) || []).length > 0 };

          $scope.validateForm = function () {
            var res = [
              $scope.isEmpty($scope.fetchifyAddress.fullName),
              $scope.isEmpty($scope.fetchifyAddress.line_1),
              $scope.isEmpty($scope.fetchifyAddress.postal_code),
              $scope.isEmpty($scope.fetchifyAddress.locality)];
            return res.filter(function (x) { return x }).length == 0;
          }

          $scope.saveAddress = function () {

            if ($scope.validateForm()) {

              var newAddress = {
                FullName: $scope.fetchifyAddress.fullName,
                CountryId: $scope.selectedCountry.id,
                Number: '',
                HouseStreet: $scope.fetchifyAddress.line_1,
                Area: $scope.fetchifyAddress.line_2,
                City: $scope.fetchifyAddress.locality,
                Postcode: $scope.fetchifyAddress.postal_code
              };

              /* Now write the address, to the user. */
              accountService.addAddress(newAddress).$promise.then(function (savedAddress) {
                $scope.addresses.push(savedAddress);

                $scope.bind.selectedAddressId = savedAddress.id;
                $scope.continue();
              });
            }
          }
        }],
      link: function (scope, element, attrs, controllers) {

        var checkoutCtrl = controllers[0],
          checkoutModule = controllers[1];

        scope.bind = {
          selectedAddressId: null
        };

        scope.stage = 1;

        scope.getAddress = function () {
          var model = checkoutCtrl.getModel();
          if (!model) return;

          // console.info('Getting address from model', model);
          return model[scope.property];
        };

        scope.getModel = function () {
          return checkoutCtrl.getModel();
        }

        scope.getExcludedCountryIso2 = function () {
          return scope.getModel().eventAddress.countryIso
        }

        /* Set current stage*/
        scope.change = function (stage) {

          switch (stage) {
            case 1: // Show selected address page
              break;
            case 2: // Address list page
              scope.listAddresses();
              break;
            case 3: // Fetchify page
              scope.listCountries()
                .then(function () {
                  scope.initializeFetchify();
                });
              break;
            case 4: // Confirm address page
              break;
          }

          scope.stage = stage;
        };

        scope.setIfConditionCheck = function() {
          var model = checkoutCtrl.getModel();
          if (! scope.setIfCondition) return false;

          return model[scope.setIfCondition];
        };

        scope.continue = function () {
          console.info(`[addressModule] [${scope.setProperty}] continue, setting [${scope.setProperty}] with [${scope.bind.selectedAddressId}]`);

          var model = checkoutCtrl.getModel();

          if (scope.addresses) {
            var find = scope.addresses.find(function (add) { return add.id == scope.bind.selectedAddressId; });
            if (find) model[scope.property] = find;
          }
          model.summary[scope.setProperty] = scope.bind.selectedAddressId;

          /* If a pre-condition is set for a second property, and
             that pre-condition is met, set the second property */
          if (scope.setIfCondition) {
            var condition = model[scope.setIfCondition];
            if (condition) {
              console.info(`[addressModule] [${scope.setIfProperty}] continue, setting [${scope.setIfProperty}] with [${scope.bind.selectedAddressId}]`);
              model.summary[scope.setIfProperty] = scope.bind.selectedAddressId;
            }
          }

          /* Reset addresses dialog back to first base */
          scope.change(1);


          /* TODO Overwriting the long form of the summary address with the
             current address value. */

          /* Updating the address requires an update on pricing. */
          checkoutCtrl.commit().then(function () {
            scope.onContinue();
            checkoutModule.complete();
          });
        };

        (function () {
          var model = checkoutCtrl.getModel();
          console.info(`[addressModule] initialising module for [${scope.property}]`);

          var address = model[scope.property];


          /* A new user will not have a default address set, and should be taken directly to add address. */
          if (address && !address.id) {
            scope.change(3); // No address - go straight to add address.
          } else if (model[scope.property]){
            scope.bind.selectedAddressId = model[scope.property].id;
          }

        })();
      },
      // controller: controller,
      templateUrl: '/assets/html/directives/checkout/addressModule.html'
    }
  });