angular.module(app.appName)
	.controller('accountController', [
		'$scope', '$route', '$window', 'storageService',
        function ($scope, $route, $window, storageService) {
            var self = this;

            if (!storageService.get("authenticated")) {
                $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
            }

            $scope.items = [{ value: "AccountDetails", description: "ACCOUNTDETAILS_BUTTON", icon: "fa fa-user" },
                            { value: "Approvals", description: "APPROVALS_BUTTON", icon: "fa fa-thumbs-up" },
                            { value: "Addresses", description: "ADDRESSES_BUTTON", icon: "fa fa-home" },
                            { value: "Downloads", description: "DOWNLOADS_BUTTON", icon: "fa fa-download" },
                            { value: "Orders", description: "ORDERS_BUTTON", icon: "fa fa-sticky-note-o" }];

        }]);