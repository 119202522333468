/* An actual paymentFactory that returns the */


angular.module(app.appName)
   .service("paymentFactory",
      ["$q", "braintreeService", "worldpayService", "paymentService",
         function ($q, braintreeService, worldpayService, paymentService) {

            /* Will return methods for completing payments methods
               for each payment type */
            this.getPaymentMethodPromise = function (paymentMethod, instance, data) {

               switch (paymentMethod) {
                  case "BraintreeCards":
                     return $q(function (resolve, reject) {

                        instance.tokenize(
                           {
                              cardholderName: data.brainTreeCards.cardName
                           })
                           .then(function (payload) {

                              braintreeService.getThreeDsConfiguration().then(function (response) {

                                 var threeDsConfigure = response.data;
                                 var threeDsPromise = (threeDsConfigure.threeDsEnabled) ?
                                    function () {
                                       // From the server response, generate a verification request, for 3DS
                                       var verifyRequest = {
                                          amount: data.amount(),
                                          nonce: payload.nonce,
                                          bin: payload.details.bin,
                                          collectDeviceData: true,
                                          challengeRequested: true,
                                          email: data.user().email,
                                          onLookupComplete: function (data, next) {
                                             next();
                                          }
                                       };
                                       Object.assign(verifyRequest, threeDsConfigure);
                                       delete payload.threeDsEnabled;

                                       console.info("[getPaymentMethodPromise] [BraintreeCards] Using 3Ds", verifyRequest);

                                       return data.brainTreeCards.threeDs.verifyCard(verifyRequest);
                                    }
                                    : function () {
                                       console.info("[getPaymentMethodPromise] [BraintreeCards] No 3Ds");
                                       return $q(function (resolve) { resolve(payload); });
                                    };

                                 threeDsPromise().then(function (payload) {

                                    console.info("[threeDsPromise] Returned payload", payload);

                                    braintreeService
                                       .generateSale(payload.nonce, paymentMethod)
                                       .then(function (result) {
                                          resolve(result.data.target);

                                       }, function (result) {
                                          reject(result.data.message);
                                       });
                                    // send nonce and verification data to your server
                                 }).catch(function (err) {
                                    console.log(err);
                                    reject(err);
                                 });
                              })
                                 .catch(function (tokenizeErr) {
                                    reject(tokenizeErr);
                                 });

                           });

                     });
                  case "BraintreeApplepay":
                     return $q(function (resolve, reject) {

                        // Validate Merchant
                        data.session.onvalidatemerchant = function (event) {
                           data.applePayInstance.performValidation({
                              validationURL: event.validationURL,
                              displayName: 'My Store'
                           }, function (err, merchantSession) {
                              if (err) {
                                 console.log('[applepay] merchant validation error', err);
                                 // You should show an error to the user, e.g. 'Apple Pay failed to load.'

                                 reject();
                                 return;
                              }
                              data.session.completeMerchantValidation(merchantSession);
                           });
                        };

                        // Authorise payment, handle redirect
                        data.session.onpaymentauthorized = function (event) {
                           console.log('Your shipping address is:', event.payment.shippingContact);

                           data.applePayInstance.tokenize({
                              token: event.payment.token
                           }, function (tokenizeErr, payload) {
                              if (tokenizeErr) {
                                 console.error('Error tokenizing Apple Pay:', tokenizeErr);
                                 data.session.completePayment(ApplePaySession.STATUS_FAILURE);

                                 reject();
                                 return;
                              }

                              // Send payload.nonce to your server.
                              console.log('nonce:', payload.nonce);

                              console.info('[paymentFactory.BraintreeGpay] Successful Apple payment, posting to Shop', payload);
                              braintreeService.generateSale(payload.nonce, paymentMethod)
                                 .then(function (result) {
                                    // After you have transacted with the payload.nonce,
                                    // call `completePayment` to dismiss the Apple Pay sheet.
                                    data.session.completePayment(ApplePaySession.STATUS_SUCCESS);
                                    resolve(result.data.target);
                                 }, function (result) {
                                    // After you have transacted with the payload.nonce,
                                    // call `completePayment` to dismiss the Apple Pay sheet.
                                    data.session.completePayment(ApplePaySession.STATUS_FAILURE);
                                    reject(result.data.message);
                                 });
                           });
                        };

                        data.session.begin();
                     });
                  case "BraintreeGpay":
                     return $q(function (resolve, reject) {
                        var paymentDataRequest = data.googlePaymentInstance.createPaymentDataRequest({
                           transactionInfo: data.transactionInfo
                        }
                        );

                        console.info('[paymentFactory.BraintreeGpay] Sending payment request to GPay', paymentDataRequest);

                        // We recommend collecting billing address information, at minimum
                        // billing postal code, and passing that billing postal code with all
                        // Google Pay card transactions as a best practice.
                        // See all available options at https://developers.google.com/pay/api/web/reference/object

                        // We should already have this information.


                        var cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
                        cardPaymentMethod.parameters.billingAddressRequired = true;
                        cardPaymentMethod.parameters.billingAddressParameters = {
                           format: 'FULL',
                           phoneNumberRequired: true
                        };

                        data.paymentsClient.loadPaymentData(paymentDataRequest).then(function (paymentData) {
                           data.googlePaymentInstance.parseResponse(paymentData, function (err, payload) {
                              if (err) {
                                 // Handle parsing error
                                 console.log("Error on parsing response,", {
                                    paymentData: paymentData,
                                    err: err,
                                    payload: payload
                                 })
                                 return;
                              }

                              // Send result.nonce to your server
                              // result.type may be either "AndroidPayCard" or "PayPalAccount", and
                              // paymentData will contain the billingAddress for card payments

                              console.info('[paymentFactory.BraintreeGpay] Successful GPay payment, posting to Shop', payload);

                              /* We need to generate a waiting while this is happening. */

                              braintreeService.generateSale(payload.nonce, paymentMethod, data.deviceData)
                                 .then(function (result) {
                                    resolve(result.data.target);
                                 }, function (result) {
                                    reject(result.data.message);
                                 })
                           });
                        }).catch(function (err) {
                           console.error('[paymentFactory.BraintreeGpay] Error in GPay payment', err);
                        });
                     });
                  case "BraintreePaypal":
                     return $q(function (resolve, reject) {
                        return instance.tokenizePayment(data)
                           .then(function (payload) {
                              braintreeService
                                 .generateSale(payload.nonce, paymentMethod)
                                 .then(function (result) {
                                    resolve(result.data.target);
                                 }, function (result) {
                                    reject(result.data.message);
                                 })
                           });
                     });
                  case "WorldpayCards":
                  case "WorldpayAlipay":
                  case "WorldpayUnionPay":
                     return $q(function (resolve, reject) {
                        worldpayService
                           .generateSale(paymentMethod, "en")
                           .then(function (result) {
                              resolve(result.data);
                           }, function (result) {
                              reject(result.message);
                           })

                     });
                  case "WeChatPay":
                  case "Telephone":
                  case "Cheque":
                  case "BankTransfer":
                  case "BankTransferVietnam":
                  case "Free":
                     return $q(function (resolve) {
                        /* We're going to need a call to something that
                           completes the sale */
                        paymentService
                           .generateSale(paymentMethod)
                           .$promise
                           .then(function (result) {
                              resolve(result.target);
                           })
                     });
                  default:
                     return $q(function (resolve, reject) {
                        console.error("[paymentFactory] Unhandled payment type", paymentMethod);
                        reject("Unhandled payment type " + paymentMethod);
                     });
               }
            }
         }

      ]);