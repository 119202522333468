/*
    General Store is used for general storage of key-value-pairs.

    To create a new KvP, call the set method with a key as a string, and a value. 
    Note: The value can be any data type.

    When the kvp is returned, you will get the whole object with both the key and the value. In order
    to retrieve the data you stored, just grab .value from the kvp object. If you store an object
    you wil need to call .value.<YourObjectKey>.
*/

angular.module(app.appName)
    .factory('generalStore', ['_',
        function (_) {
            var self = this;
            self.values = [];

            return {
                get: function (key) {
                    // If we pass a key as a string, we want to get a specific kvp, otherwise we just return the whole array.
                	if (key) {
                		var kvp = _.findWhere(self.values, { key: key });

                		// If we can't find a key-value-pair, we are creating one with a null value to avoid null reference exceptions when
						// trying to access .value of a null reference.
                		if (!kvp)
                			kvp = { key: key, value: null }
                		
                        return kvp
                    } else {
                        return self.values;
                    }
                },                
                set: function (key, value) {
                    // If we already have a kvp in the array with the current key, overwrite it, otherwise create a new one.
                    var found = _.findWhere(self.values, { key: key });
                    if (found) {
                        found.key = key;
                        found.value = angular.copy(value);
                    } else {
                        self.values.push({ key: key, value: value });
                    }
                }
            };
        }
    ]);