angular.module(app.appName)
    .controller('accountDetailsController', [
            '$scope', 'accountService', 'validationService', '$rootScope', '$window', 'storageService', '$q', 'notifyService', 'cacheService', '$timeout',
        function ($scope, accountService, validationService, $rootScope, $window, storageService, $q, notifyService, cacheService, $timeout) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            if (!storageService.get("authenticated")) {
                $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
            }

            /* This could be substituted with resolvers */
            var prereq = [

                accountService.getCountries(false).$promise,
                accountService.getDetails().$promise
            ];

            $q.all(prereq).then(function(data) {

                $scope.countries = data[0].list; // -- countries.

                $scope.model = data[1]; // -- account details.
            });

            $scope.updateCountry = function (id) {
                $scope.model.countryId = id;
            }

            $scope.submit = function () {
                var data = [];
                var fields = [
                    { name: "Email", valid: true },
                    { name: "Forename", valid: true },
                    { name: "Surname", valid: true },
                    { name: "Country", valid: true }
                ]
                $scope.loading = true;
                var details = [
                    $scope.model.email,
                    $scope.model.forename,
                    $scope.model.surname,
                    $scope.model.countryId
                ];
                var desc = [
                    "Email",
                    "Forename",
                    "Surname",
                    "Country"
                ];
                for (i = 0; i < desc.length; i++) {
                    var entry = { description: desc[i], value: details[i] }
                    data.push(entry);
                }
                var errors = validationService.checkAccountInput(data);

                if (!errors.length) {

                    accountService.updateDetails(JSON.stringify($scope.model)).$promise.then(function (data) {
                        cacheService.obliterate();

                    	notifyService.success("DETAILS_UPDATED");
                        $scope.alert = null;
                        $timeout(function () {
                            $scope.loading = false;
                            $window.location.reload();
                        }, 5000);
                    });
                }
                else {
                    for (i = 0; i < desc.length; i++) {
                        if (_.contains(errors, desc[i])) {
                            fields[i].valid = false;
                        }
                    }
                    $scope.fields = fields;
                    $scope.loading = false;
                }
            }

        }
    ]
 );