angular.module(app.appName)
    .factory('storageService', [
        'localStorageService',
        function (localStorageService) {
            // TODO: Implement Default Expiry Date. If (currentDate > expiry) don't return
           return {
                set: function (key, value) {
                    localStorageService.set(key, value);
                },
                get: function (key) {
                    if (app.useLocalStorage === true)
                        return localStorageService.get(key);
                    else
                        return null;
                },
                remove: function(key) {
                    localStorageService.remove(key);
                }
            };
        }
    ]);


// Store that backs on to local storage to
// determine if a set of upsells has been displayed
// to a user for a given visit.
angular.module(app.appName)
    .factory('upsellStore', [
        'storageService',
        function(storageService) {

            return {
                get : function(imageFolderId) {
                    var upsellObj = storageService.get('upsell') || {};
                    return upsellObj[imageFolderId];
                },

                set : function(imageFolderId, value) {
                    var upsellObj = storageService.get('upsell') || {};
                    upsellObj[imageFolderId] = value;
                    storageService.set('upsell', upsellObj);
                }
            };

        }
    ]);