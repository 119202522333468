angular.module(app.appName)
	.service('paymentService', [
		'paymentResource',
		function (paymentResource) {
			return {
				updateForCheckout: function (dto) {
					if (dto) {
						console.info("[paymentResource.updateForCheckout] post", dto);
						return paymentResource.post(dto);
					} else {
						console.info("[paymentResource.updateForCheckout] put");
						return paymentResource.put();
					}
				},
				setAddress: function (shippingAddressId, invoiceAddressId) {
					return paymentResource.setAddress({ shippingAddressId: shippingAddressId, invoiceAddressId: invoiceAddressId });
				},
				setAdditional: function (childName, className) {
					return paymentResource.setAdditional({ childName: childName, className: className });
				},
				generateSale: function (paymentMethod) {
					return paymentResource.generateSale({ paymentMethod: paymentMethod });
				}
			}
		}
	]);