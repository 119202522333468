angular.module(app.appName)
	.factory('paymentResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
			var api = apiUrl() + '/Pay/';
			return $resource(api, {}, {
				put: {
					method: 'PUT',
					url: api + 'Put',
				},
				post: {
					method: 'POST',
					url: api + 'Post',
				},
				// setAddress: {
				// 	method: 'POST',
				// 	url: api + 'SetAddress',
				// 	params: {
				// 		shippingAddressId: '@shippingAddressId', // enum
				// 		invoiceAddressId: '@invoiceAddressId' // GUID
				// 	}
				// },
				// setAdditional: {
				// 	method: 'POST',
				// 	url: api + "SetAdditional",
				// },
				generateSale: {
					method: 'POST',
					url: api + 'GenerateSale',
					params: {
						paymentMethod: '@paymentMethod'
					}
				}
			});
		}
	]);