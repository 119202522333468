angular.module(app.appName)
    .directive('approvals', function () {
        var controller = [
            '$scope', '$window',
        function ($scope, $window) {
            var self = this;

        }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/account/approvals/approvals.html'
        }
    });