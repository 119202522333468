angular.module(app.appName)
  .directive('sameAsModule', function () {
    return {
      require: ['^^checkoutContainer', '^^checkoutModule'],
      scope: {
        sourceProperty: '@',
        targetProperty: '@',
        sameAs: '='
      },
      controller: ['$scope', function ($scope) {
        // $scope.sameAs = false;
      }],
      link: function (scope, element, attrs, controllers) {

        var checkoutCtrl = controllers[0],
          checkoutModule = controllers[1];

        var setSame = function () {
          var model = checkoutCtrl.getModel();

          if (scope.sameAs) {
            model.summary[scope.targetProperty] = model.summary[scope.sourceProperty];
            console.info(`[sameAsModule] [${scope.targetProperty}] ${model.summary[scope.sourceProperty]}`);
          }
        };


        scope.continue = function () {
          console.info(`[sameAsModule] continue`);

          setSame();

          /* Need to confirm all fields are valid before we move on. */
          checkoutModule.complete();
        };

        /* On change, determine if same or different and call. */
        scope.change = function () {
          setSame();

          console.info(`[sameAsModule] [change] ${scope.sameAs}`);
        };

      },
      // controller: controller,
      templateUrl: '/assets/html/directives/checkout/sameAsModule.html'
    }
  });