angular.module(app.appName)
	.factory('albumService', [
		function () {
			return {
				groupAlbums: function (albumsPassed) {
					// Create a copy of all of our albums before we do any manipulation to avoid messing with the.
					var albums = angular.copy(albumsPassed);
					var years = [];

					// We are re-arranging and sorting/grouping the array
					years = _.chain(albums.folders)
							// First group everything by eventYear
							.groupBy('eventYear')
							// We are now mapping our object into a new group which we defined below
							.map(function (year) {
								// Tells our mapped object to group our 'year' (passed in from underscore) by sittingPassword
								return _.groupBy(year, 'eventCode')
							})
							// Because we're using _.chain() we need to call .value() at the end to return the proper value
							.value();

					_.each(years, function (year) {
						// Foreach year object in the years array, use a forin loop to grab object key of the event
						for (var y in year) {
							// sort the event by private sitting first to ensure we always have our private sitting first
							_.sortBy(year[y], function (sitting) {
								return sitting.private == true;
							});
							// If we don't have an eventYear on our array object, grab it from the first event.
							if (!year.eventYear) {
								year.eventYear = year[y][0].eventYear;
							}
						}
					});

					// We want to overwrite our old folders with our newly grouped and sorted array(s)
					return albumsPassed.folders = { 'years': years };
				}
			}
		}
	]);