angular.module(app.appName)
    .directive('orders', function () {
        var controller = [
            '$scope', '$window',
        function ($scope, $window) {
            var self = this;

        }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/account/orderHistory/orders.html'
        }
    });