angular.module(app.appName)
  .directive('tabModule', function () {
   return {
      scope: {
        model: '=',       // Linked model
      },
      controller: ['$scope', function($scope) {
      }],
      transclude: true,
      templateUrl: '/assets/html/directives/checkout/tabModule.html'
    }
  });

