angular.module(app.appName)
    .controller('changePasswordController', [
            '$scope', '$translate', '$routeParams', 'accountService', 'validationService', '$rootScope', '$window', 'storageService', '$q', 'notifyService', '$timeout', 'routeService', 'authService', 'homeService', 'enums',
        function ($scope, $translate, $routeParams, accountService, validationService, $rootScope, $window, storageService, $q, notifyService, $timeout, routeService, authService, homeService, enums) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.passwordsMatch = true;
            $scope.oldPasswordCorrect = true;
            $scope.loadingPage = $q.defer();
            $scope.token = $routeParams.token;
            if ($scope.token) {
                $scope.requestOldPassword = false;
            } else {
                if (!storageService.get("authenticated")) {
                    $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
                }
                $scope.requestOldPassword = true;
            }

            accountService.getDetails().$promise.then(function (details) {

                $scope.passwords = details;

                $scope.loadingPage.resolve();
            });

            $scope.changePassword = function () {
                $scope.loading = true;
                $scope.setPasswordErrorMessage();
                var data = [];
                var passwordFields;
                var passwordDetails;
                var passwordDescriptions;
                if ($scope.token) {
                    $scope.passwords.token = $scope.token;
                    passwordFields = [{ name: "New Password", valid: true }, { name: "Confirm New Password", valid: true }]
                    passwordDetails = [$scope.passwords.newPassword, $scope.passwords.confirmPassword];
                    passwordDescriptions = ["New Password", "Confirm New Password"];
                } else {
                    passwordFields = [{ name: "Old Password", valid: true }, { name: "New Password", valid: true }, { name: "Confirm New Password", valid: true }]
                    passwordDetails = [$scope.passwords.oldPassword, $scope.passwords.newPassword, $scope.passwords.confirmPassword];
                    passwordDescriptions = ["Old Password", "New Password", "Confirm New Password"];
                }
                
                for (i = 0; i < passwordDescriptions.length; i++) {
                    var entry = { description: passwordDescriptions[i], value: passwordDetails[i] }
                    data.push(entry);
                }
                var errors = validationService.checkPasswordInput(data);

                if (!errors.length) {
                    $scope.passwordsMatch = passwordDetails[passwordDescriptions.length - 2] == passwordDetails[passwordDescriptions.length - 1];
                    if ($scope.passwordsMatch) {
                        accountService.changePassword($scope.passwords).$promise.then(function (data) {
                            if (data.success == "true") {
                                notifyService.success("PASSWORD_UPDATED_SUCCESS");
                                if (!authService.isAuthenticated()) {
                                    var jwtPayload = $scope.parseJwt($scope.token);
                                    var email = jwtPayload.email;
                                    var sittingPassword = jwtPayload.sittingPassword;
                                    if (sittingPassword) {
                                        // Add sitting to ValidatedSittingEmail
                                        var dto = {
                                            sittingPassword: sittingPassword,
                                            emailAddress: email,
                                            stage: enums.homeLoginRequestStage.addSitting,
                                        };
                                        homeService.homeLogin(dto).then(function (result) {
                                            authService.login({ username: email, password: $scope.passwords.newPassword }).then(function (result) {
                                                if (result.success == true) {
                                                    storageService.set('authenticated', true);
                                                    $rootScope.$broadcast('authenticationSet');
                                                    $scope.passwords = null;
                                                    $scope.loading = false;
                                                    routeService.redirect(`/MyAlbums/?sittingId=${sittingPassword}`);
                                                }
                                            });
                                        });
                                    } else {
                                        authService.login({ username: email, password: $scope.passwords.newPassword }).then(function (result) {
                                            if (result.success == true) {
                                                storageService.set('authenticated', true);
                                                $rootScope.$broadcast('authenticationSet');
                                                $scope.passwords = null;
                                                $scope.loading = false;
                                                routeService.redirect();
                                            }
                                        });
                                    }
                                }
                            } else {
                                $scope.requestDenied = true;
                            }
                            $scope.loading = false;
                            if (!$scope.token) {
                                $scope.oldPasswordCorrect = false;
                            }
                        });
                    }
                    else {
                        $scope.loading = false;
                    }
                }
                else {
                    for (i = 0; i < passwordDescriptions.length; i++) {
                        if (_.contains(errors, passwordDescriptions[i])) {
                            passwordFields[i].valid = false;
                        }
                    }
                    $scope.fields = passwordFields;
                    $scope.loading = false;
                }

            }

            $scope.showResetForm = function () {
                $scope.showReset = !$scope.showReset;
            }

            $scope.parseJwt = function (token) {
                var base64Url = token.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
            
                return JSON.parse(jsonPayload);
            }

            $scope.setPasswordErrorMessage = function () {
                var loginUrl = window.location.host == 'https://shop.pret-a-portrait.cn/Login' 
                    ? 'https://shop.pret-a-portrait.cn/' : 'https://shop.pret-a-portrait.net/Login';
                return $translate('ERROR_PASSWORD_LINK_INVALID', { loginUrl: loginUrl }).then(function (translation) {
                    $scope.errorMessage = translation;
                });
            }
        }
    ]
 );