angular.module(app.appName)
    .factory('routeService', [
        '$location', '$window', '$route', 'helpers', 'cacheService', 'debug',
        function ($location, $window, $route, helpers, cacheService, debug) {
            var currentRoute = { name: '', params: {} };
            var previousRoute = "";
            var previousRouteName = { name: '' };

            // Family tree represents the hierarchy of the routing
            // of the site.
            // Regex represents an album code.
            var familyTree =
            {
                "MYALBUMS": {
                    "ALBUM": {
                        "[0-9]+": {
                            "PREVIEW": null
                        },
                        "FAVOURITES": null
                    },
                },
                "PACKS": {
                    "FAVS": null,
                    "PACK": null
                },
                "ACCOUNT": {
                    "ACCOUNTDETAILS": {
                        "CHANGEPASSWORD": null,
                    },
                    "ORDERS": null,
                    "DOWNLOADS": null,
                    "APPROVALS": null,
                    "LOGIN": null,
                },
                "CHECKINGOUT": {
                    "BASKET": null,
                    "CHECKOUT": null,
                },
                "PREREGISTER": {
                    "NOTIFYME": null,
                    "NOTIFYTHANKS": null
                }
            };

            // For each node in the tree, set the parent object.
            var setParent = function (o, parent) {
                Object.entries(o).forEach(function (entry) {
                    var key, value;
                    key = entry[0];
                    value = entry[1];
                    // var [key, value] = entry;

                    if (!value) {
                        o[key] = {}; // Create a new object if one does not exist.
                    } else {
                        setParent(o[key], entry); // Call down to child node.
                    }
                    o[key].parent = parent;
                });
            };
            setParent(familyTree, null);

            /* Collect all all nodes into a single list of nodes for traversal */
            var nodes = [];
            var collectNodes = function (o) {
                Object.entries(o).forEach(function (entry) {
                    var key, value;
                    key = entry[0];
                    value = entry[1];

                    if (key != "parent") { // Don't collect parents

                        nodes.push({ key: key, value: value });

                        if (value) {
                            collectNodes(value); // Depth first search of node collection.
                        }
                    }
                });
            };
            collectNodes(familyTree);

            /* Find an individual node in the list. */
            var findNode = function (key) {
                var found = nodes.filter(function (node) { return key.match(new RegExp(node.key)); });
                return found[0];
            }

            var accountLink = "Account";

            return {
                get: function () {
                    // console.info("[routeService ] GET: ", currentRoute);
                    return currentRoute;
                },
                set: function (name, params) {

                    console.debug('[routeService.set]', { name: name, params: params });

                    var splitName = name.split('/');

                    // Determine component by path.
                    // If numeric, then step back.
                    var isNumeric = /^\d+$/;
                    var nonNumericParts = splitName
                        .filter(function (part) { return part }) // Non empty
                        .filter(function (part) { return !isNumeric.test(part); }) // Non numeric;

                    currentRoute.name = name == '/' ? '' : nonNumericParts.reverse()[0].toUpperCase();
                    currentRoute.params = params;
                    currentRoute.fullName = name;

                    console.debug("[routeService]: previous/current ", { previous: previousRouteName.name, current: currentRoute.name });

                    this.hasParentRouteChanged();
                },
                getPreviousName: function () {
                    return previousRouteName;
                },
                setPreviousName: function (url) {
                    var splitUrl = url.split('/');
                    previousRouteName.name = splitUrl[splitUrl.length - 1] != '' ? splitUrl[splitUrl.length - 1].toUpperCase() : splitUrl[splitUrl.length - 2].toUpperCase();
                },
                getPreviousRoute: function () {
                    if (!previousRoute || helpers.isNullOrUndefined(previousRoute)) {
                        previousRoute = "/MyAlbums";
                    }
                    return previousRoute;
                },
                setPreviousRoute: function (url) {
                    previousRoute = url;
                },
                redirect: function (specifiedPath) {
                    var previousRoute = this.getPreviousRoute();
                    if (specifiedPath) {
                        $location.url(specifiedPath);
                    }
                    else if (previousRoute && !previousRoute.toUpperCase().indexOf(currentRoute.name.toUpperCase())) {
                        $window.location.href = previousRoute;
                    }
                    else {
                        $location.path("/MyAlbums");
                    }
                },

                /* Find the current node in the family tree and return the corresponding root. */
                getRootRoute: function (route) {

                    var node = findNode(route);
                    if (node == null) {
                        return null; // If not found in the tree return null
                    }

                    /* Decompose parent */
                    var key = node.key;
                    var value = node.value;
                    while (value && value.parent) {
                        key = value.parent[0];
                        value = value.parent[1]; // Use array notation rather than [key, value decomposition]
                    }

                    return key;
                },

                /* Has the parent route change */
                hasParentRouteChanged: function () {
                    var previousRouteParent = this.getRootRoute(previousRouteName.name);
                    var currentRouteParent = this.getRootRoute(currentRoute.name);

                    console.debug("[routeService] previous/current", { previous: previousRouteParent, current: currentRouteParent });

                    /* If parent route has changed, obliterate cache */
                    if (previousRouteParent != currentRouteParent) {
                        cacheService.obliterate();
                    }
                },

                getAccountLink: function () {
                    return accountLink;
                },
                setAccountLink: function (newRoute) {
                    accountLink = newRoute;
                },

                /* checks for the presence of a keyword in a route*/
                routeHasKeyword: function(route, keyword) {
                    return (route || '').toUpperCase().indexOf(keyword) > -1;
                },

                /* Gets an object representing the current album
                   attributes from the current route */
                getAlbumRouteAsObject: function () {

                    var albumParams = {
                        favourites: false,
                        albumId: null,
                        sittingId: null,
                        imageId: null,
                        route: ''
                    };

                    var currentRoute = this.get().name;

                    albumParams.albumId = $route.current.params.albumId;
                    albumParams.sittingId = $route.current.params.sittingId;
                    albumParams.imageId = $route.current.params.imageId;

                    /* Favourites is filtered on route */
                    if (currentRoute.toUpperCase() == 'FAVOURITES') {
                        albumParams.favourites = true;
                    }

                    albumParams.route = currentRoute;

                    return albumParams;
                }
            }
        }
    ]);