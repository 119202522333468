angular.module(app.appName)
	.factory('basketResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl() + '/Basket/';
		    return $resource(api, {}, {
		        get: {
		            method: 'GET',
		            url: api + 'Index'
		        },
		        setQuantity: {
		        	method: 'POST',
		        	url: api + 'Quantity',
		        	params: {
		        		itemId: '@itemId', // GUID
						quantity: '@quantity' // int
		        	}
		        },
                deleteItem: {
                    method: 'POST',
                    url: api + 'Delete',
                    //params: {
                    //    itemId: '@itemId' // GUID
                    //}
                }
		    });
		}
	]);