angular.module(app.appName)
	.factory('itemBuilderService', ['$rootScope', '$q', 'generalStore', 'itemService', 'itemStore', 'debug', 'dimensions',
		function ($rootScope, $q, generalStore, itemService, itemStore, debug, dimensions) {

			/*
			 * Return a service for building a default/composite item for submitting to the store
			 * this is abstracted away from the UI components of catProducts/ modalPicker, etc.
			*/

			var that = {

				updateProductItemWithImages: function(productItem, currentPreview, currentFolderId, ratio) {
						productItem.imgFolderId = currentFolderId;

						if (productItem.imgQuantity > 0) {

							/* Image specific product */
							console.info("[itemBuilderService] Building a image specific product");

							productItem.images[0].isPlaceholder = false;
							productItem.images[0].imgIndex = (currentPreview || {}).id;
							productItem.images[0].ratio = ratio;
							that.setIsSelectedOnCorrectOption(productItem, ratio); // Not convinced the ratio is right.
							that.setDimensions(ratio, productItem); // WHERE IS RATIO COMING FROM
						} else {

							/* Sitting specific product */
							console.info("[itemBuilderService] Building a sitting specific product");

							productItem.images = [{}];
							productItem.images[0].position = 1;
							productItem.images[0].isPlaceholder = false;

							var nonCustomPricing = generalStore.get('currentSitting').value.none.images // Get images from store
								.filter(function (image) { return !image.customPricing; });				// Filter for custom pricing
							productItem.images[0].imgIndex = nonCustomPricing[0].id;
						}

						return productItem;
				},

				/* Build and return a default product for a given productItem */
				buildDefaultItem: function (product, currentPreview, currentFolderId, ratio) {
					return $q(function (resolve) {
						itemService.get(product.id, null, null, null, currentFolderId).then(function (newItem) {
							that.updateProductItemWithImages(newItem, currentPreview, currentFolderId, ratio);
							resolve(newItem);
					});
					});
				},

				saveBulkProductItem: function(productItems, product)
				{

					var defer = $q.defer();

					itemService.save(productItems, null, false).then(function(responses) {

						// Need to match the saved request to the response.
						// Note, they should line up.

						// After saving, create the broadcast for each request.
						_.each(responses.item, function(response) {

							console.info("[itemService.save]", response);

							$rootScope.$broadcast('imageAddedToBasket', { imagesAdded: response.item.images, product: product });
							$rootScope.$broadcast('productAddedToBasket', { product: product });
						});

						defer.resolve();
					});


					return defer.promise;
				},

				/* Perform standard actions associated with saving a product */
				saveProductItem: function (productItem, quantity, product) {
					var defer = $q.defer();

					// what is we don't actually save the item, but
					// we immediately resolve?

					productItem.quantity = quantity;

					itemService.save([productItem], quantity, false).then(function (savedItem) {

						console.info("[itemService.save]", savedItem);

						$rootScope.$broadcast('imageAddedToBasket', { imagesAdded: savedItem.item[0].item.images, product: product });
						$rootScope.$broadcast('productAddedToBasket', { product: product });

						// Resolve a promise with saved item
						defer.resolve(savedItem.item[0]);
						// defer.resolve(null);
					});

					return defer.promise;
				},

				/* Returns ratio if passed a current preview */
				currentPreviewRatio: function (currentPreview) {
					if (currentPreview) {
						var ratio = (currentPreview.preview.height / currentPreview.preview.width).toFixed(4);
						return ratio;
					}
				},

				/* Sets a set of dimensions for a product, based
					 in a preview image */
				setDimensions: function (previewRatio, item, index) {

					//get some variables (after the alteration above).
					index = index || 0;
					var findProduct = item.images[index];

					var productRatio = null;
					if (item.options && item.options.length > 0) // packs do not have options
					{
						var productDimension = _.find(item.options[0].items, function (optionItem) {
							return optionItem.selected === true;
						});
						if (productDimension != null) {
							productRatio = productDimension.ratio;
						};
					}

					if (!productRatio) productRatio = previewRatio; // Set it to be the same;

					var edgeDim = dimensions.previewMax;

					/* Now calculate Preview Size */
					if (productRatio == 1) {
						findProduct.previewHeight = edgeDim;
						findProduct.previewWidth = edgeDim;
					}
					else if (productRatio < 1) { // both vert
						findProduct.previewWidth = edgeDim;
						findProduct.previewHeight = Math.floor(edgeDim * productRatio);
					}
					else if (productRatio > 1) { // both horiz
						findProduct.previewWidth = Math.floor(edgeDim * productRatio);
					}
				},

				/* Determine if the grouped contains tagged option items */
				hasTag: function (option) {

					if (typeof (option.hasTag) === 'undefined') {																// Cache the results to stop repeat expensive calls.
						option.hasTag = option.items.filter(function (x) { return x.tag; }).length > 0;							// If the group contains a single tag, then we consider it tagged.
					}
					return option.hasTag;
				},

				/* Determine if all the items in the group are tagged */
				allTagged: function (option) {

					if (typeof (option.allTagged) === 'undefined') {																// Cache the results to stop repeat expensive calls.
						option.allTagged = option.items.filter(function (x) { return x.tag; }).length == option.items.length;	// If all items are tagged, then we consider it allTagged.
					}
					return option.allTagged;
				},

				/* Returns the selected options for the given item. */
				selectedOptions: function (item) {
					var selectedItems = item.options.map(function (option) {
						return option.items.filter(function (item) {
							return item.selected;
						});
					});
					return [].concat.apply([], selectedItems);
				},

				/* Returned if an item has a selected, tagged option, if so
					 returns the first selected tag. */
				isSelectedTagged: function (item) {
					var selectedOptions = that.selectedOptions(item);
					var taggedOptions = selectedOptions.filter(function (option) { return option.tag; });
					return taggedOptions[0];
				},

				/* Return option items that match the current product/album context */
				getOptions: function (option, ratio) {

					/* In the case where we are not in the context
						 of an image, then we use the ratio from the selected option. */

					// var ratio = $scope.ratio; 																// Return ratio from scope.
					var hasTag = that.hasTag(option);														// Group contains tagged option items.
					var allTagged = that.allTagged(option);

					if (allTagged && !ratio) {
						var currentlySelected = _.findWhere(option.items, { selected: true });					// Get the currently selected item
						if (currentlySelected) {
							ratio = currentlySelected.ratio;
						}
					}

					var optionItems = ratio && hasTag ?
						_.where(option.items, { ratio: ratio }) :											// Match on ratio.
						_.where(option.items, { tag: null });												// Otherwise, return untagged items.

					return optionItems;
				},

				/* Validates and switches a tagged option group */
				validatedTaggedOption: function (option, ratio) {
					if (that.hasTag(option)) {																				// Make sure this is a tagged group.
						var validOptions = that.getOptions(option, ratio);															// Fetch list of valid options for group.
						currentlySelected = _.findWhere(option.items, { selected: true });										// Get the currently selected item

						/* If there is no currently selected - then return null. */
						if (currentlySelected) {
							currentlySelected.selected = false;																	// Deselect

							if (!_.contains(validOptions, currentlySelected)) {													// If there is no valid currently selected item or no currently selected item is not valid
								var tag = (currentlySelected || { tag: null }).tag; 											// Find the tag to match on
								currentlySelected = _.findWhere(validOptions, { tag: tag });									// And return the first match on that tag.
							}

							return currentlySelected;
						}

					}

					return null;
				},

				/* Ensure that an option is selected on a valid product item option */
				setIsSelectedOnCorrectOption: function (productItem, ratio) {

					angular.forEach(productItem.options, function (option) {
						// We are updating the size option
						if (that.hasTag(option)) {

							var newSelectedOption = that.validatedTaggedOption(option, ratio);
							if (newSelectedOption) {
								newSelectedOption.selected = true;
							}
						}
					});
				},

				updateSelectedOptions: function (product, item, ratio) {
					product.selectedOptions = [];

					if (item) {
						// If in the context of an item.
						angular.forEach(item.options, function (option) {
							// Foreach option group
							var currentlySelected = null;

							// Do we need to check for $scope.ratio?
							if (that.hasTag(option)) {
								// If contains mix of tags/non-tags
								currentlySelected = that.validatedTaggedOption(
									option,
									ratio
								); // Go and get/validate the selected tagged option.
							} else {
								currentlySelected = _.findWhere(option.items, { selected: true }); // If not tagged - find the simply find selected option.
							}

							/* push defaults */
							if (currentlySelected) {
								// So long as we have a valid selection
								currentlySelected.selected = true; // Select the item.
								option.item = currentlySelected; // Set the item on the group
								if (!_.findWhere(product.selectedOptions, { id: option.id })) {
									// Add the item to the defaults
									product.selectedOptions.push(option);
								}
							}
						});
					} else {
						angular.forEach(product.options, function (option) {
							// If not an item, iterate through product options looking for default.
							/* This should be updating the selected options
									 for the product default.*/

							if (option.optionItem != null) {
								option.optionItem.selected = true;
							}

							product.selectedOptions.push({
								displayName: option.optionGroup.displayName,
								item: option.optionItem,
							});
						});
					}
				}
			};
			return that;
		}
	]);