angular.module(app.appName)
  .factory('homeResource', [
    '$resource', 'apiUrl', function ($resource, apiUrl) {
      var api = apiUrl() + '/Home/';
      return $resource(api, {}, {
        homeLogin: {
          method: 'POST',
          url: api + 'HomeLogin',
        },
        verifyEmailUrl: {
          method: 'POST',
          url: api + 'VerifyEmailUrl',
        }
      });
    }
  ]);