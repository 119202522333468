angular.module(app.appName)
  .service('homeService',
    ['homeResource', '$rootScope', 'cacheService',
      function (homeResource, $rootScope, cacheService) {
        this.homeLogin = function (dto) {
          return homeResource.homeLogin(dto).$promise.then(function (result) {
            cacheService.obliterate();
            return result;
          });
        };

        this.verifyEmailUrl = function (token) {
          return homeResource.verifyEmailUrl({token: token}).$promise.then(function (result) {
            cacheService.obliterate();
            return result;
          });
        };
      }
    ]
  );