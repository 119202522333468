angular.module(app.appName)
  .directive('checkoutContainer', function () {
    return {
      scope: {
        updateModel: '&', // Linked update function
      },
      controller: ['$scope', '$q', 'paymentService', function ($scope, $q, $paymentService) {

        var getModel = function() {
          return $scope.$parent.model;
        };
        this.getModel = getModel;

        this.refresh = function () {
          // this.active = data.checkoutModules[4]; // 0 by default.
          this.active = getModel().checkoutModules[0]; // 0 by default.
          console.info(`[checkoutContainer] [refresh] setting '${this.active}' to active.`);
        };

        this.commit = function () {

          var summary = getModel().summary;

          /* Needs to return a promise to commit the data. */
          return $q(function(resolve) {
            console.info("[checkoutContainer] [commit] This is a promise to commit the model",
              summary);

              $paymentService.updateForCheckout(summary).$promise.then(function(data) {
                // We're assuming this returns ok.

                console.info("[checkoutContainer] [commit] model commited.");

                $scope.$parent.updateModel(data);
                // $scope.updateModel(data);

                resolve();
              });
          });
        };

        this.hasModule = function (moduleName) {
          var modules = getModel().checkoutModules;
          if (!modules) return;
          return modules.indexOf(moduleName) > -1;
        };

        this.next = function () {
          var curIndex = getModel().checkoutModules.indexOf(this.active);
          var newIndex = curIndex + 1;
          this.active = getModel().checkoutModules[newIndex];

          console.info(`[checkoutContainer] [next] setting '${this.active}' to active.`);
        };

        this.setActive = function (name) {
          var idx = getModel().checkoutModules.indexOf(name);
          this.active = getModel().checkoutModules[idx];
          console.info(`[checkoutContainer] [setActive] setting '${this.active}' to active.`);
        };

        this.refresh();
      }],

      transclude: true,
      template: '<ng-transclude></ng-transclude>'
    }
  });

