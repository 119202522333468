angular.module(app.appName)
    .directive('albumProduct', function () {
    	var controller = [
            '$scope', 'routeService', '$route', 'navService', 'enums',
            function ($scope, routeService, $route, navService, enums) {

							$scope.launchProduct = function() {

								var parms = $route.current.params;

								// Redirect to packs with context of product
								// and sitting.


								// .when('/Album/:sittingId/:albumId/:pictureId/Preview', {

								var sittingId = $route.current.params.sittingId;
								var albumId = $route.current.params.albumId; // Do we have this?
								var productId = $scope.product.id;

								if ($scope.isPremium($scope.product)) {
									navService.premium(productId, albumId);
								}
								else if ($scope.isPack($scope.product)) {
									navService.pack(albumId, productId);
								}
								else if ($scope.isWhole($scope.product)) {
									navService.whole(sittingId, albumId, productId);
								}
								else {
									navService.packs(sittingId, albumId, productId);
								}

								/* If not any of these, assume regular product type, and navigate
								   to pack page. */


								console.error('Unknown product type', $scope.product);
							};

							$scope.isPremium = function (product) {
								return product.productTypeId == enums.productType.misp;
							};

							$scope.isPack = function (product) {
								return product.productTypeId == enums.productType.p;
							};

							$scope.isWhole = function (product) {
								return product.imgQuantity == 0;
							}
           }
    	];
    	return {
    		scope: {
    			sittingData: '=',
          product: '=',
				action: '&'
    		},
    		controller: controller,
    		templateUrl: '/assets/html/directives/albums/albumProduct.html'
    	}
    });