
angular.module(app.appName)
	.controller('notifyThanksController', [
		'$scope', '$rootScope', '$route', 'routeService', 'sessionValidData',
        function ($scope, $rootScope, $route, routeService, sessionValidData) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.sessionValid = sessionValidData;
        }
]);