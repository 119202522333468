angular.module(app.appName)
	.factory('authResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl() + '/User/';
		    return $resource(api, {}, {
		        login: {
		            method: 'POST',
		            url: api + 'JsonPLogin',
		            //params: {
		            //    productId: '@productId', // int?
		            //    itemId: '@itemId', // guid?
		            //    folderId: '@folderId', // int?
		            //},
		            //cache: true
		        },
		        logout: {
		            method: 'GET',
		            url: api + 'Logout'
		        },
		        register: {
		            method: 'POST',
		            url: api + 'Register'
		        },
		        test: {
		            method: 'GET',
                    url: api + 'Test'
		        },
		        resetPassword: {
		            method: 'POST',
		            url: api + 'ForgotPassword',
		            params: {
                        email: '@email', // string
												sittingPassword: '@sittingPassword' // string
		            }
		        },
		        checkUserExists: {
		            method: 'POST',
		            url: api + 'QuickUserCheck'
		        }
		    });
		}
	]);