angular.module(app.appName)
    .factory('myPicturesService', [
        'myPicturesResource', '$q', 'albumService', 'previewResource', 'sittingStore', 'generalStore', '_', 'statStore', 'basketService', 'cacheService',
        function (myPicturesResource, $q, albumService, previewResource, sittingStore, generalStore, _, statStore, basketService, cacheService) {
            return {
                // General purpose public functions
                sortByFavourite: function (array) {
                    // Sorts the array by imgFileName to get them in alphabetical order, and then
                    // by isFavourite so our favourites are always at the top
                    array = _(array).chain()
                        .sortBy(function (item) { return item.imgFileName })
                        .sortBy(function (item) { return item.isFavourite })
                        .sortBy(function (item) { return item.isSelected })
                        .value();
                    return array;
                },
                setFavouriteOnPicture: function (picture) {
                    picture.isFavourite = !picture.isFavourite;

                    // If our picture is now a favourite, add to the count, otherwise it's not a favourite. Minus from count.
                    picture.isFavourite ? statStore.favouriteCount++ : statStore.favouriteCount--;
                },
                initFavouritesOnPictures: function (favourites, array) {
                    _.each(favourites, function (image) {
                        image.isFavourite = true;
                        if (!_.find(array, function (imageInArray) { return imageInArray.id == image.id })) {
                            // If we can't find the image in the regular images array, then we must add it.
                            array.push(image);
                        }
                    });

                    // We use the sort function to make sure the images are definitely in the correct order (by favourite)
                    array = this.sortByFavourite(array);

                    // Returning a copy of the array back to the caller in case we wanted to assign it to a new variable
                    return angular.copy(array);
                },
                setPictureBasketCount: function (array) {
                    basketService.get().$promise.then(function (basketData) {
                        _.each(basketData.basketItems, function (basketItem) {
                            _.each(basketItem.item.images, function (image) {
                                var foundImage = _.find(array, function (arrayImage) {
                                    return arrayImage.id === image.imgIndex;
                                });
                                if (foundImage.basketCount) {
                                    foundImage.basketCount++;
                                } else {
                                    foundImage.basketCount = 1;
                                }
                            });
                        });
                    });
                    return angular.copy(array);
                },
                selectedToTop: function (array) {
                    array = _(array).chain()
                        .sortBy(function (item) { return item.isSelected })
                        .value();
                    return array;
                },

                // MyPictures Resource Functions
                setupShowMoreOnEvents: function (data) {
                    _.each(data.none.folders.years, function (year) {
                        _.each(year, function (sitting) {
                            sitting.showMore = false;
                        });
                    });
                },
                addSitting: function (sittingId, getRootFolder) {
                    // We can't effectively obliterate the cache until the addSitting action is complete.
                    // So we need to then and then return a promise with the original content.

                    var defer = $q.defer();

                    /* Call add, on resolution, obliterate cache, */
                    myPicturesResource.add({ sittingId: sittingId, getRootFolder: getRootFolder }).
                        $promise.then(function (data) {

                            cacheService.obliterate();
                            defer.resolve(data); // resolve promise.
                        });

                    return defer.promise;
                },
                getSitting: function (id, isFavourite, productId) {
                    var deferred = $q.defer();

                    myPicturesResource
                        .getSitting({ id: id, favourite: isFavourite, productId: productId }).
                        $promise.then(this.handleSitting)
                        .then(function (sitting) {
                            deferred.resolve(sitting);
                        });

                    return deferred.promise;
                },

                handleSitting: function (sitting) {
                    var deferred = $q.defer();

                    if (sitting.none && sitting.none.folders && sitting.exclusionFolders && sitting.none.folders.length > 0 && sitting.exclusionFolders.length > 0) {
                        sitting.none.folders = _.filter(sitting.none.folders, function (folder) {
                            return _.indexOf(sitting.exclusionFolders, folder.id) === -1;
                        });
                    }
                    // Make sure the favurites also exist on the 'none' array
                    if (sitting.favourites && sitting.none) {
                        if (!sitting.none.images) {
                            sitting.none.images = [];
                        }
                        for (var i = 0; i < sitting.favourites.images.length; i++) {
                            sitting.none.images.push(sitting.favourites.images[i]);
                        }
                        //sitting.favourites.folders = albumService.groupAlbums(sitting.none);
                    }
                    if (sitting.none && sitting.none.folders) // Make sure 'none' exists before calling the service. It doesn't exist for favourites.
                        sitting.none.folders = albumService.groupAlbums(sitting.none);

                    deferred.resolve(sitting);
                    sittingStore.set(sitting);
                    return deferred;
                },
                changeStatus: function (id, type, status, adjacentId, direction, folder) {
                    cacheService.obliterate();
                    return myPicturesResource.changeStatus({ id: id, type: type, status: status, adjacentId: adjacentId, direction: direction, folder: folder });
                },

                // Preview Resource Functions
                getPreview: function (id, isFolder, ignoreFavourites) {
                    return previewResource.get({ id: id, folder: isFolder, ignoreFavourites: ignoreFavourites });
                },

                // Sets additional client side exclusions on images if image ratios
                // do not match specified ratio.
                setClientSideExclusions: function (folder, ratio) {

                    _.each(folder.images, function (image) {

                        /*  If no tag ratio, then filter images with ratios
                            set. */
                        if (!ratio && image.ratio) {
                            image.exclude = true;
                        }

                        /* if tag ratio, then filter images if their ratios do
                           not match. */
                        if (ratio && image.ratio != ratio) {
                            image.exclude = true;
                        }
                    });
                }
            }
        }
    ]);