angular.module(app.appName)
	.factory('productResource', [
		'sessionService', '$resource', 'apiUrl', 'useCache', 'config',
		function (sessionService, $resource, apiUrl, useCache, config) {
			var api = apiUrl() + '/Products/';
			var newApiUrl = config.apiUrl + '/Products/';
			return $resource(api, {}, {
				getProductData: {
					method: 'POST',
					url: config.apiUrl + '/Item/Index',
					params: {
						productId: '@productId', // int
						folderId: '@folderId' // int
					},
					headers: {
						'Authorization': sessionService.getAuthHeader
					}
				},
				getProductById: {
					method: 'GET',
					cache: useCache(),
					url: apiUrl() + '/Product/Index',
					params: {
						id: '@id', // int
						folderId: '@folderId' // int
					},
				},
				getProductAndChildrenById: {
					method: 'GET',
					cache: useCache(),
					url: apiUrl() + '/Product/Index',
					params: {
						id: '@id', // int
						folderId: '@folderId', // int
						children: true
					},
				},
				getProducts: {
					method: 'GET',
					cache: useCache(),
					url: newApiUrl + 'Index',
					params: { imageId: '@imageId', folderId: '@folderId', fav: '@fav' },
					headers: {
						'Authorization': sessionService.getAuthHeader
					},
					//cache: true
				}
			});
		}
	]);