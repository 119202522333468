angular.module(app.appName)
   .directive('address', function () {
   	var controller = [
		'$scope', 'generalStore', 'accountService', 'validationService', '$rootScope', 'storageService', '$window', '$translate', 'notifyService', '$timeout', '$q', 'enums',
	function ($scope, generalStore, accountService, validationService, $rootScope, storageService, $window, $translate, notifyService, $timeout, $q, enums) {
		var self = this;
		var clicks = 0;
		$scope.currentAddress = {};
		$scope.newAddress = {};
		$scope.loadingPage = $q.defer();
		$scope.addEdit = false;
		$scope.alert = null;
		$scope.deliverToSchoolNoAlternative = false;
		$scope.canCopyAddress = true;
		$scope.changingAddress = false;

		$rootScope.$on('deliverToSchoolNoAlternative', function () {
			$scope.deliverToSchoolNoAlternative = true;
			$scope.schoolOnlyCountryId = generalStore.get('schoolOnlyCountryId').value;
		})

		var forDelivery = $scope.addressType == "delivery"; // return a modified list of countries for the Delivery address because we cannot deliver to every country
		getCountries(forDelivery);

		if (!storageService.get("authenticated")) {
			$window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
		}

		$scope.check = function (index, addressType, addressesLength) {
			for (i = 0; i < addressesLength; i++) {
				var btn = angular.element(document.querySelector('#radio-button-' + addressType + '-' + i));
				btn.removeClass('fa-check-circle-o');
				btn.addClass('fa-circle-o');
			}
			var selectedBtn = angular.element(document.querySelector('#radio-button-' + addressType + '-' + index));
			selectedBtn.removeClass('fa-circle-o');
			selectedBtn.addClass('fa-check-circle-o');
		}

		$scope.update = function (index, addressType, addressesLength, value) {
			for (i = 0; i < addressesLength; i++) {
				var option = angular.element(document.querySelector('#' + addressType + '-' + i));
				option.removeClass('bold');
			}
			var selectedOption = angular.element(document.querySelector('#' + addressType + '-' + index));
			selectedOption.addClass('bold');
			$scope.defaultAddress = value;

			$scope.changingAddress = false;
			$scope.updateAddress(addressType, value);
			selectedOption.removeClass('bold');
			clicks = 0;
		}

		$scope.updateAddress = function (key, value) {
			generalStore.set(key, value);
			$scope.updateCheckoutAddress({ key: key, value: value, same: $scope.copyAddress, newAddress: true });
		}

		$rootScope.$on('termsAccepted', function () {
			$scope.checkAddress({ addressType: $scope.addressType, address: $scope.defaultAddress });
		})

		$scope.change = function () {
			getCountries(forDelivery);
			$scope.changingAddress = !$scope.changingAddress;
		}

		$scope.add = function () {
			$scope.addEdit = true;
			$scope.newAddress = {};
			document.getElementById("addressCountrySelect-" + $scope.addressType).selectedIndex = -1;
			if ($scope.addressType == "delivery") {
				// When adding a delivery address, the checkbox for copying to billing should be checked by default
				addressCopy($scope.addressType);
			}
		}

		function getCountries(forDelivery) {
			$scope.loadingPage.resolve();
		}

		$scope.cancel = function () {
			// User didn't save changes - restore what the model looked like when the user first clicked Add or Edit
			angular.copy($scope.cachedAddress, $scope.newAddress);
			$scope.addEdit = false;
			$scope.alert = null;
			if ($scope.copyAddress) {
			    addressCopy($scope.addressType);
			}
		};

		$scope.postcodeLookup = function (countryId, fullName) {
			$scope.loading = true;
			var postcode = $scope.newAddress.postcode;
			if (postcode) {
				accountService.lookupAddressByPostcode($scope.newAddress.postcode).$promise.then(function (data) {
					$scope.newAddress = data;
					$scope.newAddress.postcode = postcode.toUpperCase();
					$scope.newAddress.number = null;
					$scope.newAddress.fullName = $scope.cachedAddress ? $scope.cachedAddress.fullName : fullName;
					$scope.newAddress.countryId = $scope.cachedAddress ? $scope.cachedAddress.countryId : countryId;
					$scope.alert = data.found == true ? null : 'POSTCODE_NOT_FOUND';
					$scope.loading = false;
				});
			}
			else {
				$scope.loading = false;
				$scope.alert = 'POSTCODE_NOT_FOUND';
			}
		}

		$scope.updateCountry = function (countryId) {
			$scope.newAddress.countryId = countryId;
			$scope.canCopyAddress = !$scope.deliverToSchoolNoAlternative || $scope.addressType == "delivery" || countryId != generalStore.get('schoolOnlyCountryId').value;
		}

		$scope.delete = function (id) {
			$scope.loading = true;
			accountService.deleteAddress(id).$promise.then(function (data) {
				if (data.success == true) {
					$scope.alert = null;
					notifyService.success("ADDRESS_DELETED_SUCCESS");

				}
				else {
					$scope.alert = 'GENERIC_ALERT';
				}

				$timeout(function () {
					$scope.loading = false;
					$window.location.reload();
				}, 5000);
			});
		}

		$scope.save = function () {
			$scope.newAddress.destination = $scope.addressType == "delivery" ? enums.destination.delivery : enums.destination.invoice;
			$scope.loading = true;
			$scope.changingAddress = false;
			var addrData = [];
			var fields = [{ name: "Full Name", valid: true }, { name: "Country", valid: true }, { name: "Postcode", valid: true }, { name: "House Number", valid: true }, { name: "Street", valid: true }, { name: "City", valid: true }]

			var addrInput = [$scope.newAddress.fullName, $scope.newAddress.countryId, $scope.newAddress.postcode, $scope.newAddress.number, $scope.newAddress.houseStreet, $scope.newAddress.city];
			var addressDesc = ["Full Name", "Country", "Postcode", "House Number", "Street", "City"];
			for (i = 0; i < addressDesc.length; i++) {
				var entry = { description: addressDesc[i], value: addrInput[i] }
				addrData.push(entry);
			}
			var errors = validationService.checkAddressInput(addrData);

			if (!errors.length) {
				accountService.addAddress(JSON.stringify($scope.newAddress)).$promise.then(function (data) {
				    $scope.checkAddress({ addressType: $scope.addressType, address: data, same: $scope.copyAddress });
					$scope.updateCheckoutAddress({ key: $scope.addressType, value: data, same: $scope.copyAddress, newAddress: true });
					$scope.loading = false;
					$scope.confirmAddressSaved();
				});
			}
			else {
				for (i = 0; i < addressDesc.length; i++) {
					if (_.contains(errors, addressDesc[i])) {
						fields[i].valid = false;
					}
				}
				$scope.fields = fields;
				$scope.loading = false;
			}
		}

		$scope.confirmAddressSaved = function () {
			$scope.alert = null;
			notifyService.success("ADDRESS_SAVED_SUCCESS");

			// Reload the default view after a few seconds so the user can clearly see which address they've chosen but can easily select again if they see instantly that they've made a mistake
			$scope.addEdit = false;
			$scope.loadingPage.resolve;

		}

		$rootScope.$on('addressSaved', function () {
		    if ($scope.copyAddress) {
		        addressCopy($scope.addressType);
		    }
		})

		$scope.addressCopy = function (addressType) {
			addressCopy(addressType);
		}

		function addressCopy(addressType) {
			$scope.copyAddress = !$scope.copyAddress;
			var checkbox = angular.element(document.querySelector('#' + addressType + '-address-copy'));
			if (checkbox.hasClass('fa-square-o')) {
				checkbox.removeClass('fa-square-o');
				checkbox.addClass('fa-check-square-o');
			}
			else {
				checkbox.removeClass('fa-check-square-o');
				checkbox.addClass('fa-square-o');
			}
		}

	}
   	];
   	return {
   		scope: { addressType: "=", addresses: "=", defaultAddress: "=", addressToCopy: "=", addressTitle: "=", addressType: "=", updateCheckoutAddress: '&', checkAddress: '&', countries: "=" },
   		controller: controller,
   		templateUrl: '/assets/html/directives/checkout/address.html'
   	}
   });