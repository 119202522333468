angular.module(app.appName)
	.factory('productService', [
		'$q', 'productResource', 'itemResource', 'helpers', '_', 'generalStore', '$route', 'routeService', 'debug',
		function ($q, productResource, itemResource, helpers, _, generalStore, $route, routeService, debug) {
			var self = this;
			self.getProductData = function (product) {
				productResource.getProductData({ productId: product.id }).$promise.then(function (data) {
					product.productData.description = data.description;
				});
			}
			self.setProductData = function (product) {
				if (helpers.isNullOrUndefined(product.productData))
					product.productData = {};
				return product;
			}
			self.getProducts = function (imageId, folderId, isFavourite) {
				console.info("[productService] getProducts for ", { imageId: imageId, folderId: folderId, isFavourite: isFavourite });

				return productResource.getProducts({ imageId: imageId, folderId: folderId, fav: isFavourite });
			};
			self.filterProducts = function () {

				self.getProducts().$promise.then(function (allProducts) {
					_.each(allProducts.products, function (p) {

					});
				});
			}
			return {
				getProducts: function (imageId, folderId, isFavourite) {
					return productResource.getProducts({ imageId: imageId, folderId: folderId, fav: isFavourite });
				},
				getProductData: function (product, folderId) {
					return productResource.getProductData({ productId: product.id, folderId: folderId });
				},
				getProductAndChildrenById: function (productId, folderId) {
					return productResource.getProductAndChildrenById({ id: productId, folderId: folderId });
				},
				getProductById: function (productId, folderId) {
					return productResource.getProductById({ id: productId, folderId: folderId });
				},
				setProductData: function (product) {
					return self.setProductData(product);
				},
				filterProducts: function (availableProducts) {
					return self.filterProducts(availableProducts);
				},
				/*
										The data is returned in a format that's difficult to work with
										This function makes sure that it's in a good format for the front end
								*/


				/* this is only called from products.js directive. */

				filterProductsByCategory: function (imageId, folderId, filterProductId) {
					return $q(function (resolve) {

						var isFave = routeService.get().name.toUpperCase() == 'FAVS';

						return self.getProducts(imageId, folderId, isFave).$promise.then(function (data) {
							// self.currentCategories = generalStore.get('previousProducts').value;
							// Setup our categories for use in the HTML
							var productCategories = [];

							_.each(data.categories, function (category, index) {

								if (index > 3) {
									return;
								}

								// If category.Products hasn't been defined, we need to define it
								if (helpers.isNullOrUndefined(category.products))
									category.products = [];

								// Set the products on the current category to a filtered list via the category id
								category.products = _.filter(data.products,
									function (p) {
										var qualifies = _.contains(p.categoriesId, category.id);
										if (filterProductId) {
											qualifies = qualifies && p.id == filterProductId;
										}
										return qualifies;
									});

								// Add the filtered list to our categories on the scope
								// TODO: REMOVE THIS AFTER TESTING
								productCategories.push(category);
							});

							return resolve(productCategories);
						});
					});
				}
			}
		}
	]);