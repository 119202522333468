/*
    This is a child modal contained within our base modal directive.
    We have access to the base modal scope which means we can access
    data on our core modal directive using $scope.modalBase.<object>
*/

angular.module(app.appName).directive("modalPicturePicker", function () {
  var controller = [
    "$rootScope",
    "$scope",
    "$route",
    "$q",
    "navService",
    "routeService",
    "sittingStore",
    "helpers",
    "itemService",
    "myPicturesService",
    "albumService",
    "itemStore",
    "generalStore",
    "_",
    "modalTypes",
    "modalService",
    "notifyService",
    "$timeout",
    "$interval",
    "itemBuilderService",
    function (
      $rootScope,
      $scope,
      $route,
      $q,
      navService,
      routeService,
      sittingStore,
      helpers,
      itemService,
      myPicturesService,
      albumService,
      itemStore,
      generalStore,
      _,
      modalTypes,
      modalService,
      notifyService,
      $timeout,
      $interval,
      itemBuilderService
    ) {
      var self = this;


      self.errorPrefix = "modalPicturePicker.js Error :: ";

      /* Progress bar */
      self.progressTarget = 0;
      $scope.progressMax = 100;
      $scope.progressValue = 0;
      self.stop = null;
      self.imagePreselected = false;

      /* Behold, the only legitamate use for a timeout! */
      $scope.addProgress = function (targetAddition) {

        $scope.progressValue += targetAddition;

        /* Animation attempt, but framerate is unreliable. */

        // var max = self.progressTarget + targetAddition;
        // self.progressTarget = Math.min($scope.progressMax, max);

        // var frame = function() {
        //   $scope.progressValue = $scope.progressValue + 1;
        //   if ($scope.progressValue >= self.progressTarget) {
        //     console.log('[frame] cancel interval');
        //     $interval.cancel(self.stop);
        //     self.stop = null;
        //   }
        //   console.log(`[frame] ${$scope.progressValue} ${self.progressTarget}`);
        // };

        // if (!self.stop) {
        //   self.stop = $interval(frame, 1);
        // }
      };

      self.singleImageProduct = function () {
        return $scope.amountOfPicturesRequired === 1;
      };

      /* unpopulatedimages/populated images are used for multi image products. */
      self.unpopulatedImages = function () {

        /* Ensure there's a product here to count in the first place! */

        var arr = self.productItems.map(function (productItem) {
          return productItem.images.filter(function (x) { return !x.imgIndex; });
        });
        return arr.reduce(function (a, b) { return a.concat(b); }, []);
      };

      self.populatedImages = function () {
        var arr = self.productItems.map(function (productItem) {
          return productItem.images.filter(function (x) { return x.imgIndex; });
        });
        return arr.reduce(function (a, b) { return a.concat(b); }, []);
      };

      self.allProductItemsWithImage = function () {
        /* Product a set of of the form {productItem, image} that allow
       easy indexing of an individual image. */
        var allProductItems = self.productItems
          .map(function (productItem) {
            return productItem.images.map(function (image) {
              return { productItem: productItem, image: image };
            });
          })
          .reduce(function (a, b) {
            return a.concat(b);
          }, []);

        return allProductItems;
      };

      /* Return the first productItem with an unpopulated image
         slot. */
      self.firstUnpopulatedImage = function () {
        return self.allProductItemsWithImage().filter(function (x) {
          return !x.image || !x.image.imgIndex;
        })[0]; // Find first unpopulated item.
      };

      self.productItemQuantityCount = function () {
        var productCount = self.productItems.reduce(function (acc, curr) {
          /* Only accumulate quantity if image is populated. */
          return (curr.images[0].imgIndex) ? acc + curr.quantity : acc;
        }, 0);
        return productCount;
      };

      self.updateHeader = function () {

        /* What type of product are we creating? */
        var headerFn = self.singleImageProduct() ?
          function () {
            var productItemCount = self.productItemQuantityCount();

            return modalService.updateProductHeader(productItemCount);
          } :
          function () {
            // var selectedPicturesCount = $scope.productItems[0].;
            var selectedPicturesCount = self.populatedImages().length;
            var amountOfPicturesRequired = $scope.amountOfPicturesRequired;

            return modalService
              .updateHeader(selectedPicturesCount, amountOfPicturesRequired)
          };

        headerFn().then(function (translation) {
          $scope.modalBase.headerTitle = translation;
        });
      };

      self.addProductItem = function () {
        /* Adds another product item to the stack from the template and
           returns. */

        var newProductItem = {};
        Object.assign(newProductItem, self.productItemTemplate);
        newProductItem.images = self.productItemTemplate.images.map(function (
          image
        ) {
          var newImage = {};
          Object.assign(newImage, image);

          newImage.imgIndex = null;
          newImage.isPlaceholder = true;
          newImage.lowRes = null;
          newImage.ratio = null;

          return newImage;
        });

        newProductItem.quantity = 1; // Set quantity - new prop.

        self.productItems.push(newProductItem);
        return newProductItem;
      };

      /* Removes product item from the productItem collection. */
      self.removeProductItem = function (productItem) {

        var index = self.productItems.indexOf(productItem);
        if (index == -1) return;

        self.productItems.splice(index, 1);
      };

      /* Update self variables from data */
      self.updateData = function (data) {

        data = data || {}; // Handle null input

        self.product = data.product;

        /* We now set up a template to generate products from. */
        self.productItemTemplate = data.item || {};
        self.productItemTemplate.images = data.item.images || [];

        self.currentPreview = data.currentPreview;
        self.route = data.route;

        // self.productItem = data.item || {};
        // self.productItem.images = self.productItem.images || [];

        self.route = data.route;

        self.productItems = [];
        self.addProductItem(); // Create copy of product item from template.

        $scope.productName = self.product.name;

        $scope.amountOfPicturesRequired = self.productItemTemplate.imgQuantity;
        self.updateHeader();
        /* Also update number of images selected, etc.*/
      };

      self.updateData($scope.modalBase.data);

      $scope.amountOfPicturesRequired = 1;
      $scope.showLightbox = false;
      $scope.lastSelectedImageUrl = '';
      $scope.showUnavailableMessage = false;

      $scope.getPrettyEventDate = function (date) {
        return helpers.prettifyDate(helpers.trimDate(date));
      };

      /* Call parent action if not disabled. */
      $scope.modalBase.backAction = function () {
        if (!$scope.basketDisable) {
          self.productItems = [];
          $scope.modalBase.closeModal();
        }
      };

      $scope.modalBase.disableBackAction = function () {
        return $scope.basketDisable;
      };

      $scope.modalBase.disableContinueAction = function () {
        var result = $scope.basketDisable;

        if (self.singleImageProduct()) {
          /* If single image, then if we have a product, then
             we can add to basket. */
          var quantity = self.productItemQuantityCount();
          // console.info(`[modalPicturePicker] [disableContinueAction] quantity: ${quantity}`);
          result = result || (quantity < 1);
        } else {
          /* If multi-image, then if we can add pictures, we can't add
             to basket. */
          result = result || self.canAddPictures();
        }

        // console.info(`[modalPicturePicker] [disableContinueAction] ${$scope.basketDisable}`);

        return result;
      };

      $scope.modalBase.showContinueSpinner = function () {
        return $scope.basketDisable;
      };

      $scope.modalBase.showContinueAction = function () {
        return true;
      }

      $scope.modalBase.continueAction = function () {
        // console.info(`[modalPicturePicker] [continueAction]`);

        // Check the button isn't disabled. If it is, do nothing
        if ($scope.modalBase.disableContinueAction()) {
          return;
        }

        $scope.basketDisable = true;

        _.each(self.productItems, function (productItem) {
          _.each(productItem.images, function (image) {
            // console.info("[modalPicture.continueAction]", { image: image, ratio: image.ratio });
            var ratio = image.ratio; // This will probably not be set yet.
            if (ratio) {
              itemBuilderService.setDimensions(ratio, productItem);
            }
          });
        });

        itemBuilderService.saveBulkProductItem(self.productItems, self.product).then(function() {
          $scope.progressValue = $scope.progressMax;

          // console.info(`[modalPicturePicker] [continueAction] Promise.all(saveActions) completed.`);
          self.productItems = [];
          $scope.modalBase.closeModal();
        });
      };

      $scope.modalBase.backMessageKey = "BUTTON_CLOSE_PICTURE_PICKER";
      $scope.modalBase.backIcon = "fa-close";

      if ($scope.modalBase.isMobile) {
        // If we're on mobile we want to override the modalBase.backAction function to go back to the products modal.
        $scope.modalBase.backAction = function () {

          if (!$scope.basketDisable) {
            return;
          }

          $scope.modalBase.showModal(
            modalTypes.products,
            null,
            true,
            generalStore.get("currentProductCategory").value.name
          );
        };
      }

      self.canAddPictures = function () {
        /* This now gets a little more complicated, since we're about to create multiple products instead. */

        // Okay.  If it's a single image product, then the answer,
        // is yes.

        var singleImageProduct = self.singleImageProduct();
        var unPopulatedImages = self.unpopulatedImages();

        // console.info(`[canAddPictures] singleImageProduct:${singleImageProduct}, unPopulatedImages:${unPopulatedImages.length}`);

        if (self.singleImageProduct()) return true;

        // If it's not a single image product, then it depends on
        // How many images are unpopulated.
        return unPopulatedImages.length != 0;
      };

      /* This is going to need to know which product to remove the picture from */
      self.decreasePictureFromProduct = function (picture) {

        var imageOnItem = self.allProductItemsWithImage().filter(function (x) {
          return x.image && x.image.imgIndex == picture.id;
        });

        if (imageOnItem.length > 0) {
          imageOnItem = imageOnItem[0];
        }

        var removeImage = self.singleImageProduct() ?
          imageOnItem.productItem.quantity === 1 :    /* Single image product only removed if q 0 */
          true;                                       /* Multi image products always removed */

        if (removeImage) {
          imageOnItem.image.imgIndex = null;
          imageOnItem.image.isPlaceholder = true;
          imageOnItem.image.lowRes = null;
        }

        self.updateCountForPicture(picture, -1);

        /* We only update product item count for single image product. */
        if (self.singleImageProduct()) {
          self.updateCountForProductItem(imageOnItem.productItem, -1);
        }

        /* TODO: We're doing to need to remove the product if the product is empty. */
        self.updateHeader();
      };

      /* Quantity is not a regular property for productItem.
         Manage it. */
      self.updateCountForProductItem = function (productItem, countInc) {
        // console.info(`[modalPicturePicker] [updateCountForPicture] productItem:${productItem.id}, count:${countInc}`)

        if (helpers.isNullOrUndefined(productItem.quantity)) {
          productItem.quantity = 1;
        }

        productItem.quantity = productItem.quantity + countInc;
        if (productItem.quantity < 1) {
          /* A productItem can not have a quantity lower than 1. */
          self.removeProductItem(productItem);
        }
      }

      self.updateCountForPicture = function (picture, countInc) {
        // console.info(`[modalPicturePicker] [updateCountForPicture] picture:${picture.id}, count:${countInc}`)

        // Tells the UI to show that the picture has been selected.
        //picture.isSelected = true;

        var collection = $scope.sitting &&
        $scope.sitting.none &&
        $scope.sitting.none.images ? $scope.sitting.none.images : $scope.sitting.favourites.images;

        var p = _.find(collection, { id: picture.id });
        if (p) {
          if (helpers.isNullOrUndefined(p.amountSelected)) {
            p.amountSelected = 0;
          }
          p.amountSelected = p.amountSelected + countInc;

          p.isSelected = p.amountSelected && p.amountSelected > 0;
        }
      };

      self.addPictureToProduct = function (picture) {
        picture.isPlaceholder = false;

        var imageToSet;

        /* Determine the type of product we're working on. */
        /* If it's a single picture product, then we have a different workflow than
           multi image products. */
        if (self.singleImageProduct()) {
          /* If image already exists in a product, then all we do is
           increase the quantity of that product. */

          imageToSet = self
            .allProductItemsWithImage()
            .filter(function (x) {
              return x.image && x.image.imgIndex == picture.id;
            });

          if (imageToSet.length > 0) {
            imageToSet = imageToSet[0];
            /* We've found the item. */
            self.updateCountForProductItem(imageToSet.productItem, 1);

          } else {
            /* Otherwise add a new product */

            // looks for an empty image slot on the product item
            // this will point directly to the position of the image.

            imageToSet = self.firstUnpopulatedImage(); // may exist.
            if (!imageToSet) {
              self.addProductItem();
              imageToSet = self.firstUnpopulatedImage();
            }

            if (imageToSet) {
              // Sets the image data on the Item object to send to the server
              imageToSet.image.isPlaceholder = false;
              imageToSet.image.imgIndex = picture.id;
              imageToSet.image.lowRes = picture.lowRes;
              imageToSet.image.ratio = picture.ratio;
            }
          }

          self.updateCountForPicture(picture, 1);

          itemBuilderService.setDimensions(
            picture.ratio,
            imageToSet.productItem,
            imageToSet.productItem.images.indexOf(imageToSet.image)
          );

        } else {

          /* In a multi image product we want to see if the productItem exists
             first. */

          imageToSet = self.firstUnpopulatedImage();
          if (!imageToSet) {
            self.addProductItem();
            imageToSet = self.firstUnpopulatedImage();
          }

          if (imageToSet) {
            // Sets the image data on the Item object to send to the server
            imageToSet.image.isPlaceholder = false;
            imageToSet.image.imgIndex = picture.id;
            imageToSet.image.lowRes = picture.lowRes;
            imageToSet.image.ratio = picture.ratio;

            itemBuilderService.setDimensions(
              picture.ratio,
              imageToSet.productItem,
              imageToSet.productItem.images.indexOf(imageToSet.image)
            );


          }


          self.updateCountForPicture(picture, 1);
        }

        // Force the header of the modal to update to the correct values.
        self.updateHeader();
      };

      // Only show the quantity changer element if the user can add more that one image
      $scope.showQuantityChangerElement = function () {
        // We should only ever allow users to add one of each image if they are buying JPEGS

        if (self.productItemTemplate.productName) {
          if (
            self.productItemTemplate.productName
              .toUpperCase()
              .indexOf("JPEG") >= 0
          ) {
            return false;
          }
        }

        return true;

      };

      $scope.increaseQuantityOfPictureChosen = function (picture, $event) {
        // This prevents the element clicks to bubble through the DOM
        $event.stopPropagation();

        if ($scope.basketDisable) {
          return;
        }


        if (self.canAddPictures()) {
          self.addPictureToProduct(picture);
        } else {
          // console.info("[modelPicturePicker] [increaseQuantityOfPictureChosen] canAddPictures fail.")
        }
      };

      $scope.decreaseQuantityOfPictureChosen = function (picture, $event) {
        // This prevents the element clicks to bubble through the DOM
        $event.stopPropagation();

        if ($scope.basketDisable) {
          return;
        }

        /* Why don't we want to decrease the quantity first? */

        self.decreasePictureFromProduct(picture);

      };

      $scope.togglePicture = function (picture) {

        if ($scope.basketDisable) {
          return;
        }

        // Picture was previously, and is currently, selected.
        if (picture.isSelected) {
          self.decreasePictureFromProduct(picture);
        } else {
          if (self.canAddPictures()) {
            self.addPictureToProduct(picture);
          }
        }
        $scope.showLightbox = false;
      };

      $scope.pictureIsSelected = function (picture) {
        return picture.isSelected;
      };

      var markProductImages = function (image) {
        /* we're going to need a collection for productItem.images */

        var allProductItemsWithImage = self.allProductItemsWithImage();
        var found = allProductItemsWithImage.filter(function (x) {
          return x.image && x.image.imgIndex == image.id;
        });

        if (found.length) {
          image.isSelected = true;
          image.amountSelected = found[0].productItem.quantity;
        }
      };

      $scope.loadAlbumPicturesSync = function (sittingId, folderId, productId, favourites) {
        var deferred = $q.defer();

        modalService
          .loadAlbumPictures(sittingId, folderId, productId, favourites)
          .then(function (data) {

            if (data.favourites && data.none) {
              // We have favourites so we need to initialise them
              data.none.images = myPicturesService.initFavouritesOnPictures(
                data.favourites.images,
                data.none.images
              );
            }

            /* Mark each image that's in the product. */

            if (data.none) _.each(data.none.images, markProductImages);
            if (data.favourites) _.each(data.favourites.images, markProductImages);

            /* We also need to find if the product is tagged */
            var tag = itemBuilderService.isSelectedTagged(
              self.productItemTemplate
            );
            if (data.none) {
              myPicturesService.setClientSideExclusions(
                data.none,
                (tag || {}).ratio
              );
            }
            if (data.favourites) {
              myPicturesService.setClientSideExclusions(
                data.favourites,
                (tag || {}).ratio
              );
            }

            $scope.sitting = data;
            deferred.resolve($scope.sitting);
          });

        return deferred.promise;
      };

      self.load = function (folder) {
        $scope
          .loadAlbumPicturesSync(
            folder.sittingId,
            folder.id,
            self.product.id,
            folder.isFavourite
          )
          .then(function (sitting) {
            $scope.sitting = angular.copy(sitting); // Load sitting.

            if ($scope.sitting.none) {
              $scope.sitting.none.images = myPicturesService.selectedToTop(
                $scope.sitting.none.images
              );
            } else {
              $scope.sitting.none = {images: null};
            }

            // If in preview, add to product
            if (self.currentPreview && !self.imagePreselected) {
              self.addPictureToProduct(self.currentPreview);
              self.imagePreselected = true;
            }

            self.initCallback();
          });
      };

      self.initCallback = function () {
        if ($scope.sitting.none.images) {
          // We are viewing an individual album
          sittingStore.saveProductAlbum(self.product.id, $scope.sitting);
        } else if (
          $scope.sitting.none.folders &&
          $scope.sitting.none.folders.years.length > 0
        ) {
          // We are viewing all albums for the sitting
          sittingStore.saveProductSitting($scope.sitting);
        }

        $scope.loadAll = false;
        $scope.isLoading = false;
      };

      $scope.displayLightbox = function (img) {
        $scope.lightboxImg = img;
        $scope.showLightbox = true;
        $scope.lightboxToggleImg = img.isSelected
          ? "LIGHTBOX_DISCARD_IMAGE"
          : "LIGHTBOX_SELECT_IMAGE";
      };

      $scope.closeLightbox = function () {
        $scope.showLightbox = false;
      };

      $scope.displayUnavailableMessage = function (img) {
        $scope.lastSelectedImageUrl = 'Album/' + img.sittingSittingId + '/' + img.imageFolderId + '/' + img.id + '/Preview';
        $scope.showUnavailableMessage = true;
      };

      $scope.closeUnavailableMessage = function () {
        $scope.showUnavailableMessage = false;
      };

      $scope.setQuantity = function (img, newQuantity) {
        basketService
          .setQuantity(basketItem.id, newQuantity)
          .$promise.then(function (returnedItem) {
            // Update the item's properties with the saved item
            $scope.item.quantity = returnedItem.quantity;
            $scope.item.price = returnedItem.price;
            basketService.get().$promise.then(function (data) {
              $scope.updateBasketData({ data: data });
              $timeout(function () {
                $scope.disableQuantityChange = false;
              }, 1000);
            });
          });
      };

      $scope.$watch("disableQuantityChange", function () {
        // Camouflage the input text to avoid it "flickering" while the numbers update
        var inputTextColor = $scope.disableQuantityChange ? "white" : "black";
        var inputs = document.getElementsByClassName("basket-quantity-input");
        angular.forEach(inputs, function (input) {
          input.style.color = inputTextColor;
        });
      });

      $scope.$watch("modalBase.data", function (newData) {
        self.updateData(newData);

        if ($scope.sitting && $scope.sitting.none.images) {
          for (var i = 0; i < $scope.sitting.none.images.length; i++) {
            self.decreasePictureFromProduct($scope.sitting.none.images[i]);
          }
        }

        $scope.currentAlbumId = $scope.modalBase.getModalAlbumId(self.route);
      });

      self.init = function () {
        // We're watching the data on the modalBase because we need to update our child modal $scope objects
        // if we have a change of data. This is to ensure we don't get any data inconsistencies.
      };

      self.init();

      $rootScope.$on('currentFolder', function (e, args) {
        $scope.modalBase.setModalAlbumId(args.id);
        self.load(args);
      });

    },
  ];
  return {
    restrict: "E",
    // 'scope: true' tells this child directive to create its own scope usable within the current directive.
    // What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
    // modalBase object.
    scope: true,
    controller: controller,
    templateUrl: "/assets/html/directives/modal/modalPicturePicker.html",
  };
});
