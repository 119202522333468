angular.module(app.appName)
	.factory('previewService', [
		'itemResource', 'itemStore', 'helpers', '$q', 'statStore', 'notifyService',
		function (itemResource, itemStore, helpers, $q, statStore, notifyService) {
			return {
				setNextAndPreviousIds: function (array, item) {
					if (!item.next) {
						if (_.last(array).id == item.id) { // We are selecting the last iteme in the album.
							item.next = array[0].id
						} else {
							var idx = array.indexOf(item) + 1;
							item.next = array[idx].id;
						}
					}

					if (!item.previous) {
						if (_.first(array).id == item.id) { // Our item is the first in the array
							item.previous = _.last(array).id;
						} else {
							var idx = array.indexOf(item) - 1;
							item.previous = array[idx].id;
						}
					}
				},
				/* Returns a previously selected image preview as
				   a set getAlbumRouteAsObject compatible object. */
				getPreviewAsObject: function (preview, favourites) {
					return {
						favourites: favourites,
						albumId: preview.imageFolderId,
						sittingId: preview.sittingSittingId,
						imageId: preview.id,
						route: ''
					};
				}
			}
		}
	]);