
angular.module(app.appName)
	.controller('notifyMeController', [
		'$scope', '$rootScope', '$route', 'routeService', 'sittingData', 'customerData', 'sessionValidData',
        function ($scope, $rootScope, $route, routeService, sittingData, customerData, sessionValidData) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.sitting = sittingData;
            $scope.customer = customerData;

            $scope.sessionValid = sessionValidData;
}]);