angular.module(app.appName)
    .service('authService', [
        'authResource', 'storageService', 'cacheService', '$rootScope',
        function (authResource, storageService, cacheService, $rootScope) {
            this.login = function (loginData) {
                return authResource.login(loginData).$promise.then(function (result) {
                    cacheService.obliterate();
                    return result;
                });
            }

            this.logout = function () {
                $rootScope.$broadcast('loggingOut');
                cacheService.obliterate();
                storageService.set('authenticated', false);
                storageService.set('validatedEmail', false);
                $rootScope.$broadcast('loggedOut');
                return authResource.logout();
            }

            this.register = function (registrationData) {
                return authResource.register(registrationData).$promise.then(function (result) {
                    cacheService.obliterate();
                    return result;
                });
            }

            this.isAuthenticated = function () {
                return storageService.get('authenticated');
            };

            this.resetPassword = function (form) {
                return authResource.resetPassword(form).$promise.then(function (result) {
                    return result;
                });
            },

            this.checkUserExists = function (email) {
                return authResource.checkUserExists({ email: email });
            }
        }
    ]);