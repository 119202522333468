angular.module(app.appName)
    .controller('approvalsController', [
            '$scope', '$rootScope', '$window', 'storageService', '$q', 'accountService', 'helpers', 'routeService', '$timeout', 'notifyService',
        function ($scope, $rootScope, $window, storageService, $q, accountService, helpers, routeService, $timeout, notifyService) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            if (!storageService.get("authenticated")) {
                $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
            }

            $scope.approvalDetails = false;
            routeService.setAccountLink("Account"); // We are in top Approvals view, so back button should take us back to My Account

            $scope.loadingPage = $q.defer();
            accountService.getApprovals().$promise.then(function (data) {
                var approvals = data;
                angular.forEach(approvals, function (approval) {
                    approval.orderDate = helpers.prettifyDate(helpers.trimDate(approval.orderDate));
                });
                $scope.approvals = approvals;
                $scope.loadingPage.resolve();
            });

            $scope.view = function (approvalId) {
                routeService.setAccountLink("Approvals/#"); // Don't go all the way back to My Account, just reload the top Approvals view
                $scope.approvalData = $q.defer();
                $scope.approvalDetails = true;
                $scope.approvalData.resolve();
                accountService.getApprovalItems(approvalId).$promise.then(function (data) {
                    $scope.approvalItems = data;
                    angular.forEach($scope.approvalItems, function (item) {

                        /* Create a ezp-model for zooming */
                        item.ezpModel = { thumb: item.preview.url, small: item.preview.url, large: item.preview.url };

                        item.revisionEnabled = false;
                        item.alert = 'APPROVAL_MODE';
                    })
                    $scope.approvalDetails = true;
                    $scope.approvalData.resolve();
                });
            }

            $scope.approve = function (id) {
                accountService.approveItem(id).$promise.then(function (data) {
                    if (data.success == true) {
                        notifyService.success();
                        $timeout(function () {
                            $window.location.reload();
                            $scope.loading = false;
                        }, 5000);
                    }
                    else {
                        notifyService.error();
                    }
                })
            }

            $scope.revise = function (id, comments) {
                $scope.loading = true;
                accountService.reviseApprovalItem(id, comments).$promise.then(function (data) {
                    if (data.success == true) {
                        notifyService.success('REVISION_SUBMITTED');
                        $timeout(function () {
                            $window.location.reload();
                            $scope.loading = false;
                        }, 5000);
                    }
                    else {
                        notifyService.error();
                    }
                })
            }

            $scope.toggleButtons = function ($index, comments) {
                // A comment is required when requesting revision
                // No comment is required when approving
                if (!comments | comments.length < 5) {
                    $scope.approvalItems[$index].revisionEnabled = false;
                    $scope.approvalItems[$index].alert = 'APPROVAL_MODE';
                }
                else {
                    $scope.approvalItems[$index].revisionEnabled = true;
                    $scope.approvalItems[$index].alert = 'REVISION_MODE';
                }
            }
        }
    ]);