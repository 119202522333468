angular.module(app.appName)
	.directive('favourite', function () {
		var controller = ['$scope', '$rootScope', 'myPicturesService', 'enums', '$attrs', 'statStore', 'storageService', 'modalTypes',
			function ($scope, $rootScope,  myPicturesService, enums, $attrs, statStore, storageService, modalTypes) {
				var self = this;

				$scope.togglePictureFavouriteState = function (picture) {
					if (!$attrs.readOnly) {
						var isLoggedIn = storageService.get("authenticated");
						if (!isLoggedIn && statStore.favouriteCount == 0) {
							$rootScope.modal.showModal(modalTypes.loginReminder);
						}
						// If the picture is a favourite, we want to assign the correct enum value to pass to the changeStatus method
						var setFavourite = picture.isFavourite ? enums.imagePreferenceStatus.none : enums.imagePreferenceStatus.favourite;
						myPicturesService.changeStatus(picture.id, enums.imagePreferenceType.image, setFavourite, null, null, false)
							.$promise.then(function (data) {
								// If there isn't an error, we have successfully set the favourite
								if (data.error == false) {
									myPicturesService.setFavouriteOnPicture(picture);
									//$scope.sittingData.none.images = myPicturesService.sortByFavourite($scope.sittingData.none.images);
									$rootScope.$broadcast('favouriteChanged');
								} else {
									notifyService.error();
								}
							});
					}
				}

				$scope.goToBasket = function ($event) {
				    $event.stopPropagation();
				    $location.path("/Basket");
				}
			}
		];

		return {
			scope: {
				image: '=',
				readOnly: '=?'
			},
			controller: controller,
			templateUrl: '/assets/html/directives/favourite.html'
		}
	});