angular.module(app.appName)
    .controller('downloadsController', [
            '$scope', '$rootScope', '$window', 'storageService', 'accountService', '$q', 'helpers',
        function ($scope, $rootScope, $window, storageService, accountService, $q, helpers) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.downloadStatus = {
				available: 0,
				processing: 1,
                onHold: 2,
				expired: 3
			}

            self.paymentStatuses = [
                'CCPENDING',
                'PENDING',
                'COMPLETED',
                'FAILED',
                'COMMUNICATION',
                'FRAUD',
                'PREAUTH',
                'SERVERFAIL',
                'PAYPALPENDING'
            ];

            if (!storageService.get("authenticated")) {
                $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
            }

            $scope.loadingPage = $q.defer();
            self.resolves = {
                orderData: $q(function (resolve) {
                    accountService.getOrders().$promise.then(function (data) {
                        resolve(data);
                    });
                }),
                downloadData: $q(function (resolve) {
                    accountService.getDownloads().$promise.then(function (data) {
                        resolve(data);
                    });
                })
            };

            $q.all(self.resolves).then(function (resolved) {
                $scope.downloads = resolved.downloadData;
                $scope.orders = resolved.orderData;
                angular.forEach($scope.downloads, function (download) {
                    download.orderDate = helpers.prettifyDate(helpers.trimDate(download.orderDate));
                    if (download.zipTimeout) {
                        download.zipTimeout = helpers.prettifyDate(helpers.trimDate(download.zipTimeout));
                    } else {
                        download.zipTimeout = "-";
                    }

                    var orderData = $scope.orders.find(function (order) {return order.orderNumber == download.orderNumber});

                    if (download.zipUploaded == null) {
                        if (orderData && $scope.onHold(orderData.paymentStatus)) {
                            download.status = $scope.downloadStatus.onHold;
                        } else {
                            download.status = $scope.downloadStatus.processing;
                        }
                    } else if (download.expired) {
                        download.status = $scope.downloadStatus.expired;
                    } else {
                        download.status = $scope.downloadStatus.available;
                    }
                });

                $scope.loadingPage.resolve();
            });

            $scope.onHold = function(paymentStatus) {
                if (self.paymentStatuses[paymentStatus] == 'COMPLETED') {
                    return false;
                } else {
                    return true;
                }
            }

            $scope.startDownload = function(download) {
                accountService.getDownloadUrl(download.orderItemId).$promise
                .then(function(data){
                    window.location.assign(data.url);
                });
            }
        }
    ]);
