angular.module(app.appName)
    .controller('basketController', [
        '$scope', '$rootScope', 'basketData', 'navService', 'generalStore', '$translate', 'routeService', 'debug', 'modalTypes', 'enums', '$timeout', 'basketService', '$q', 'storageService',
        function ($scope, $rootScope, basketData, navService, generalStore, $translate, routeService, debug, modalTypes, enums, $timeout, basketService, $q, storageService) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.navService = navService;
            $scope.generalStore = generalStore;
            $scope.noBasket = false;
            $scope.confirmCrop = false;

            $scope.isValidatedSession = storageService.get('validatedEmail');

            // $scope.basketData = basketData;

            $scope.showClearBasket = window.allowDebug;

            $scope.loadingPage = $q.defer();
            basketData.$promise.then(function (data) {
                if ($scope.showClearBasket) {
                    $scope.originalBasketData = angular.copy(data);
                }
                $scope.updateBasketData(data);
                $scope.loadingPage.resolve();
            });

            // dev function to aid debugging
            $scope.clearBasket = function() {
                var deletePromises = $scope.originalBasketData.basketItems.map(function(basketItem) {
                        basketService.deleteItem(basketItem.id);
                    });

                    $q.all(deletePromises).then(function() {
                        alert("Dev - Basket cleared - please refresh page.");
                    });
            };

            $scope.updateBasketData = function (data) {
                console.info("[basketController] Updating basket data on controller.");

                console.info("Basket data", data);

                $scope.evaluateItems(data);

                $scope.basketData = data;

                if (!data.noBasket) {
                    // Rearrange the basketItems so that child items are nested within their parent.
                    var childBasketItems = data.basketItems.filter( function(basketItem) { return basketItem.parentId; } );
                    $scope.basketData.basketItems = $scope.basketData.basketItems.filter( function(basketItem) {
                        return !basketItem.parentId; }).map( function(parentBasketItem) {
                            parentBasketItem.childItems = childBasketItems.filter( function(childItem) { return childItem.parentId == parentBasketItem.id; } );
                            return parentBasketItem; });

                    $rootScope.$broadcast('statsChanged');
                }
                // This is an object so that we can pass it to the $translate for translation. Must be an object
                $scope.basketItemsCount = { basketItemsCount: $scope.basketData.basketItems != null ? $scope.basketData.basketItems.length : 0 };
                $scope.basketHeaderItems = self.getTranslation($scope.basketItemsCount.basketItemsCount);
            }

            self.getTranslation = function (basketItemsCount) {
                return $translate('BASKET_HEADER_ITEMS', { basketItemsCount: basketItemsCount }).then(function (translation) {
                    return translation;
                });
            }

            /* Make decisions about the items in the basket, using state. */
            $scope.evaluateItems = function (data) {

                $scope.confirmCrop = false;
                if (!data.noBasket) {
                    data.basketItems.forEach(function (basketItem) {

                        var crop = (basketItem.options.filter(x => x.crop !== null)[0] || {}).crop;
                        var processType = (basketItem.options.filter(x => x.processType)[0] || {}).processType;
                        var orientation = (basketItem.options.filter(x => x.orientation)[0] || {}).orientation;

                        // Set defaults
                        basketItem.specialInstructions = enums.specialInstructions.allowed;
                        basketItem.customerInstructions = false;
                        basketItem.adjustPicture = false;
                        basketItem.cropping = crop === undefined ? true : crop;

                        // Change on type
                        // Process Type can apply on any option type

                        if (processType == 'oi' || processType == 'ow') {
                            basketItem.specialInstructions = enums.specialInstructions.none;
                            // basketItem.cropping = false;
                            basketItem.cropping = crop === undefined ? false : crop;
                            basketItem.adjustPicture = false;
                        }
                        if (processType == 'op') {
                            basketItem.specialInstructions = enums.specialInstructions.none;
                            basketItem.adjustPicture = true;
                            basketItem.customerInstructions = 'BASKETITEM_INSTRUCTIONS_CROP';

                            if (crop) { $scope.confirmCrop = true; }
                        }
                        if (processType == 'os') {
                            basketItem.specialInstructions = enums.specialInstructions.required;
                            basketItem.adjustPicture = true;
                            basketItem.customerInstructions = 'BASKETITEM_INSTRUCTIONS_SPECIAL';
                        }

                        /* If orientation is defined at all, do not allow the
                           customer to rotate the image. */
                        if (orientation) {
                            basketItem.lockRotation = true;
                        }
                    });
                }
            }

            $scope.redirectToLogin = function () {
                routeService.setPreviousRoute('/Basket');
                generalStore.set('nextRoute', "/Basket");
                routeService.redirect("/Login");
            }

            $scope.redirectToRegister = function () {
                routeService.setPreviousRoute('/Basket');
                generalStore.set('nextRoute', "/Basket");
                routeService.redirect("/Register");
            }

            $scope.offsetHeader = function () {
                return $('header .header').height() + $('.banner').height() + 10;
            };

            /* Take the user to the first crop */
            $scope.highlightCrop = function () {

                var cropInstructions = $("[ng-data-instructions='BASKETITEM_INSTRUCTIONS_CROP']").first();

                $([document.documentElement, document.body]).animate({
                    scrollTop: cropInstructions.offset().top - $scope.offsetHeader()
                }, 200);
            }

            /* Check basket validity before redirecting to checkout. */
            /* Display modal if any parts require confirmation. */
            $scope.confirmBasket = function () {

                /* check all required items have special instructions */
                /* if any are incomplete, scroll to complete */
                var required = $('textarea.required:visible')
                if (required.length) {
                    var first = required.first();

                    var instructions = first.parents('.basket-pictures').children('.crop-instructions');

                    $([document.documentElement, document.body]).animate({
                        scrollTop: instructions.offset().top - $scope.offsetHeader()
                    }, 200);

                    $translate('CONFIRM_SPECIAL_INSTRUCTIONS').then(function (translation) {
                        first.addClass('highlight');
                        first.attr('placeholder', translation);

                        $timeout(function () {
                            first.removeClass('highlight');
                            first.removeAttr('placeholder');
                        }, 4000);
                    });
                    return; // stop
                }


                if ($scope.confirmCrop) {
                    $rootScope.modal.showModal(
                        modalTypes.confirmCrop,
                        {
                            continue: function () { $scope.checkout(); },
                            close: function () { $scope.highlightCrop(); }
                        },
                        $scope.mobile
                    );
                } else {
                    $scope.checkout();
                }
            }

            $scope.checkout = function () {
                routeService.setPreviousRoute('/Basket');
                routeService.redirect("/Checkout");
            }
        }
    ]);