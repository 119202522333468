angular.module(app.appName)
	.factory('feedbackResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl() + '/Feedback/';
		    return $resource(api, {}, {
					get: {
						method: 'GET',
					url: api + 'Get'
					},
		    	post: {
		    		method: 'POST',
					url: api + 'Post'
		    	}
		    });
		}
	]);