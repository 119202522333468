/*
    Sitting Ids:
    WPYA1035MVB
    CALCPE27121203D
    PPSG210213P
    PPRG050213P
*/

angular.module(app.appName)
	.controller('myPicturesController', [
		'$scope', 'myPicturesService', 'productService', 'helpers', '_', function ($scope, myPicturesService, productService, helpers, _) {
		    var self = this;
		    $scope.sittingId = 'PPSG210213P';
		    self.changeSitting = function (id) {
		        $scope.sitting = myPicturesService.getSitting(id);
		        myPicturesService.getSitting(id).promise.then(function (d) {
		        });
		    };

		    // Ensures the method gets called at least once to setup data.
		    self.changeSitting($scope.sittingId);

		    $scope.changeFolder = function (id) {
		        self.changeSitting(id);
		    };
		    $scope.previewImage = function (pictureId) {
		        $scope.sitting.previewPicture = _.find($scope.sitting.none.images, { id: pictureId });

		        $scope.sitting.showPreview = true;
		    };
		}]);