angular.module(app.appName)
  .directive('tabBar', function () {
   return {
      scope: {
        model: '=',       // Linked model
        defaultTab: '=',
      },
      link: function(scope, element, attrs, controller, transcludeFn) {

        /* What do we care about? */
        /* We want all child elements */

        var _tabs = Array.from(element[0].children[0].getElementsByTagName('tab'))
        scope.tabs = _tabs
            .map(function(tab) {
                return {
                    label: scope.$eval(tab.getAttribute('label')), // Do we need to eval this?
                    hide: scope.$eval(tab.getAttribute('ng-hide'))
                }
            }); // DOMObj
          hidden = 0;
          scope.tabs.forEach(function(tab) {
              if (tab.hide) hidden++;
          })
          if (scope.tabs.length - hidden === 1) {
              scope.hideAllTabs = true;
          }
        // scope.tabNames = tabs.map(function(tab) { return tab.getAttribute('label')});

        /* Set all tabs to hidden */
        var hideTabs = function() {
          _tabs.forEach(function(tab) {
            tab.classList.add('hidden');
          });
        };

        /* On a tab being selected, */
        scope.selectTab = function(index) {

          scope.selectedIndex = index;

          hideTabs();
          _tabs[index].classList.remove('hidden');
        }

        /* Select first tab */
        scope.selectTab(scope.defaultTab || 0);

      },
     transclude: true,
      templateUrl: '/assets/html/directives/checkout/tabBarModule.html'
    }
  });

