angular.module(app.appName)
    .directive('approvalItems', function () {
        var controller = [
            '$scope', '$timeout',
            function ($scope, $timeout) {
            	var self = this;

                $scope.previewImage = function(item) {

                    $.fancyboxPlus({href:item.preview.url});
                }
            }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/account/approvals/approvalItems.html'
        }
    });