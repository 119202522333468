angular.module(app.appName)
	.directive('basketItem', function () {
		var controller = [
			'$scope', '$rootScope', 'basketService', 'itemService', 'itemResource', '_', 'notifyService', 'statStore', 'cacheService', 'debug', 'enums',
			function ($scope, $rootScope, basketService, itemService, itemResource, _, notifyService, statStore, cacheService, debug, enums) {

				var debug = debug();

				var self = this;
				$scope.showAreYouSure = false;
				$scope.desktop = window.innerWidth >= 1280;
				$scope.showChildItems = false;
				$scope.rotateLoadingRecord = {};

				$scope.toggleAreYouSure = function () {
					$scope.showAreYouSure = !$scope.showAreYouSure;
				};

				// This is what we pass to the "are-you-sure" directive which gets called when he hit "yes".
				// This will remove the product from the basket.
				$scope.removeItemFromBasket = function () {
					cacheService.obliterate();
					$scope.item.childItems.forEach( function(childItem) {
						basketService.deleteItem(childItem.id, function() {});
					});

					basketService.deleteItem($scope.item.id, function (data) {
						cacheService.obliterate();
						notifyService.success("successfuly deleted the item from  your basket!");
						$scope.basket = _.filter($scope.basket, function (item) { return item.id !== $scope.item.id });
						$scope.basketCount.basketItemsCount--;
						statStore.basketCount = $scope.basketCount.basketItemsCount;
						$scope.updateBasketData({ data: data });
					});
				};

				// Options come back as one comma-separated string, so we need to split it so we can display them on multiple lines
				//var myString = '';
				//_.each($scope.item.options, function (item) {
				//    myString += item.description + ',';
				//});
				//$scope.item.options = myString;
				//$scope.item.options = $scope.item.options.split(',');

				//for (var option in $scope.item.options) {
				//    // After splitting our string, we have whitespace. Trim it off.
				//    $scope.item.options[option] = $scope.item.options[option].trim();
				//}

				$scope.incrementQuantity = function (basketItem) {
					$scope.disableQuantityChange = true;
					if (basketItem.quantity < 999) {
						basketItem.quantity++;
					}
					$scope.setQuantity(basketItem, basketItem.quantity);
					$scope.setChildItemQuantity(basketItem, basketItem.quantity);
				};

				$scope.decrementQuantity = function (basketItem) {
					$scope.disableQuantityChange = true;
					if (basketItem.quantity > 1) {
						basketItem.quantity--;
					}
					$scope.setQuantity(basketItem, basketItem.quantity);
					$scope.setChildItemQuantity(basketItem, basketItem.quantity);
				};

				$scope.setChildItemQuantity = function (basketItem, quantity) {
					basketItem.childItems.forEach( function(childItem) {
						childItem.quantity = quantity
						$scope.setQuantity(childItem, quantity);
					});
				};

				$scope.typeInQuantity = function (basketItem, newQuantity, index) {

					if (newQuantity) {
						var inputs = document.getElementsByClassName("basket-quantity-input");
						angular.forEach(inputs, function (input) {
							if (isNaN(input.value) || input.value <= 0) {
								input.value = 1;
								newQuantity = 1;
							}
						});
						$scope.disableQuantityChange = true;
						$scope.setQuantity(basketItem, newQuantity);
					}
				};

				$scope.$watch('disableQuantityChange', function () {
					console.debug("[basketItem] disableQuantityChange value: ", $scope.disableQuantityChange);
					// Camouflage the input text to avoid it "flickering" while the numbers update
					var inputTextColor = $scope.disableQuantityChange ? "white" : "black";
					var inputs = document.getElementsByClassName("basket-quantity-input");
					angular.forEach(inputs, function (input) {
						input.style.color = inputTextColor;
					});
				})

				$scope.setQuantity = function (basketItem, newQuantity) {
					basketService.setQuantity(basketItem.id, newQuantity).$promise.then(
						function (data) {
							$scope.updateBasketData({
								data: data
							});
							$scope.disableQuantityChange = false;
						});
				};

				$scope.toggleAdjustPicture = function (childItem) {
					if (childItem) {
						childItem.adjustPicture = !childItem.adjustPicture;
					}
					else {
						$scope.item.adjustPicture = !$scope.item.adjustPicture;
					}
				};

				$scope.toggleChildItems = function () {
					$scope.showChildItems = !$scope.showChildItems;
				}

				$scope.applyRotation = function (position, childItem) {
					if (childItem) {
						$scope.rotateLoadingRecord[childItem.itemId] = true;
						itemResource.rotateImage({ itemId: childItem.itemId, position: position }).$promise.then(function (data) {
							var index = _.findIndex(childItem.item.images, function (item) {
								return item.position === position;
							});
							childItem.item.images[index] = data;
							itemResource.generatePip({ itemId: childItem.itemId }).$promise.then(function (pip) {
								childItem.pip = pip;
								$scope.rotateLoadingRecord[childItem.itemId] = false;
							});
						});
					} else {
						$scope.rotateLoadingRecord[$scope.item.itemId] = true;
						itemResource.rotateImage({ itemId: $scope.item.itemId, position: position }).$promise.then(function (data) {
							var index = _.findIndex($scope.item.item.images, function (item) {
								return item.position === position;
							});
							$scope.item.item.images[index] = data;
							itemResource.generatePip({ itemId: $scope.item.itemId }).$promise.then(function (pip) {
								$scope.item.pip = pip;
								$scope.rotateLoadingRecord[$scope.item.itemId] = false;
							});
						});
					}
				};

				$scope.rotateLoading = function (itemId) {
					return $scope.rotateLoadingRecord[itemId];
				}

				$scope.editNote = function (itemId, note) {
					$scope.error = false;
					var tick = document.getElementById(itemId);
					angular.element(tick).removeClass("fa fa-check");
					$scope.loading = true;
					itemService.editNote(itemId, note).$promise.then(function (data) {
						var response = "" + data[0] + "" + data[1];
						if (response == "OK") {
							if (! $scope.checkNoteRequired()) {
								angular.element(tick).addClass("fa fa-check");
							}
						}
						else {
							$scope.error = true;
						}
						$scope.loading = false;

					})
				};

				$scope.checkNoteRequired = function() {
					var notes = $scope.item.itemNotes;
					var empty = notes === null || notes.match(/^ *$/) !== null;
					var response = ($scope.item.specialInstructions == enums.specialInstructions.required) && empty
					return response;
				};
			}
		];
		return {
			restrict: 'E',
			scope: {
				item: "=",
				basket: "=",
				basketCount: "=",
				updateBasketData: '&'
			},
			controller: controller,
			templateUrl: '/assets/html/directives/basketItem/basketItem.html'
		}
	});