angular.module(app.appName)
   .directive('load', function () {
       var controller = ['$scope', '$q', '$timeout', 'routeService', '$route', function ($scope, $q, $timeout, routeService, $route) {
           $scope.isLoading = true;
           $scope.isPortrait = false;
           $q.all($scope.waitFor).then(function () {

               $scope.isLoading = false;

               if (window.innerWidth < 1408) {
                   // Mobile fix: When in Preview, hide the pictures while working out if width needs to be manipulated so the user doesn't see the size changing
                   if (routeService.get().name == 'ALBUM' && $route.current.params.pictureId && document.getElementsByClassName('picture-preview')) {
                       angular.forEach(document.getElementsByClassName('picture-preview'), function (picture) {
                           picture.style.opacity = 0;
                       })
                   }

                   $timeout(function () {
                       // Fix the loading div to stop portrait images stretching too much
                       // The fix-fade class must only be applied if we are in Preview - otherwise the rest of the styles get messed up
                       if (document.getElementById('preview-img')) {
                           $scope.isPortrait = document.getElementById('preview-img').offsetWidth < document.getElementById('preview-img').offsetHeight;
                           document.getElementsByClassName('picture-preview')[0].style.opacity = 1;
                       }
                   }, 500);
               }
           });
       }];

       return {
           restrict: 'AE',
           templateUrl: '/assets/html/directives/loading.html',
           scope: {
               waitFor: '='
           },
           transclude: true,
           controller: controller
       }

   });
