angular.module(app.appName)
	.factory('countryResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl() + '/User/';
		    return $resource(api, {}, {
		        get: {
		            method: 'GET',
		            url: api + 'GetCountries',
		            params: {
                        forDelivery: '@forDelivery' // bool
		            }
		        }

		    });
		}
	]);