var app = {
    appName: 'pret',
	useLocalStorage: true,
}

angular.module(app.appName, [
	'ngResource',
    'ngRoute',
    'pascalprecht.translate',
    'LocalStorageModule',
    'ngSanitize',
    'ngTouch',
    'ngAnimate',
    'ezplus'
]);

angular.module(app.appName)
    .config(['$routeProvider', '$locationProvider', '$httpProvider', '$animateProvider', '$provide',
        function ($routeProvider, $locationProvider, $httpProvider, $animateProvider, $provide) {
            if (!window.location.origin) {
                window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            }
            $locationProvider.html5Mode(true, { requireBase: false });
			$animateProvider.classNameFilter(/animate/);

            $provide.factory('errorInterceptor', [
				'$q', '$rootScope', 'storageService', 'paramService',
				function ($q, $rootScope, storageService, paramService) {
            	return {
            	    'request': function (config) {
                        // We have to use a custom paramService to get the parameter as we don't have access to the $route here because the route resolving happens after the interceptor
	                    var lang = paramService.getParam('lang');
            			if (document.cookie.indexOf("AUTH") == -1) {
            				storageService.set('authenticated', false);
            			}
						// We don't want to append the translate call! This causes the $translate service to not get all translations
						// if (config.url.toLowerCase().indexOf('translate/get') < 0) {
						config.params = config.params || {};
						if (config.url.toLowerCase().indexOf('translate/get') < 0 && config.url.toLowerCase().indexOf('.html') < 0) {
							if (!lang) {
								lang = storageService.get('culture') != null ? storageService.get('culture') : 'en';
							}
							config.params.lang = lang;
						}
            			return config || $q.when(config);
            		},
            		'requestError': function (rejection) {
            			return $q.reject(rejection);
            		},
            		'response': function (response) {
            			return response || $q.when(response);
            		},
            		'responseError': function (rejection) {
            			if (rejection.status == '403') {
            				storageService.set('authenticated', false);
            				$rootScope.$broadcast('loggedOut');
            				if (window.location.pathname != "/register" || window.location/pathname != "/login") {
								window.location = "/login";
							}

            				return $q.reject(rejection);
            			}

            			if (rejection.status == '500') {
            				humane.create({ baseCls: 'humane-jackedup', timeout: 5000, clickToClose: true, addnCls: 'humane-error' })
            					.log('An unexpected error has occured. Please refresh and try again.');
            				console.log('Error: ', rejection);
            			}

            			return $q.reject(rejection);
            		}
            	};
            }]);

            $httpProvider.interceptors.push('errorInterceptor');
        }
    ]);

angular.module(app.appName)
	.value('apiUrl', function () {
		return window.location.origin + "/api";
	});


// Should we be using http caching?
angular.module(app.appName)
	.value('useCache', function () {
		return true;
	});

// Should console logging/debugging statements be turned on in the application?
angular.module(app.appName)
	.value('debug', function () {
		return false;
	});

angular.module(app.appName)
	.value('paypalEnvironment', function() {
		return window.paypalEnvironment;
	});

// Setup jQuery
angular.module(app.appName)
    .factory('$', function () { return $; });

angular.module(app.appName)
    .factory('_', function () {
        return window._; // assumes underscore has already been loaded on the page
    });

angular.module(app.appName)
    .factory('interact', function () {
        return window.interact;
    });

angular.module(app.appName)
    .factory('notify', function () {
        var humaneNotify = humane.create({ baseCls: 'humane-jackedup' });
    });

angular.module(app.appName)
		.factory('config', function () {
			return window.appConfig || {};
		});