angular.module(app.appName)
    .service('notifyService', [
		'$translate',
        function ($translate) {
        	var self = this;
        	self.create = function (type) {
        		return humane.create({ baseCls: 'humane-jackedup', timeout: 5000, clickToClose: true, addnCls: type != null ? 'humane-' + type.toLowerCase() : null });
        	}

        	return {
        		/*
					<summary>Creates and returns an instance of a notification</summary>
					<param name="type" type="string">The type of the notification. This is used by the class to add a class of '.humane-<type>'</param>
				*/
        		create: function (type) {
        			return self.create(type);
        		},
        		// Gets a notification instance and then calls it with the message
        		// Defaults type to none (blue notification)
        		log: function (translationKey, type) {
        			$translate(translationKey).then(function (translation) {
						return self.create('error').log(translation);
        			});
        			return this.create(type).log(message);
        		},
        		// Logs an error notification
				// If you pass no message, a generic error will be displayed.
        		error: function (translationKey) {
        			if (translationKey == null) {
        				return $translate('ERROR_GENERIC').then(function (translation) {
        					return self.create('error').log(translation);
        				});
        			} else {
						$translate(translationKey).then(function (translation) {
							return self.create('error').log(translation);
						});
        			}
        		},
        		// Logs a success notification
        		success: function (translationKey) {
        			$translate(translationKey).then(function (translation) {
        				return self.create('success').log(translation);
        			});
        		}
        	}
        }
    ]);