angular.module(app.appName)
    .controller('albumController', [
        '$rootScope', '$scope', '$route', 'helpers', 'enums', '_', 'myPicturesService', 'navService', 'sittingData', 'sittingStore', 'routeService', 'notifyService', 'generalStore', 'statStore', 'productData', 'basketService', 'previewService', '$location', 'upsellStore', '$timeout', '$translate', '$q',
        function ($rootScope, $scope, $route, helpers, enums, _, myPicturesService, navService, sittingData, sittingStore, routeService, notifyService, generalStore, statStore, productData, basketService, previewService, $location, upsellStore, $timeout, $translate, $q) {

            var self = this;

            $scope.$emit('showProducts', { state : false });

            var routeName = routeService.get().name;
            var params = { albumName: navService.getAlbumName() };
            routeService.set(routeName, params);
            $scope.navService = navService;
            $scope.routeService = routeService;

            $rootScope.containerClass = 'album-container';

            $scope.sittingData = sittingData;

            $scope.loading = true;

            // Is this firing on reload?

            $q.all([sittingData.promise, productData.$promise]).then(function(resolves) {

                if (resolves[0].none == null) {
                    routeService.redirect('/MyAlbums');
                    return;
                }

                /* Digest cycle problem, so to allow loading to behave, put in a 0 timeout.
                   I don't like this. */
                $timeout(function () {

                    var sittingData = resolves[0];
                    var productData = resolves[1];

                    sittingData.none.images = myPicturesService.initFavouritesOnPictures(sittingData.favourites.images, sittingData.none.images);
                    $scope.sitting = sittingData;
                    $scope.promotedProducts = productData.products
                        .filter(function(product) { return product.promoteGallery; });

                    $scope.promotedProducts
                        .sort(function(productA, productB) { return productA.promoteGallery - productB.promoteGallery});

                    var usedProducts = Math.floor($scope.sitting.none.images.length / 2);
                    $scope.remainingProducts = $scope.promotedProducts.slice(usedProducts);

                    sittingStore.set($scope.sitting);
                    generalStore.set('currentSitting', $scope.sitting);
                    generalStore.set('currentSittingId', $route.current.params.sittingId);
                    generalStore.set('currentAlbumId', $route.current.params.albumId);
                    generalStore.set('filterProductId', null);
                    $rootScope.$broadcast('changeAlbum', { albumId: $route.current.params.albumId });
                    $rootScope.$broadcast('productsTrigger', { albumId: $route.current.params.albumId });

                    $rootScope.$emit('disableBuy', { disable: true });

                    var discountPromise = $translate('DISCOUNTS');
                    var expiringPromise = $translate('DISCOUNTS_EXPIRING');
                    $q.all([discountPromise, expiringPromise]).then(function (translations) {
                        $scope.discountString = translations[0];
                        $scope.expiringString = translations[1];
                    });

                    clearInterval($scope.upsellTimer);
                    $scope.upsellTimer = setInterval($scope.updateUpsellTimer, 1000);
                    $scope.nextUpsellTimeout = $scope.updateNextUpsell()
                    $scope.displayUpsells();

                    $scope.loading = false;
                }, 0);
            });

            self.albumId = $route.current.params.albumId;
            $scope.albumId = self.albumId;

            $scope.previewPicture = function (picture) {
                $rootScope.$emit('disableBuy', { disable: true });
                generalStore.set('focusElement', picture.id);
                previewService.setNextAndPreviousIds($scope.sitting.none.images, picture);
                navService.previewPicture($route.current.params.sittingId, $route.current.params.albumId, picture.id);
            };

            $scope.goToBasket = function ($event) {
                $event.stopPropagation();
                $location.path("/Basket");
            }

            $scope.setFocus = function () {
                // Typing in the add sitting input triggers this function. If 'focusElement' is an album image, which
                // it will be when navigating back from preview, this will cause focus to be removed from the input
                // after each keypress.
                if (document.activeElement.nodeName.toLowerCase() != 'input') {
                    // This restores focus to the previously selected album image when navigating back from preview
                    // so scroll position is maintained.
                    var selectedImage = document.getElementById(generalStore.get('focusElement').value);
                    if (selectedImage) {
                        selectedImage.focus();
                    }
                }
            };

            /* Manage upsell state */
            $scope.upsellOpen = false;

            /* Check if upsells for this album have previously been visited.
               If they have, do not display, otherwise display. */
            $scope.displayUpsells = function () {
                $scope.upsellOpen = !upsellStore.get($scope.albumId) || false;
                upsellStore.set($scope.albumId, true); // we have now visited
            };

            $scope.getUpsells = function() {
                // Earlybird upsell is shown in heading. Don't show again in upsell list.
                return $scope.sitting.upsells.filter(function(x) {return x.discountRuleTypeId != enums.discountRuleType.earlybird});
            };

            $scope.toggleUpsell = function () {
                $scope.upsellOpen = !$scope.upsellOpen;
            };

            $scope.updateNextUpsell = function() {
                return $scope.sitting.upsells
                    .filter(function(x) {return x.discountRuleTypeId == enums.discountRuleType.earlybird})
                    .filter(function(x) {return new Date(x.validUntil).setDate(new Date(x.validUntil).getDate() + 1) > new Date().getTime()})
                    .sort(function(x, y) {return new Date(x.validUntil) - new Date(y.validUntil)})
                    .find(function(x) {return x != null});
            }

            $scope.updateUpsellTimer = function() {
                if (! $scope.nextUpsellTimeout) {
                    return;
                }

                var expiryTime = new Date($scope.nextUpsellTimeout.validUntil);
                expiryTime.setDate(expiryTime.getDate() + 1); // Add a day to the expiry time because Pret.
                var distance = expiryTime - new Date().getTime();

                var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                var timerString = ''
                if (days > 0) {
                    timerString += days + 'd ';
                }
                if (hours > 0 || timerString !== '') {
                    timerString += hours + 'h ';
                }
                if (minutes > 0 || timerString !== '') {
                    timerString += minutes + 'm ';
                }
                timerString += seconds + 's ';

                document.getElementById('upsellTimer').innerHTML = $scope.nextUpsellTimeout.description + $scope.expiringString + timerString;

                // If the count down is finished, get another upsell or stop.
                if (distance < 0) {
                    $scope.nextUpsellTimeout = $scope.updateNextUpsell()
                    if (! $scope.nextUpsellTimeout) {
                        clearInterval($scope.upsellTimer);
                        document.getElementById('upsellTimer').innerHTML = $scope.discountString;
                    }
                }
            };

            $scope.showPreferencesReminder = function() {
                if ($scope.statStore.marketing.prefs) {
                    var nullPreference = $scope.statStore.marketing.prefs.find(function (x) {
                        return x.value == null;
                    });

                    if (nullPreference) {
                        return true;
                    }
                }

                return false;
            }

            $rootScope.$on('disableBuy', function () {
                // This is triggered on first load.  Smurf.
                $scope.loading = true;
            });

            $rootScope.$on('imageAddedToBasket', function (evt, args) {
                if (args.imagesAdded) {
                    _.each(args.imagesAdded, function (imageToFind) {
                        var imageToAdd = _.find($scope.sitting.none.images, { id: imageToFind.imgIndex });

                        if (args.amountOfTimesImageAdded)
                            // If basketCount is already greater than 0, just increment, otherwise set to 1. This avoids NaN errors
                            imageToAdd.basketCount > 0 ? imageToAdd.basketCount += args.amountOfTimesImageAdded : imageToAdd.basketCount = args.amountOfTimesImageAdded;
                        else
                            imageToAdd.basketCount > 0 ? imageToAdd.basketCount++ : imageToAdd.basketCount = 1;

                    });
                }
            });

            $scope.$on('$destroy', function iVeBeenDismissed() {
                clearInterval($scope.upsellTimer);
            });

            // On an album change, reset the modal album to null.
            // This will force the modals to use the currentAlbumId.
            $rootScope.$on('albumChange', function() {
                generalStore.set('modalAlbumId', null);
            });
        }]);