angular.module(app.appName)
	.service('modalService', [
		'sittingStore', 'myPicturesService', 'albumService', '$q', '$translate', 'generalStore', 'myPicturesResource', 'itemBuilderService',
		function (sittingStore, myPicturesService, albumService, $q, $translate, generalStore, myPicturesResource, itemBuilderService) {
			var self = this;

			// Updates the HTML for the header
			self.updateProductHeader = function (selectedProductCount) {

				var key = selectedProductCount == 1 ?
					'X_PICTURE_SELECTED_SINGULAR' : 'X_PICTURES_SELECTED_PLURAL';
				return $translate(key, { x: selectedProductCount }).then(function (translation) {
					return translation;
				});
			};

			// Updates the HTML for the header
			self.updateHeader = function (selectedImageCount, requiredImageCount) {
				return $translate('MODAL_X_OF_Y_SELECTED', { x: selectedImageCount, y: requiredImageCount }).then(function (translation) {
					return translation;
				});
			};

			var initExclusions = function (data) {
				var imageExclusion = {};
				var folderExclusions = {};

				// Populate exclusions
				_.each(data.exclusions, function (exclude) {
					imageExclusion[exclude] = true;
				});
				_.each(data.exclusionFolders, function (exclude) {
					folderExclusions[exclude] = true;
				});

				// Setup exclusions for folders
				if (data.none) {
					_.each(data.none.images, function (image) {
						if (imageExclusion[image.id] == true) {
							image.exclude = true;
						}
					});
					_.each(data.none.folders, function (image) {
						if (folderExclusions[image.id] == true) {
							image.exclude = true;
						}
					});
				}

				// Setup exclusions for favourites
				if (data.favourites) {
					_.each(data.favourites.images, function (image) {
						if (imageExclusion[image.id] == true) {
							image.exclude = true;
						}
					});
				}
			};

			/* How do we load favourites? */
			self.loadAlbumPictures = function (sittingId, folderId, productId, favourites) {

				// Creates a new promise
				var deferred = $q.defer();

				var productAlbum = sittingStore.getProductAlbum(productId, folderId);
				// If our productAlbum doesn't exist, and we are passing a sittingId & folderId
				// it means that we are trying to load an album, but we don't have one. Get one from the server!

				if (favourites) {
					myPicturesService.getSitting(null, true, productId).then(function (favouritesPromise) {
						favouritesPromise.promise.then(function (favourites) {
							if (productId) {
								initExclusions(favourites);
							}
							console.log('[modalService.loadAlbumPictures] load favourites');
							deferred.resolve(favourites);
						})
					});
				}
				else if (sittingId && folderId) { // Trying to load an album in modal if (productAlbum)
					if (productAlbum) {
						// Our data is set and we are good to continue
						deferred.resolve(productAlbum.data);
					} else { // We don't have one, get from the server
						myPicturesService.getSitting(folderId, null, productId).then(function (sittingPromise) {
							sittingPromise.promise.then(function (sitting) {
								//at this point we have all the images of our sitting. What we need to do is to mark incompatible pictures if we have a productId.
								if (productId) {
									initExclusions(sitting);
								}
								// Save our new album to the store for later.
								sittingStore.saveProductAlbum(productId, folderId, sitting);

								console.log('[modalService.loadAlbumPictures] load folder');
								deferred.resolve(sitting);
							});
						});
					}
				} else {
					// We don't do root folders anymore
					deferred.resolve({
						none: {}
					});
				}

				// Return our promise with our sitting data;
				return deferred.promise;
			};
		}
	]);