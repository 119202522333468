/*
    The store convention allows us to share data throughout the application without using the rootScope.
    It contains private variables on the 'self', and the public methods expose this data.

    In order to access the data, simply inject the sittingStore into your controller, and call the public
    methods.

    There is currently no null checking, so you will need to do this in your controllers. There is simple
    console warnings for null data though.
*/

angular.module(app.appName)
    .factory('sittingStore', [
        function () {
        	var self = this;
        	self.sitting = null;

        	self.productSitting = null;
        	self.productAlbums = [];

        	return {
        		// Simple method to retrieve the stored sitting.
        		get: function () {
        			console.warn('[sittingStore] Sitting is null. If this is unexpected, please make sure the sitting is being set.');
        			return self.sitting;
        		},

        		// Simple method which sets the private 'self.sitting' variable which can be retrieved using the 'get' method.
        		set: function (sitting) {
        			self.sitting = sitting;
        		},

        		getProductSitting: function () {
        			return self.productSitting;
        		},
        		saveProductSitting: function (sitting) {
        			self.productSitting = sitting;
        		},
        		getProductAlbums: function() {
        			return self.productAlbums;
        		},
        		getProductAlbum: function (productId, albumId) {
        			return _.findWhere(self.productAlbums, { productId: productId, albumId: albumId });
        		},
        		saveProductAlbum: function (productId, albumId, album) {
        			var found = _.findWhere(self.productAlbums, { productId: productId, albumId: albumId });
        			if (found) {
        				found.data = album;
        			} else {
        				self.productAlbums.push({ productId: productId, albumId: albumId, data: album });
        			}
        		},
        		nukeProductAlbums: function () {
        			self.productSitting = null;
        			self.productAlbums = [];
        		}
        	};
        }
    ]);