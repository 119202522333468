angular.module(app.appName)
    .service('braintreeService', [
        'apiUrl', '$http',
        function (apiUrl, $http ) {
            var self = this;

            var api = apiUrl() + "/Braintree/"

        	return {

                getThreeDsConfiguration: function() {
                    return $http.get(api + '/ThreeDsConfiguration');
                },

        		getClientToken: function () {
                    return $http.get(api + "/ClientToken");
                },

                generateSale: function(payment_method_nonce, payment_provider, device_data) {
                    // debugger;
                    return $http.post(api + "/GenerateSale",
                        {
                            nonce: payment_method_nonce,
                            paymentProvider: payment_provider,
                            deviceData: device_data
                        });
                }
           }
        }
    ]);