/*
    This is used to store any constant values to use in the app.
    If you need to use any of the constants, you just need to inject it
    into your controller.
*/
angular.module(app.appName)
		.constant('dimensions', {
				'previewMax': 150
		})
    .constant('modalTypes', {
        'picturePicker': 'picturePicker',
        'products': 'products',
        'premium': 'premium',
        'pack': 'pack',
        'whole': 'whole',
				'confirmCrop': 'confirmCrop',
				'packReminder': 'packReminder',
				'loginReminder': 'loginReminder'
    })
		.constant('enums', {
	    'imagePreferenceType':
			{
			    'image': 0,
			    'imageFolder': 1,
			    'sitting': 2
			},

	    'imagePreferenceStatus':
			{
			    'none': 0,
			    'favourite': 1,
			    'hide': 2
			},

			'categoryType':
			{
				'gallerySingleProducts': 1,
				'packs': 2
			},

	    'productType':
            {
                'si': 1, // Single Image, Single Product
                'misp': 2, // Multi-Image, Single Product
                'mimp': 6, // Multi-Image, Multiple Product
								'p': 24 // Pack
            },
	    'destination':
            {
                'delivery': 'delivery',
                'invoice': 'invoice'
            },
	    'productCategory':
			{
				'jpeg': 6,
				'prints': 4,
				'multiImage': 5,
				'more': 2
			},
	    'deliverToSchool': {
	        'no': 0,
	        'yes': 1,
            'yesAndFilter': 2
	    },
			'deliveryFilter': {
					'excludedAll': 1,
					'eventOnly': 2
			},
			'specialInstructions': {
				'none': 0,
				'allowed': 1,
				'required': 2
			},
			'discountRuleType': {
					'redeemable': 1,
					'previousOrder': 2,
					'bogod': 3,
					'multibuy': 4,
					'promoCode': 5,
					'totalBasketProduct': 6,
			    'earlybird': 7,
					'shipping': 10,
					'staged': 11,
					'promoCodePerson': 12,
					'sittingRedeem': 13
			},
			'homeLoginRequestStage': {
				'validateSitting': 0,
        'validateEmail': 1,
				'validateEmailLink': 2,
				'validatePassword': 3,
        'registerFirst': 4,
        'acceptPreferences': 5,
				'register': 6,
				'addSitting': 7,
        'done': 8
			},
			'homeLoginResponse': {
				'sittingFound': 0,
        'sittingNotFound': 1,
        'sittingNotReady': 2,
        'attemptLogin': 3,
        'validateEmail': 4,
        'validationPending': 5,
				'success': 6,
				'failure': 7
			},
			'marketingPreference': {
				'emailNotify': 1,
				'optOutMarketing': 2
			},
			'orientation': {
				'horizontal': 0,
				'vertical': 1,
				'square': 2
			},
	});

angular.module(app.appName)
   .value('introJs', introJs);
