angular.module(app.appName)
    .directive('albumFolders', function () {
    	var controller = [
            '$scope', 'albumService', 'sittingStore', 'generalStore',
            function ($scope, albumService, sittingStore, generalStore) {
            	var watch = $scope.$watch('sittingData', function (newData) {
            		if (newData) {
            			newData.none.folders = albumService.groupAlbums(newData.none);
            			$scope.setupSittingData = newData
            			//sittingStore.set(newData);
            			watch();
            		}

            		$scope.getPrettyEventDate = function (date) {
            		    return helpers.prettifyDate(helpers.trimDate(date));
            		}

            	});
            }
    	];
    	return {
    		scope: {
    			sittingData: '=',
				action: '&'
    		},
    		controller: controller,
    		templateUrl: '/assets/html/directives/albums/albumFolders.html'
    	}
    });