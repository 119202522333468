angular.module(app.appName)
    .directive('banner', function () {
        var controller = [
            '$rootScope', '$scope', '$element', 'navService', 'routeService', 'sittingStore', 'generalStore', 'myPicturesResource', 'notifyService', 'myPicturesService', 'statStore', 'productService', '$route', '$window', 'enums',
            function ($rootScope, $scope, $element, navService, routeService, sittingStore, generalStore, myPicturesResource, notifyService, myPicturesService, statStore, productService, $route, $window, enums) {
                var self = this;
                $scope.statStore = statStore;

                /*
                    We did have the banner stick on page scroll, but it was removed as we are now scrolling the two page sections independently.
                    The funcationality is left in just in case we need it at a later date.
                */
                //self.headerHeight = document.getElementsByTagName('header')[0].clientHeight;
                //self.setHeight = function (height) {
                //    var newHeight = height - ((self.headerHeight + self.bannerHeight) + 6); // For some reason the banner is missing 4px of the height
                //}
                //window.onscroll = function () {
                //    console.log('hi', window.scrollY);
                //    if (window.scrollY >= self.headerHeight) {
                //        $element[0].style.position = 'fixed';
                //        $element[0].style.top = '0';
                //        $element[0].style.width = '100%';
                //        $element[0].style.zIndex = '1';
                //    } else {
                //        $element[0].removeAttribute('style');
                //    }
                //    self.setHeight(window.innerHeight);
                //}

                $scope.back = function () {
                    window.history.back();
                }

                /* How do we know which album to load, and if we should load an album. */

                $scope.navigateCategory = function (category) {

                    /* Perform more comprehensive routing, since using default
                       routing is causing problems. */

                        var albumId = generalStore.get('currentAlbumId').value;
                        var sittingId = generalStore.get('currentSittingId').value;

                       if (category.categoryTypeId == enums.categoryType.gallerySingleProducts)
                       {
                            if (sittingId && albumId) {
                                navService.album(sittingId, albumId);
                            } else {
                                if (routeService.get().name.includes('FAV')) {
                                    navService.favAlbum();
                                } else {
                                    navService.album();
                                }
                            }
                       }
                       else if (category.categoryTypeId == enums.categoryType.packs)
                       {
                            if (sittingId && albumId)
                            {
                                navService.packs(sittingId, albumId);
                            } else {
                                if (routeService.get().name.includes('FAV')) {
                                    navService.favPacks();
                                } else {
                                    navService.packs();
                                }

                            }
                       }
                       else
                       {
                            console.error('[banner.navigateCategory] Unknown category type', category.categoryType);
                       }
                }

                $scope.isCategoryCurrentCategory = function (category) {

                    // If the route root matches category active root, category true.
                    return (routeService.getRootRoute(routeService.get().name)) == category.activeRoute;
                }

                $scope.filterProductsByCategory = function (imageId, folderId) {
                    // if (generalStore.get('currentAlbumId').value && (routeService.get().name == 'ALBUM' && !$route.current.params.pictureId) && !folderId) {
                    //     folderId = generalStore.get('currentAlbumId').value;
                    // }
                    productService.filterProductsByCategory(null, null).then(function (categoryData) {
                        $scope.categories = categoryData;
                    });
                };

                var productsTriggerHandler = function (scope, args) {
                    args != null
                        ? $scope.filterProductsByCategory(args.imageId, args.albumId, args.forceUpdate)
                        : $scope.filterProductsByCategory();
                }
                var unbindEventHandler = $rootScope.$on('productsTrigger', productsTriggerHandler);

                $scope.$on('$destroy', function () {
                    unbindEventHandler();
                });

                $scope.updateCurrentSitting = function () {
                    $scope.currentImageId = (
                        generalStore.get('currentPreviewImage') || {}
                    ).value;
                    $scope.currentSittingId = (
                        generalStore.get("currentSittingId") || {}
                    ).value;
                    $scope.currentAlbumId = (
                        generalStore.get("currentAlbumId") || {}
                    ).value;
                };

                $scope.showCategories = function () {
                    var currentRouteName = routeService.get().name;

                    var showCategories = currentRouteName == 'MYALBUMS'
                        || currentRouteName == 'ALBUM'
                        || currentRouteName == 'FAVOURITES'
                        || currentRouteName == 'PREVIEW'
                        || currentRouteName == 'PACKS'
                        || currentRouteName == 'FAVS'
                        || currentRouteName == 'PREMIUM'
                        || currentRouteName == 'WHOLE';

                    // Defer loading of categories until we
                    // know we need them.
                    // This stops them loading on the home page.
                    if (showCategories && ! $scope.tryLoadedCategories && ! $scope.categories) {
                        // Mark so we only attempt to load categories once.
                        $scope.tryLoadedCategories = true;
                        $scope.filterProductsByCategory($scope.currentImageId, $scope.currentAlbumId);
                    }

                    return showCategories;
               }

                $scope.showBannerTitle = function () {
                    var currentRouteName = routeService.get().name;
                    return currentRouteName == 'ACCOUNT'
                        || currentRouteName == 'ACCOUNTDETAILS'
                        || currentRouteName == 'ADDRESSES'
                        || currentRouteName == 'ORDERS'
                        || currentRouteName == 'APPROVALS'
                        || currentRouteName == 'DOWNLOADS'
                        || currentRouteName == 'BASKET'
                        || currentRouteName == 'REGISTER'
                        || currentRouteName == 'CHANGEPASSWORD';
                }

                // Triggers on album change
                $rootScope.$on("productsTrigger", function () {
                    $scope.updateCurrentSitting();
                });

                $rootScope.$on("sittingAdded", function () {
                    $scope.updateCurrentSitting();
                });

                $scope.updateCurrentSitting();

                $scope.navService = navService;
                $scope.routeService = routeService;
                $scope.sittingStore = sittingStore;
                $scope.generalStore = generalStore;
                $scope.$route = $route;
                $scope.mobile = window.innerWidth < 720;
            }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/banner.html'
        }
    });