angular.module(app.appName)
    .directive('crop', function () {
        var controller = [
            '$scope', 'basketService', 'itemResource', '_', 'interact', '$rootScope', '$element', '$window',
	        function ($scope, basketService, itemResource, _, interact, $rootScope, $element, $window) {
	            var self = this;
	            $scope.cropBox = {};

	            $scope.cropBox.showCrop = false;

	            $scope.showCropImage = function (position) {
	            	$scope.loading = true;

							$scope.setDims = function(data) {
	                    if (window.innerWidth < 468) {
													var sizeMultiplier = data.image.width / (window.innerWidth - 140);
	                        data.image.width = data.image.width / sizeMultiplier;
	                        data.image.height = data.image.height / sizeMultiplier;
	                    }
	                    else if (window.innerWidth > 1024) {
	                        data.image.width += data.image.width / 2;
	                        data.image.height += data.image.height / 2
	                    }
	                    $scope.cropBox.image = {};
	                    $scope.cropBox.image.url = data.image.url;
	                    $scope.cropBox.image.width = data.image.width;
	                    $scope.cropBox.image.height = data.image.height;
	                    $scope.cropBox.x0 = data.x0;
	                    $scope.cropBox.y0 = data.y0;
	                    $scope.cropBox.y1 = data.y1;
	                    $scope.cropBox.x1 = data.x1;
	                    //if (!$scope.cropBox.dx && !$scope.cropBox.dy) {

	                    $scope.cropBox.dx = 0;
	                    $scope.cropBox.dy = 0;
	                    //}


	                    $scope.cropBox.initialCropBoxWidth = ($scope.cropBox.x1 - $scope.cropBox.x0) * data.image.width;
	                    $scope.cropBox.initialCropBoxHeight = ($scope.cropBox.y1 - $scope.cropBox.y0) * data.image.height;
	                    $scope.cropBox.cropBoxWidth = ($scope.cropBox.x1 - $scope.cropBox.x0) * data.image.width;
	                    $scope.cropBox.cropBoxHeight = ($scope.cropBox.y1 - $scope.cropBox.y0) * data.image.height;
	                    $scope.cropBox.showCrop = true;
	                    $scope.loading = false;
	                    setTimeout(function () {
	                        var windowWidth = document.getElementById('myModal').offsetWidth;
	                        document.getElementById('draggable-box').style.width = ($scope.cropBox.image.width * ($scope.cropBox.x1 - $scope.cropBox.x0)) + 'px';
	                        document.getElementById('draggable-box').style.height = ($scope.cropBox.image.height * ($scope.cropBox.y1 - $scope.cropBox.y0)) + 'px';
	                        document.getElementById('draggable-box').style.left = ((windowWidth / 2) - (data.image.width / 2)) + ($scope.cropBox.x0 * data.image.width) + $scope.cropBox.dx + 'px';
	                        document.getElementById('draggable-box').style.top = 40 + (data.image.height * $scope.cropBox.y0) + $scope.cropBox.dy + 'px';
	                        document.getElementById('draggable-box').style.webkitTransform = document.getElementById('draggable-box').style.transform = null;
	                        document.getElementById('draggable-box').setAttribute('data-x', 0);
	                        document.getElementById('draggable-box').setAttribute('data-y', 0);
	                        document.getElementById('foreground').style.width = ($scope.cropBox.image.width * ($scope.cropBox.x1 - $scope.cropBox.x0)) + 'px';
	                        document.getElementById('foreground').style.height = ($scope.cropBox.image.height * ($scope.cropBox.y1 - $scope.cropBox.y0)) + 'px';
	                        document.getElementById('foreground').style.left = ((windowWidth / 2) - (data.image.width / 2)) + ($scope.cropBox.x0 * data.image.width) + $scope.cropBox.dx + 'px';
	                        document.getElementById('foreground').style.top = 40 + (data.image.height * $scope.cropBox.y0) + $scope.cropBox.dy + 'px';
	                        document.getElementById('foreground').style.webkitTransform = document.getElementById('foreground').style.transform = null;
	                        document.getElementById('foreground').setAttribute('data-x', 0);
	                        document.getElementById('foreground').setAttribute('data-y', 0);
	                    }, 50);
							}

               itemResource.cropImage({ itemId: $scope.item.itemId, position: position }).$promise.then(function (data) {
											$scope.data = data;
											$scope.setDims($scope.data);
	                });

	                $scope.initCropbox();
	            }

							/* Reset cropping on window resize */
							$scope.onResize = function() {
								$scope.initCropbox($scope.data);
							};

	            $scope.hideCropImage = function () {
	                $scope.cropBox.showCrop = false;
	            }
	            $scope.resetCropBox = function (position) {
	                $scope.loading = true;
	                itemResource.resetCropBox({ itemId: $scope.item.itemId, position: position }).$promise.then(function (data) {
											$scope.data = data;
											$scope.setDims($scope.data);
	                });
	            }

	            $scope.saveCropBox = function () {
	                $scope.loading = true;
	                var postToServer = {
	                    itemId: $scope.item.itemId,
	                    position: $scope.image.position,
	                    custom: true,
	                    x1: ($scope.cropBox.x0 + (($scope.cropBox.x1 - $scope.cropBox.x0) * ($scope.cropBox.cropBoxWidth / $scope.cropBox.initialCropBoxWidth))) + ($scope.cropBox.dx / $scope.cropBox.image.width),
	                    y1: ($scope.cropBox.y0 + (($scope.cropBox.y1 - $scope.cropBox.y0) * ($scope.cropBox.cropBoxHeight / $scope.cropBox.initialCropBoxHeight))) + ($scope.cropBox.dy / $scope.cropBox.image.height),
	                    x0: $scope.cropBox.x0 + ($scope.cropBox.dx / $scope.cropBox.image.width),
	                    y0: $scope.cropBox.y0 + ($scope.cropBox.dy / $scope.cropBox.image.height)
	                }
	                itemResource.cropImageWithCoordinates(postToServer).$promise.then(function (data) {
	                    $scope.image.previewUrl = data.previewUrl;
	                    itemResource.generatePip({ itemId: $scope.item.itemId }).$promise.then(function (pip) {
	                        $scope.item.pip = pip;
	                        $scope.hideCropImage();
	                        $scope.loading = false;
	                    });
	                });
	            }

	            $scope.applyTint = function (tintEnum, position) {
	            	if (tintEnum === 0) {
	            		$scope.colourLoading = true;
	            		itemResource.tintImage({ itemId: $scope.item.itemId, position: position, tint: 'Color' }).$promise.then(function (data) {
	            			var index = _.findIndex($scope.item.item.images, function (item) {
	            				return item.position === position;
	            			});
	            			$scope.item.item.images[index] = data;
	            			itemResource.generatePip({ itemId: $scope.item.itemId }).$promise.then(function (pip) {
	            				$scope.item.pip = pip;
	            				$scope.colourLoading = false;
	            			});
	            		});
	            	} else if (tintEnum === 1) {
	            		$scope.bwLoading = true;
	            		itemResource.tintImage({ itemId: $scope.item.itemId, position: position, tint: 'BW' }).$promise.then(function (data) {
	            			var index = _.findIndex($scope.item.item.images, function (item) {
	            				return item.position === position;
	            			});
	            			$scope.item.item.images[index] = data;
	            			itemResource.generatePip({ itemId: $scope.item.itemId }).$promise.then(function (pip) {
	            				$scope.item.pip = pip;
	            				$scope.bwLoading = false;
	            			});
	            		});
								}

	            	// } else if (tintEnum === 2) {
	            	// 	$scope.sepiaLoading = true;
	            	// 	itemResource.tintImage({ itemId: $scope.item.itemId, position: position, tint: 'Sepia' }).$promise.then(function (data) {
	            	// 		var index = _.findIndex($scope.item.item.images, function (item) {
	            	// 			return item.position === position;
	            	// 		});
	            	// 		$scope.item.item.images[index] = data;
	            	// 		itemResource.generatePip({ itemId: $scope.item.itemId }).$promise.then(function (pip) {
	            	// 			$scope.item.pip = pip;
	            	// 			$scope.sepiaLoading = false;
	            	// 		});
	            	// 	});
	            	// }

	            }

	            $scope.initCropbox = function (element) {
	                // target elements with the "draggable" class
	                interact('.cropbox')
                    //draggable event
                  .draggable({
                      // Disable inertial throwing
                      inertia: false,
                      // keep the element within the area of it's parent
                      //restrict: {
                      //    restriction: "parent",
                      //    endOnly: false,
                      //    elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
                      //},
                      // enable autoScroll
                      autoScroll: true,

                      // call this function on every dragmove event
                      onmove: function (event) {
                          var target = event.target,

                        // keep the dragged position in the data-x/data-y attributes
                        x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
                        y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
                          // translate the element
                          target.style.webkitTransform =
                          target.style.transform =
                            'translate(' + x + 'px, ' + y + 'px)';

                          // update the posiion attributes
                          target.setAttribute('data-x', x);
                          target.setAttribute('data-y', y);

                          // Repeat everything for the white box behind the cropper so that the area to be cropped is highlighted
                          var foreground = document.getElementById('foreground');

                          foreground.style.webkitTransform =
                          foreground.style.transform =
                            'translate(' + x + 'px, ' + y + 'px)';
                          foreground.setAttribute('data-x', x);
                          foreground.setAttribute('data-y', y);

                      },
                      onend: function (event) {
                          var target = event.target,
                        // keep the dragged position in the data-x/data-y attributes
                        x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
                        y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                          $scope.cropBox.dx = parseFloat(target.getAttribute('data-x'));
                          $scope.cropBox.dy = parseFloat(target.getAttribute('data-y'));
                      }
                  })
                    //resizable event
                    .resizable({ preserveAspectRatio: true, edges: { left: true, right: true, bottom: true, top: true } })
                    .on('resizemove', function (event) {
                        var target = event.target,
                            x = (parseFloat(target.getAttribute('data-x')) || 0),
                            y = (parseFloat(target.getAttribute('data-y')) || 0);

                        // update the element's style
                        target.style.width = event.rect.width + 'px';
                        $scope.cropBox.cropBoxWidth = event.rect.width;
                        target.style.height = event.rect.height + 'px';
                        $scope.cropBox.cropBoxHeight = event.rect.height;

                        // translate when resizing from top or left edges
                        //x += event.deltaRect.left;
                        //y += event.deltaRect.top;

                        target.style.webkitTransform = target.style.transform =
                            'translate(' + x + 'px,' + y + 'px)';

                        target.setAttribute('data-x', x);
                        target.setAttribute('data-y', y);
                        $scope.cropBox.dx = parseFloat(target.getAttribute('data-x'));
                        $scope.cropBox.dy = parseFloat(target.getAttribute('data-y'));

                        // Repeat everything for the white box behind the cropper so that the area to be cropped is highlighted
                        var foreground = document.getElementById('foreground');

                        foreground.style.width = event.rect.width + 'px';
                        foreground.style.height = event.rect.height + 'px';

                        foreground.style.webkitTransform = target.style.transform =
                            'translate(' + x + 'px,' + y + 'px)';

                        foreground.setAttribute('data-x', x);
                        foreground.setAttribute('data-y', y);

                    });

	            };

	            self.dragMoveListener = function (event) {
	                var target = event.target,
                    // keep the dragged position in the data-x/data-y attributes
                    x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
                    y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

	                // translate the element
	                target.style.webkitTransform =
                target.style.transform =
                  'translate(' + x + 'px, ' + y + 'px)';

	                // update the posiion attributes
	                target.setAttribute('data-x', x);
	                target.setAttribute('data-y', y);
	            }

	            // this is used later in the resizing and gesture demos
	            window.dragMoveListener = self.dragMoveListener;


	            $scope.$watch('cropBox', function () {
	                //console.log($scope.cropBox.dx);
	            });

							angular.element($window).on('resize', $scope.onResize);
	        }
        ];
        return {
            restrict: 'E',
            scope: {
                item: "=",
                image: "="
            },
            controller: controller,
            templateUrl: '/assets/html/directives/basketItem/crop.html'
        }
    });
