angular.module(app.appName)
	.directive('areYouSure',
		function () {
			var controller = [
				'$scope',
				function ($scope) {
					$scope.callback = $scope.callback;
					$scope.setCondition = function(condition) {
						$scope.condition = condition;
					}
				}
			];

			return {
				scope: {
					message: '@',
					condition: '=',
					callback: '&',
					customClasses: '@?' // BROKEN. DO NOT USE. Left to fix.
				},
				controller: controller,
				templateUrl: '/assets/html/directives/areYouSure.html'
			}
		}
	);