angular.module(app.appName)
	.service("stringService",
        function () {
            var self = this;
            this.getDescriptions = function (description) {
                var sentences = description.split('.');
                var shortDescription = sentences[0] + '.';
                var longDescription;
                for (var i = 1; i < sentences.length; i++) {
                    longDescription += sentences[i];
                    if (i < sentences.length - 1) {
                        longDescription += '.';
                    }
                }
                longDescription = longDescription != null ? longDescription.replace("undefined", "") : null;
                var descriptions = { short: shortDescription, long: longDescription };
                return descriptions;
            }
        }
	);