angular.module(app.appName)
    .directive('myAlbums', function () {
        var controller = [
            '$scope', '$element', '$route', 'sittingStore', 'navService', 'helpers', '$timeout', '$window', 'routeService', 'userService', 'statStore',
            function ($scope, $element, $route, sittingStore, navService, helpers, $timeout, $window, routeService, userService, statStore) {
                $scope.sittingId = $route.current.params.sittingId;
                $scope.navService = navService;
                var attr = document.createAttribute('fix-and-scroll');

                // userService.getStats().$promise.then(function (stats) {
                //     statStore.favouriteCount = stats.favouriteCount;
                //     statStore.basketCount = stats.basketCount;
                // });

                if ($scope.fixed)
                    $element[0].setAttribute('fix-and-scroll', '');

                $scope.getPrettyEventDate = function(date) {
                   return helpers.prettifyDate(helpers.trimDate(date));
                }
            }
        ];
        return {
            scope: {
                fixed: '=?',
                sittingData: '=?',
                loading: '=?',
                sittingCallback: '&'
            },
            controller: controller,
            templateUrl: '/assets/html/directives/albums/myAlbums.html'
        }
    });