angular.module(app.appName).directive('categoryProducts', function () {
  var controller = [
    '$scope',
    '$rootScope',
    '$route',
    '$q',
    '$timeout',
    'productService',
    'generalStore',
    'modalTypes',
    'itemService',
    'routeService',
    'itemStore',
    '_',
    'navService',
    'enums',
    '$location',
    'debug',
    'itemBuilderService',
    function (
      $scope,
      $rootScope,
      $route,
      $q,
      $timeout,
      productService,
      generalStore,
      modalTypes,
      itemService,
      routeService,
      itemStore,
      _,
      navService,
      enums,
      $location,
      debug,
      itemBuilderService
    ) {

      var self = this;
      $scope.modalTypes = modalTypes;
      $scope.routeService = routeService;
      $scope.generalStore = generalStore;
      $scope.itemStore = itemStore;
      $scope.$route = $route;
      $scope.loadingOptions = false;

      // Return item from store, if exists.
      $scope.productItem = (generalStore.get('currentProductItem') || {}).value;

      $scope.getPipUrl = function (product) {
        if (!itemStore.publicProductItem.pip) return null;

        return itemStore.publicProductItem.pip;
      };

      $scope.getPreviewUrl = function () {
        return generalStore.get('currentPreviewImage') != null
          ? generalStore.get('currentPreviewImage').value.url
          : null;
      };

      $scope.goToBasket = function ($event) {
        $event.stopPropagation();
        $location.path('/Basket');
      };

      // Returns true if this is a single product list in packs
      $scope.isSingleProductList = function() {
        return (routeService.get().name == "PACKS") && $route.current.params.productId;
      }

      var addToBasket = 'BUTTON_ADD_TO_BASKET';
      var selectPicturesToBuy = 'BUTTON_SELECT_PICTURES_TO_BUY';
      var selectPictures = 'BUTTON_SELECT_PICTURES';
      var selectSitting = 'BUTTON_SELECT_SITTING';
      var selectMultiBuy = 'BUTTON_MULTIBUY';

      $scope.getPrimaryButtonText = function (product) {
        // Single image context occurs when focused on a
        // single image.

        if ($scope.isInPreview() || $scope.isSingleProductList()) {
          if ($scope.isWhole(product)) return addToBasket;
          if (product.productTypeId === enums.productType.mimp) return selectPicturesToBuy;
          if (product.productTypeId === enums.productType.p) return selectPictures;
          if (product.productTypeId === enums.productType.misp) return selectPictures;
          return addToBasket;
        } else {
          if ($scope.isWhole(product)) return selectSitting;
          if (product.productTypeId === enums.productType.mimp) return selectPicturesToBuy;
          if (product.productTypeId === enums.productType.p) return selectPictures;
          if (product.productTypeId === enums.productType.misp) return selectPictures;
          return selectPicturesToBuy;
        }
     };

     $scope.getSecondaryButtonText = function() {
      if ($scope.isSingleProductList()) {
        return selectPicturesToBuy;
      } else {
        return selectMultiBuy;
      }
     };

      $scope.showMultibuy = function (product) {

        return (
          (! product.maxQuantitySitting || product.maxQuantitySitting > 1) &&
          $scope.isSingleImageProduct(product) &&
          !$scope.isWhole(product) &&
          ($scope.isInPreview() || $scope.isSingleProductList())
        );
      };

      $scope.updateProductOptions = function (product, option, optionItem) {
        option.loading = true;
        product.showSpinner = true;
        $scope.disableOptions = true;
        var currentSelectedOption = _.findWhere(option.items, {
          selected: true,
        });
        if (currentSelectedOption) {
          currentSelectedOption.selected = false;
        }

        // Checks we have selected a real option and not the null "Please Select..."
        if (optionItem != null) {
          optionItem.selected = true;
          option.completed = true;

          itemBuilderService.setDimensions(
            itemBuilderService.currentPreviewRatio(self.currentPreview),
            $scope.productItem
          );
          $scope.productItem.images[0].isPlaceholder = false;

          if (self.currentPreview) {
            $scope.productItem.images[0].imgIndex = self.currentPreview.id;
          }

          self.setupProductItem(product).then(function (savedItem) {
            self.updateSavedItem(product, savedItem);
            option.loading = false;
            product.showSpinner = false;
          });
        }
      };

      $scope.getOptions = function (option) {
        return itemBuilderService.getOptions(option, $scope.ratio);
      };

      self.updateSavedItem = function (product, savedItem) {
        console.info('[categoryProducts] update saved product', {
          productItem: $scope.productItem,
        });

        $scope.productItem.options = savedItem.options;
        $scope.productItem.price = savedItem.price;
        $scope.productItem.finalPrice = savedItem.finalPrice;

        generalStore.set('currentProductItem', $scope.productItem);

        self.updateSelectedOptions(product, savedItem);
        $scope.checkIncompleteOptions(product, savedItem);

        $scope.disableOptions = false;
      };

      $scope.getSelectedOptions = function (product) {
        /* Check if selectedOptions is populated. */
        /* If not, then populate. */
        if (product.selectedOptions == null) {
          self.updateSelectedOptions(product);
        }

        return product.selectedOptions;
      };

      /* Set the selected options at the product level. */
      self.updateSelectedOptions = function (product, item) {
        return itemBuilderService.updateSelectedOptions(product, item, $scope.ratio);
      }

      /* Returns the currently selected option. */
      $scope.getSelectedOption = function (option) {
        var currentlySelected = itemBuilderService.hasTag(option) // If tagged group
          ? itemBuilderService.validatedTaggedOption(option, $scope.ratio) // Return the validated selected option
          : _.findWhere(option.items, { selected: true }); // Otherwise just return the selected item.

        if (currentlySelected) {
          currentlySelected.selected = true;
        }

        return currentlySelected;
      };

      /* Return the description for the given option item dependant
           on route. */
      $scope.getDescription = function (optionItem, option) {
        var isInPicture = $route.current.params.pictureId;
        var hasTag = itemBuilderService.hasTag(option);

        return !isInPicture && hasTag // If not in picture and group is tagged.
          ? optionItem.tag || optionItem.description // Attempt to use tag and fall back to description.
          : optionItem.description; // Otherwise use description.
      };

      $scope.isSingleImageProduct = function (product) {
        return product.productTypeId == enums.productType.si;
      };

      self.collapseAllProductDetails = function (exceptProduct) {
        var productsToClose = _.filter(
          $scope.currentProductCategory.products,
          function (product) {
            return (
              product.showDetails &&
              (!exceptProduct || product.id != exceptProduct.id)
            );
          }
        );
        _.each(productsToClose, function (product) {
          product.showDetails = false;
          self.updateSelectedOptions(product, null);
        });

        console.info(
          '[categoryProducts].collapseAllProductDetails'
        );
        $scope.productItem = null;
        generalStore.set('currentProductItem', null);
        itemStore.clear();
      };

      $scope.isInPreview = function () {
        return window.location.pathname.toLowerCase().indexOf('preview') > 0;
      };

      self.shouldSkipModals = function (product) {
        return ($scope.isInPreview() || $scope.isSingleProductList()) && $scope.productImagesPopulated(product, $scope.productItem);
      };


      /* Are all images populated on the product */
      $scope.productImagesPopulated = function(product, productItem) {

        if (product.imgQuantity == 0) return true;

        if (productItem == null) return false;

        /* If any images are unpopulated, then the check fails. */
        var unPopulated = productItem.images.filter(function(image) { return ! image.imgIndex || image.isPlaceholder; });
        return unPopulated.length == 0;
      };

      $scope.checkIncompleteOptions = function (product, productItem) {


        /* Ensure that productItem is related to product */
        var count;
        if (productItem && productItem.productId == product.id) {
          // return productItem.hasIncompleteOptions();
          // ProductItem uses a different completed notation to product.
          count = productItem.options.filter(function (x) {
            return x.completed == false;
          }).length;
          // console.info(`[checkIncompleteOptions] Builder Check productId:${product.id} incomplete:${count}`);
        } else {
          // return product.hasIncompleteOptions();

          // Options only records selected option groups.
          if (product.options == null || product.options.length < 1) return true;

          count = product.options.filter(function (x) {
            return x.optionGroup.completed == false;
          }).length;

          // console.info(`[checkIncompleteOptions] Collapsed Check productId:${product.id} incomplete:${count}`);
        }

        console.log('[categoryProducts.checkIncompleteOptions] count', count);
        return count > 0; // Return number of incomplete options
      };

      $scope.disableBuy = function (product) {
        // We want to disable the Buy if:
        // - We should skip modals (we're on a picture preview) and showSpinner is true (we are showing the spinner so an action is in progress)
        // OR
        // - We have incomplete product options
        if ($scope.isPremium(product)) {
          return false;
        }

        return (
          $scope.disableBuyButton ||
          (product.showSpinner && self.shouldSkipModals(product)) ||
          $scope.checkIncompleteOptions(product, $scope.productItem) ||
          (!product.showDetails && $scope.checkIncompleteOptions(product)) ||
          (product.showDetails && $scope.loadingOptions) ||
          (product.showDetails && $scope.disableOptions)
        );
      };

      $rootScope.$on('disableBuy', function (evt, data) {
        $scope.disableBuyButton = data.disable;
      });

      $scope.isPremium = function (product) {
        return product.productTypeId == enums.productType.misp;
      };

      $scope.isPack = function (product) {
        return product.productTypeId == enums.productType.p;
      };

      $scope.isWhole = function (product) {
        return product.imgQuantity == 0;
      }

      $scope.showPackReminderModal = function (basketItem, product) {

        console.log('[categoryProducts.showPackReminderModal]', basketItem);

        var upsell = basketItem.allItemDiscounts.map(function (x) { return x.upsellOnAddToBasket; }).includes(true);

        $rootScope.modal.showModal(
          $scope.modalTypes.packReminder,
          {
            product: product,
            upsell: upsell
          },
          $scope.mobile)
      }

      $scope.isPreview = function () {
        return routeService.routeHasKeyword(routeService.get().name, 'PREVIEW');
      }

      // If we are in a preview of a picture, we just want to build and buy the product, we don't need the modal (If it's a single image product).
      // If we are building a *multi* image product, we still need the modal but we want to pre-select our current image.
      // If we are handling a premium product we need to go to a separate workflow.
      // If it's a single image product, we need to show the pack reminder modal after the product is built.
      $scope.buildProduct = function (product, forceShowModal, preselectInModal) {

        self.currentPreview = generalStore.get('currentPreviewImage').value;

        // Set folder id to currentPreview image first.  If not, fall back to general store.
        var folderId = (self.currentPreview || {}).imageFolderId || (generalStore.get('currentAlbumId') || {}).value;

        self.updateRatio(self.currentPreview);
        generalStore.set('currentProduct', product);

        generalStore.set('lastProductScrollTop', $rootScope.isMobile ? document.documentElement.scrollTop : $('.products-list').scrollTop());
        var pr = routeService.getPreviousRoute();
        if (
          self.currentPreview && true
          // pr &&
          // pr.toUpperCase().indexOf('PREVIEW') > 0
        ) {
          if (!itemStore.publicProductItem.images)
            itemStore.publicProductItem.images = [];

          itemStore.publicProductItem.images[0] = self.currentPreview;
        }

        /* If premium or pack, navigate to premium/pack builder. */
        if ($scope.isPremium(product)) {
          navService.premium(product.id,  folderId, (self.currentPreview || {}).id);
          // Make sure all modals are closed. eg mobile products modal.
          $rootScope.modal.closeModal();
        } else if ($scope.isPack(product)) {
          navService.pack(folderId, product.id, (self.currentPreview || {}).id);
          // Make sure all modals are closed. eg mobile products modal.
          $rootScope.modal.closeModal();
        } else {

          /* Whole products not in preview should be directed
             to builder. */

          if ($scope.isWhole(product) && !$scope.isPreview()) {
            navService.whole($route.current.params.sittingId, folderId, product.id);
            $rootScope.modal.closeModal();
          }
          else {
            var showModal = !self.shouldSkipModals(product) || forceShowModal;

            // If we are on a preview, skip the modals and add to basket
            if (!showModal) {
              product.showSpinner = true;

              /* State of product is being changed. */

              /* Build and save for default item*/
              /* We also now need to pass the context of the current folder. */

              itemBuilderService.updateProductItemWithImages($scope.productItem, self.currentPreview, folderId, $scope.ratio);

              itemBuilderService
                .saveProductItem($scope.productItem, 1, product)
                .then(function (savedItem) {
                  $scope.basketDisable = false;
                  product.showSpinner = false;

                  // If a single product, then take user back to gallery,
                  // otherwise, show user modal.
                  if ($scope.isSingleProductList()) {
                    // We must be here from gallery.
                    navService.album($route.current.params.sittingId, $route.current.params.albumId);
                  } else {
                    // We don't want the pack reminder if we're in packs or if only one product allowed.
                    if ($scope.isPreview()
                      && !$scope.isPack(product)
                      && !$scope.isPremium(product)
                      && !$scope.isWhole(product)
                      && product.maxQuantitySitting > 1) {
                      $scope.showPackReminderModal(savedItem.basketItem, product);
                    }
                  }
                });

            } else {
              // Show our modal
              var itemBuildPromise;
              if (
                $scope.productItem &&
                $scope.productItem.productId == product.id
              ) {
                itemBuildPromise = $q(function (resolve) {
                  var itemBuild = $scope.productItem;
                  resolve(itemBuild);
                });
              } else {
                /* If we have no item, build one. */
                itemBuildPromise = itemBuilderService.buildDefaultItem(
                  product,
                  self.currentPreview,
                  $scope.ratio
                );
              }

              var route = routeService.getAlbumRouteAsObject();

              itemBuildPromise.then(function (itemBuild) {
                $rootScope.modal.showModal(
                  modalTypes.picturePicker,
                  {
                    product: product,
                    item: itemBuild,
                    currentPreview: preselectInModal ? self.currentPreview : null,
                    route: route,
                  },
                  $scope.mobile
                );
              });
            }
          }
        }
      };

      $rootScope.$on('buildProduct', function (evt, args) {
        $scope.buildProduct(args.product, args.forceShowModal);
      });

      /* Should an individual product be hidden or not dependant on if the displayed
           image is width specific and the product is width specific. */
      $scope.getProducts = function () {

        // If no category set, or no product collection set, return empty.
        if (
          !$scope.currentProductCategory ||
          !$scope.currentProductCategory.products
        ) {
          return [];
        }

        var products = $scope.currentProductCategory.products;

        // You can't buy the favourites album as a whole sitting product.
        if (window.location.href.includes('Favourites')) {
          products = products.filter(function (product) { return !$scope.isWhole(product) });
        }

        var ratio = $scope.ratio;

        // Where no preview or no ratio, return *all* products.
        // F 	F 			no filter
        // F 	T 			no filter
        // T 	F 			no filter
        // T 	T 			filter

        if (!self.currentPreview || !ratio) {
          return products;
        } else {
          return _.where(products, { widthSpecific: true });
        }
      };

      $scope.resetProductItemOptions = function () {
        $scope.loadingOptions = true;
        if ($scope.productItem) {
          angular.forEach($scope.productItem.options, function (option) {
            angular.forEach(option.items, function (item) {
              item.selected = false;
            });
          });
        }
      }

      $scope.showProductDetails = function (product) {
        /* Product details are open, setup for product item options. */

        var params = {
          productId: product.id,
          imageId: null,
          galleryImageId: null,
          albumId: null,
        };

        /* Set additional parameters for given routing */
        var currentRoute = routeService.get().name;
         params.albumId = $route.current.params.albumId;
         params.galleryImageId = $route.current.params.pictureId;
        if ($scope.isInPreview()) {
          params.imageId = self.currentPreview.id;
        }

        self.currentPreview = generalStore.get('currentPreviewImage').value;
        self.updateRatio(self.currentPreview);

        /* Call productService and set up returning productItem */
        itemService.get(params.productId, null, params.imageId, params.galleryImageId, params.albumId, $scope.ratio)
        .then(function (productItem) {

            generalStore.set('currentProductItem', $scope.productItem);
            console.info(
              '[categoryProducts] Now I set productitem from service',
              { productItem: productItem }
            );


            /* Set up productItem on displaying details. */
            $scope.productItem = productItem;

            $scope.checkIncompleteOptions(product, productItem);

            itemBuilderService.setIsSelectedOnCorrectOption(
              productItem,
              $scope.ratio
            ); // Ensure product is valid
            self.updateSelectedOptions(product, productItem); // Update selected options from returned options

            // Image specific components
            if (params.imageId) {
              if (!productItem.images || !productItem.images.length) {
                // If no images are defined, create an array
                productItem.images = [{}];
              }
              productItem.images[0].imgIndex = self.currentPreview.id;
              productItem.images[0].isPlaceholder = false;
              itemBuilderService.setDimensions(
                itemBuilderService.currentPreviewRatio(self.currentPreview),
                productItem
              );

            }

            self.setupProductItem(product).then(function (savedItem) {
              console.info('[categoryProducts] now I ask to save product');
              self.updateSavedItem(product, savedItem);
            });
          });
      };

      $scope.hideProductDetails = function (product) {
        /* Product details are collapsed, setup for default options. */

        console.info(
          '[categoryProducts].hideProductDetails'
        );
        $scope.productItem = null;
        itemStore.clear();
        self.updateSelectedOptions(product, null);
      };

      $scope.toggleProductDetailsHandler = function(product) {
        if ($scope.isSingleProductList()) {
          return;
        } else {
          $scope.toggleProductDetails(product);
        }
      }

      $scope.toggleProductDetails = function (product, forceOpen) {
        console.info('Toggling product details', {
          productItem: $scope.productItem,
        });

        $scope.resetProductItemOptions();

        self.currentPreview = generalStore.get('currentPreviewImage').value;
        self.updateRatio(self.currentPreview);

        self.collapseAllProductDetails(product); // Collapse everything that's not the current product.

        product.showDetails = forceOpen || !(product.showDetails || false); // Invert.

        if (product.showDetails) {
          $scope.showProductDetails(product);
        } else {
          $scope.hideProductDetails(product);
        }
      };

      $scope.bypassOptions = function (product) {
        // Packs and premium products should not show options in the product
        // because they're in the builder modal.

        var isPreview = $scope.isPreview();

        return $scope.isPremium(product) ||
          $scope.isPack(product) ||
          (!isPreview && $scope.isWhole(product));
      }

      /* Save and return product. */
      self.setupProductItem = function (product) {
        var deferred = $q.defer();
        $scope.productItem.preview = true;
        itemService
          .post($scope.productItem)
          .$promise.then(function (savedItem) {
            itemStore.set(product.id, savedItem);

            itemStore.publicProductItem.pip = savedItem.pip;
            itemStore.publicProductItem.price = savedItem.price;
            itemStore.publicProductItem.finalPrice = savedItem.finalPrice;
            generalStore.set('currentProductItem', $scope.productItem);
            $scope.loadingOptions = false;
            deferred.resolve(savedItem);
          });
        return deferred.promise;
      };

      // Update the scope ratio on change to the current preview.
      self.updateRatio = function (currentPreview) {
        $scope.ratio = (currentPreview || {}).ratio;
      };

      $scope.filterOptions = function (options) {
        _.each(options, function (option) { });
      };

      $scope.toggleLongDescription = function (product, index) {
        product.showLongDescription = !product.showLongDescription;
      };

      var basketAddListener = $rootScope.$on('imageAddedToBasket', function (evt, args) {
        if (args.product) {
          var product = _.find($scope.currentProductCategory.products, {
            id: args.product.id,
          });
          (product && product.basketCount >= 0)
            ? product.basketCount++
            : (product.basketCount = 1);
        }
      });

      $scope.$on('$destroy', function() {
        basketAddListener();
      });

      /* Check after a product change if the current productItem is valid.
        If so, rebuild and keep, otherwise close. */
      var checkCurrentProductItem = function (evt, params) {

        // If a single product list, reset and show the single product
        // then return.
        if ($scope.isSingleProductList()) {
            var products = $scope.getProducts();
            var foundProduct = products.find(x => x.id == $route.current.params.productId);
            $scope.toggleProductDetails(foundProduct, true);
            return;
        }

        /* Put in a timeout to allow th digest cycle to complete before executing. */
        $timeout(function () {
          self.currentPreview = generalStore.get('currentPreviewImage').value;
          self.updateRatio(self.currentPreview);

          if (($scope.productItem || {}).productId) {
            console.log('[categoryProducts.checkCurrentProductItem] check productItem');

            var products = $scope.getProducts();
            var foundProduct = products.find(x => x.id == $scope.productItem.productId);

            if (foundProduct) {
              foundProduct.showSpinner = true;
            }

            if (!foundProduct) {
              $scope.productItem = null;
            } else if (
                ! ($scope.productItem.options && $scope.productItem.options[0]) ||
                ($scope.productItem.options[0].ratio != $scope.ratio) ||
                ($scope.productItem.images.length == 0)) { // If ratio's don't match or images empty, reset.
              $scope.resetProductItemOptions();
              $scope.showProductDetails(foundProduct);
              foundProduct.showDetails = true;
              foundProduct.showSpinner = false;
              $scope.loadingOptions = false;
            } else if (self.currentPreview) {
              $scope.productItem.images[0].imgIndex = self.currentPreview.id;
              $scope.productItem.images[0].isPlaceholder = false;
              itemBuilderService.setDimensions(
                itemBuilderService.currentPreviewRatio(self.currentPreview),
                $scope.productItem
              );
              self.setupProductItem(foundProduct).then(function (savedItem) {
                console.info('[categoryProducts] now I ask to save product');
                self.updateSavedItem(foundProduct, savedItem);
                foundProduct.showSpinner = false;
              });
            } else if (!self.currentPreview) {
              $scope.productItem.images[0].imgIndex = null;
              $scope.productItem.images[0].isPlaceholder = true;
              self.setupProductItem(foundProduct).then(function (savedItem) {
                console.info('[categoryProducts] now I ask to save product');
                self.updateSavedItem(foundProduct, savedItem);
                foundProduct.showSpinner = false;
              });
            }
          }
        }, 0);
      };

      /* At this point, product type hasn't changed. */

      /* on products being updated, check validity for shop */
      // $scope.$watch('currentProductCategory', checkCurrentProductItem);
      // $scope.$watch('currentProductCategory.products', checkCurrentProductItem);

      $scope.$on('productItemCheck', function() {
        console.info('[categoryProducts.productItemCheck]');
        checkCurrentProductItem();
      });
    },
  ];
  return {
    scope: {
      modalBase: '=?',
      currentProductCategory: '=',
      mobile: '=?',
    },
    controller: controller,
    templateUrl: '/assets/html/directives/categoryProducts.html',
  };
});