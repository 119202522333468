angular.module(app.appName)
    .directive('icon', function () {
        var controller = [
            '$scope',
            function ($scope) {
                var self = this;
            	var errorPrefix = '[Icon.js Error] ';
            	if (!$scope.type && !$scope.condition) {
            		console.error(errorPrefix + 'Please pass either a type or a condition.');
            		return;
            	}
                self.iconDirectory = '/assets/images/icons/';
                $scope.src = self.iconDirectory;

                switch ($scope.type.toLowerCase()) {
                    case 'arrow':
                    case 'back':
                        $scope.src += 'Arrow.png';
                        break;
                    // case 'arrow':
                    //     $scope.src += 'Arrow.png';
                    //     break;
                    case 'basket':
                    case 'basket-grey':
                        $scope.src += 'Basket-Grey.png';
                        break;
                    case 'basket-blue':
                        $scope.src += 'Basket-Blue.png';
                        break;
                    case 'basket-white':
                        $scope.src += 'Basket-White.png';
                        break;
                    case 'chevron':
                    case 'chevron-up':
                        $scope.src += 'Chevron-Up.png';
                        break;
                    case 'chevron-down':
                        $scope.src += 'Chevron-Down.png';
                        break;
                    case 'heart':
                    case 'heart-full':
                    case 'favourite':
                        $scope.src += 'Heart-Full.png';
                        break;
                    case 'heart-empty':
                        $scope.src += 'Heart-Empty.png';
                        break;
                    case 'heart-blue':
                        $scope.src += 'Heart-Blue.png';
                        break;
                    case 'menu':
                        $scope.src += 'Menu.png';
                        break;
                	case 'adjust-color':
                	case 'adjust-colour':
                		$scope.src += 'Adjust-Colour.png';
                		break;
                	case 'adjust-bw':
                		$scope.src += 'Adjust-BW.png';
                		break;
                	case 'adjust-sepia':
                		$scope.src += 'Adjust-Sepia.png';
                		break;
                    case 'check-mark':
                        $scope.src += 'Check-Mark.png';
                        break;
                    default:
                        $scope.src += null;
                        break;
                }

                if ($scope.src == null)
                	console.error(errorPrefix + 'No type passed to icon.js', $scope.src);
            }
        ];
        return {
            scope: {
            	type: '@?',
				condition: '=?'
            },
            controller: controller,
            templateUrl: '/assets/html/directives/icon.html'
        }
    });