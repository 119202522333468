angular.module(app.appName)
    .controller('wechatpayController', [
        '$scope', '$rootScope', 'jsApiParams', '$window', '$location',
        function ($scope, $rootScope, jsApiParams, $window, $location) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.weChatPayAvailable = false;
            $scope.weChatPayStatus = "pending";

            $scope.jsApiParams = jsApiParams;

            // start weChatPay process
            $scope.jsApiCall = function() {

                $scope.weChatPayAvailable = true;

                $window.WeixinJSBridge.invoke(
                    'getBrandWCPayRequest',
                    jsApiParams,
                    function(res){
                        //WeixinJSBridge.log(res.err_msg);
                        //alert(res.err_code+res.err_desc+res.err_msg);
                        if(res.err_msg == "get_brand_wcpay_request:ok"){
                            //payment made
                            $scope.weChatPayStatus = "success";
                            $location.path("/pay/complete");
                        }else{
                            //failed or cancelled
                            $scope.weChatPayStatus = "failed";
                            $location.path("/pay/fail");
                        }
                    }
                );
            };

            /* Determine is Bridge is available on platform. */
            /* If platform is not yet ready, then */
            $scope.tryStart = function() {

                /* Be explicit about document */
                var document = $window.document;

                if (typeof $window.WeixinJSBridge == "undefined"){
                    if( document.addEventListener ){
                        document.addEventListener('WeixinJSBridgeReady', $scope.jsApiCall, false);
                    }else if (document.attachEvent){
                        document.attachEvent('WeixinJSBridgeReady', jsApiCall);
                        document.attachEvent('onWeixinJSBridgeReady', jsApiCall);
                    }
                }else{
                    $scope.jsApiCall();
                }
            };

            $scope.tryStart();
        }
    ]);