angular.module(app.appName)
  .directive('checkoutModule', function () {
    return {
      require: '^^checkoutContainer',
      scope: {
        module: '@'       // Name of this module in list
      },
      controller: ['$scope', function ($scope) {

        $scope.hasBeenActive = false;
        $scope.hasBeenCompleted = false;

        this.complete = function() {
          $scope.hasBeenCompleted = true;
          $scope.next();
        };

       }],
      link: function (scope, element, attrs, checkoutCtrl) {

        scope.next = function() { checkoutCtrl.next(); }

        scope.isActive = function () {
          // console.info(`[checkoutModule] [isActive] checking ${scope.module} is active ${checkoutCtrl.active}`);
          if (checkoutCtrl.active == scope.module) {
            scope.hasBeenActive = true;
            return true;
          }
          return false;
        };

        scope.containsModule = function() {
          var modules = checkoutCtrl.getModel();
          if (!modules) return false;
          return modules.checkoutModules.indexOf(scope.module) > -1;
        };

        scope.moduleIndex = function () {
          var modules = checkoutCtrl.getModel();
          if (!modules) return;
          return modules.checkoutModules.indexOf(scope.module) + 1;
        };

        scope.moduleName = function () {
          return scope.module;
        };

        scope.show = function(hasBeenActive) {
          if (hasBeenActive) {
            checkoutCtrl.setActive(scope.module);
          }
        };
      },
      transclude: true,
      templateUrl: '/assets/html/directives/checkout/checkoutModule.html'
    }
  });

