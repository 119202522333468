angular.module(app.appName)
    .factory('pipService', [
        '$location', 'dimensions',
        function ($location, dimensions) {
            var albumName = "";
            return {
            	setDimensions: function (imageArray,productItem) {
            		var selectedItems = _.filter(imageArray, function (image) { return image.isSelected === true });


            		_.each(selectedItems, function (item) {
            			var ratio = (item.lowRes.height / item.lowRes.width).toFixed(4);

            			//first thing we need to do is check if the productItem has a tag. If it does then we need to always check the ratio of the picture and select the product that has
            			//the same ratio as the picture.
            			var selectedProductItem = _.find(productItem.options[0].items, function (optionItem) {
            				return optionItem.selected === true;
            			});

            			//not sure if this part is needed at this point? everything i had tested went through the modalpicturepicker.
            			if (productItem.options[0].items[0].tag !== undefined && productItem.options[0].items[0].tag !== null) {
            				if (selectedProductItem.ratio !== ratio) {
            					selectedProductItem.selected = false;
            					var newSelectItem = _.find(productItem.options[0].items, function (optionItem) {
            						return optionItem.ratio.toFixed(4) === ratio && optionItem.tag === selectedProductItem.tag;
            					});
            					newSelectItem.selected = true;

            				}
            			}

            			//get some variables (after the alteration above).
            			var findProduct = _.find(productItem.images, function (product) { return product.imgIndex === item.id });
            			var sendRatioToServer = _.find(productItem.options[0].items, function (optionItem) {
            				return optionItem.selected === true;
            			}).ratio;

									var edgeDim = dimensions.previewMax;

            			//edge case where we dont have ratio on the options
            			if (!sendRatioToServer) {
            				if (ratio <= 1) {
            					findProduct.previewHeight = edgeDim * ratio; // -- user then productRatio
            					findProduct.previewWidth = edgeDim;
            				} else {
            					findProduct.previewHeight = edgeDim;
            					findProduct.previewWidth = edgeDim * ratio;; // -- user then productRatio
            				}
            			} else {
            				if (ratio < 1) {
            					if (sendRatioToServer <= 1) {
            						findProduct.previewHeight = edgeDim * sendRatioToServer; // -- user then productRatio
            						findProduct.previewWidth = edgeDim;
            					} else {
            						findProduct.previewHeight = edgeDim;
            						findProduct.previewWidth = edgeDim * sendRatioToServer;; // -- user then productRatio
            					}
            				} else if (ratio === 1) {
            					findProduct.previewHeight = edgeDim;
            					findProduct.previewWidth = edgeDim;
            				} else {
            					if (sendRatioToServer <= 1) {
            						findProduct.previewHeight = edgeDim;
            						findProduct.previewWidth = edgeDim * sendRatioToServer; // -- user then productRatio
            					} else {
            						findProduct.previewHeight = edgeDim * sendRatioToServer; // -- user then productRatio
            						findProduct.previewWidth = edgeDim;
            					}
            				}
            			}
            		});

            	}
            }
        }
    ]);

