angular.module(app.appName)
	.factory("helpers", [function() {
			return {
			    isNullOrUndefined: function (obj) {
			        return obj === undefined || obj === null;
			    },
			    trimDate: function (date) {
			        return date.split("T")[0];
			    },
			    prettifyDate: function (date) {
			        date = date.split("-");
			        if (date[2].charAt(0) == "0") {
			            date[2] = date[2].substring(1);
			        }
			        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
			        var suffixes = [{ key: "st", values: ["1", "21", "31"] }, { key: "nd", values: ["2", "22"] }, { key: "rd", values: ["3", "23"] }];
			        for (i = 0; i < suffixes.length; i++) {
			            if (_.contains(suffixes[i].values, date[2])) {
			                var daySuffix = suffixes[i].key;
			            }
			        }
			        if (daySuffix) {
			            var day = date[2] + daySuffix;
			        }
			        else {
			            var day = date[2] + "th";
			        }
			        var month = months[date[1] - 1];
			        var year = date[0];
			        return day + " " + month + " " + year;
			    }
			}
		}
	]);