angular.module(app.appName)
    .directive('disableOnClick', function () {
        var controller = [
            '$scope', '$element',
            function ($scope, $element) {
                // Ensures button gets disabled on first click
                $element.bind('click', function () {
                    $element.prop('disabled', true);
                });

                $scope.$watch('disableOnClick', function (disableOnClick) {
                    if (disableOnClick == true)
                        $element.prop('disabled', true);
                    else
                        $element.prop('disabled', false);
                });
            }
        ];
        return {
            controller: controller,
            restrict: 'A',
            scope: {
                disableOnClick: '='
            }
        }
    });