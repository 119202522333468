angular.module(app.appName)
	.factory('previewResource', [
		'$resource', 'apiUrl', 'useCache', function ($resource, apiUrl, useCache) {
			var api = apiUrl() + '/Preview/';
			return $resource(api, {}, {
				get: {
					method: 'GET',
					url: api + 'Get',
					params: {
					    id: '@id', // int
                        folder: '@folder' // bool
					},
                    cache: useCache()
				},
			});
		}
	]);