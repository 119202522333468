angular.module(app.appName)
    .directive('albumPicture', function () {
        var controller = [
            '$scope', '$element', '$route', 'sittingStore', 'navService',
            function ($scope, $element, $route, sittingStore, navService) {

            }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/albums/albumPicture.html'
        }
    });