angular.module(app.appName)
    .directive('register', function () {
        var controller = [
            '$scope',
            '$rootScope',
            'authService',
            'storageService',
            'userService',
            'accountService',
            'validationService',
            'routeService',
            'notifyService',
            '$timeout',
            '$filter',
            '$window',
            'statStore',
            'generalStore',
            '$q',
            'debug',
        function ($scope,
          $rootScope,
          authService,
          storageService,
          userService,
          accountService,
          validationService,
          routeService,
          notifyService,
          $timeout,
          $filter,
          $window,
          statStore,
          generalStore,
          $q,
          debug) {
            var self = this;

            $scope.showMore = false;

            $scope.showPassword = false;
            $scope.showNotifyMeCallout = false;
			$scope.showOptOutCallout = false;

            $scope.model = {};

            $scope.validated = {
                email: null,
                isValidated: false
            };

            $scope.setupModel = function() {
                $scope.validated.isValidated = storageService.get('validatedEmail');
                $scope.validated.email = statStore.email;
                $scope.model.email = statStore.email;

                if (!$scope.validated.isValidated) {
                    $scope.model.notifyMe = true;
                    $scope.model.optOutMarketing = false;
                }
            }

            // Assign the statStore to scope.
            // Watch the stat store for change of email,
            // and then update the validated address.
            // Make sure you destroy when leaving page.
            $scope.statStore = statStore;
            var unwatch = $scope.$on('statsChanged', function() {
                console.info("[register] statsChanged watch triggered");
                $scope.setupModel();
            });

            $scope.$on('$destroy', function() {
                unwatch();
            });

            // Sometimes stat store email will have changed before we start watching it.
            if ('set' in statStore) {
                console.info("[register] statsChanged set before watch started");
                $scope.setupModel();
            }

            var countryPrerequisites = [
                accountService.getCountries(false).$promise, // data[0]
                userService.getLocation().$promise           // data[1]
            ];

            /* Load prerequisites for finding a user's country. */
            $q.all(countryPrerequisites).then(function(data) {

                /* Once we have the country and session data, then attempt to find
                   and assign the users country with it. */

                $scope.countries = data[0].list;

                var session = data[1]; // Apparently...

                var userCountry = _.findWhere($scope.countries, { id: session.countryId }) || $scope.countries[0];
                if (userCountry) {
                    // document.getElementById('registerCountrySelect').options[userCountry.position].selected = 'selected';
                    $scope.model.country = userCountry;
                }
            });

            $scope.passwordStrength = 1;
            $scope.validPasswordsMatch = true;
            $scope.userExists = false;

            /* Change value of showPassword, trigger change in DOM */
            $scope.changeShowPassword = function(val, scopeParam) {
                $scope[scopeParam] = val;
            };

            /* Lookup table of validation fields */
            $scope.userFields = [
                { name: "Forename", valid: true },
                { name: "Surname", valid: true },
                { name: "Country", valid: true },
                { name: "Email", valid: true },
                { name: "Password", valid: true },
                { name: "Confirm Password", valid: true }
            ];

            /* Lookup table of user descriptions */
            $scope.userDesc = [
                "Forename",
                "Surname",
                "Country",
                "Email",
                "Password",
                "Confirm Password"
            ];

            /* Lookup table of user inputs */
            $scope.userInputs = function() {
                return [
                    $scope.model.forename,
                    $scope.model.surname,
                    $scope.model.country,
                    $scope.model.email,
                    $scope.model.password,
                    $scope.model.confirmPassword
                ];
            };

            /* Validate all fields */
            $scope.validateGlobal = function() {

                var userInputs = $scope.userInputs();

                var errors = $scope.userFields.reduce(function(errors, userField, index) {

                    /* Map over each set of fields and add the errors to the set */
                    var userDesc = $scope.userDesc[index];
                    var userInput = userInputs[index];

                    /* Collect errors for field and apply to collection */
                    var localErrors = $scope.validateField(userDesc, userInput, userField);
                    Array.prototype.push.apply(errors, localErrors);

                    return errors; // Assign to acc
                }, []);

                return errors;
            };

            /* Validate an individual field by index */
            $scope.validateFieldIndex = function(index) {
                var userDesc = $scope.userDesc[index];
                var userInput = $scope.userInputs()[index];
                var userField = $scope.userFields[index];

                return $scope.validateField(userDesc, userInput, userField);
            };

            /* Validate an individual field and return a result - to be aggregated */
            $scope.validateField = function(userDesc, userInput, userField) {
                var userData = [ { description: userDesc, value: userInput }];
                var errors = validationService.checkAccountInput(userData);

                /* Set flag on userField */
                userField.valid = ! errors.length;

                return errors;
            };

            /* password and confirm password are matched */
            $scope.passwordsMatch = function() {
                if (! $scope.model.confirmPassword) { // Empty result does not trigger
                    return false;
                }

                $scope.validPasswordsMatch = validationService.checkEquality($scope.model.password, $scope.model.confirmPassword);
                return $scope.validPasswordsMatch;
            };

            var mediumStrength = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"); // 6 chars, 1 lower, 1 upper, 1 digit.
            var highStrength = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"); // 8 chars, 1 lower, 1 upper, 1 digit, 1 special.

            $scope.validatePasswordStrength = function() {
                var password = $scope.model.password;

                if (!password) {
                    $scope.passwordStrength = 1; // Minimum.
                }

                if (mediumStrength.test(password)) { $scope.passwordStrength = 2; }
                if (highStrength.test(password)) { $scope.passwordStrength = 3; }

                console.debug("[Register] Evaluating password complexity: ", $scope.passwordStrength);
            };

            $scope.register = function () {
                $scope.loading = true;
                var email = $filter('lowercase')($scope.model.email);

                /* Locally scope to make this work... */
                var userDesc = $scope.userDesc;
                var userInput = $scope.userInputs();

                var userData = [];
                for (i = 0; i < userDesc.length; i++) {
                    var entry = { description: userDesc[i], value: userInput[i] }
                    userData.push(entry);
                }

                var errors = $scope.validateGlobal();
                // var errors = validationService.checkAccountInput(userData);
                // Filter emails to be case insensitive to make validation easier

                $scope.model.email = email;
                if (!errors.length && $scope.passwordsMatch()) {
                    authService.checkUserExists(JSON.stringify(email)).$promise.then(function (data) {
                        $scope.userExists = data.userExists;
                        if ($scope.userExists == 0) {
                            authService.register($scope.model).then(function (result) {
                                if (result.success == true) {
                                    authService.login({ username: $scope.model.email, password: $scope.model.password }).then(function (result) {
                                        if (result.success == true) {
                                            storageService.set('authenticated', true);
                                            notifyService.success('LOGIN_SUCCESS');
                                            $rootScope.$broadcast('authenticationSet');
                                            var nextRoute = generalStore.get('nextRoute').value;
                                            if (nextRoute) {
                                                routeService.redirect(nextRoute);
                                            }
                                            else {
                                                routeService.redirect();
                                            }
                                        }
                                    });
                                }
                                else {
                                    $scope.model.password = null;
                                    $scope.model.confirmPassword = null;
                                    $scope.loading = false;
                                }
                            });
                        }
                        else {
                            $scope.model.password = null;
                            $scope.model.confirmPassword = null;
                            $scope.loading = false;
                        }

                    });

                }
                else {
                   $scope.loading = false;
                }
                $scope.model.countryId = $scope.model.country.id;
            }

            $scope.bypassRegister = function() {
				routeService.redirect('/MyAlbums/');
			}

            $scope.toggleOptOutCallout = function() {
				$scope.showOptOutCallout = !$scope.showOptOutCallout;
			};

			$scope.toggleNotifyMeCallout = function() {
				$scope.showNotifyMeCallout = !$scope.showNotifyMeCallout;
			};

            $scope.getRegisterButtonString = function() {
                if ($scope.validated.isValidated
                    && routeService.getPreviousRoute().toUpperCase().indexOf('BASKET') < 0) {
                    return 'REGISTER_AND_VIEW_PICTURES';
                } else {
                    return 'REGISTER';
                }
            };

            $scope.showRegisterBypass = function() {
                return $scope.validated.isValidated && routeService.getPreviousRoute().toUpperCase().indexOf('BASKET') < 0;
            };
       }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/login/register.html'
        }
    });