angular.module(app.appName)
    .service('sittingNotificationService', [
        'sittingNotificationResource',
        function (sittingNotificationResource) {
        	var self = this;

        	return {
        		post: function (model) {
        		    return sittingNotificationResource.post(model);
        		},
        	}
        }
    ]);