angular.module(app.appName)
    .directive('language', function () {
        var controller = [
            '$rootScope', '$scope', '$translate', '$route', 'storageService', 'translationService', '_', 'generalStore',
            function ($rootScope, $scope, $translate, $route, storageService, translationService, _, generalStore) {
                var self = this;
                self.cultureKey = 'culture';

                $scope.culture = '';
                $scope.cultures = [
                    { key: 'en', value: 'CULTURE_ENGLISH' },
                    { key: 'es', value: 'CULTURE_SPANISH' },
                    { key: 'ko', value: 'CULTURE_KOREAN' },
                    { key: 'ar', value: 'CULTURE_ARABIC', rtl: true },
                    { key: 'vi', value: 'CULTURE_VIETNAMESE'},
                    { key: 'zh-hans', value: 'CULTURE_CHINESE' },
                ];

                $scope.changeLanguage = function (culture) {
                    console.info('[language] Language has been changed to: ', culture);
                    var cultureObject = _.findWhere($scope.cultures, { key: culture });
                    $rootScope.isRtlLanguage = cultureObject.rtl;
                    translationService.setCulture(culture);
                    $rootScope.$emit('languageChanged', culture);

                    generalStore.set('culture', culture);
                }

                $scope.getCultureFlag = function (culture) {
                    if (culture) {
                        var base = '/assets/images/icons/flags/';
                        return base += culture.toLowerCase() + '.png';
                    }
                }

                // Sniff the user's culture from the browser, return preference
                // by ranking.
                var sniffCulture = function() {
                    var sniffedCultures =
                        $scope.cultures.filter(function(culture) {
                            return navigator.languages.indexOf(culture.key) > -1 });
                    sniffedCultures.sort(function (cultureA, cultureB) {
                        return navigator.languages.indexOf(cultureA.key) - navigator.languages.indexOf(cultureB.key)});
                    var sniffedCulture = sniffedCultures.length > 0
                        ? sniffedCultures[0].key : null;

                    return sniffedCulture;
                };

                self.init = function() {
                    var storedCulture = storageService.get(self.cultureKey);
                    var sniffedCulture = sniffCulture();

                    var routeLang = (($route.current || {}).params || {}).lang;
                    if (routeLang) {
                        $scope.culture = routeLang;
                    } else if (storedCulture) {
                        $scope.culture = storedCulture;
                    } else if (sniffedCulture) {
                        $scope.culture = sniffedCulture;
                    } else {
                        $scope.culture = translationService.defaultCulture;
                    }

                    $scope.changeLanguage($scope.culture);
                }();
            }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/language.html'
        }
    });
