
angular.module(app.appName).directive("modalLoginReminder", function () {
  var controller = [
    '$scope',
    'routeService',
    'storageService',
   function (
      $scope,
      routeService,
      storageService
   ) {
      $scope.basketDisable = true;
      // These seem to persist from other modals so am resetting them.
      $scope.modalBase.headerTitle = '';
      $scope.modalBase.backMessageKey = null;
      $scope.modalBase.backIcon = null;
      $scope.modalBase.showContinueAction = false;

      $scope.isValidatedSession = storageService.get('validatedEmail');

      $scope.continue = function() {
        $scope.modalBase.closeModal();
      };

      $scope.goToLogin = function() {
        routeService.redirect('/Login');
        $scope.modalBase.closeModal();
      };

      $scope.goToRegister = function() {
        routeService.redirect('/Register');
        $scope.modalBase.closeModal();
      };

      $scope.getLoginStatus = function() {
        if (storageService.get("validatedEmail")) {
          return 'EMAIL SESSION'
        } else {
          return 'NON-EMAIL SESSION'
        }
      }
    },
  ];
  return {
    restrict: "E",
    // 'scope: true' tells this child directive to create its own scope usable within the current directive.
    // What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
    // modalBase object.
    scope: true,
    controller: controller,
    templateUrl: "/assets/html/directives/modal/modalLoginReminder.html",
  };
});
