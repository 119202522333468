angular.module(app.appName)
	.controller('picturePreviewController', [
		'$rootScope', '$scope', '$route', 'helpers', '_', '$location', 'previewData', 'navService', 'routeService', 'myPicturesService', 'sittingStore', 'generalStore', 'previewService', '$timeout', '$filter', 'filterFavourites',
        function ($rootScope, $scope, $route, helpers, _, $location, previewData, navService, routeService, myPicturesService, sittingStore, generalStore, previewService, $timeout, $filter, filterFavourites) {
            var self = this;

            $scope.$emit('showProducts', { state : true });

            self.routeParams = $route.current.params;
            $scope.previewData = previewData;


            $rootScope.$emit('disableBuy', { disable: false });

            // Set image filename to an empty string while we load the Preview data to avoid an unsightly "Preview - undefined" label

            $scope.filterFavourites = filterFavourites;

            previewData.promise.then(function (data) {
                if (filterFavourites) {

                    /* Favs only */

                    $scope.favourites = data.favourites; // Cheat

                    // Kian made me do it
                    $scope.favourites.images = _.chain($scope.favourites.images)
                        .sortBy('imgFileName')
                        .sortBy('eventYear')
                        .value();

                    $scope.favourites.images.forEach(function(x) { x.isFavourite = true; });

                    $scope.preview = _.findWhere($scope.favourites.images, { id: parseInt($route.current.params.pictureId) });

                    console.log("EZ", $scope.preview);

                    if ($scope.preview.zoom && !$rootScope.isMobile) {
                        $timeout(function() {
                            $("#zoom_01").ezPlus({
                                constrainType: 'height',
                                constrainSize: 200,
                                zoomType: 'lens',
                                containZoomLens: true,
                            });
                        });
                    }

                    var routeName = routeService.get().fullName;
                    var params = { name: previewData.filename };
                    routeService.set(routeName, params);
                    generalStore.set('currentPreviewImage', $scope.preview);
                    generalStore.set('previewImageAllowedProducts', data.products);

                    $rootScope.$broadcast('productsTrigger', { imageId: $route.current.params.pictureId });

                    previewService.setNextAndPreviousIds($scope.favourites.images, $scope.preview);
                } else {

                    /* Regular sitting */

                    if (data.favourites)
                        data.none.images = myPicturesService.initFavouritesOnPictures(data.favourites.images, data.none.images);
                    $scope.sitting = data;
                    $scope.preview = _.findWhere($scope.sitting.none.images, { id: parseInt($route.current.params.pictureId) });

                    console.log("EZ", $scope.preview);

                    if ($scope.preview.zoom && !$rootScope.isMobile) {
                        // $scope.ezpModel = {
                        //     small: $scope.preview.lowRes.url,
                        //     large: $scope.preview.zoom.url };
                        $timeout(function() {
                            $("#zoom_01").ezPlus({
                                constrainType: 'height',
                                constrainSize: 200,
                                zoomType: 'lens',
                                containZoomLens: true,
                            });
                        });
                    }
                    var routeName = routeService.get().name;
                    var params = { name: previewData.filename };
                    routeService.set(routeName, params);
                    generalStore.set('currentSitting', $scope.sitting);
                    generalStore.set('currentPreviewSitting', { sittingId: data.sittingId, albumId: data.albumId });
                    generalStore.set('currentPreviewImage', $scope.preview);
                    generalStore.set('previewImageAllowedProducts', data.products);
                    $rootScope.$broadcast('productsTrigger', { imageId: $route.current.params.pictureId });

                    previewService.setNextAndPreviousIds($scope.sitting.none.images, $scope.preview);
                }

                // $timeout(function () {
                //     if (navigator.userAgent.search("Firefox") >= 0) {
                //         // Add explicit src so that Firefox can pick up the image
                //         var fade = document.getElementById('fade');
                //         var img = document.getElementById('preview-img');
                //         img.style.backgroundImage = 'url("' + $scope.preview.lowRes.url + '") no-repeat';
                //         img.style.height = fade.height + "px";
                //         var zoom = document.getElementById('zoom_01');
                //         zoom.src = $scope.preview.lowRes.url;
                //     }
                // }, 50);
            });

            $scope.goToBasket = function ($event) {
                $event.stopPropagation();
                $location.path("/Basket");
            }

            // Ascending will be a boolean, if true we want the next picture, otherwise we want the previous picture.
            $scope.changePicture = function (ascending) {
                $scope.loading = true;
                generalStore.set('lastProductScrollTop', $rootScope.isMobile ? document.documentElement.scrollTop : $('.products-list').scrollTop());
                if (ascending) {

                    if ($scope.filterFavourites) {
                        navService.previewFavourite($scope.preview.next);
                    } else {
                        navService.previewPicture(self.routeParams.sittingId, self.routeParams.albumId, $scope.preview.next);
                    }
                } else {
                    if ($scope.filterFavourites) {
                        navService.previewFavourite($scope.preview.previous);
                    } else {
                        navService.previewPicture(self.routeParams.sittingId, self.routeParams.albumId, $scope.preview.previous);
                    }

                }
            }

            if (window.innerWidth < 768) {
                $scope.lensSize = 100;
            }

            var imageLoad = function() {
                $scope.loading = false;
            };

            $rootScope.$watch('showingModal', function (newVal) {
                $scope.showingModal = newVal;

                $timeout(function() {
                    var image = document.getElementById('zoom_01');
                    if (image) {
                        image.addEventListener('load', imageLoad);
                    }
                }, 0);
           });

            $rootScope.$on('imageAddedToBasket', function (evt, args) {
				if (args.imagesAdded) {
                    var previewAddedToBasket = _.find(args.imagesAdded, function(imageToFind) { return imageToFind.imgIndex == $scope.preview.id; });
                    if (previewAddedToBasket) {
                        if (args.amountOfTimesImageAdded)
                            // If basketCount is already greater than 0, just increment, otherwise set to 1. This avoids NaN errors
                            $scope.preview.basketCount > 0 ? $scope.preview.basketCount += args.amountOfTimesImageAdded : $scope.preview.basketCount = args.amountOfTimesImageAdded;
                        else
                            $scope.preview.basketCount > 0 ? $scope.preview.basketCount++ : $scope.preview.basketCount = 1;
                    }
                }

                // if (args.id == $scope.preview.id) {
                //     if (args.amountOfTimesImageAdded)
                //         // If basketCount is already greater than 0, just increment, otherwise set to 1. This avoids NaN errors
                //         $scope.preview.basketCount > 0 ? $scope.preview.basketCount += args.amountOfTimesImageAdded : $scope.preview.basketCount = args.amountOfTimesImageAdded;
                //     else
                //         $scope.preview.basketCount > 0 ? $scope.preview.basketCount++ : $scope.preview.basketCount = 1;
                // }
            });

            window.addEventListener("scroll", function() {
                // Make preview image small and sticky when scrolled down.
                $('#preview-img-sticky').css('top', $('.header-mobile').height() + $('#banner-main').height() + 4);
                var y = window.scrollY;
                if (y >= $('#preview-img').height() + $('.header-mobile').height() + $('#banner-main').height() - 60) {
                    $('#preview-img-sticky').show(100);
                } else {
                    $('#preview-img-sticky').hide(100);
                }

                // Remove scroll down notification when scrolled down.
                if (y <= 60) {
                    $('#scroll-notification-text').show(10);
                    $('#scroll-notification').show(200);
                } else {
                    $('#scroll-notification-text').hide(10);
                    $('#scroll-notification').hide(200);
                }
            });

            // Show scroll down notification when products are below the page.
            $scope.showScrollNotification = function() {
                return window.innerWidth <= 1280 && $('#desktop-products').position().top > window.innerHeight - 50;
            }

            $scope.scrollToProducts = function() {
                window.scrollTo({
                    top: $('#desktop-products').position().top - ($('.header-mobile').height() + $('#banner-main').height() + 30),
                    left: 0,
                    behavior: "smooth",
                  });
            }
        }]);