angular.module(app.appName)
  .factory('fetchifyService', ['accountService', '$translate', '$q', '$rootScope',
    function (accountService, $translate, $q, $rootScope) {

      /* Return list of all countries to be used for determining placeholders */

      var countries = null;

      var searchMap = null;

      var initCountries = function (result) {
        console.info(`[fetchifyService.initCountries]`);
        countries = result;
      }

      var initSearchMap = function () {
        /* We need to create a map of countries */
        console.info(`[fetchifyService.initSearchMap]`);
        searchMap = {};
        // debugger;
        countries.list.forEach(function (country) {
          var key = 'FETCHIFY_SEARCH_' + (country.fetchifyFidelity || 0)
          searchMap[country.iso2] = $translate.getTranslationTable()[key] ||
            $translate.getTranslationTable("en")[key];
        });
      }

      var cc = null;
      var initFetchify = function () {
        console.info(`[fetchifyService.initFetchify]`);
        cc = new clickToAddress({
          style: {
            ambient: 'dark',
            accent: 'lightBlue',
          },
          countrySelector: false,
          gfxMode: 2,
          accessToken: 'e6972-ff275-68b7a-06e00',
          enabledCountries: [],
          domMode: 'id',
          getIpLocation: false,
          showLogo: false,
          countryLanguage: 'en',
          texts: {
            placeholder_country_overrides: searchMap
          }
        });
      }

      // We can wrap  this in a top level promise.
      var initPromise = $q(function (resolve) {
        console.info(`[fetchifyService.initPromise]`);
        accountService.getCountries(false).$promise
          .then(initCountries)
          .then(initSearchMap)
          .then(initFetchify)
          .then(function () {
            resolve(cc)
          });
      });

      return {
        cc: $q(function (resolve) {
          console.info(`[fetchifyService.ccPromise]`);
          initPromise.then(function (cc) {
            resolve(cc);
          });
        })
      };
    }]);