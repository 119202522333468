angular.module(app.appName)
	.directive('spinner', function () {
		var controller = [
			'$scope', '$element', '$compile',
			function ($scope, $element, $compile) {
				$element.html('<i data-ng-show="spinner" class="fa fa-spinner fa-spin"></i> ' + $element.text());
				$compile($element.contents())($scope);
			}
		];

		return {
			scope: {
				spinner: '=',
			},
			controller: controller,
		}
	});