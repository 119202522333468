angular.module(app.appName)
  .directive('imageFolders',
    ['$', '$rootScope', 'routeService', 'helpers', 'statStore', 'generalStore', '$timeout', '$q', 'myPicturesService', 'userService', 'enums', '$translate', '$route',
      function ($, $rootScope, routeService, helpers, statStore, generalStore, $timeout, $q, myPicturesService, userService, enums, $translate, $route) {
        return {
          scope: {
            selectedImageFolderId: '=selected',
            hideFavourites: '=hideFavourites',
            modal: '=modal'
          },
          link: function (scope, element, attrs) {

            var fn = function (year) {
              return Object.values(year).flat().filter(function (x) { return x.sittingId; });
            }

            scope.isFavouriteRoute = function () {
              return routeService.get().name.includes('FAV');
            }

            scope.imageFoldersView = []; // Must stay same.
            scope.loading = true;

            scope.getImageFolders = function () {
              return $q(function (resolve) {

                $q.all([
                  $translate('DISCOUNTS'),
                  $translate('DISCOUNTS_EXPIRING'),
                  $translate('EXPIRES'),
                  $translate('EXPIRES_IN'),
                  $translate('EXPIRES_TODAY'),
                  $translate('EXPIRES_TOMORROW')]).then(function (translations) {
                    scope.discountString = translations[0];
                    scope.expiringString = translations[1];
                    scope.expiresString = translations[2];
                    scope.expiresInString = translations[3];
                    scope.expiresTodayString = translations[4];
                    scope.expiresTomorrowString = translations[5];
                });

                var statsPromise = $q(function (resolve) {
                  userService.getStats().$promise.then(function (stats) {
                    resolve(stats.counts);
                  });
                });

                var folderPromise = $q(function (resolve) {
                  myPicturesService.getSitting(null, null, null).then(function (imageFolderData) {
                    scope.imageFolderData = imageFolderData;
                    imageFolderData.promise.then(function (data) {
                      scope.imageFolders = data;

                      console.info('[imageFolders.getImageFolders] imageFolders none', scope.imageFolders);
                      resolve(scope.imageFolders);
                    });
                  });
                });

                $q.all([statsPromise, folderPromise]).then(function (resolves) {
                  var stats = resolves[0];
                  var imageFolders = resolves[1];

                  scope.updateImageFolderView(imageFolders, stats);
                  resolve(imageFolders);
                  scope.loading = false;
                });
              });
            }

            scope.updateImageFolderView = function (imageFolders, stats) {
              var noneFolders = imageFolders.none.folders.years.flatMap(fn);
              noneFolders.forEach(function (folder) {
                folder.albumUrl = "/Album/" + folder.sittingId + "/" + folder.id;
                folder.packUrl = "/Packs/" + folder.sittingId + "/" + folder.id;
                folder.isFavourite = false;
                folder.earlybirdString = scope.getEarlyBirdTime(folder.upsells);
              });

              // Sort descending by eventDate.
              noneFolders.sort( function (a, b) {
                 return Date.parse(b.eventDate) - Date.parse(a.eventDate);
                });

              // Do not display favourites for packs
              // var isPacks = routeService.get().name == 'PACKS';
              // if (isPacks) scope.hideFavourites = true;

              if (!(scope.hideFavourites) && stats.favouriteCount > 0) {
                noneFolders.unshift({
                  id: null,
                  organisationName: 'Favourites',
                  isFavourite: true,
                  eventDate: null,
                  preview: { url: '/assets/images/icons/Heart-Large.png' },
                  albumUrl: "/Favourites",
                  packUrl: "/Packs/Favs"
                });
              }

              scope.imageFoldersView = noneFolders;
              return noneFolders;
            }

            scope.getEarlyBirdTime = function(upsells) {
              var earlybird = upsells
                    .filter(function(x) {return x.discountRuleTypeId == enums.discountRuleType.earlybird})
                    .filter(function(x) {return new Date(x.validUntil).setDate(new Date(x.validUntil).getDate() + 1) > new Date().getTime()})
                    .sort(function(x, y) {return new Date(x.validUntil) - new Date(y.validUntil)})
                    .find(function(x) {return x != null});
              if (!earlybird) {
                return '';
              }

              var expiryTime = new Date(earlybird.validUntil);
              expiryTime.setDate(expiryTime.getDate() + 1); // Add a day to the expiry time because Pret.
              var distance = expiryTime - new Date().getTime();

              var formatExpiryDate;
              if (distance < 48 * 60 * 60 * 1000) {
                formatExpiryDate = scope.expiresInString + ' ' + Math.floor(distance / (1000 * 60 * 60)) + 'h';
              } else {
                const options = {
                  month: "long",
                  day: "numeric"
                };
                formatExpiryDate = scope.expiresString + ' ' + new Date(earlybird.validUntil).toLocaleDateString(undefined, options);
              }

              return earlybird.description + ' - ' + formatExpiryDate;
            };

            /* Update folder list on status changed. */
            $rootScope.$on('favouriteChanged', function() {
              scope.getImageFolders(); } );
            $rootScope.$on('sittingAdded', function() {
              scope.getImageFolders(); });

            scope.getCurrentFolderIndex = function () {

              if (scope.isFavouriteRoute()) return 0;

              return scope.imageFoldersView.map(function (x) { return x.id; }).indexOf(parseInt(scope.selectedImageFolderId));
            }

            scope.getScrollX = function () {
              var width = $('.album-image-folders .folder').first().outerWidth(true) || 205;
              var selectedPosition = (scope.getCurrentFolderIndex()) * width;
              var viewPosition = generalStore.get('imageFolderScrollX').value || 0; // Left edge.
              var viewWidth = $('.album-image-folders-container').first().outerWidth(true) || 1200;
              if (selectedPosition < viewPosition) {
                return selectedPosition;
              }
              else if (selectedPosition > viewPosition + viewWidth - width) {
                return selectedPosition - viewWidth + width;
              }
              else {
                return generalStore.get('imageFolderScrollX').value || 0;
              }
            }

            scope.setScrollX = function () {
              $timeout(
                function () {
                  var first = $('.album-image-folders')[0];
                  if (first) {
                    var scrollLeft = scope.getScrollX();
                    $('.album-image-folders')[0].scrollLeft = scrollLeft;
                    console.info('[imageFolders.init] Setting scroll position to ', scrollLeft);
                  }

                }, 0);
            }

            scope.init = function () {
              scope.setScrollX();
              scope.getImageFolders().then(scope.initSelectFolder);
            }

            scope.getImageFolderWidth = function () {
              var imageFolders = scope.imageFoldersView.length;

              // Get first folder for dimension
              var width = $('.album-image-folders .folder').first().outerWidth(true) || 205;

              return imageFolders * width;
            }

            scope.isSelected = function (folder) {
              if (folder.isFavourite && scope.isFavouriteRoute()) {
                return true;
              }
              else if (folder.id == scope.selectedImageFolderId && !scope.isFavouriteRoute()) {
                return true;
              }
              return false;
            }

            scope.incrementFolder = function () {
              var currentIndex = scope.getCurrentFolderIndex();
              var nextIndex = currentIndex >= scope.imageFoldersView.length - 1 ? currentIndex : currentIndex + 1;
              scope.selectImageFolder(scope.imageFoldersView[nextIndex]);
            }

            scope.decrementFolder = function () {
              var currentIndex = scope.getCurrentFolderIndex();
              var nextIndex = currentIndex <= 0 ? currentIndex : currentIndex - 1;
              scope.selectImageFolder(scope.imageFoldersView[nextIndex]);
            }

            scope.selectImageFolder = function (folder, skipLoading) {
              if ($('.album-image-folders')[0]) {
                generalStore.set('imageFolderScrollX', $('.album-image-folders')[0].scrollLeft);
              }
              scope.setScrollX();

              scope.selectedImageFolderId = folder.id; // This is outside of controller and is being persisted.

              generalStore.set('lastSelectedFolder', folder); // Need to persist selected folder after navigation.

              // This is emitted if we're in a modal.
              // Skip if on init.
              scope.modal
                ? function () {
                  console.info('[imageFolders.selectImageFolder] Change selected folder to', folder);

                  $rootScope.$emit('currentFolder', folder);
                }()
                : function () {
                    if (! skipLoading) { $rootScope.$emit('loading') };
                    $rootScope.$emit('currentFolder', folder);

                    // We only need to change the route *if* the folder has changed.
                    // Otherwise it's a double reload.

                    if ($route.current)
                    {
                      if (routeService.get().name == 'ALBUM' || routeService.get().name == 'FAVOURITES') {
                        if (folder.sittingId != $route.current.params.sittingId ||
                          folder.id != $route.current.params.albumId) {
                            routeService.redirect(folder.albumUrl);
                          }
                      } else if (routeService.get().name == 'PACKS' || routeService.get().name == 'FAVS') {

                        if (folder.sittingId != $route.current.params.sittingId ||
                          folder.id != $route.current.params.albumId) {
                              routeService.redirect(folder.packUrl);
                          }
                      }
                    }
                }();
            }

            /* Checks if a requested folder is available and sets. */
            scope.initSelectFolder = function () {

            // Attempt to use selected folder Id first.
            // Then fall back to last selected folder.
            // Eventually use first found folder.

            var folder = null;
            if (scope.selectedImageFolderId) {
              console.info('[imageFolders.initSelectFolder] scope.selectedImageFolderId', scope.selectedImageFolderId);
              folder = scope.imageFoldersView.find(function (folder) { return folder.id == scope.selectedImageFolderId })
            }
            else {
              var folderId = generalStore.get('lastSelectedFolder').value;
              if (folderId) {
                console.info('[imageFolders.initSelectFolder] generalStore', folderId);
                folder = scope.imageFoldersView.find(function (folder) { return folder.id == scope.selectedImageFolderId })
              }
            }

            if (!folder) folder = scope.imageFoldersView[0];

            if (folder) scope.selectImageFolder(folder, true);
          }

            scope.getPrettyEventDate = function (date) {
            if (date == null) {
              return '';
            }
            return helpers.prettifyDate(helpers.trimDate(date));
          }

          scope.init();

          $rootScope.$on('updatedImageFolders', function () {
            console.log("Update to image folders", scope.imageFolders);
            scope.init();
          });

          $rootScope.$on('authenticationSet', function () {
            console.log("Update to image folders", scope.imageFolders);
            scope.init();
          });

          $rootScope.$on('changeAlbum', function(s, args) {
            scope.selectedImageFolderId = args.albumId;
            scope.init();
          });
      },
          templateUrl: '/assets/html/directives/imageFolders/imageFolders.html'
      }
      }]
  );
