angular.module(app.appName)
    .service('worldpayService', [
        'apiUrl', '$http',
        function (apiUrl, $http ) {
            var self = this;

            var api = apiUrl() + "/WorldPay/"

        	return {
                generateSale: function(payment_provider, lang) {
                    return $http.post(api + "/GenerateSale",
                        {
                            paymentProvider: payment_provider,
                            lang: lang
                        });
                }
           }
        }
    ]);

