angular.module(app.appName)
    .directive('notifyThanks', function () {
        var controller = [

            '$scope', '$rootScope',
            function ($scope, $rootScope) {

                var self = this;

                $scope.backLink = $scope.sessionValid.isValid
                    ? "/MyAlbums"
                    : "https://www.pret-a-portrait.net/";
            }
        ];
        return {
            controller: controller,
            scope: {
                sessionValid: "="
            },
            templateUrl: '/assets/html/directives/notifyThanks/notifyThanks.html'
        };
    });