angular.module(app.appName)
	.factory('itemResource', [
		'sessionService', '$resource', 'apiUrl', 'useCache', 'config',
			function (sessionService, $resource, apiUrl, useCache, config) {
		    var api = apiUrl() + '/Item/';
				var newApiUrl = config.apiUrl + '/Item/';
		    return $resource(api, {}, {
		        get: {
		            method: 'GET',
		            url: newApiUrl + 'Index',
		            params: {
		                productId: '@productId', // int?
		                itemId: '@itemId', // guid?
										imageId: '@imageId', // int?
		                folderId: '@folderId', // int?
		                preview: '@preview'//bool
		            },
								headers: {
									'Authorization': sessionService.getAuthHeader
								},
		            cache: useCache()
		        },
		        put: {
		            method: 'PUT',
		            url: newApiUrl + 'Put',
								headers: {
									'Authorization': sessionService.getAuthHeader
								}
		        },
		        post: {
		            method: 'POST',
		            url: newApiUrl + 'Post',
								headers: {
									'Authorization': sessionService.getAuthHeader
								},
		        },
        		editNote: {
		            method: 'POST',
		            url: api + 'EditNote',
		            params: {
		                itemId: '@itemId', // guid
		                note: '@note' // string
		            }
		        },
		        tintImage: {
		            method: 'POST',
		            url: api + 'TintImage',
		            params: {
		                itemId: '@itemId',
		                position: '@position',
		                tint: '@tint'
		            }
		        },
		        rotateImage: {
		            method: 'POST',
		            url: api + 'RotateImage',
		            params: {
		                itemId: '@itemId',
		                position: '@position'
		            }
		        },
		        cropImage: {
		            method: 'GET',
		            url: api + 'CropImage',
		            params: {
		                itemId: '@itemId',
		                position: '@position'
		            }
		        },
		        cropImageWithCoordinates: {
		            method: 'POST',
		            url: api + 'CropImage',
		            data: {
		                itemId: '@itemId',
		                position: '@position',
		                x0: 'x0',
		                x1: 'x1',
		                y0: 'y0',
		                y1: 'y1',
		                custom: 'custom'
		            }
		        },
		        resetCropBox: {
		            method: 'GET',
		            url: api + 'ResetCrop',
		            params: {
		                itemId: '@itemId',
		                position: '@position'
		            }
		        },
		        generatePip: {
		            method: 'POST',
		            url: api + 'Pip',
		            params: {
		                itemId: '@itemId'
		            }
		        },
		        addImage: {
		            method: 'POST',
		            url: api + 'AddImage',
		            params: {
		                itemId: '@itemId', // guid
		                imageId: '@position', // int
		                position: '@position', // int
		                previewWidth: '@previewWidth', // int
		                previewHeight: '@previewHeight', // int
		                original: '@original', // bool
		            }
		        }
		    });
		}
	]);