angular.module(app.appName)
	.factory('userResource', [
		'$resource', 'apiUrl', 'useCache', function($resource, apiUrl, useCache) {
			var api = apiUrl() + '/User/';
			return $resource(api, {}, {
				getStats: {
					method: 'GET',
					cache: useCache(),
					url: api + 'Status'
				},
				getLocation: {
				    method: 'GET',
				    url: apiUrl() + '/Session/Index'
				},
				setPreferences: {
					method: 'POST',
					url: api + 'SetMarketingPreferences'
				}
			});
		}
	]);