angular.module(app.appName)
    .service('basketService', [
        'basketResource', '$rootScope', 'cacheService',
        function (basketResource, $rootScope, cacheService) {
        	var self = this;

        	return {
        		get: function () {
        			return basketResource.get();
        		},
        		setQuantity: function (itemId, quantity) {
					cacheService.obliterate();
        		    return basketResource.setQuantity({ itemId: itemId, quantity: quantity });
        		},
        		deleteItem: function (itemId, callback) {
					cacheService.obliterate();
        		    return basketResource.deleteItem({ itemId: itemId }).$promise.then(function (data) {
		                callback(data);
		            });
        		}
        	}
        }
    ]);