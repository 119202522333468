angular.module(app.appName).directive("pretFooter", function () {
  var controller = [
    "$scope",
    "generalStore",
    "translationService",
    "config",
    "$rootScope",
    function ($scope, generalStore, translationService, config, $rootScope) {
      $scope.translationService = translationService;
      $scope.cookiesAccepted = Cookies.get("cookiesAccepted");
      $scope.acceptCookies = function () {
        Cookies.set("cookiesAccepted", true, { expires: 30 });
        $scope.cookiesAccepted = true;
      };

      $scope.config = config;

      $scope.updateCurrentSitting = function () {
        $scope.currentSittingId = (
          generalStore.get("currentSittingId") || {}
        ).value;
        $scope.currentAlbumId = (
          generalStore.get("currentAlbumId") || {}
        ).value;
      };

      $rootScope.$on("productsTrigger", function () {
        $scope.updateCurrentSitting();
      });

      $scope.generateFeedbackUrl = function() {
        var url = "/Feedback";

        if (! $scope.currentSittingId) return url;

        return url + "?sittingId=" + $scope.currentSittingId;
      }


      $scope.generateUrl = function (location) {
        var url = config.wwwUrl;

        if (config.generateLinksAsHashes) {
          if (location.toLowerCase() == "home") {
            // knowingly left blank
          } else {
            url += "#" + location.toLowerCase();
          }
        } else {
          var culture = translationService.getCurrentCulture();
          if (location) {
            if (location.toLowerCase() == "home") {
              if (culture != "en") {
                url += "/" + culture;
              }
            } else {
              if (culture != "en") {
                url += "/" + culture;
              }
              url += "/" + location.toLowerCase();
            }
          }
        }

        return url;
      };
    },
  ];
  return {
    controller: controller,
    templateUrl: "/assets/html/directives/pretFooter.html",
  };
});
