angular.module(app.appName)
  .filter('replaceUrlPart', [function () {
    return function (inputString, stringToReplace, targetStringPart, trimTrailingSlash) {
      //Handle nullable date
      if (!inputString || !stringToReplace || !targetStringPart) {
        if (trimTrailingSlash && targetStringPart.endsWith('/')) targetStringPart = targetStringPart.slice(0, -1);
        console.error(`[replaceUrlPartFilter] :: Not all properties are valid: inputString: '${inputString}', stringToReplace: '${stringToReplace}', targetStringPart: '${targetStringPart}'`)
        return ''
      }

      return inputString.replace(stringToReplace, targetStringPart)
    }
  }])