/*
This SQL is useful for checking the products and categories on the DB. Run as a view to modify them.

SELECT        TOP (100) PERCENT ProductId, CategoryId,
                             (SELECT        Product_Name
                               FROM            dbo.tblProduct AS p
                               WHERE        (ProductID = pc.ProductId)) AS ProductName,
                             (SELECT        Category_Name
                               FROM            dbo.tblCategories
                               WHERE        (CategoryId = pc.CategoryId)) AS Category
FROM            dbo.trelProductCategories AS pc
WHERE        (ProductId IN
                             (SELECT        dbo.tblProduct_PricingGroup_Product.ProductID
                               FROM            dbo.tblSittings INNER JOIN
                                                         dbo.tblProduct_PricingGroup ON dbo.tblSittings.Product_PricingGroupID = dbo.tblProduct_PricingGroup.Product_PricingGroupID INNER JOIN
                                                         dbo.tblProduct_PricingGroup_Product ON dbo.tblProduct_PricingGroup.Product_PricingGroupID = dbo.tblProduct_PricingGroup_Product.Product_PricingGroupID
                               WHERE        (dbo.tblSittings.SittingPassword = N'PPRG050213P')))
ORDER BY Category

*/

angular.module(app.appName)
    .directive('products', function () {
        var controller = [
            '$rootScope', '$scope', '$route', '$q', '$timeout', 'productService', 'helpers', 'modalTypes', 'itemService', 'itemStore', 'generalStore', '_', 'routeService', 'enums',
            function ($rootScope, $scope, $route, $q, $timeout, productService, helpers, modalTypes, itemService, itemStore, generalStore, _, routeService, enums) {
                var self = this;
                self.products = {};
                // Adds the modalTypes constants to the current scope so that we can use them in the html.
                $scope.modalTypes = modalTypes;
                $scope.currentProductCategory = null;
                $scope.categories = [];
                $scope.disableCategories = true;

                $scope.visible = false;

                $scope.filterProductsByCategory = function (imageId, folderId, forceUpdate) {

                    if (!$scope.visible) return;

                    $scope.loading = true;

                    if (generalStore.get('currentAlbumId').value &&
                    ((routeService.get().name == 'ALBUM' || routeService.get().name == 'PACKS') && !$route.current.params.pictureId) && !folderId) {
                        folderId = generalStore.get('currentAlbumId').value;
                    }

                    var filterProductId = generalStore.get('filterProductId').value;

                    return productService.filterProductsByCategory(imageId, folderId, filterProductId).then(function (categoryData) {
                        // If we have new data, or our products aren't set, we must set them.

                        const oldProductData = $scope.categories.map(category => ({
                            categoryId: category.id,
                            products: category.products.map(product => ({
                                productId: product.id,
                                price: product.price
                            }))
                        }));
                        const newProductData = categoryData.map(category => ({
                            categoryId: category.id,
                            products: category.products.map(product => ({
                                productId: product.id,
                                price: product.price
                            }))
                        }));

                        var productDataChanged = ! angular.equals(oldProductData, newProductData);

                        /* If the data is identical, then there is no change. */
                        if (productDataChanged) {
                            $scope.categories = categoryData;
                        }

                        var currentCategory = generalStore.get('currentProductCategory').value;
                        var categoryToChangeTo;

                        /* Set category based on route. */
                        if (routeService.get().name == 'PREVIEW') {
                            categoryToChangeTo = categoryData.find(x => x.categoryTypeId == enums.categoryType.gallerySingleProducts);
                        } else if (routeService.get().name == 'PACKS' || routeService.get().name == 'FAVS') {
                            categoryToChangeTo = categoryData.find(x => x.categoryTypeId == enums.categoryType.packs);
                        }

                        var categoryChange = (currentCategory || {}).id !== categoryToChangeTo.id;


                        if (categoryChange) {
                            var element = document.getElementsByClassName('products-list')[0];
                            element.scrollTop = 0;
                            generalStore.set('currentProductCategory', categoryToChangeTo);
                        }

                        if (productDataChanged || categoryChange) {
                            $scope.toggleCurrentProductCategory(categoryToChangeTo, true, true, true);
                        }

                        $rootScope.$emit('disableBuy', { mobile: $scope.mobile, disable: false });

                        $scope.loading = false;

                        // Restore product scroll position.
                        $timeout(function () {

                        // Must be performed after render, hence timeout
                        $rootScope.$broadcast('productItemCheck');

                            var previousRoute = routeService.getPreviousRoute();
                            if (routeService.get().name != 'PACKS' &&
                                (previousRoute.includes('Preview') ||
                                    previousRoute.includes('Premium') ||
                                    previousRoute.includes('Packs') ||
                                    previousRoute.includes('Whole'))) {
                                $rootScope.isMobile ?
                                    window.scrollTo(0, generalStore.get('lastProductScrollTop').value) :
                                    $('.products-list').scrollTop(generalStore.get('lastProductScrollTop').value);
                            }
                        }, 0);
                    });
                };

                $rootScope.$on('disableBuy', function (evt, data) {
                    $scope.disableCategories = data.disable;
                });

                $rootScope.$on('productAddedToBasket', function (e, product) {
                    if (product.product) {
                        if (product.product.maxQuantitySitting) {
                            if (product.product.maxQuantitySitting < 999 && product.product.maxQuantitySitting > 1)
                                product.product.maxQuantitySitting--;

                            if (product.product.maxQuantitySitting == 1) {
                                angular.forEach($scope.categories, function (category) {
                                    var itemToRemove = _.find(category.products, { id: product.product.id });
                                    var idx = category.products.indexOf(itemToRemove);
                                    category.products.splice(idx, 1);
                                });
                            }

                        }
                    } else {
                        if (product.maxQuantitySitting) {
                            if (product.maxQuantitySitting < 999 && product.maxQuantitySitting > 1)
                                product.maxQuantitySitting--;

                            if (product.maxQuantitySitting == 1) {
                                angular.forEach($scope.categories, function (category) {
                                    var itemToRemove = _.find(category.products, { id: product.id });
                                    var idx = category.products.indexOf(itemToRemove);
                                    category.products.splice(idx, 1);
                                });
                            }
                        }
                    }

                    if ($scope.categories.length > 0) {
                        $rootScope.newCategories = $scope.categories;
                    }
                });

                $scope.getCategoryImg = function (categoryId) {
                    var imgLocation = '/assets/images/icons/products/';
                    switch (categoryId) {
                        case enums.productCategory.jpeg: // JPEG
                            return imgLocation += 'jpegs.png';
                            break;
                        case enums.productCategory.prints: // Prints
                            return imgLocation += 'prints.png';
                            break;
                        case enums.productCategory.multiImage: // Multi-image Products
                            return imgLocation += 'multi-image.png';
                            break;
                        case enums.productCategory.more: // More
                            return imgLocation += 'more.png';
                            break;
                        default:
                            return;
                            break;
                    }
                }

                // $scope.showModalIfMobile = function (category) {
                //     if ($scope.mobile && $scope.disableCategories == false) {
                //         $rootScope.$emit('disableBuy', { mobile: false, disable: false });
                //         $scope.modalBase.showModal(modalTypes.products, category, true);
                //     }
                // }


                self.setBreakerColour = function () {
                    $scope.breakerColor = $scope.currentProductCategory.color;
                }

                $scope.isCategoryCurrentCategory = function (category) {
                    if (category && generalStore.get('currentProductCategory').value) {
                        return category.id == generalStore.get('currentProductCategory').value.id;
                    }
                }

                $scope.toggleCurrentProductCategory = function (category, updateCategory, forceIgnoreDisable, doNotGetProducts) {

                    if (!$scope.disableCategories || forceIgnoreDisable) {
                        $scope.currentProductCategory = category;

                        $scope.filterProductsByPreviewImage(category);

                        if (updateCategory) {
                            generalStore.set('currentProductCategory', category);
                        }
                        /* I think we need to tell products to update. */

                        // $scope.loading = false;
                        $rootScope.$emit('disableBuy', { mobile: $scope.mobile, disable: false });
                    }
                }

                $scope.filterProductsByPreviewImage = function (category) {
                    if (generalStore.get('previewImageAllowedProducts').value && (routeService.get().name == 'ALBUM' && $route.current.params.pictureId)) {
                        var allowedProductList = generalStore.get('currentPreviewImage').value;

                        var allowedIds = {};
                        _.each(allowedProductList.products, function (allowedProduct) { allowedIds[allowedProduct.id] = true; });

                        var filteredProducts = _.filter(category.products, function (product) {
                            return allowedIds[product.id] && _.indexOf(product.categoriesId, category.id) > -1;
                            //|| product.productTypeId != enums.productType.misp;
                        }, allowedProductList);

                        console.log('filter products by preview image');
                        $scope.currentProductCategory.products = filteredProducts;

                        /* Collapse currently selected product. */
                    }
                }

                // Creates a new variable off the $scope for the product. We pass this to our modal so that
                // we're not manipulating the data on the product object itself. This keeps the products in
                // a "perfect" state at all times.
                $scope.createProductCopy = function (product) {
                    var newProduct = angular.copy(product);
                    return newProduct;
                }

                // $scope.filterProductsByCategory(); // Can we do this this early?

                var productsTriggerHandler = function (scope, args) {
                    args != null
                        ? $scope.filterProductsByCategory(args.imageId, args.albumId, args.forceUpdate)
                        : $scope.filterProductsByCategory();

                }
                var unbindEventHandler = $rootScope.$on('productsTrigger', productsTriggerHandler);
                $scope.$on('$destroy', function () {
                    unbindEventHandler();
                });

                $rootScope.$on('showProducts', function (showProductsHandler, args) {
                    console.log("[products] args.state : ", args.state);
                    $scope.visible = args.state;
                });

                // window.addEventListener('resize', function () {
                //     self.setMobile();
                // });
            }
        ];
        return {
            scope: {
            },
            controller: controller,
            templateUrl: '/assets/html/directives/products.html'
        }
    });