angular.module(app.appName)
	.controller('premiumBuilderController', [
		'$scope', '$route', '$q', 'routeService', 'generalStore', 'productService', 'albumService', 'previewService', '$rootScope', 'itemService', 'itemStore', 'modalTypes', '$timeout', 'stringService', 'pipService', 'itemBuilderService',
		function ($scope, $route, $q, routeService, generalStore, productService, albumService, previewService, $rootScope, itemService, itemStore, modalTypes, $timeout, stringService, pipService, itemBuilderService) {
			var self = this;

			$scope.$emit('showProducts', { state : false });

			$scope.loadingPage = $q.defer();
			$scope.itemStore = itemStore;
			$scope.toggleDescription = false;
			$scope.toggleDescriptionLabel = 'SHOW_MORE';

			$scope.previewRoute = {};

			$scope.setupItem = function (image) {
				// Setup our productItem position and isChangingPicture before we try and modify the image.
				// This is so we know which image we are changing.
				itemStore.publicProductItem.positionId = image.position;
				itemStore.publicProductItem.isChangingPicture = !image.isPlaceholder;
			}

			$scope.cancel = function () {
				//routeService.getPreviousRoute().toUpperCase().indexOf('PREMIUM') < 0 ? routeService.getPreviousRoute() : '/MyAlbums/'))
				routeService.redirect();
			}

			// Sets up the options in the UI
			$scope.setupOptions = function (optionGroup) {
				return _.findWhere(optionGroup.items, { selected: true });
			}

			$scope.updateProductOptions = function (productItem, option, optionItem) {

				$scope.disableOptions = true;
				option.loading = true;
				var currentSelectedOption = _.findWhere(option.items, { selected: true });
				if (currentSelectedOption) {
					currentSelectedOption.selected = false;
				}
				// Checks we have selected a real option and not the null "Please Select..."
				if (optionItem != null) {
					optionItem.selected = true;
					itemService.post(itemStore.publicProductItem).$promise.then(function (savedItem) {
						// We need to update the productItem to reflect our changes
						itemStore.publicProductItem.options = savedItem.options;
						itemStore.publicProductItem.pip = savedItem.pip;
						itemStore.publicProductItem.price = savedItem.price;
						itemStore.publicProductItem.finalPrice = savedItem.finalPrice;
						itemStore.set(savedItem.productId, itemStore.publicProductItem);
						generalStore.set('currentProductItem', itemStore.publicProductItem);
						$scope.checkIncompleteOptions(productItem, savedItem);
						option.loading = false;
						$scope.disableOptions = false;
					});
				}
			}

			self.backupProductOptions = function (productItem) {
				self.productOptionsBackup = angular.copy(productItem.options);
			}

			$scope.resetProductItemOptions = function () {
				itemStore.publicProductItem.options = self.productOptionsBackup;
				$scope.checkIncompleteOptions(itemStore.publicProductItem);
			}

			$scope.checkIncompleteOptions = function (productItem) {
				productItem.hasIncompleteOptions = _.find(productItem.options, { completed: false }) != undefined;
				return productItem.hasIncompleteOptions;
			}

			self.checkIncompletePictures = function () {
				return _.findWhere(itemStore.publicProductItem.images, { imgIndex: null }) != null ? true : false;
			}

			$scope.getPreviewUrl = function () {
				if (!itemStore.publicProductItem)
					return null; // We have no item in the store. Return nothing.

				// We should use the pip if:
				// a. It exists on the productItem
				// AND
				// b. We have at least 1 image set on the productItem.
				var shouldUsePip = itemStore.publicProductItem.pip != null;
				//the below was added but now kian has done the work to return an empty pip creation so no longer needed
				//&& _.findWhere(itemStore.publicProductItem.images, { isPlaceholder: false }) != null ? true : false;
				if (shouldUsePip)
					return itemStore.publicProductItem.pip.url;
				else
					return itemStore.publicProductItem.productThumbnailUrl;

			}

			$scope.startPicker = function (image) {

				$scope.setupItem(image);

				$rootScope.modal.showModal(
					modalTypes.premium,
					{
						product: self.product,
						item: itemStore.publicProductItem,
						currentPreview: null,
						route: $scope.previewRoute,
					},
					false);
			};

			$scope.disableBuy = function (productItem) {
				// We want to disable the Buy if:
				// - We should showSpinner is true (we are showing the spinner so an action is in progress)
				// OR
				// - We have incomplete product options
				return productItem == null || productItem.showSpinner || $scope.checkIncompleteOptions(productItem) || self.checkIncompletePictures();
			}

			self.getImageOnProduct = function (isPreview) {
				// We only have one image slot, just grab it.
				if (itemStore.publicProductItem.images && isPreview) {
					return itemStore.publicProductItem.images[0];
				}

				// Returns a reference to the image with the current position on the actual public array we are working from.
				return _.findWhere(itemStore.publicProductItem.images, { position: itemStore.publicProductItem.positionId });
			}

			self.addImageToProduct = function (picture, isPreview) {

				// - Get image by positionId from productItem.images
				var image = self.getImageOnProduct(isPreview);
				// - set imgIndex = picture.id
				image.imgIndex = picture.id;

				itemBuilderService.setDimensions(
					itemBuilderService.currentPreviewRatio(picture),
					itemStore.publicProductItem);

				// - set previewUrl = picture.preview.url
				image.previewUrl = picture.preview != null ? picture.preview.url : picture.image.url;
				// - Set placeholder false so the productItem knows the image is a real image
				image.isPlaceholder = false;

				picture.isSelected = true;
				pipService.setDimensions(itemStore.publicProductItem.images, itemStore.publicProductItem);
				itemService.post(itemStore.publicProductItem).$promise.then(function (savedItem) {
					// We can't just overwrite the entire object as there are some properties that we don't want to overwrite,
					// such as Options, description etc.
					itemStore.publicProductItem.images = savedItem.images;
					itemStore.publicProductItem.images[0].previewUrl = picture.preview != null ? picture.preview.url : picture.image.url;
					itemStore.publicProductItem.price = savedItem.price;
					itemStore.publicProductItem.pip = savedItem.pip;
					itemStore.publicProductItem.showSpinner = false;
				});

				// Tells the server to generate a PiP for the product preview
				itemStore.publicProductItem.preview = true;
			}

			$scope.addToBasket = function () {
				// Show our spinner on our buy button
				itemStore.publicProductItem.showSpinner = true;
				// Save/Persist this item so that it has an id for adding to basket
				itemService.save([itemStore.publicProductItem], 1, true).then(function (itemToSave) {

					itemStore.publicProductItem = itemToSave.item[0].item;

						// We are done. Remove spinner.
						itemStore.publicProductItem.showSpinner = false;
						var previousRoute = routeService.getPreviousRoute();
						if (previousRoute.includes('Album')) {
							previousRoute = previousRoute.substring(previousRoute.indexOf('Album') - 1);
						}
						else if (previousRoute.includes('Packs')) {
							previousRoute = previousRoute.substring(previousRoute.indexOf('Packs') - 1);
						}
						else {
							previousRoute = "/MyAlbums";
						}

						routeService.redirect(previousRoute);
				});
			}

			// Makes sure the Product Item has the proper Product data
			self.setupProductDataOnProductItem = function () {
				// self.product is null
				itemStore.publicProductItem.description = self.product.description;
				var descriptions = stringService.getDescriptions(self.product.description);
				itemStore.publicProductItem.shortDescription = descriptions.short;
				itemStore.publicProductItem.longDescription = descriptions.long;
			}

			$scope.toggleLongDescription = function () {
				$scope.toggleDescription = !$scope.toggleDescription;
				$scope.toggleDescriptionLabel = !$scope.toggleDescription ? 'SHOW_MORE' : 'SHOW_LESS';
			}

			self.setupProductData = function (productId) {
				return $q(function (resolve) {
					// Setup a new promise
					// Attempt to grab a product from the store
					self.product = generalStore.get('currentProduct').value;
					// Make sure we have a product, and it matches the id in our $route
					if (self.product && self.product.id == productId) {

						resolve(self.product);
						// All is good with the product. Setup the sitting data
						// Resolve and return the promise
					} else {
						// Our product in the store doesn't match our id. We must get it from the server
						return productService.getProductById(productId).$promise.then(function (product) {

							// Should itemStore be set at this point?

							console.log('[premiumBuilderController.setupProductData]', product);
							self.product = product;
							generalStore.set('currentProduct', self.product);
							// Resolve and return the promise

							resolve(self.product);
						});
					}
				});
			}

			$scope.init = function () {
				/* Set up item from productId on route. */
				var productId = $route.current.params.productId;
				var imageFolderId = $route.current.params.imageFolderId;
				var imageId = $route.current.params.imageId;

				var productPromise = self.setupProductData(productId);

				var preview = generalStore.get('currentPreviewImage').value;

				// We need folder contexts for all.
				var itemPromise = itemService.get(productId, null, imageId, imageId, imageFolderId);

				$q.all([productPromise, itemPromise]).then(function(resolves) {
					$scope.product = resolves[0];
					var newItem = resolves[1];

					console.info('[premiumBuilderController.init] newItem', newItem);
					itemStore.publicProductItem = newItem;
					self.setupProductDataOnProductItem();
					self.backupProductOptions(newItem);

					/* Find routing details*/
					/* Setup images */
					self.previousRoute = routeService.getPreviousRoute();

					// Resolve the loading promise to show the page
					$scope.loadingPage.resolve();
				});

			};
			$scope.init();

			$rootScope.$on('rendering', function () {
				$scope.loading = true;
				$timeout(function () {
					$scope.loading = false;
				}, 0);
			});

			$rootScope.$on('cultureChange', function (data) {
				productService.getProducts(null, $route.current.params.albumId).$promise.then(function (data) {
					if (itemStore.publicProductItem) {
						var itemId = itemStore.publicProductItem.productId;
						var translatedItem = _.findWhere(data.products, { id: itemId });
						itemStore.publicProductItem.productName = translatedItem.name;
						itemStore.publicProductItem.description = translatedItem.description;
					}
				});
			});
		}
	]);