angular.module(app.appName)
    .directive('termsAndConditionsModule', function () {
        return {
            require: ['^^checkoutContainer', '^^checkoutModule'],
            scope: {
                settable: '=', /* Is the user allowed to change the address value? */
                onContinue: '&'
            },
            controller: ['$scope', function ($scope) {
                $scope.accepted = false;
            }],
            link: function (scope, element, attrs, controllers) {

                var checkoutCtrl = controllers[0],
                    checkoutModule = controllers[1];

                scope.continue = function () {
                    console.info(`[termsAndConditionsModule] continue`);

                    /* Need to confirm all fields are valid before we move on. */
                    checkoutModule.complete();
                };
            },
            templateUrl: '/assets/html/directives/checkout/termsAndConditionsModule.html'
        }
    });