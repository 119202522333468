angular.module(app.appName)
    .directive('login', function () {
        var controller = [
            '$scope', '$translate', 'authService', 'generalStore', 'storageService', '$window', '$rootScope', 'notifyService', '$timeout', '$q', 'routeService', '$location', '$filter', 'sessionResource', 'sessionService',
        function ($scope, $translate, authService, generalStore, storageService, $window, $rootScope, notifyService, $timeout, $q, routeService, $location, $filter, sessionResource, sessionService) {
            var self = this;

            $scope.showPassword = false;

            $scope.showReset = false;
            $scope.username = null;
            $scope.password = null;
            $scope.isLoggedIn = storageService.get("authenticated");
            $scope.showRegisterMessage = false;
            $scope.showAddSittingMessage = false;
            $scope.homeUrl = window.location.host == 'https://shop.pret-a-portrait.cn/'
                ? 'https://www.pret-a-portrait.cn/' : 'https://www.pret-a-portrait.net/'

            sessionResource.sessionValid().$promise.then(function (data) {
                $scope.hasSittings = data.hasSittings;
                if (!$scope.isLoggedIn && $scope.hasSittings) {
                    $scope.showRegisterMessage = true;
                } else if (!$scope.hasSittings) {
                    $scope.showAddSittingMessage = true;
                }
            });

            $scope.login = function () {
                $scope.userNotFound = false;
                $scope.invalidUsername = !$scope.username;
                $scope.invalidPassword = !$scope.password;
                if (!$scope.invalidUsername && !$scope.invalidPassword) {
					var username = $scope.username.toLowerCase();
                    $scope.loading = true;
                    authService.login({ username: username, password: $scope.password }).then(function (result) {
                        $translate(result.message).then(function(translatedLoginMessage) {
                            $scope.loginMessage = translatedLoginMessage;
                        });
                        if (result.success == true) {
                            sessionService.updateSessionToken().then(function() {
                                storageService.set('authenticated', true);
                                notifyService.success('LOGIN_SUCCESS');
                                $rootScope.$broadcast('authenticationSet');
                                var nextRoute = generalStore.get('nextRoute').value;
                                if (nextRoute) {
                                    routeService.redirect(nextRoute);
                                }
                                else {
                                    routeService.redirect();
                                }
                            });
                        }
                        else {
                            $scope.userNotFound = true;
                            $scope.loading = false;
                        }
                    });
                }
            }

            $scope.showResetForm = function () {
                $scope.showReset = !$scope.showReset;
            }

            $scope.resetPassword = function () {
                $scope.userNotFound = false;
                $scope.invalidEmail = !$scope.form;
                if (!$scope.invalidEmail) {
                    var emailFormat = /^[^@]+@[^@]+\.[^@]+$/;
                    $scope.invalidEmail = !emailFormat.test($scope.form.email);
                }
                if (!$scope.invalidEmail) {
                    $scope.loading = true;
                    authService.resetPassword($scope.form).then(function (data) {
                        if (data.success == "true") {
                            $scope.userNotFound = false;
                            notifyService.success('RESET_EMAIL_SUCCESS');
                            $scope.loading = false;
                            $scope.showResetForm();
                        }
                        else {
                            $scope.userNotFound = true;
                            $scope.loading = false;
                        }
                    })
                }
            }

            /* Change value of showPassword, trigger change in DOM */
            $scope.changeShowPassword = function(val) {
                $scope.showPassword = val;
            };

        }
        ];
        return {
            controller: controller,
            templateUrl: '/assets/html/directives/login/login.html'
        }
    });