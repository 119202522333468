angular.module(app.appName)
    .controller('addressesController', ['$scope', 'accountService', 'validationService', '$rootScope', 'storageService', '$window', '$translate', 'notifyService', '$timeout', '$q', 'routeService',
        function ($scope, accountService, validationService, $rootScope, storageService, $window, $translate, notifyService, $timeout, $q, routeService) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.currentAddress = {};
            $scope.loadingPage = $q.defer();
            $scope.addEdit = false;
            $scope.alert = null;
            if (!storageService.get("authenticated")) {
                $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
            }

            routeService.setAccountLink("Account"); // We are in top Addresses view, so back button should take us back to My Account

            // Pass false as this is a generic address being added to the Account, not a delivery address being added at Checkout
            accountService.getCountries(false).$promise.then(function (data) {

                $scope.countries = data.list;

                accountService.getAddresses().$promise.then(function (addresses) {

                    addresses = addresses.list;

                    if (addresses.length) {
                        $scope.addresses = addresses;
                        for (i = 0; i < addresses.length; i++) {
                            $scope.addresses[i].country = _.findWhere($scope.countries, { id: addresses[i].countryId });
                        }
                    }
                    $scope.loadingPage.resolve();
                });
            });


            $scope.add = function (address) {
                $scope.addEdit = true;
                routeService.setAccountLink("Addresses/#"); // Don't go all the way back to My Account, just reload the top Addresses view
                if (address) {
                    $scope.addressModel = angular.copy(address);

                    $scope.addressModel.houseStreet = $scope.addressModel.houseStreetFull; // Copy over where housenumber is populated.
                    $scope.addressModel.number = '';

                    var selectedCountryOption = _.findWhere($scope.countries, { id: address.countryId });
                    document.getElementById('addressCountrySelect').options[selectedCountryOption.position].selected = 'selected';
                }
                else {
                    $scope.addressModel = {};
                    $scope.cachedAddress = { fullName: null, country: null, postcode: null, number: null, street: null, area: null, city: null, county: null };;
                }
            }

            $scope.cancel = function () {
                // User didn't save changes - restore what the model looked like when the user first clicked Add or Edit
                angular.copy($scope.cachedAddress, $scope.addressModel);

                $scope.addEdit = false;
                $scope.alert = null;
            }

            $scope.postcodeLookup = function (countryId, fullName) {
                // $scope.cachedAddress = angular.copy($scope.addressModel);
                $scope.loading = true;
                var postcode = $scope.addressModel.postcode;
                if (postcode) {
                    var addressId = $scope.addressModel.id;
                    accountService.lookupAddressByPostcode($scope.addressModel.postcode).$promise.then(function (data) {
                        $scope.addressModel = data;
                        $scope.addressModel.postcode = postcode.toUpperCase();
                        $scope.addressModel.number = null;
                        $scope.addressModel.id = addressId;
                        $scope.addressModel.fullName = $scope.cachedAddress.fullName ? $scope.cachedAddress.fullName : fullName;
                        $scope.addressModel.countryId = $scope.cachedAddress.countryId ? $scope.cachedAddress.countryId : countryId;
                        $scope.alert = data.found == true ? null : 'POSTCODE_NOT_FOUND';
                        $scope.loading = false;
                    });
                }
                else {
                    $scope.loading = false;
                    $scope.alert = 'POSTCODE_NOT_FOUND';
                }
            }

            $scope.updateCountryId = function () {
                $scope.addressModel.countryId = ($scope.addressModel.country || {}).id;
            }

            $scope.delete = function (id) {
                $scope.loading = true;
                accountService.deleteAddress(id).$promise.then(function (data) {
                    if (data.success == true) {
                        $scope.alert = null;
                        notifyService.success("ADDRESS_DELETED_SUCCESS");

                    }
                    else {
                        $scope.alert = 'GENERIC_ALERT';
                    }

                    $timeout(function () {
                        $scope.loading = false;
                        $window.location.reload();
                    }, 5000);
                });
            }

            $scope.save = function () {
                $scope.loading = true;
                var addrData = [];
                var fields = [{ name: "Name", valid: true }, { name: "Country", valid: true }, { name: "Zip", valid: true }, { name: "Street", valid: true }, { name: "City", valid: true }]

                /* Validation only works in english??? */
                var addrInput = [$scope.addressModel.fullName, $scope.addressModel.countryId, $scope.addressModel.postcode, $scope.addressModel.houseStreet, $scope.addressModel.city];
                var addressDesc = ["Name", "Country", "Zip", "Street", "City"];
                for (var i = 0; i < addressDesc.length; i++) {
                    var entry = { description: addressDesc[i], value: addrInput[i] }
                    addrData.push(entry);
                }
                var errors = validationService.checkAddressInput(addrData)

                if (!errors.length) {
                    if ($scope.addressModel.id) {
                        accountService.editAddress($scope.addressModel.id, $scope.addressModel).$promise.then(function (data) {
                            $scope.confirmAddressSaved();
                        });
                    }
                    else {
                        accountService.addAddress($scope.addressModel).$promise.then(function (data) {
                            $scope.confirmAddressSaved();
                        });
                    }
                }
                else {
                    for (i = 0; i < addressDesc.length; i++) {
                        if (_.contains(errors, addressDesc[i])) {
                            fields[i].valid = false;
                        }
                    }
                    $scope.fields = fields;
                    $scope.loading = false;
                }
            }

            $scope.confirmAddressSaved = function () {
                $scope.alert = null;
                notifyService.success("ADDRESS_SAVED_SUCCESS");

                $timeout(function () {
                    $scope.addEdit = false;
                    $scope.loadingPage.resolve;
                    $window.location.reload();
                }, 5000);

            }
        }
    ]);