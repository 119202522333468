angular.module(app.appName)
    .directive('password', function () {
        var controller = [
            '$scope', 'routeService',
        function ($scope, routeService) {
            var self = this;

            $scope.domain = {
                sittingPassword: $scope.sittingPassword
            };

            $scope.login = function () {
                var url = "/MyAlbums/" + $scope.domain.sittingPassword;
                routeService.setPreviousRoute("/sittingPassword?sittingId=" + $scope.sittingPassword);
                routeService.redirect(url);
           };
        }
        ];
        return {
            controller: controller,
            scope: {
                sittingPassword: "=",
                invalidPassword: "="
            },
            templateUrl: '/assets/html/directives/password/password.html'
        }
    });