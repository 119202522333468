angular.module(app.appName)
  .directive('additionalModule', function () {
    return {
      require: ['^^checkoutContainer', '^^checkoutModule'],
      scope: {
        settable: '=', /* Is the user allowed to change the address value? */
        onContinue: '&'
      },
      controller: ['$scope', function ($scope) {
      }],
      link: function (scope, element, attrs, controllers) {

        var checkoutCtrl = controllers[0],
            checkoutModule = controllers[1];

        scope.model = function() {
          var model = checkoutCtrl.getModel();
          return model;
        }

        scope.templateConditions = {
          requiresAdditional: scope.model().requiresAdditional,
          requiresTelephone: scope.model().confirmTelephone
        }

        scope.formInvalid = function () {
          var telephoneValid = !scope.templateConditions.requiresTelephone || (scope.templateConditions.requiresTelephone && scope.model().summary.telephone),
              additionalValid = !scope.templateConditions.requiresAdditional || (scope.templateConditions.requiresAdditional && scope.model().summary.additional.className && scope.model().summary.additional.childName);
          console.info(`[telephoneValid] : ${telephoneValid}`)
          console.info(`[additionalValid] : ${additionalValid}`)
          return !telephoneValid || !additionalValid;
        }

        scope.continue = function() {
          console.info(`[additionalModule] continue, setting`);

          /* Need to confirm all fields are valid before we move on. */
          checkoutModule.complete();
        };

     },
      // controller: controller,
      templateUrl: '/assets/html/directives/checkout/additionalModule.html'
    }
  });