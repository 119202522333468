angular.module(app.appName)
	.factory('sessionResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl() + '/session/';
		    return $resource(api, {}, {
		    	sessionValid: {
		    		method: 'POST',
		    		url: api + 'SessionValid'
		    	},

					token: {
						method: 'GET',
						url: api + 'Token',
						responseType: 'text'
				}
		    });
		}
	]);