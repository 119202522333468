angular.module(app.appName).directive("header", function () {
  var controller = [
    "$scope",
    "$rootScope",
    "storageService",
    "generalStore",
    "$window",
    "authService",
    "notifyService",
    "statStore",
    "sessionResource",
    "translationService",
    "config",
    function (
      $scope,
      $rootScope,
      storageService,
      generalStore,
      $window,
      authService,
      notifyService,
      statStore,
      sessionResource,
      translationService,
      config
    ) {

      $scope.statStore = statStore;
      $scope.basketCount = statStore.basketCount;
      $scope.favouriteCount = statStore.favouriteCount;
      $scope.generalStore = generalStore;
      $scope.title = "Pret-a-Portrait";
      $scope.isLoggedIn = storageService.get("authenticated");

      $scope.isValidatedEmail = storageService.get("validatedEmail");
      $scope.mobileMenu = {
        open: false,
      };

      $scope.config = config;

      $scope.showSessionNavigation = false;
      /* Get validity of session */
      sessionResource.sessionValid().$promise.then(function (data) {
        /* If session if not valid, set flag to hide navigation elements */
        $scope.hasSittings = data.isValid;
      });

      $scope.generateUrl = function (location) {
        var url = config.wwwUrl;

        if (config.generateLinksAsHashes) {
          if (location.toLowerCase() == "home") {
            // knowingly left blank
          } else {
            url += "#" + location.toLowerCase();
          }
        } else {
          var culture = translationService.getCurrentCulture();
          if (location) {
            if (location.toLowerCase() == "home") {
              if (culture != "en") {
                url += "/" + culture;
              }
            } else {
              if (culture != "en") {
                url += "/" + culture;
              }
              url += "/" + location.toLowerCase();
            }
          }
        }

        return url;
      };

      $scope.toggleMenu = function () {
        $scope.mobileMenu.open = !$scope.mobileMenu.open;
      };

      $scope.logout = function () {
        $scope.isLoading = true;

        authService.logout().$promise.then(function() {
          notifyService.success("LOGOUT_SUCCESS");

          storageService.set("authenticated", false);
          $rootScope.$broadcast("authenticationSet");
          $window.location.href = config.wwwUrl;
        });
      };

      $scope.updateCurrentSitting = function () {
        $scope.currentSittingId = (
          generalStore.get("currentSittingId") || {}
        ).value;
        $scope.currentAlbumId = (
          generalStore.get("currentAlbumId") || {}
        ).value;
      };

      $rootScope.$on("authenticationSet", function () {
        $scope.isLoggedIn = storageService.get("authenticated");
      });

      $rootScope.$on("validatedEmailSet", function () {
        $scope.isValidatedEmail = storageService.get("validatedEmail");
      });

      $rootScope.$on("sittingAdded", function () {
        $scope.hasSittings = true; // Safe to assume that session is valid.
      });

      $rootScope.$on("productsTrigger", function () {
        $scope.updateCurrentSitting();
      });

      $scope.updateCurrentSitting();
    },
  ];
  return {
    controller: controller,
    templateUrl: "/assets/html/directives/header2.html",
  };
});
