angular.module(app.appName)
	.directive('addSitting',
		function () {
			var controller = [
				'$rootScope', '$scope', 'myPicturesService', 'notifyService', 'routeService', '$location', '$window', 'storageService', 'statStore', 'homeService', 'enums', 'navService',
				function ($rootScope, $scope, myPicturesService, notifyService, routeService, $location, $window, storageService, statStore, homeService, enums, navService) {
					$scope.sittingId = '';
					$scope.showInput = false;
					$scope.changeSittingId = function (sittingId) {
						$scope.addingSitting = true;
						myPicturesService.addSitting(sittingId, true).then(function (data) {
							if (data.response == "Found") {
								if (!storageService.get('authenticated') && statStore.email) {
									// Add sitting to ValidatedSittingEmail
									var dto = {
										sittingPassword: sittingId,
										emailAddress: statStore.email,
										stage: enums.homeLoginRequestStage.addSitting,
									};
									// debugger;
									homeService.homeLogin(dto).then(function (result) {
										$scope.dto = result;
									});
								};
								/* Sitting is ready - take user to sittings. */
								myPicturesService.handleSitting(data.data).promise.then(function (sittingData) {
									notifyService.success('SITTING_ID_ADDED');
									myPicturesService.setupShowMoreOnEvents(sittingData);
									$scope.callback({ $data: sittingData });
									$scope.addingSitting = false;
									$rootScope.$broadcast("productsTrigger");
									$rootScope.$broadcast("sittingAdded"); // Used in header

									// The sitting has already been handled, so let's redirect to the correct folder.
									navService.album(data.sittingId, data.folder);
								});
							}
							else if (data.response == "NotReady") {
								/* Sitting is not ready - take user to advanced sign up. */
								routeService.setPreviousRoute('/MyAlbums/?sittingId=' + sittingId);
								routeService.redirect("/NotifyMe/" + sittingId);
							}
							else {
								$scope.addingSitting = false;
								notifyService.error('INVALID_SITTING_ID');
							}
						});
					}

					$scope.toggleInput = function () {
						$scope.showInput = !$scope.showInput;
						$(".sitting-input").width($scope.showInput ? 254 : 0);
					};
				}
			];

			return {
				scope: {
					sittingData: '=',
					callback: '&',
				},
				controller: controller,
				templateUrl: '/assets/html/directives/addSitting.html'
			}
		}
	);