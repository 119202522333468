angular.module(app.appName)
    .directive('mobileSummary', function () {
        var controller = [
            '$scope',
            function ($scope) {
            	var self = this;
                $scope.showItems = false;

                console.info(`[summary] model is [${JSON.stringify($scope.summaryData)}]`)

                $scope.toggleItems = function() {
                    $scope.showItems = !$scope.showItems
                }

            }
        ];
        return {
            controller: controller,
            scope: {
                summaryData: '='
            },
            templateUrl: '/assets/html/directives/checkout/mobileSummary.html'
        }
    });