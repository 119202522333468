angular.module(app.appName)


    .controller('checkoutController', [
        '$rootScope', '$scope', '$q', 'checkoutData', 'accountData',
        function ($rootScope, $scope, $q, checkoutData, accountData) {

            $scope.$emit('showProducts', { state : false });

            var refresh = function (data) {

                $scope.model = data[0];      // checkoutData
                $scope.user = data[1];
                $scope.model.user = $scope.user; // accountData

                $scope.loadingPage.resolve();
            };

            $scope.getDefaultTabIndex = function () {

                var model = $scope.model;

                if ($scope.model.deliverToSchool == 0) return 1; // No DTS

                if (model.eventAddress.id == model.summary.shippingAddressId &&
                    model.summary.shippingAddressId) {
                    return 0; // If event address, display.
                } else {
                    if (model.summary.shippingAddressId) {
                        return 1; // If not event address, display.
                    }
                }
                return 0; // Show default school tab.
            }

            $scope.containsModule = function (moduleName) {
                var modules = $scope.model;
                if (!modules) return false;
                return modules.checkoutModules.indexOf(moduleName) > -1;
            };

            $scope.bind = {
                sameAddress: true
            };

            $scope.changeSame = function (sameAs) {
                console.info(`[checkoutController] [changeSame] SameAs`);
            };

            /* Once you update the object, none of the references will
               continue to hold. */
            /* FUCK. */

            $scope.updateModel = function (model) {
                console.info('[checkoutController] [updateModel] UPDATEMODEL');
                $scope.model = model;
                $scope.model.user = $scope.user; // accountData
            };

            /* Wait on all data to resolve before loading page. */
            $scope.loadingPage = $q.defer();
            $q.all([checkoutData.$promise, accountData.$promise]).then(refresh);
        }]);
