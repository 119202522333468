/*
    Sitting Ids:
    WPYA1035MVB
    CALCPE27121203D
    PPSG210213P
    PPRG050213P
*/

angular.module(app.appName)
	.controller('packController', [
		'$rootScope', 'generalStore', '$route',
        function ($rootScope, generalStore, $route) {

            var params = $route.current.params;

            console.info("Route params", params);

            // debugger;

            // If coming in from path, set current sitting and album.
            generalStore.set('currentSittingId', $route.current.params.sittingId);
            generalStore.set('currentAlbumId', $route.current.params.albumId);
            generalStore.set('filterProductId', $route.current.params.productId);

            // If we're in favourites, we possibly need to reset currentAlbumId.

            $rootScope.$emit('showProducts', { state : true });

            $rootScope.productsConfig.class = 'packs';
            $rootScope.productsConfig.colWidth = '12';
            $rootScope.productsConfig.right = false;

            $rootScope.$broadcast('changeAlbum', { albumId: $route.current.params.albumId });
            $rootScope.$broadcast('productsTrigger', { albumId: $route.current.params.albumId });

            // Reset the modalAlbum, so we don't persist
            // modal albums between products.
            generalStore.set('modalAlbumId', null);
        }]);