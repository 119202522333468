angular.module(app.appName)
	.directive('ngClickHref', [
		function () {
			var controller = [
				'$scope', '$element', '$attrs', '$parse',
				function ($scope, $element, $attrs, $parse) {
				}
			]

			return {
				restrict: 'A',
				scope: {},
				controller: controller
			}
		}
	]);