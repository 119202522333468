angular.module(app.appName)
    .directive('sameAsCheckbox', function () {
        var controller = [
            '$scope',
            function ($scope) {
              $scope.changed = function() {
                $scope.onChange();
              };
            }
        ];
        return {
            controller: controller,
            scope: {
                sameAs : '=',
                onChange: '&'
            },
            templateUrl: '/assets/html/directives/checkout/sameAsCheckbox.html'
        }
    });