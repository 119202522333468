angular.module(app.appName)
    .factory('itemService', [
        'itemResource', 'itemStore', 'helpers', '$q', 'statStore', 'notifyService', 'cacheService',
        function (itemResource, itemStore, helpers, $q, statStore, notifyService, cacheService) {
            return {
                /*
                    Gets a product item. To create a new item, pass null to itemId.
                */
                get: function (productId, itemId, galleryImageId, imgIndex, folderId, ratio) {

                    // Attempt to retrieve an item from our itemStore
                    // Not an error.  Leave uncommented.
                    var item;
                    // var item = itemStore.get(productId, itemId, imageId);

                    // If we don't have an item, create one on the server, otherwise we just return our retrieved item in a promise
                    if (helpers.isNullOrUndefined(item) || item.success == false) {
                        return itemResource.get({ productId: productId, itemId: itemId, galleryImageId: galleryImageId, imgIndex: imgIndex, folderId: folderId, ratio: ratio }).$promise.then(function (newItem) {
                            item = newItem;
                            itemStore.set(productId, newItem);
                            return item;
                        });
                    } else {
                        // We need to return a promise here so that we can reliably use .then() when calling the service.
                        var deferred = $q.defer();
                        deferred.resolve(item);
                        return deferred.promise;
                    }
                },
                /*
                    Creates a new product item.
                */
                set: function (productId, folderId) {
                    return itemResource.get({ productId: productId, itemId: null, folderId: folderId })
                },

                post: function (item) {
                    return itemResource.post(item);
                },

                // Quantity is a redudant property at this point.
                save: function (items, quantity, notify) {

                    // Sum all quantities for items added.
                    quantity = items.reduce(function (a, b) { return a + b.quantity; }, 0);

                    return $q(function(resolve) {
                        return itemResource.put(items).$promise.then(function(data) {
                            cacheService.obliterate();
                            statStore.basketCount += quantity;
                            if (notify) notifyService.success('ADD_TO_BASKET_SUCCESS');
                            resolve(data);
                        });
                    });
               },

               editNote: function (itemId, note) {
                    return itemResource.editNote({ itemId: itemId, note: note })
                },
                /*
                    Adds an image to a product item.
                */
                addImage: function (itemId, imageId, position, previewWidth, previewHeight, original) {

                }
            };
        }
    ]);