angular.module(app.appName)
	.controller('passwordController', [
		'$scope', '$rootScope', '$route', 'routeService',
        function ($scope, $rootScope, $route, routeService) {
            var self = this;

            $rootScope.$emit('showProducts', { state : false });

            /* Pass params to directive */
            $scope.sittingPassword = $route.current.params.sittingId;
            $scope.invalidPassword = $route.current.params.notFound;
}]);