angular.module(app.appName)
	.directive('preferenceModal',
		function () {
			var controller = ['$scope', '$translate', 'enums', 'userService',
				function ($scope, $translate, enums, userService) {

					$scope.loading = true;

					$scope.showCallout = {};

					$scope.getPreferenceStrings = function(id) {
						switch (id) {
							case enums.marketingPreference.emailNotify:
								return {
									description: 'NOTIFYME_PICTURES',
									callout: 'NOTIFYME_PICTURES_CALLOUT'
								};
							
							case enums.marketingPreference.optOutMarketing:
								return {
									description: 'PRIVACY_OPTOUT',
									callout: 'PRIVACY_OPTOUT_CALLOUT'
								};
						}
					}

					$scope.preferenceDto = $scope.preferenceData
						.filter(function(preference) { return preference.value == null; })
						.map(function(populatedPreference) {
							var strings = $scope.getPreferenceStrings(populatedPreference.marketingPreferenceId);
							return { 
								marketingPreferenceId: populatedPreference.marketingPreferenceId,
								string: strings.description,
								calloutString: strings.callout,
								showCallout: false,
								value: populatedPreference.marketingPreferenceId == enums.marketingPreference.emailNotify ? true : false
							};
						})

					$scope.toggleCallout = function(preference) {
						preference.showCallout = !preference.showCallout;
					}

					$scope.loading = false;

					$scope.continue = function() {
						$scope.loading = true;
						userService.setPreferences({ prefs: $scope.preferenceDto }).$promise.then(function (result) {
							$scope.loading = false;
							window.location.reload();
						});
					}
				}
			];

			return {
				scope: {
					preferenceData: '='
				},
				controller: controller,
				templateUrl: '/assets/html/directives/preferenceModal.html'
			}
		}
	);