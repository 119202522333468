/*
    This directive acts as the base for the modals throughout the site.
    It contains a $scope.modalBase object which contains all of the reusable
    base functions and other data to be accessed by child modals.

    Overridable functions on the modalBase:
    - showContinueSpinner
    - disableContinueAction
    - showContinueAction
    - continueAction - REQUIRED
    - backAction
*/
angular.module(app.appName)
    .directive('modal', function () {
        var controller = [
            '$scope', '$rootScope', '$q', 'navService', 'sittingStore', 'modalTypes', 'itemService', 'generalStore',
            function ($scope, $rootScope, $q, navService, sittingStore, modalTypes, itemService, generalStore) {
                $scope.modalTypes = modalTypes;
                $scope.basketDisable = false;
                // This allows us to isolate the base functions and data into a common object which
                // we can call from child directives, eg: $scope.modalBase.closeModal().

                var showModal = function (type, data, isMobile, title) {
                    // We want to tell the modal that we are in mobile mode if we are using a mobile modal.
                    if (isMobile == true)
                        $scope.modalBase.isMobile = true;
                    // If we pass a type to the method, we need to set it on the base so we can access it elsewhere
                    if (type && type != $scope.modalBase.type)
                        $scope.modalBase.type = type;
                    console.log(`[modalCore.showModal] ${type}`);
                    // If we pass a custom title, we need to set it, otherwise we're leaving it alone and allowing the child modals to set the title
                    if (title && title != $scope.modalBase.headerTitle)
                        $scope.modalBase.headerTitle = title;

                    // If we are showing the products modal or we are showing the picture picker on desktop, we need to make sure
                    // we are resetting the backAction, otherwise we can never close the modal. Add logic to the 'if' when/if you
                    // need to close the modal at another point.
                    if (type == modalTypes.products ||
                        (type == modalTypes.picturePicker && isMobile == false)) {
                        $scope.modalBase.backAction = function () { $scope.modalBase.closeModal() };
                    }

                    // We need to be able to move data between modals, so we store it on the modalBase.data
                    if (data && data != this.data) {
                        // Creates a copy of the data so that we don't get into an inconsistent state outside of the modal
                        $scope.modalBase.data = angular.copy(data);
                    }

                    $rootScope.showingModal = true;
                };


                // We should put ANY functions or variables on the base modal on this object.
                // This way we ensure that we won't accidentally overwrite something in a child directive.
                $scope.modalBase = {
                    type: null,
                    data: null,
                    isMobile: false,
                    headerTitle: '',
                    backMessageKey: null,
                    // If you want a back icon, make it a font-awesome class. eg: 'fa-close' or 'fa-remove'
                    backIcon: null,
                    showContinueSpinner: function () { return false; },
                    disableBackAction: function () { return false; },
                    disableContinueAction: function () { return true; },
                    showContinueAction: function () { return this.type != modalTypes.products && this.type != modalTypes.confirmCrop && this.type != modalTypes.packReminder; },
                    // Override this function to provide functionality to continue, such as "Add to Basket"
                    continueAction: function () { console.error('You must overwrite the $scope.modalBase.continueAction function in order for it to perform any action.'); },
                    // Override this function to provide extra functionality on the "Back" button.
                    // NOTE: If you DO override this function and you need it to eventually call 'this.closeModal()'
                    //       you will need to add it to your child via: $scope.modalBase.closeModal()
                    backAction: function () { this.closeModal(); },
                    closeModal: function () {
                        // Reset the data back to null so that we have a good starting point when we show another modal
                        this.data = null;
                        $rootScope.showingModal = false;
                        sittingStore.nukeProductAlbums();
                    },
                    getModalAlbumId: function (route) {
                        var modalAlbum = (generalStore.get('modalAlbumId') || {}).value;
                        var currentAlbumId = (generalStore.get('currentAlbumId') || {}).value;
                        var routeAlbumId = (route || {}).albumId;
                        return modalAlbum || currentAlbumId || routeAlbumId;
                    },
                    setModalAlbumId: function (value) {
                        generalStore.set('modalAlbumId', value);
                    },
                    showModal: showModal
                }

                $rootScope.modal = $scope.modalBase;
            }
        ];
        return {
            restrict: 'E',
            controller: controller,
            templateUrl: '/assets/html/directives/modal/modalCore.html'
        }
    });

