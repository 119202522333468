angular.module(app.appName)
	.factory('sittingNotificationResource', [
		'$resource', 'apiUrl', function ($resource, apiUrl) {
		    var api = apiUrl() + '/SittingNotification/';
		    return $resource(api, {}, {
		        post: {
		            method: 'POST',
		            url: api + 'Post',
		        },
		    });
		}
	]);