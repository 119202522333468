angular.module(app.appName)
    .controller('ordersController', [
            '$scope', '$rootScope', '$window', 'storageService', '$q', 'accountService', 'helpers', 'routeService', 'notifyService', 'cacheService', '$timeout', 'helpers',
        function ($scope, $rootScope, $window, storageService, $q, accountService, helpers, routeService, notifyService, cacheService, $timeout, helpers) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            if (!storageService.get("authenticated")) {
                $window.location.href = $window.location.href.substring(0, $window.location.href.indexOf("Account")) + "/Login";
            }
            $scope.orderDetails = false;
            routeService.setAccountLink("Account"); // We are in top Orders view, so back button should take us back to My Account

            $scope.loadingPage = $q.defer();
            accountService.getOrders().$promise.then(function (data) {
                $scope.orders = data;
                angular.forEach($scope.orders, function (order) {
                    order.orderDate = helpers.prettifyDate(helpers.trimDate(order.orderDate));
                    order.description = "";
                    for (i = 0; i < order.items.length; i++) {
                        if (!helpers.isNullOrUndefined(order.items[i])) {
                            order.description += order.items[i];
                        }
                        if (i < order.items.length - 1) {
                            order.description += ", ";
                        }
                    }
                });
                $scope.loadingPage.resolve();
            });

/*            $scope.view = function (orderId) {
                $scope.orderData = $q.defer();
                routeService.setAccountLink("Orders/#"); // Don't go all the way back to My Account, just reload the top Orders view
                $scope.orderDetails = true;
                $scope.orderData.resolve();
                accountService.getOrder(orderId).$promise.then(function (data) {
                    $scope.order = data;
                    $scope.order.orderDate = helpers.prettifyDate(helpers.trimDate($scope.order.orderDate));
                    $scope.orderDetails = true;
                    $scope.orderData.resolve();
                });
            }*/

            $scope.clone = function (itemId) {
                $scope.loading = true;
                accountService.cloneOrder(itemId).$promise.then(function (data) {
                    if (data.success == true) {
                        cacheService.obliterate();
                        notifyService.success('ADD_TO_BASKET_SUCCESS');
                        $timeout(function () {
                            $scope.loading = false;
                            routeService.redirect("/Basket");
                        }, 5000);
                    }
                });
            }
        }
    ]);