angular.module(app.appName)
	.factory('accountResource', [
		'$resource', 'apiUrl', 'config', 'sessionService',
			function ($resource, apiUrl, config, sessionService) {
		    var api = apiUrl();
				var newApiUrl = config.apiUrl;
		    return $resource(api, {}, {
				test: {
					method: 'Get',
					url: api + '/User/Test'
				},
		        getDetails: {
		            method: 'GET',
		            url: api + '/User/Details'
		        },
		        updateDetails: {
		            method: 'POST',
		            url: api + '/User/Details'
		        },
		        getAddresses: {
		            method: 'GET',
		            url: api + '/Address/Index',
		            params: {
		                destination: '@destination' // enum
		            }
		        },
		        addAddress: {
		            method: 'POST',
		            url: api + '/Address/Post'
		        },
		        editAddress: {
		            method: 'PUT',
		            url: api + '/Address/Put/:id',
		            params: {
		                id: '@id' // GUID
		            },
		        },
		        deleteAddress: {
		            method: 'DELETE',
		            url: api + '/Address/Delete/:id',
		            params: {
		                id: '@id' // GUID
		            },
		        },
		        postcodeLookup: {
		            method: 'POST',
		            url: api + '/Address/LookupPostcode/:postcode',
		            params: {
		                postcode: '@postcode' // string
		            },
		        },
		        changePassword: {
		            method: 'POST',
		            url: api + '/User/ChangePassword'
		        },
		        getOrders: {
		            method: 'GET',
		            url: api + '/Orders/Index',
		            isArray: true
		        },
		        getOrder: {
		            method: 'GET',
		            url: api + '/Orders/Get/:id',
		            params: {
		                id: '@id' // GUID
		            },
		        },
		        cloneOrder: {
		            method: 'POST',
		            url: api + '/Orders/Clone/:id',
		            params: {
		                id: '@id' // GUID
		            }
		        },
		        getDownloads: {
		            method: 'GET',
		            url: newApiUrl + '/Download/Get',
								headers: {
									'Authorization': sessionService.getAuthHeader
								},
		            isArray: true
		        },
		        getDownloadUrl: {
		            method: 'Post',
		            url: newApiUrl + '/Download/GetDownloadUrl',
								headers: {
									'Authorization': sessionService.getAuthHeader
								},
		        },

		        getApprovals: {
		            method: 'GET',
		            url: api + '/Approval/Index',
		            isArray: true
		        },
		        //getApprovals2: {
		        //    method: 'GET',
		        //    url: api + '/Approval/Get',
		        //    params: {
		        //        id: '@id' // GUID
		        //    },
		        //    isArray: true
		        //},
		        getApprovalItems: {
		            method: 'GET',
		            url: api + '/Approval/Get',
		            params: {
		                id: '@id' // GUID
		            },
	            isArray: true
		        },
		        saveApprovals: {
		            method: 'POST',
		            url: api + '/Approval/Save'
		        },
		        reviseApprovalItem: {
		            method: 'POST',
		            url: api + '/Approval/Revise',
		            params: {
		                approvalItemId: '@id', // GUID
                        comment: '@comment' // string
		            }
		        },
		        approveItem: {
		            method: 'POST',
		            url: api + '/Approval/Approve',
		            params: {
		                approvalItemId: '@id' // GUID
		            }
		        }
		    });
		}
	]);