angular.module(app.appName)
    .directive('fixAndScroll', function () {
        var controller = [
            '$scope', '$element', 'routeService',
            function ($scope, $element, routeService) {
                var self = this;

                // Get the height of the <header> element and the main banner element
                self.headerHeight = document.getElementsByTagName('header')[0].clientHeight + 60; // Because the header height is slightly off, we have to add 60
                self.bannerHeight = 40; // We can't get the element because it may not exist in the DOM yet

                if (routeService.get().name == 'PACKS') {
                    $element[0].classList.add('offset-top');
                    self.bannerHeight = 200;
                }

                self.setClassName = function () {
                    // Get the height of the element that we are fixing
                    $element[0].classList.add('fix-and-scroll');

                    if ($scope.size == 'small')
                        $element[0].classList.add('fix-and-scroll-small');
                };

                self.setHeight = function (height) {
                    var newHeight = height - ((self.headerHeight + self.bannerHeight) + 70); // The +70 is the banner margin (20) and the height of the footer (50)
                    newHeight = newHeight - ($scope.offset || 0); // Image folder browser
                    // debugger;
                    $element[0].style.height = newHeight + 'px';
                }

                // We want to call our setHeight() function when we resize the browser to make
                // sure that the height is always correct

                $scope.mobile;
                self.setMobile = function () {
                    $scope.mobile = window.innerWidth < 1280;
                }

                window.addEventListener('resize', function () {
                    self.setForDevice();
                });

                self.setForDevice = function () {
                    self.setMobile();
                    if ($scope.mobile) {
                        $element[0].style.height = 'auto';
                        $element[0].classList.remove('fix-and-scroll');
                        $element[0].classList.remove('fix-and-scroll-small');
                    } else {
                        self.setClassName();
                        self.setHeight(window.innerHeight);
                    }

                }

                self.init = function () {
                    self.setForDevice();
                }();
            }
        ];
        return {
            restrict: 'A',
            scope: {
                size: '@?',
                offset: '='
            },
            controller: controller,
        }
    });