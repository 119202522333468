angular.module(app.appName)
	.controller('feedbackController', [
		'$rootScope', '$scope', '$translate', 'notifyService', 'feedbackResource', 'validationService', '$filter', '$route',
		function ($rootScope, $scope, $translate, notifyService, feedbackResource, validationService, $filter, $route) {

			var self = this;

			$scope.$emit('showProducts', { state : false });

			$scope.sittingId = null;
			$scope.submittingForm = false;
			$scope.showFeedbackForm = true;

			$scope.form = {
				name: '',
				email: '',
				sitting: '',
				experience: '',
				experienceRating: 0,
				website: '',
				websiteRating: 0
			}

			$scope.refresh = function () {

				// If were given a sittingId, we need a sittingPassword

				feedbackResource.get({ sittingId: $route.current.params.sittingId }).$promise.then(function (d) {
					$scope.form = d;
				});
			}
			$scope.refresh();

			$scope.setExperience = function (expRating) {
				$scope.form.experienceRating = expRating;
			}

			$scope.setWebsiteRating = function (webRating) {
				$scope.form.websiteRating = webRating;
			}

			$scope.isValid = function () {
				return ($scope.form.name && $scope.form.email);
			}

			$scope.validateEmail = function (email) {
				var emailInput = $filter('lowercase')(email);
				$scope.invalidEmail = validationService.isInvalidEmail(emailInput);
			}

			$scope.validateName = function (name) {
				$scope.invalidName = !name || name == null || name.length < 2;
			}

			$scope.submit = function () {
				$scope.submittingForm = true;
				feedbackResource.post($scope.form).$promise.then(function (d) {
					if (d.success) {
						$scope.showFeedbackForm = false
					}

					$scope.submittingForm = false;
				});
			};
		}]);