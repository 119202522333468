angular.module(app.appName)
  .service('sessionService', [
    'sessionResource', 'apiUrl', '$http', '$q',
    function (sessionResource, apiUrl, $http, $q) {

      // Returns session token and stores for duration.
      // Does *not* handle expiration.

      var that = this;

      // that._sessionToken = "";
      // that._sessionExpiry = null;

      var api = apiUrl() + '/session';

      this.updateSessionToken = function () {
        // Session tokens last for seven days.
        // Do not call until near expiry.
        return (!window.sessionStorage._sessionExpiry || window.sessionStorage._sessionExpiry.getDate() + 6 > new Date()) ?
          that.renewSessionToken() :
          that.fetchSessionToken;
      };

      // Forces renewal of session token.
      this.renewSessionToken = function () {
        return $q(function (resolve) {
        return $http.get(api + "/token").then(function (response) {
          console.log("[sessionService.fetchSessionToken] Setting token:", response.data);
          window.sessionStorage._sessionToken = response.data;
          resolve(window.sessionStorage._sessionToken);
        });
        });
      };

      // Returns whatever is held in session token
      this.fetchSessionToken = function () {
        return $q(function (resolve) {
          resolve(window.sessionStorage._sessionToken);
        });
      };

      // Returns pre-formatted bearer token
      this.getAuthHeader = function () {
        console.log("[sessionService.getHeaders] Requested to set headers");
        if (window.sessionStorage._sessionToken) {
          console.log("[sessionService.getHeaders] Setting auth:", window.sessionStorage._sessionToken);
          return 'Bearer ' + window.sessionStorage._sessionToken;
        }
      }
    }
  ]);