angular.module(app.appName)
    .directive('notifyMe', function () {
        var controller = [

            '$scope', '$rootScope', 'sittingNotificationService', 'routeService', 'validationService',
            function ($scope, $rootScope, sittingNotificationService, routeService, validationService) {

                var self = this;

                /* Set-up "back" link, dependant on session validity */
                $scope.backLink = $scope.sessionValid.isValid
                    ? "/MyAlbums/"
                    : "https://www.pret-a-portrait.net";

                $scope.domain = {
                    sittingPassword: $scope.sitting.sittingPassword,
                    fullName: "",
                    email: ""
                }

                /* Build full name from customer data */
                if ($scope.customer.forename || $scope.customer.surname) {
                    $scope.domain.fullName = [$scope.customer.forename, $scope.customer.surname]
                        .map(function(x) { return (x || "").trim(); }) // Trim names
                        .filter(function(x) { return x; }) // Non-empty
                        .join(" ");
                }

                // validation service expects
                if ($scope.customer.email) {
                    $scope.domain.emailAddress = $scope.customer.email;
                }

                $scope.userFields = [
                    { input: function() { return $scope.domain.fullName },
                      name: "FullName",
                      valid: true },
                    { input: function() { return $scope.domain.emailAddress },
                      name: "Email",
                      valid: true }
                ];

                /* Validate an individual field by index */
                $scope.validateFieldIndex = function(index) {
                    var userField = $scope.userFields[index];

                    return $scope.validateField(userField.name, userField.input(), userField);
                };

                /* Validate an individual field and return a result - to be aggregated */
                $scope.validateField = function(userDesc, userInput, userField) {
                    var userData = [ { description: userDesc, value: userInput }];
                    var errors = validationService.checkAccountInput(userData);

                    /* Set flag on userField */
                    userField.valid = ! errors.length;

                    return errors;
                };

                /* Validate all fields */
                $scope.validateGlobal = function() {

                    var errors = $scope.userFields.reduce(function(errors, userField) {

                        /* Map over each set of fields and add the errors to the set */
                        var userDesc = userField.name;
                        var userInput = userField.input();

                        /* Collect errors for field and apply to collection */
                        var localErrors = $scope.validateField(userDesc, userInput, userField);
                        Array.prototype.push.apply(errors, localErrors);

                        return errors; // Assign to acc
                    }, []);

                    return errors;
                };

                /* Submit notification data */
                $scope.submit = function() {

                    /* Validate form */
                    if ($scope.validateGlobal().length == 0) {
                        /* Send form to service */
                        sittingNotificationService.post($scope.domain).$promise.then(function(resp) {

                            /* If all okay, redirect the user */
                            routeService.setPreviousRoute('/NotifyMe/' + $scope.sittingPassword);
                            routeService.redirect("/NotifyThanks");
                        });
                    }

                    return false; // Stop form submission
                };
            }
        ];
        return {
            controller: controller,
            scope: {
                customer: "=",
                sitting: "=",
                sessionValid: "="
            },
            templateUrl: '/assets/html/directives/notifyMe/notifyMe.html'
        };
    });