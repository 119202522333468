angular.module(app.appName)
    .directive('summary', function () {
        var controller = [
            '$scope',
            function ($scope) {
            	var self = this;
                console.info(`[summary] model is [${JSON.stringify($scope.summaryData)}]`)
            }
        ];
        return {
            controller: controller,
            scope: {
                summaryData: '='
            },
            templateUrl: '/assets/html/directives/checkout/summary.html'
        }
    });