angular.module(app.appName)
	.service('animationService', [
		function () {
			var self = this;

			return {
				setProductchildElementHeight: function (entity, parentId, childId, condition) {
					// Regex to get numbers from styles
					var reg = /\d+/g;

					// If the pased id strings don't start with a '#' we want to make sure it does. this is a failsafe
					if (parentId.charAt(0) != '#')
						parentId = '#' + parentId;
					if (childId.charAt(0) != '#')
						childId = '#' + childId;

					var parentElement = $(parentId);
					var childElement = $(childId);

					var parentPaddingTop = parseInt(parentElement.css("padding-top").match(reg)); // Get the padding-top and use regex to strip out the 'px'
					var parentPaddingBottom = parseInt(parentElement.css("padding-bottom").match(reg)); // Get the padding-bottom and use regex to strip out the 'px'
					// Add the padding-top to padding-bottom to calculate the paddings.
					// We also need to add the height of the parentElement to the childElement to get the total height
					// that the parent needs.
					var parentTotalHeight = parentPaddingTop + parentPaddingBottom + parentElement.height() + childElement.height();

					var childElementPaddingTop = parseInt(childElement.css("padding-top").match(reg)); // Get the padding-top and use regex to strip out the 'px'
					var childElementPaddingBottom = parseInt(childElement.css("padding-bottom").match(reg)); // Get the padding-bottom and use regex to strip out the 'px'
					var childElementTotalHeight = childElementPaddingTop + childElementPaddingBottom + childElement.height(); // Add paddings with the height of the child

					var totalHeight = parentTotalHeight + childElementTotalHeight; // Create a variable with the total height of parent + child elements

					/*
						The reason we need to do the calculations is because the CSS sets the height of the parent
						element, and hides all overflow (the child element gets hidden). When we want to show the 
						child element, we just increase the size of the parent element to show what's been hidden.
					*/

					// If our condition is met, set the max-height of our parent to be the total height
					if (condition) {
						parentElement.css("max-height", totalHeight);
					} else {
						// Otherwise remove the inline-style, therefore resetting the heights
						parentElement.removeAttr('style');
					}
				}
			}
		}
	]);