angular.module(app.appName)
	.controller('homeController', [
		'$q', '$rootScope', '$scope', 'homeService', '$location', '$route', '$translate', 'routeService', 'authService', 'storageService', 'accountService', 'notifyService', 'generalStore', 'enums', '$timeout', 'sessionResource', 'myPicturesService', '$routeParams', 'config', 'statStore', 'navService', 'sessionService',
		function ($q, $rootScope, $scope, homeService, $location, $route, $translate, routeService, authService, storageService, accountService, notifyService, generalStore, enums, $timeout, sessionResource, myPicturesService, $routeParams, config, statStore, navService, sessionService) {
			var self = this;

			sessionResource.sessionValid().$promise.then(function (data) {
				$scope.hasSession = data.isValid;
				$scope.isLoggedIn = data.isLoggedIn;
			});
			$scope.hasValidatedEmailSession = storageService.get('validatedEmail');

			$scope.showForm = true;
			$scope.showPasswordText = false;
			$scope.showFullCookieMessage = false;
			$scope.showInvalidSittingMessage = false;
			$scope.showInvalidEmailMessage = false;
			$scope.showNotReadySittingMessage = false;
			$scope.showLoginMessage = false;
			$scope.showCountryErrorMessage = false;
			$scope.showNotifyMePreference = true;
			$scope.showNotifyMeCallout = false;
			$scope.showNotifymeInfo = false;
			$scope.showOptOutCallout = false;
			$scope.showCodeInput = false;
			$scope.showButton = true;
			$scope.showPreferences = true;
			$scope.showValidationEmailNotReceived = false;
			$scope.showEmailBlurb = false;
			$scope.loginMessage = null;
			$scope.showInvalidOtpMessage = false;
			$scope.loading = false;
			$scope.countries = null;

			$scope.addSitting = function (sittingPassword) {
				return $q(function (resolve) {
					sessionService.updateSessionToken().then(function () {
						myPicturesService.addSitting(sittingPassword, true).then(function (data) {
							if (data.response == "Found") {
								myPicturesService.handleSitting(data.data).promise.then(function (sittingData) {
									// Forced update of the session token
									// on adding a sitting.
									notifyService.success('SITTING_ID_ADDED');
									myPicturesService.setupShowMoreOnEvents(sittingData);
									$rootScope.$broadcast("sittingAdded"); // Used in header
									resolve(sittingData);
								});
							}
							else {
								notifyService.error('INVALID_SITTING_ID');
								resolve(null);
							}
						});
					});
				});
			}

			$scope.token = $routeParams.token;
			$scope.showInvalidTokenMessage = false;
			if ($scope.token) {
				$scope.showForm = false;
				homeService.verifyEmailUrl($scope.token).then(function (result) {
					if (result.success) {
						storageService.set('validatedEmail', true);
						$rootScope.$broadcast('validatedEmailSet');
						$scope.addSitting(result.sittingPassword).then(function (data) {
							if (result.showRegistration) {
								navService.register();
							} else {
								$scope.navAlbum(data, result.sittingPassword);
							}
						});
					} else {
						$scope.showInvalidTokenMessage = true;
					}
				});
			} else {
				// If logged in to a validated session, unless navigating here via home logo button,
				// bypass the home page and go to shop.
				if (storageService.get('authenticated')) {
					if (routeService.getPreviousRoute() == config.wwwUrl) {
						$scope.showForm = false;
						navService.album();
					}
				};
			}

			$scope.emailNotifyDefault = true;
			$scope.optOutMarketingDefault = false;

			$scope.dto = {
				sittingPassword: null,
				sittingId: null,
				emailAddress: null,
				emailNotify: $scope.emailNotifyDefault,
				optOutMarketing: $scope.optOutMarketingDefault,
				response: null, // Provided by API.
				stage: enums.homeLoginRequestStage.validateSitting, // Determines form state. Changed here.
				requireEmail: null,
				showRegistration: null,
				doNotShowNotifyMePreference: null,
				emailValidationCode: null,
				country: null,
				firstName: null,
				surname: null
			};

			$scope.validationCode = [null, null, null, null];

			$scope.submitButtonStrings = {};
			$q.all([
				$translate('NEXT'),
				$translate('VIEW_PICTURES'),
				$translate('LOGIN'),
				$translate('NOTIFYME_BUTTON'),
				$translate('VALIDATE_EMAIL'),
				$translate('REGISTER_AND_VIEW_PICTURES')]).then(function (translations) {
					$scope.submitButtonStrings[enums.homeLoginRequestStage.validateSitting] = translations[0];
					$scope.submitButtonStrings[enums.homeLoginRequestStage.validateEmail] = translations[0];
					$scope.submitButtonStrings[enums.homeLoginRequestStage.validatePassword] = translations[2];
					$scope.submitButtonStrings[enums.homeLoginRequestStage.registerFirst] = translations[3];
					$scope.submitButtonStrings[enums.homeLoginRequestStage.validateEmailLink] = translations[1];
					$scope.submitButtonStrings[enums.homeLoginRequestStage.acceptPreferences] = translations[4];
					$scope.submitButtonStrings[enums.homeLoginRequestStage.register] = translations[5];
				});

			accountService.getCountries(false).$promise.then(function (data) {
				$scope.countries = data.list;

				// Put UK first in list.
				var uk = $scope.countries.find(function (x) {
					return x.name == 'United Kingdom';
				});
				if (uk) {
					var ukIndex = $scope.countries.indexOf(uk);
					$scope.countries.splice(ukIndex, 1);
					$scope.countries.splice(0, 1, uk);
				}
			});

			$scope.validateSitting = function () {
				return $q(function (resolve) {
					switch ($scope.dto.response) {
						case enums.homeLoginResponse.sittingFound:
							$scope.showNotifyMePreference = !$scope.dto.doNotShowNotifyMePreference;
							if ($scope.hasSession) {
								if (statStore.email) {
									$scope.dto.stage = enums.homeLoginRequestStage.addSitting;
									$scope.dto.emailAddress = statStore.email;
									$scope.dto.emailNotify = null;
									$scope.dto.optOutMarketing = null;
									homeService.homeLogin($scope.dto).then(function (result) {

										storageService.set('validatedEmail', true);
										$rootScope.$broadcast('validatedEmailSet');

										$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
											$scope.showForm = false;
											$scope.navAlbum(data, $scope.dto.sittingPassword);
											resolve();
										});
									});
								} else {
									$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
										$scope.showForm = false;
										$scope.navAlbum(data, $scope.dto.sittingPassword);
										resolve();
									});
								}
							} else {

								$scope.showInvalidSittingMessage = false;

								document.getElementById('sitting-input').classList.remove('invalid');
								if ($scope.dto.requireEmail) {
									$scope.dto.stage = enums.homeLoginRequestStage.validateEmail;
									resolve();
								} else {
									$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
										$scope.showForm = false;
										$scope.navAlbum(data, $scope.dto.sittingPassword);
										resolve();
									});
								}
							}
							break;

						case enums.homeLoginResponse.sittingNotFound:
							$scope.showInvalidSittingMessage = true;
							document.getElementById('sitting-input').classList.add('invalid');
							resolve();
							break;

						case enums.homeLoginResponse.sittingNotReady:
							$scope.showInvalidSittingMessage = false;
							$scope.showNotReadySittingMessage = true;
							document.getElementById('sitting-input').classList.remove('invalid');
							$scope.dto.emailNotify = null;
							$scope.dto.optOutMarketing = null;
							$scope.dto.stage = enums.homeLoginRequestStage.registerFirst;
							resolve();
							break;
					}
				});
			};

			$scope.validateEmail = function () {
				return $q(function (resolve) {
					switch ($scope.dto.response) {
						case enums.homeLoginResponse.attemptLogin:
							$scope.dto.stage = enums.homeLoginRequestStage.validatePassword;
							$translate('ACCOUNT_FOUND').then(function (translatedLoginMessage) {
								$scope.loginMessage = translatedLoginMessage;
								$scope.showLoginMessage = true;
								resolve();
							});
							break;

						case enums.homeLoginResponse.success:

							storageService.set('validatedEmail', true);
							$rootScope.$broadcast('validatedEmailSet');

							$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
								$scope.showForm = false;
								if ($scope.dto.showRegistration) {
									navService.register();
									resolve();
								} else {
									$scope.navAlbum(data, $scope.dto.sittingPassword);
									resolve();
								}
							});
							break;

						case enums.homeLoginResponse.validationPending:
							$scope.dto.stage = enums.homeLoginRequestStage.validateEmailLink;
							resolve();
							break;

						default:
							$scope.dto.stage = enums.homeLoginRequestStage.acceptPreferences;
							resolve();
					}
				});
			};

			$scope.resendValidationEmail = function () {
				$scope.showInvalidOtpMessage = false;
				$scope.dto.stage = enums.homeLoginRequestStage.acceptPreferences;
				$scope.validationCode = [null, null, null, null];
				$scope.showPreferences = false;
				$scope.submit().then(function () {
					$scope.showValidationEmailNotReceived = true;
					$scope.showPreferences = true;
				});
			};

			$scope.confirmValidationCode = function () {
				return $q(function (resolve) {
					storageService.set('validatedEmail', true);
					$rootScope.$broadcast('validatedEmailSet');

					$scope.showInvalidOtpMessage = false;
					if ($scope.dto.response == enums.homeLoginResponse.success) {
						$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
							$scope.showForm = false;
							if ($scope.dto.showRegistration) {
								navService.register();
							} else {
								$scope.navAlbum(data, $scope.dto.sittingPassword);
							}
						});
					} else {
						$scope.showInvalidOtpMessage = true;
					}
					resolve();
				});
			};

			$scope.advanceFocus = function (id) {
				$timeout(function () {
					if (id == $scope.validationCode.length - 1) {
						document.getElementById('submit-button').focus();
					} else {
						document.getElementById(`validation-code-${id + 1}`).focus();
					}
				});
			};

			$scope.login = function () {
				return $q(function (resolve) {
					var username = $scope.dto.emailAddress.toLowerCase();
					$scope.loading = true;
					authService.login({ username: username, password: $scope.dto.password }).then(function (result) {
						$translate(result.message).then(function (translatedLoginMessage) {
							$scope.loginMessage = translatedLoginMessage;
						});
						if (result.success == true) {
							storageService.set('authenticated', true);
							notifyService.success('LOGIN_SUCCESS');
							$rootScope.$broadcast('authenticationSet');

							$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
								$scope.showForm = false;
								$scope.navAlbum(data, $scope.dto.sittingPassword);
								resolve();
							});
						}
						else {
							$scope.showLoginMessage = true;
							$scope.loading = false;
							resolve();
						}
					});
				});
			};

			$scope.resetPassword = function () {
				$scope.loading = true;
				var form = {
					email: $scope.dto.emailAddress,
					sittingPassword: $scope.dto.sittingPassword
				}
				authService.resetPassword(form).then(function (data) {
					if (data.success == 'true') {
						notifyService.success('RESET_EMAIL_SUCCESS');
						$scope.loading = false;
					}
				})
			};

			$scope.directLogin = function () {
				$scope.showForm = false;
				routeService.redirect('/Login');
			};

			/* Redirect user to direct album based on returned sitting information from
				 addSitting */
			$scope.navAlbum = function (sittingData, sittingPassword) {

				sittingPassword = (sittingPassword || "").toUpperCase(); // SittingPasswords should be upper case.

				var lastFolder = sittingData.none.folders.years.flatMap(function (year) {
					return Object.values(year).flat().filter(function (x) { return x.sittingPassword && x.sittingPassword.toUpperCase() == sittingPassword; });
				})[0];

				if (lastFolder) {
					$scope.showForm = false;
					navService.album(lastFolder.sittingId, lastFolder.id);
				}
			};

			$scope.bypassRegister = function () {
				$scope.addSitting($scope.dto.sittingPassword).then(function (data) {
					$scope.showForm = false;
					$scope.navAlbum(data, $scope.dto.sittingPassword);
				});
			};

			$scope.sittingInputVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.validateSitting
					|| $scope.dto.stage == enums.homeLoginRequestStage.validateEmail
					|| $scope.dto.stage == enums.homeLoginRequestStage.registerFirst
					|| $scope.dto.stage == enums.homeLoginRequestStage.validatePassword
					|| $scope.dto.stage == enums.homeLoginRequestStage.validateEmailLink
					|| $scope.dto.stage == enums.homeLoginRequestStage.acceptPreferences;
			};

			$scope.emailInputVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.validateEmail
					|| $scope.dto.stage == enums.homeLoginRequestStage.registerFirst
					|| $scope.dto.stage == enums.homeLoginRequestStage.validatePassword
					|| $scope.dto.stage == enums.homeLoginRequestStage.validateEmailLink
					|| $scope.dto.stage == enums.homeLoginRequestStage.acceptPreferences
					|| $scope.dto.stage == enums.homeLoginRequestStage.register;
			};

			$scope.emailInputDisabled = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.register;
			};

			$scope.registerInputVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.register;
			};

			$scope.passwordInputVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.validatePassword
					|| $scope.dto.stage == enums.homeLoginRequestStage.register;
			};

			$scope.preferencesInputVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.acceptPreferences
					&& $scope.showPreferences;
			};

			$scope.validationInputVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.validateEmailLink;
			};

			$scope.doneMessageVisible = function () {
				return $scope.dto.stage == enums.homeLoginRequestStage.done;
			};

			$scope.hideTitle = function () {
				document.getElementById('fresh-approach-container').setAttribute("style", "opacity:0;height:0");
				document.getElementById('seasonal-message-container').setAttribute("style", "opacity:0;height:0");
			};

			$scope.showTitle = function () {
				document.getElementById('fresh-approach-container').setAttribute("style", "opacity:1;height:68px");
				document.getElementById('seasonal-message-container').removeAttribute("style");
			};

			$scope.pageVisible = function () {
				return $scope.showForm && $scope.dto.stage != enums.homeLoginRequestStage.addSitting;
			};

			$scope.toggleCookieMessage = function () {
				$scope.showFullCookieMessage = !$scope.showFullCookieMessage;
			};

			$scope.changeShowPasswordText = function (hide) {
				$scope.showPasswordText = hide;
			};

			$scope.toggleOptOutCallout = function () {
				$scope.showOptOutCallout = !$scope.showOptOutCallout;
			};

			$scope.toggleNotifyMeCallout = function () {
				$scope.showNotifyMeCallout = !$scope.showNotifyMeCallout;
			};

			$scope.toggleNotifymeInfo = function () {
				$scope.showNotifymeInfo = !$scope.showNotifymeInfo;
			}

			$scope.toggleCodeInput = function () {
				$scope.showCodeInput = !$scope.showCodeInput;
				$scope.showButton = !$scope.showButton;
			}

			$scope.toggleEmailBlurb = function () {
				$scope.showEmailBlurb = !$scope.showEmailBlurb;
			}

			$scope.checkFormRefresh = function (section) {
				if (section == 'sitting') {
					if ($scope.dto.stage != enums.homeLoginRequestStage.validateSitting) {
						$scope.dto.stage = enums.homeLoginRequestStage.validateSitting;
						$scope.showNotReadySittingMessage = false;
						$scope.dto.emailNotify = $scope.emailNotifyDefault;
						$scope.dto.optOutMarketing = $scope.optOutMarketingDefault;
						$scope.showValidationEmailNotReceived = false;
						$scope.showCodeInput = false;
						$scope.showButton = true;
						$scope.showEmailBlurb = false;
						$scope.showTitle();
					}
				}
				if (section == 'email') {
					if ($scope.dto.stage != enums.homeLoginRequestStage.validateEmail
						&& $scope.dto.stage != enums.homeLoginRequestStage.registerFirst) {
						$scope.dto.stage = enums.homeLoginRequestStage.validateEmail;
						$scope.dto.emailNotify = $scope.emailNotifyDefault;
						$scope.dto.optOutMarketing = $scope.optOutMarketingDefault;
						$scope.showValidationEmailNotReceived = false;
						$scope.showCodeInput = false;
						$scope.showButton = true;
						$scope.showEmailBlurb = false;
					}
				}
			}

			$scope.goBack = function () {
				location.reload();
			}

			$scope.isValidEmail = function (emailAddress) {
				const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
				return emailRegex.test(emailAddress);
			}

			$scope.submit = function () {
				return $q(function (resolve) {
					$scope.loading = true;
					$scope.showInvalidEmailMessage = false;
					if ($scope.dto.sittingPassword == null) {
						resolve();
						$scope.loading = false;
						return;
					}
					if ($scope.emailInputVisible() && !$scope.isValidEmail($scope.dto.emailAddress)) {
						resolve();
						$scope.showInvalidEmailMessage = true;
						$scope.loading = false;
						return;
					}
					if ($scope.dto.stage == enums.homeLoginRequestStage.validatePassword) {
						$scope.login().then(function () {
							$scope.loading = false;
							resolve();
						});
						return;
					}
					if ($scope.dto.doNotShowNotifyMePreference) {
						$scope.dto.emailNotify = null;
					}
					$scope.dto.emailValidationCode = $scope.validationCode.join('');
					homeService.homeLogin($scope.dto).then(function (result) {
						$scope.dto = result;
						switch ($scope.dto.stage) {
							case enums.homeLoginRequestStage.validateSitting:
								$scope.validateSitting().then(function () {
									$scope.hideTitle();
									resolve();
								});
								break;

							case enums.homeLoginRequestStage.validateEmail:
								$scope.validateEmail().then(function () {
									resolve();
								});
								break;

							case enums.homeLoginRequestStage.registerFirst:
								if ($scope.dto.response == enums.homeLoginResponse.success) {
									$scope.dto.stage = enums.homeLoginRequestStage.done;
									$scope.showForm = false;
								}
								break;

							case enums.homeLoginRequestStage.validateEmailLink:
								$scope.confirmValidationCode().then(function () {
									resolve();
								});
								break;

							case enums.homeLoginRequestStage.validationPending:
								$scope.dto.stage = enums.homeLoginRequestStage.validateEmailLink;
								$scope.showButton = false;
								break;

							case enums.homeLoginRequestStage.acceptPreferences:
								if ($scope.dto.doNotShowNotifyMePreference) {
									$scope.dto.emailNotify = null;
								}
								$scope.dto.stage = enums.homeLoginRequestStage.validateEmailLink;
								$scope.showButton = false;
								break;
						}

						resolve();
						$scope.loading = false;
					});
				});
			};

			// Attempt to validate submitted params
			$scope.trySubmitParams = function () {
				if ($scope.dto.sittingPassword) {
					$scope.submit().then(function () {
						if ($scope.dto.emailAddress) {
							$scope.submit();
						}
					});
				}
			}

			/* Populate params from url, if exist. */
			if ($route.current.params.sittingPassword) { $scope.dto.sittingPassword = $route.current.params.sittingPassword; }
			if ($route.current.params.email) { $scope.dto.emailAddress = $route.current.params.email; }

			if ($scope.dto.sittingPassword || $scope.dto.emailAddress) $scope.trySubmitParams();
		}
	]
	);