/*
    The store convention allows us to share data throughout the application without using the rootScope.
    It contains private variables on the 'self', and the public methods expose this data.
    In order to access the data, simply inject the Store into your controller, and call the public
    methods.
    TODO:
        - Implement local storage
*/
angular.module(app.appName)
    .factory('itemStore', [
        '_', 'helpers',
        function (_, helpers) {
            var self = this;
            self.productItems = [];

            return {
                publicProductItem: {},

                childProductItem: [],
                /*
                    Returns the whole productItems array. If you need a specific object, use the get(itemId, productId) overload.
                */
                getAll: function () {
                    if (self.productItems.length === 0)
                        console.warn("[itemStore] Attempted to retrieve items, but it is there are 0.");
                    return self.productItems;
                },
                /*
                    Returns a product item by itemId or productId. If it can't find anything in the store, it will set Success = false
                    and log a warning to the console.
                */
                get: function (productId, itemId) {
                    var item = {};
                    if (!helpers.isNullOrUndefined(productId)) {
                        item = _.find(self.productItems, function (item) { return item.productId == productId });
                    } else if (!helpers.isNullOrUndefined(itemId)) {
                        item = _.find(self.productItems, function (item) { return item.id == itemId });
                    } else {
                        item.Success = false
                        console.warn("[itemStore] Could not find a matching item. Setting Success = false");
                    }

                    return item;
                },
                /*
                    Saves the passed item to the store using the productId and item. This will be unique in the array
                    by first trying to grab the object and setting that. If it doesn't find an object, it pushes a new
                    object into the array.
                */
                set: function (productId, item) {
                    var existingItem = _.find(self.productItems, function (pi) {
                        return pi.productId == productId;
                    });

                    if (existingItem)
                        // overwriting the existing item has caused problems with the basket, so take off and nuke the existing item from orbit
                        // it's the only way to be sure
                        self.productItems = _.without(self.productItems, existingItem);

                    self.productItems.push(item);
                },

                clear: function () {
                    self.productItems = [];
                }
            };
        }
    ]);