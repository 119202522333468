angular.module(app.appName)
    .service('cacheService', [
        '$cacheFactory', 'debug', function ($cacheFactory, debug) {
            this.obliterate = function() {

                /* Remove all objects from the cache */
                var httpCache = $cacheFactory.get('$http');

                console.debug("[cacheService] Obliterate - objects in $http cache", httpCache.info().size);

                httpCache.removeAll();
            };
        }
    ]);
