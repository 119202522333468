angular.module(app.appName)
  .directive('paymentModule', function () {
    return {
      require: '^^checkoutContainer',
      scope: {
        settable: '=', /* Is the user allowed to change the address value? */
        onContinue: '&'
      },
      controller: [
        '$q', '$scope', '$window', 'paymentFactory', 'braintreeService', 'routeService', 'paypalEnvironment', 'paymentErrorService', '$translate', '$sce'
        , function ($q, $scope, $window, paymentFactory, braintreeService, routeService, paypalEnvironment, paymentErrorService, $translate, $sce) {

          /* Can we uncouple parent/child functions from on page control functions? */

          $scope.instance = null;
          $scope.paymentEnabled = false;
          $scope.paymentValid = true; // We assume valid until we are told otherwise
          $scope.payButtonVisible = true; // Pay button is hidden for certain third party callouts.
          $scope.isLoading = false;
          $scope.isPaying = false; // Only allow one instance of payment to occur.

          $scope.errorString = null;
          $scope.faCardBrand = null;

          $scope.faCards = {
            "fa-cc-visa": ["visa"],
            "fa-cc-mastercard": ["master-card", "maestro"],
            // "fa-cc-amex": ["american-express"],
            "fa-cc-diners-club": ["diners-club"],
            "fa-cc-jcb": ["jcb"],
            "fa-cc-paypal": ["paypal"],
            "fa-cc-discover": ["discover"]
          };

          /* Data to send alongside payment */
          $scope.paymentData = {
            amount: function () { return $scope.basket().finalPriceValue },
            currency: function () { return $scope.basket().currency },
            user: function () { return $scope.userDetails },
            billingAddress: function () { return $scope.basket().invoiceAddress },
            brainTreeCards: {
              cardName: ""
            }
          };

          /* Set to first payment provider */
          $scope.switchedPayment = null;
          $scope.isStaticPayment = false;

          /* Switch the payment provider. */
          $scope.switchPayment = function (paymentProvider) {
            console.info("[payment] Switching to payment provider, ", paymentProvider);
            $scope.switchedPayment = paymentProvider;

            $scope.teardownBraintree();

            $scope.payButtonVisible = true;
            $scope.errorString = null;

            switch (paymentProvider) {
              case "BraintreePaypal":
              case "BraintreeGpay":
              case "BraintreeApplepay":
                $scope.payButtonVisible = false;
              case "BraintreeCards":
                $scope.isStaticPayment = false;
                $scope.setupBraintree(paymentProvider);
                break;
              case "WorldpayCards":
              case "WorldpayAlipay":
              case "WorldpayUnionPay":
              case "WeChatPay":
              case "Telephone":
              case "Cheque":
              case "Free":
              case "BankTransfer":
              case "BankTransferVietnam":
                /* This is the general case for static payment types */
                $scope.isStaticPayment = true;
                $scope.paymentEnabled = true;
                $scope.paymentValid = true;
                break;
              default:
                console.error(
                  "This is an unhandled payment type ",
                  paymentProvider
                );
            }
          };

          $scope.pay = function (data) {
            /* We need a factory for handling payments for different
               types. */
            $scope.isLoading = true;
            $scope.isPaying = true;

            $scope.commit().then(function () {

              console.log("[paymentModule] [pay] now calling payment");

              /* Execute the payment method */
              paymentFactory
                .getPaymentMethodPromise($scope.switchedPayment, $scope.instance, data)
                .then($scope.completePayment)
                .catch(function (errors) {
                  $scope.errorPayment($scope.switchedPayment, errors);
                });
            });
          };

          $scope.setupBraintree = function (paymentProvider) {
            braintreeService.getClientToken().then(function (response) {
              switch (paymentProvider) {
                case "BraintreeCards":
                  $scope.startCardPayment(response.data);
                  break;
                case "BraintreePaypal":
                  $scope.startPaypalPayment(response.data);
                  break;
                case "BraintreeGpay":
                  $scope.startGpayPayment(response.data);
                  break;
                case "BraintreeApplepay":
                  $scope.startApplepayPayment(response.data);
                  break;
                default:
                  console.error(
                    "This is an unhandled payment type ",
                    paymentProvider
                  );
              }
            });
          };

          $scope.teardownBraintree = function () {
            if ($scope.instance != null) {
              $scope.instance.teardown(function (teardownErr) {
                if (teardownErr) {
                  console.error("[payment] Could not tear down Drop-in UI!");
                  // UI level reporting?
                } else {
                  console.info("[payment] Drop-in UI has been torn down!");
                }
                // Either way, clear the instance.
                // This could probably be more elegant.
                $scope.instance = null;
              });
            }
          };

          $scope.updateBasket = function () {

            /* Indicate to the global handler to update the basket and
               await the response. */






          };

          $scope.completePayment = function (response) {
            $scope.isPaying = false;

            if (response.Redirect != null) {
              // routeService.redirect(response.Redirect);
              $window.location.href = response.Redirect;
              // $window.location.href = result.redirect;
            } else if (response.orderId != null || response.OrderId != null) {
              var orderId = response.orderId || response.OrderId;
              routeService.redirect("/Orders/" + orderId);
            } else {
              console.error("[payment] Unable to handle paymentFactory response", response);
            }
          };

          $scope.errorPayment = function (paymentMethod, errors) {
            console.error("[payment] Error on processing payment: ", errors);

            paymentErrorService.post({
              source: paymentMethod,
              level: 'error',
              message: errors
            });

            // Handle exceptions for cryptic error messages
            errorLabelFn = $q(function(resolve) {resolve($sce.trustAsHtml(errors))});
            switch (errors)
            {
              case 'Gateway Rejected: three_d_secure':
                errorLabelFn = $translate('THREEDS_ERROR');
                break;
            }

            // Now interpolate message with display label
            errorLabelFn.then(function(displayError) {
              $translate('PAYMENT_ERROR_X', {x: displayError.$$unwrapTrustedValue()}).then(function(finalLabel) {
                $scope.errorString = finalLabel;
              });
            });

            $scope.isLoading = false;
            $scope.isPaying = false;
          };

          $scope.startApplepayPayment = function (clientToken) {

            console.info("[paymentModule] Starting Apple Pay");

            // Device does not support Apple Pay
            if (!(window.ApplePaySession && ApplePaySession.canMakePayments())) {
              // Switch notices
              document.getElementById("payment_applepay_invite").style.display = "none";
              document.getElementById("payment_applepay_unavailable").removeAttribute("style");
              document.getElementsByClassName("apple-pay-button")[0].style.display = "none";

              return;
            }

            document.getElementById("payment_applepay_invite").removeAttribute("style");
            document.getElementById("payment_applepay_unavailable").style.display = "none";
            document.getElementsByClassName("apple-pay-button")[0].removeAttribute("style");

            braintree.client.create({
              authorization: clientToken
            }, function (clientErr, clientInstance) {
              if (clientErr) {
                console.error('Error creating client:', clientErr);
                return;
              }

              braintree.applePay.create({
                client: clientInstance
              }, function (applePayErr, applePayInstance) {
                if (applePayErr) {
                  console.error('Error creating applePayInstance:', applePayErr);
                  return;
                }

                // Set up your Apple Pay button here

                var button = document.getElementsByClassName('apple-pay-button')[0];
                button.addEventListener('click', function (event) {

                  console.log('[applePay] onClick');

                  event.preventDefault();

                  var session = new ApplePaySession(3, applePayInstance.createPaymentRequest({
                    currencyCode: $scope.basket().currency,
                    total: {
                      label: 'Pret-A-Portrait Purchase',
                      // amount: "0.01"
                      amount: $scope.basket().finalPriceValue.toString(), // needs to be a string
                    },
                  }));

                  $scope.pay({
                    applePayInstance: applePayInstance,
                    session: session
                  });

                });

              });
            });
          },

            $scope.startGpayPayment = function (clientToken) {

              console.info("[paymentModule] Starting GPay");

              window.gPayEnvironment

              braintree.client
                .create({
                  authorization: clientToken
                })
                .then(function (clientInstance) {
                  var paymentsClient = new google.payments.api.PaymentsClient({
                    environment: window.gPayEnvironment // Or 'PRODUCTION'
                  });


                  var deviceData = null;

                  braintree.dataCollector.create({
                    client: clientInstance
                  }, function (err, dataCollectorInstance) {
                    if (err) {
                      // Handle error in creation of data collector
                      return;
                    }
                    // At this point, you should access the dataCollectorInstance.deviceData value and provide it
                    // to your server, e.g. by injecting it into your form as a hidden input.
                    deviceData = dataCollectorInstance.deviceData;
                  });

                  braintree.googlePayment.create({
                    client: clientInstance, // From braintree.client.create, see below for full example
                    googlePayVersion: 2,
                    googleMerchantId: window.gPayMerchantId
                  }, function (err, googlePaymentInstance) {

                    if (err) console.error("[paymentModule] GPay error", err);

                    paymentsClient.isReadyToPay({
                      // see https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest for all options
                      apiVersion: 2,
                      apiVersionMinor: 0,
                      allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
                      existingPaymentMethodRequired: true
                    }).then(function (isReadyToPay) {
                      if (isReadyToPay.result) {
                        // Set up Google Pay button
                        var container = document.getElementById("gpay-button");
                        var button = paymentsClient.createButton({
                          buttonColor: 'default',
                          buttonType: 'buy',
                          onClick: function (event) {

                            event.preventDefault();

                            $scope.pay({
                              paymentsClient: paymentsClient,
                              googlePaymentInstance: googlePaymentInstance,
                              transactionInfo: {
                                currencyCode: $scope.basket().currency,
                                totalPriceStatus: 'FINAL',
                                // totalPrice: "0.01",
                                totalPrice: $scope.basket().finalPriceValue.toString(), // needs to be a string
                              },
                              deviceData: deviceData
                            });

                          }
                        });

                        container.appendChild(button);

                        // Hide invite, show unavailable.
                        document.getElementById("payment_gpay_unavailable").style.display = "none";
                        document.getElementById("payment_gpay_invite").removeAttribute("style");

                      } else {
                        // Hide invite, show unavailable.
                        document.getElementById("payment_gpay_invite").style.display = "none";
                        document.getElementById("payment_gpay_unavailable").removeAttribute("style");
                      }
                    });
                  });
                });
            }

          /* Go get client token */
          $scope.startCardPayment = function (clientToken) {

            braintree.client
              .create({
                authorization: clientToken
              })
              .then(function (clientInstance) {
                var options = {
                  client: clientInstance,
                  styles: {
                    input: {},
                    "input.invalid": {
                      color: "red"
                    },
                    "input.valid": {
                      color: "green"
                    }
                  },
                  fields: {
                    number: {
                      selector: "#card-number",
                      placeholder: "",
                      supportedCardBrands: {
                        "american-express": false
                      }
                    },
                    cvv: {
                      selector: "#cvv",
                      placeholder: ""
                    },
                    expirationDate: {
                      selector: "#expiration-date",
                      placeholder: "mm/yy"
                    }
                  }
                };
                return $q.all([
                  braintree.hostedFields.create(options),
                  braintree.threeDSecure.create({
                    version: 2, // Will use 3DS 2 whenever possible
                    client: clientInstance
                  })
                ]);
              }).then(function (instances) {

                var hostedFieldsInstance = instances[0];
                $scope.paymentData.brainTreeCards.threeDs = instances[1];

                $scope.slideTriggered = false; // reset
                hostedFieldsInstance.on("focus", $scope.focusSlide);

                /* On a change in field state, check the status of every field
               in the form. */
                hostedFieldsInstance.on("validityChange", function () {
                  var state = hostedFieldsInstance.getState();

                  var hostedValid = Object.keys(state.fields).every(function (key) {
                    return state.fields[key].isValid;
                  });

                  // Card must have a name and not be an excluded card
                  // type.
                  var nonHostedValid = $scope.paymentData.cardName != "" && true;
                  // ! $scope.paymentData.cardType

                  console.info("[payment] Validity", $scope.paymentData);

                  var allValid = hostedValid && nonHostedValid;

                  // console.info("Payment is now:", allValid);
                  $scope.paymentValid = allValid;
                  $scope.$apply();
                });

                hostedFieldsInstance.on("cardTypeChange", function (event) {
                  if (event.cards.length === 1) {
                    var card = event.cards[0];

                    // change pay button to specify the type of card
                    // being used

                    // $scope.faCards = ["diners-club", "jcb", "paypal", "amex", "discover", "mastercard", "visa"];

                    var cardType = card.type;

                    var found = Object.keys($scope.faCards)
                      .filter(function (key) {
                        return $scope.faCards[key].indexOf(cardType) > -1
                      });

                    $scope.faCardBrand = found.length ? found[0] : 'fa-credit-card';
                  } else {
                    $scope.faCardBrand = null;
                  }
                  $scope.$apply();
                });

                if ($scope.displayInstance(hostedFieldsInstance, "BraintreeCards")) {
                  $scope.paymentEnabled = true;
                  $scope.paymentValid = false;

                  $scope.$apply();
                }
              })
              .catch(function (err) {
                console.error(err);
                // Handle error in component creation
              });
          };

          $scope.startPaypalPayment = function (clientToken) {

            braintree.client.create({
              authorization: clientToken
            }).then(function (clientInstance) {
              // Create a PayPal Checkout component.

              return braintree.paypalCheckout.create({
                client: clientInstance
              });
            }).then(function (paypalCheckoutInstance) {
              // Set up PayPal with the checkout.js library
              // https://developer.paypal.com/docs/integration/direct/express-checkout/integration-jsv4/customize-button/#supported-locales


              if ($scope.displayInstance(paypalCheckoutInstance, "BraintreePaypal")) {

                var env = paypalEnvironment();

                paypal.Button.render({
                  env: env,
                  commit: true, // This will add the transaction amount to the PayPal button
                  locale: "en_GB", // Can we pass supported locales?
                  style: {
                    size: 'medium',
                    color: 'gold',
                    shape: 'pill',
                    label: 'pay'
                  },

                  payment: function () {
                    return paypalCheckoutInstance.createPayment({
                      flow: 'checkout', // Required
                      amount: $scope.basket().finalPriceValue, // Required
                      currency: $scope.basket().currency // Required
                    });
                  },

                  /* We're going to need a way of reflecting the change in state
                      between the validation of the form, and the */

                  onAuthorize: function (data) {
                    $scope.pay(data);
                    // actions.disable(); // Only allow the payment to be created once.
                  },

                  onCancel: function (data) {
                    // console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
                  },

                  onError: function (err) {
                    console.error('[payment] checkout.js error', err);
                  }
                }, '#paypal-button');

                $scope.$apply();
              }
            }).then(function () {
              // The PayPal button will be rendered in an html element with the id
              // `paypal-button`. This function will be called when the PayPal button
              // is set up and ready to be used.
              // console.log("Apparently we're good to go");
            }).catch(function (err) {
              // Handle component creation error
              console.error("[payment] An error occured when setting up Paypal", err);
            });
          };

          /* Check that we're expecting this braintree instance.  There's a delay in
             instance creation and display, and the user can create multiple instances.
             This is a check that the expected paymentMethod mathces the currently visible
             payment method. */
          $scope.displayInstance = function (instance, paymentMethod) {
            if (paymentMethod == $scope.switchedPayment) {
              console.info("[payment] Instance matches expectation, setting and displaying", paymentMethod, instance);
              $scope.instance = instance;
              return true;
            }
            console.warn("[payment] Instance does not match expectation, ignoring", paymentMethod);
            return false;
          };

          $scope.slideTriggered = false;
          $scope.focusSlide = function () {
            if (!$scope.slideTriggered) {
              $scope.slideTriggered = true; // Only allow this to occur once.

              document.getElementById("telephone").scrollIntoView(true);
            }
          };

          // Return fully translated error message for error provided.
          $scope.getPaymentErrorMessageFull = function(errors) {

            return $translate('PAYMENT_ERROR_X', { x: errors });

            errorLabelFn = $q()
            // if (errors == 'Gateway Rejected: three_d_secure') {
            //   $translate('THREEDS_ERROR').then(function (translation) {
            //     return translation
            //   });

            //   $translate
            // }

          }

          $scope.$watch("paymentMethods", function () {
            if (
              $scope.switchedPayment === null &&
              typeof $scope.paymentMethods !== "undefined" &&
              $scope.paymentMethods !== null
            ) {
              $scope.switchPayment($scope.paymentMethods[0].key);
            }
          });

        }],
      link: function (scope, element, attrs, checkoutCtrl) {

        /* Fetch available payment methods from model. */
        /* We're going to need things we don't usually have access to
           at this point. */

        scope.commit = checkoutCtrl.commit; // Make this method available

        scope.basket = function () {
          var model = checkoutCtrl.getModel();
          return model.summary;
        }

        scope.refresh = function () {
          var model = checkoutCtrl.getModel();
          scope.paymentMethods = model.providers;
          scope.userDetails = model.user;
        };

        scope.refresh();

        scope.continue = function () {
          console.info(`[paymentModule] continue`);
          /* Need to confirm all fields are valid before we move on. */
          checkoutCtrl.next();
        };
      },
      templateUrl: '/assets/html/directives/checkout/paymentModule.html'
    }
  });