angular.module(app.appName)
	.directive('dRetentionModal',
		function () {
			var controller = ['dRetentionService', '$scope', '$rootScope', 'storageService', '$q', '$cacheFactory',
				function (dRetentionService, $scope, $rootScope, storageService, $q, $cacheFactory
				) {

					/* If we exist, then we know that we're going to display the modal. */
					$rootScope.showingDataRetentionModal = true;
					$scope.loading = true;
					$scope.requiresLogin = false;

					$scope.model = {
						policyOption: null
					};

					/* We're going to get events to check from the front end. */

					var dataRetentionEventIds = $scope.sittingData[$scope.prop];
					console.info(`[dRetentionModal] [load] for ${dataRetentionEventIds}`);

					$scope.isLoggedIn = storageService.get("authenticated"); // From header

					dRetentionService.get(dataRetentionEventIds).$promise.then(function (result) {

						/* Return a collection of policies that need to be handled. */
						console.info(`[dRetentionModal] [load]`, result);
						$scope.policies = result
						$scope.policy = $scope.policies[0];
						$scope.loading = false;
					});

					$scope.changeOption = function () {

						$scope.selectedOption = $scope.policy.options.filter(function (option) {
							return option.id === $scope.model.policyOption;
						})[0];
						console.info(`[dRetentionModal] [changeOption]`, $scope.selectedOption);
					}

					$scope.redirectToLogin = function () {
						window.location = "/login";
					}

					$scope.clearCheck = function () {
						$scope.loading = false;
						$scope.sittingData[$scope.prop] = null; // Remove check data
					}

					$scope.nextPolicy = function () {

						$scope.policies.shift();

						if ($scope.policies.length > 0) {
							$scope.policy = $scope.policies[0];
							$scope.model.policyOption = null;
							$scope.selectedOption = null;
							$scope.loading = false;
						}

						return $scope.policies.length > 0;
					}

					$scope.continue = function () {

						$scope.loading = true;
						/* Post the current policy. */

						var eventReq = $scope.policy.eventIds.map(function (eventId) {
							return dRetentionService.post({
								eventId: eventId,
								selectedPolicyOptionId: $scope.model.policyOption
							}).$promise
						});

						$q.all(eventReq).then(function () {

							// Keep track against any policy asked
							$scope.requiresLogin = $scope.selectedOption.requiresLogin || $scope.requiresLogin;

							if (!$scope.nextPolicy()) {

								$cacheFactory.get('$http').removeAll(); // Force reload of next sitting

								if (!$scope.isLoggedIn && $scope.requiresLogin) {
										$scope.redirectToLogin();
									} else {
										$scope.clearCheck();
									}
								}
							$rootScope.showingDataRetentionModal = true;
						});
					}
				}
			];

			return {
				scope: {
					sittingData: '=',
					prop: '@'
				},
				controller: controller,
				templateUrl: '/assets/html/directives/dRetentionModal.html'
			}
		}
	);