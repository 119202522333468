angular.module(app.appName)
    .service('accountService', [
        'accountResource', 'countryResource', 'storageService', '$rootScope', '$q', '$timeout',
        function (accountResource, countryResource, storageService, $rootScope, $q, $timeout) {
            this.getDetails = function () {
                return accountResource.getDetails();
            };

            this.updateDetails = function (model) {
                return accountResource.updateDetails(model);
            };

            this.test = function() {
                return accountResource.test();
            };

            this.getAddresses = function (destination) {
                return accountResource.getAddresses({ destination: destination });
            };

            this.addAddress = function (model) {
                return accountResource.addAddress(model);
            };

            this.editAddress = function (id, model) {
                return accountResource.editAddress(id, model);
            };

            this.lookupAddressByPostcode = function (postcode) {
                return accountResource.postcodeLookup({ postcode: postcode });
            };

            this.deleteAddress = function (id) {
                return accountResource.deleteAddress({ id: id });
            };

            this.getCountries = function (forDelivery) {
                    return countryResource.get({ forDelivery: forDelivery });
            };

            this.changePassword = function (model) {
                return accountResource.changePassword(model);
            };

            this.getOrders = function () {
                return accountResource.getOrders();
            };

            this.getOrder = function (id) {
                return accountResource.getOrder({ id: id });
            };

            this.cloneOrder = function (id) {
                return accountResource.cloneOrder({ id: id });
            };

            this.getDownloads = function () {
                return accountResource.getDownloads();
            };

            this.getDownloadUrl = function (orderItemId) {
                return accountResource.getDownloadUrl({orderItemId: orderItemId});
            };

            this.getApprovals = function () {
                return accountResource.getApprovals();
            };

            //this.getApprovals2 = function (id) {
            //    return accountResource.getApprovals2({ id: id });
            //};

            this.getApprovalItems = function (id) {
                return accountResource.getApprovalItems({ id: id });
            };

            this.saveApprovals = function () {
                return accountResource.getApprovals();
            };

            this.reviseApprovalItem = function (id, comment) {
                return accountResource.reviseApprovalItem({ approvalItemId: id, comment: comment });
            };

            this.approveItem = function (id) {
                return accountResource.approveItem({ approvalItemId: id });
            };
        }
    ]);
