
angular.module(app.appName)
	.controller('errorController', [
		'$scope', '$rootScope', 'reason', 'accountService', '$translate',
        function ($scope, $rootScope, reason, accountService, $translate) {
            var self = this;

            $scope.$emit('showProducts', { state : false });

            $scope.now = Date.now();

            $scope.test = accountService.test();

            self.filterEmpty = function(array) {
                return array.filter(function(x) { return x; });
            };

            var translationTable = $translate.getTranslationTable("en");

            /* Go and get the correct content for the given error reason */
            /* If no reason specified, or reason not found - fallback to default content */
            $scope.content = {
                errorHeader: translationTable[self.filterEmpty(["ERROR", reason, "HEADER"]).join("_")] ||
                    translationTable["ERROR_HEADER"],
                errorBody: translationTable[self.filterEmpty(["ERROR", reason, "BODY"]).join("_")] ||
                    translationTable["ERROR_BODY"]
            };

            /* Go and work out where to link to */
            $scope.link = reason ? "/MyAlbums" : "/Checkout";
       }
]);