angular.module(app.appName)
    .directive('termsCheckbox', function () {
        var controller = [
            '$scope',
            'config',
            'translationService',
            function ($scope, config, translationService) {

                var self = this;

                $scope.config = config;

                $scope.generateUrl = function (location) {
                    var url = config.wwwUrl;

                    if (config.generateLinksAsHashes) {
                        if (location.toLowerCase() == "home") {
                            // knowingly left blank
                        } else {
                            url += "#" + location.toLowerCase();
                        }
                    } else {
                        var culture = translationService.getCurrentCulture();
                        if (location) {
                            if (location.toLowerCase() == "home") {
                                if (culture != "en") {
                                    url += "/" + culture;
                                }
                            } else {
                                if (culture != "en") {
                                    url += "/" + culture;
                                }
                                url += "/" + location.toLowerCase();
                            }
                        }
                    }

                    return url;
                };


            }
        ];
        return {
            controller: controller,
            scope: {
                termsAccepted: '='
            },
            templateUrl: '/assets/html/directives/checkout/termsCheckbox.html'
        }
    });