angular.module(app.appName)
    .factory('navService', [
        '$location', 'routeService', 'generalStore',
        function ($location, routeService, generalStore) {
            var albumName = "";
            return {
                home: function (sittingPassword, emailAddress) {
                    var path = '/';
                    $location.path(path).search({
                        sittingPassword: sittingPassword,
                        email: emailAddress});
                },
                register: function() {
                    $location.path('/Register');
                },
            	navigate: function (path) {
            		routeService.setPreviousRoute($location.path());
                    $location.path($location.path() + path);
                },
                previewPicture: function (sittingId, albumId, pictureId) {
                    var url = '/Album/' + sittingId + '/' + albumId + '/' + pictureId + '/Preview';
                    generalStore.set('focusElement', pictureId);
                    $location.path(url);
                },
                previewFavourite: function(pictureId) {
                    var url = '/Favourites/' + pictureId + '/Preview';
                    generalStore.set('focusElement', pictureId);
                    $location.path(url);
                },
                album: function (sittingId, albumId, albumDisplayName) {
                    if (sittingId && albumId) {
                        this.setAlbumName(albumDisplayName);
                        $location.path('/Album/' + sittingId + '/' + albumId).search({});
                    } else {
                        $location.path('/Album/').search({});
                    }
                },
                favAlbum: function() {
                        $location.path('/Favourites/');
                },
                back: function () {
                    window.history.back();
                },
                premium: function (productId, folderId, imageId) {
                    if (imageId) {
                        $location.path('/Premium/' + productId + '/' + folderId + '/' + imageId);
                    } else {
                        $location.path('/Premium/' + productId + '/' + folderId + '/');
                    }
                },
                whole: function (sittingId, folderId, productId) {
                    $location.path('/Whole/' + sittingId + '/' + folderId + '/' + productId);
                },
                packs: function(sittingId, albumId, productId) {
                    if (sittingId && albumId) {
                        if (productId) {
                            $location.path('/Packs/' + sittingId + '/' + albumId + '/' + productId);
                        } else {
                            $location.path('/Packs/' + sittingId + '/' + albumId );
                        }
                    } else {
                        $location.path('/Packs/');
                    }
                },
                favPacks: function() {
                        $location.path('/Packs/Favs/');
                },
               pack: function (albumId, productId, imageId) {
                    var params = [productId, albumId, imageId];
                    var nParams = params.filter(function (f) { return f });
                    var joinedParams = nParams.join('/');

                    $location.path('/Pack/' + joinedParams);
               },
               getAlbumName: function () {
                    return albumName;
                },
                setAlbumName: function (currentAlbumName) {
                    albumName = currentAlbumName;
                }
            }
        }
    ]);

