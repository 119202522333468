angular.module(app.appName)
	.directive('cookieMessage',
		function () {
			var controller = [
				'$scope',
				function ($scope) {

				    $scope.showCookieMessage = true;

					$scope.dismissCookieMessage = function() {
						$scope.showCookieMessage = false;
					}
				}
			];

			return {
				controller: controller,
				templateUrl: '/assets/html/directives/cookieMessage.html'
			}
		}
	);