angular.module(app.appName)
	.filter('orderObjectBy', ['_', function (_) {
	return function (items, field, reverse) {
		var filtered = [];
		for (var i in items) {
			filtered.push(items[i]);
		};
		_.sortBy(filtered, function (a, b) {
			return (a[field] > b[field] ? 1 : -1);
		});
		if (reverse) filtered.reverse();
		return filtered;
	};
}]);