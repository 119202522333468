angular.module(app.appName)
	.directive('modalWhole', function () {
		var controller = [
			'$q', '$scope', 'generalStore', 'modalService', 'sittingStore', 'helpers', 'itemService', 'itemStore', '$rootScope', '$routeParams', 'pipService', 'myPicturesService', 'routeService', 'modalTypes',
			function ($q, $scope, generalStore, modalService, sittingStore, helpers, itemService, itemStore, $rootScope, $routeParams, pipService, myPicturesService, routeService, modalTypes) {
				var self = this;
				self.errorPrefix = 'modalWhole.js Error :: ';
				self.product = $scope.modalBase.data;
				self.productItem = {};
				self.productItem.images = [];
				$scope.amountOfPicturesRequired = 1;
				$scope.showLightbox = false;

				$scope.getPrettyEventDate = function (date) {
					return helpers.prettifyDate(helpers.trimDate(date));
				}

				$scope.modalBase.showContinueSpinner = function () {
					return $scope.basketDisable;
				}
				$scope.modalBase.showContinueAction = function () {
					return false;
				}

				self.getImageOnProduct = function () {

					// Returns a reference to the image with the current position on the actual public array we are working from.
					return _.findWhere(itemStore.publicProductItem.images, { position: (itemStore.publicProductItem.positionId) });
				}

				$scope.modalBase.continueAction = function (picture) {

					if (!$scope.enabled) {
						return;
					}

					/* Picture won't be selected so take first image from selected
					sitting. */

					picture = $scope.sittingImage; // Can I get the head image?

					// - Get image by positionId from productItem.images
					var image = self.getImageOnProduct();
					// - set imgIndex = picture.id
					image.imgIndex = picture.id;
					// - set previewUrl = picture.preview.url
					image.previewUrl = picture.preview.url;

					image.isPlaceholder = false;

					// Tells the server to generate a PiP for the product preview
					itemStore.publicProductItem.preview = true;

					picture.isSelected = true;
					//THIS CODE HERE SHOULD DETERMINE THE HEIGHT / WIDTH of the PLACEHOLDER
					//assume placeholder of 500x500

					pipService.setDimensions(itemStore.publicProductItem.images, itemStore.publicProductItem);


					// Post our productItem to keep it up-to-date server-side
					itemService.post(itemStore.publicProductItem).$promise.then(function (savedItem) {
						// We can't just overwrite the entire object as there are some properties that we don't want to overwrite,
						// such as Options, description etc.

						itemStore.publicProductItem.sittingPassword = savedItem.sittingPassword;
						itemStore.publicProductItem.price = savedItem.price;
						itemStore.publicProductItem.finalPrice = savedItem.finalPrice;
						itemStore.publicProductItem.pip = savedItem.pip;

						$rootScope.$broadcast('rendering');
						$scope.modalBase.closeModal();
					});
				}

				$scope.modalBase.backMessageKey = 'BUTTON_CLOSE_PICTURE_PICKER';
				$scope.modalBase.backIcon = 'fa-close';

				if ($scope.modalBase.isMobile) {
					// If we're on mobile we want to override the modalBase.backAction function to go back to the products modal.
					$scope.modalBase.backAction = function () {
						// var cat = generalStore.get('currentProductCategory').value;
						$scope.modalBase.showModal(modalTypes.Whole, null, true, generalStore.get('currentProductCategory').value.name);
					}
				}

				self.updateModalData = function (productItem) {
					self.productItem = productItem;
				}

				// Updates the HTML for the header
				self.updateHeader = function () {
					$scope.modalBase.headerTitle = '';
				}

				/* Pass null to sittingId and folderId to load favourites */
				$scope.loadAlbumPicturesSync = function (sittingId, folderId, productId, favourites, folderImageId) {
					var deferred = $q.defer();

					modalService
						.loadAlbumPictures(sittingId, folderId, productId, favourites)
						.then(function (data) {
							$scope.sitting = data;
							$scope.sittingImage = data.none.images.find(function (image) { return image.id == folderImageId; } );

							/* Check exclusions */
							$scope.enabled = data.none.images.filter(function (image) { return data.exclusions.indexOf(image.id) == -1; }).length > 0;

							deferred.resolve($scope.sitting);
						});

					return deferred.promise;
				};

				self.updateData = function (data) {

					data = data || {};

					self.product = data.product;
					self.route = data.route;

					$scope.currentAlbumId = data.route.albumId;

					if (self.product) {
						// Attempts to grab the item from the itemStore.
						itemService.get(self.product.id, self.productItem.id, null).then(function (newItem) {
							// Update our modal data with a copy of the new item so we don't modify the reference in the store by accident.

							//This is not the correct object.
							//self.updateModalData(angular.copy(newItem));
							//THIS is -- ME
							self.updateModalData(itemStore.publicProductItem);

							//IF WE ENABLE THIS THEN WE CAN NO LONGER SELECT ANY PICTURES IF NO INITIAL picture is not selected -- ME might cause collateral bugs
							//self.productItem.images = [];
						});
					}
				};

				$scope.close = function () {
					$scope.modalBase.closeModal();
				}

				// We're watching the data on the modalBase because we need to update our child modal $scope objects
				// if we have a change of data. This is to ensure we don't get any data inconsistencies.
				$scope.$watch('modalBase.data', function (newData, oldData) {

					if ($scope.sitting && $scope.sitting.none.images) {
						for (var i = 0; i < $scope.sitting.none.images.length; i++) {
							self.removePictureFromProduct($scope.sitting.none.images[i]);
						}
					}

					self.updateData(newData);
				});
				self.updateData($scope.modalBase.data);

				self.init = function () {
					self.updateHeader();
				}
				self.init();

				self.initCallback = function () {

					console.info('[modalWhole.initCallback] Stored images for display, ', $scope.sitting.none.images);

					if ($scope.sitting.none.images) {
						// We are viewing an individual album
						sittingStore.saveProductAlbum(self.product.id, $scope.sitting);
					}
					else if ($scope.sitting.none.folders && $scope.sitting.none.folders.years.length > 0) {
						// We are viewing all albums for the sitting
						sittingStore.saveProductSitting($scope.sitting);
					}

					$scope.loadAll = false;
					$scope.isLoading = false;
				}

				$rootScope.$on('currentFolder', function (e, args) {

					$scope.loadAlbumPicturesSync(
						args.sittingId,
						args.id,
						self.product.id,
						args.isFavourite,
						args.preview.id
					);
				});
			}
		];
		return {
			restrict: 'E',
			// 'scope: true' tells this child directive to create its own scope usable within the current directive.
			// What it doesn't do is isolate the scope, which would mean we couldn't access our parent directive's
			// modalBase object.
			scope: true,
			controller: controller,
			templateUrl: '/assets/html/directives/modal/modalWhole.html'
		}
	});